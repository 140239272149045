import { Button, message } from "antd";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { retrieveData, retrieveDeviceData, storeData } from "../../services/storage/Storage";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { maskEmail, maskMobileNumber } from "../../hooks/commonHooks";
import { UserErrorMessages } from "../../const/UserMessages";
import UserLoginTheme from "../../pages/user-authentication/LoginTheme/LoginTheme";
import { navigateToLogin } from "./ValidateOtpHelper";
import { ErrorMessages } from "../../const/Messages";
import { useTranslation } from "react-i18next";

function ValidateOtp() {
  const { t } = useTranslation();
  const verifyPayload = useSelector((state: any) => state?.forgotUsernameMobileNumber?.verifyPayload);
  const fromUserName = useSelector((state: any) => state?.forgotUsernameMobileNumber?.fromUserName);
  const sendChampVerificationReference = useSelector((state: any) => state?.forgotUsernameMobileNumber?.referenceNumber);
  const mailType = useSelector((state: any) => state?.userDetails?.setPassword);

  const storeUserDetails: any = retrieveData("userDetails", true);
  const realmName = retrieveData("realmName", true);
  const realmId = retrieveData("realmId", true);
  const userName = retrieveData("userName", true);
  const fromSetPassword = retrieveData("fromSetPassword", true);
  const getUserNameApi = useGetApiRequests("getUserName", "POST");
  const verifyForgetPasswordOtpApi = useGetApiRequests("validateForgetPasswordOtp", "POST");
  const sendOtpApi = useGetApiRequests("sendOtp", "POST");
  const forgotUserNameApi = useGetApiRequests("forgotUserName", "POST");
  const deviceUrl = retrieveDeviceData("device");
  const navigate = useNavigate();
  const [nonFormikError, setNonFormikError] = useState<any>(null);
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(30);
  const [showButton, setShowButton] = useState(false);
  const [responseName, setResponseName] = useState("");
  const [backToLogin, setBackToLogin] = useState(false);
  const [userMobileNumber, setUserMobileNumber] = useState<string | null>(null);
  const [userEmailID, setUserEmailID] = useState<string | null>(null);
  const [disableResendCode, setDisableResendCode] = useState(false);
  const [loader, setLoader] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState<any>(null);
  const [resendOtpClicked, setResendOtpClicked] = useState<boolean>(false);

  let countdown: NodeJS.Timeout;
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: "success",
      content: "Copied!",
    });
  };

  const handleCopyClick = async () => {
    const textToCopy = responseName;

    try {
      await navigator.clipboard.writeText(textToCopy);
      success();
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const checkAccountIsBlocked = (message: any) => {
    if ((message !== "" && message.includes("account is disabled")) || message.includes("due to multiple failed OTP attempts")) {
      setDisableResendCode(true);
    } else {
      setDisableResendCode(false);
    }
  };

  function handleErrorMessage(error: any): string {
    if (error?.response?.data?.message.includes("Invalid")) {
      return t("header.invalidOtp");
    }
    return error?.response?.data?.message;
  }
  function isClientError(status: number): boolean {
    return status === 409 || status === 404 || status === 400;
  }
  function handleErrorResponse(err: any) {
    const status = err.response.status;
    if (isClientError(status)) {
      const message = handleErrorMessage(err);
      setNonFormikError(message);
      checkAccountIsBlocked(message);
    } else {
      setNonFormikError(ErrorMessages?.unexpectedError);
    }
  }

  const sendOtp = () => {
    setResendOtpClicked(true);
    setTimer(30);
    setShowButton(false);
    setNonFormikError(null);
    setLoader(true);
    let payloadNew = null;
    if (verifyPayload?.isActive === "phone") {
      payloadNew = { phoneNumber: verifyPayload.phoneNumber, realmName: realmName, mailType: mailType, realmId };
      requestToApi(payloadNew);
    } else if (verifyPayload?.isActive === "email") {
      payloadNew = { personalEmailId: verifyPayload.userName, realmName: realmName, mailType: mailType };
      forgotUserNameApi(payloadNew)
        .then((response: any) => {
          setLoader(false);
        })
        .catch((err: any) => {
          setLoader(false);
          handleErrorResponse(err);
        });
    } else {
      payloadNew = { phoneNumber: userMobileNumber, realmName: realmName, mailType: mailType, realmId };
      requestToApi(payloadNew);
    }
  };

  const requestToApi = (payloadNew: any) => {
    setLoader(true);
    sendOtpApi(payloadNew)
      .then((response: any) => {
        const referenceNumberForNigeria = response?.data?.data?.reference;
        setReferenceNumber(referenceNumberForNigeria);
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        handleErrorResponse(err);
      });
  };

  useEffect(() => {
    if (verifyPayload?.isActive === "phone" || verifyPayload?.isActive === "email") {
      setUserMobileNumber(verifyPayload.phoneNumber);
      setUserEmailID(verifyPayload.userName);
    } else {
      init();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const init = () => {
    if (storeUserDetails?.data?.userAttributeKeyValueRepresentation) {
      // eslint-disable-next-line array-callback-return
      storeUserDetails.data.userAttributeKeyValueRepresentation.map((item: any) => {
        if (item.name === "telephoneNumber") {
          setUserMobileNumber(item.value);
        }
        if (item.name === "personal_email_id") {
          setUserEmailID(item.value);
        }
      });
    }
  };

  useEffect(() => {
    if (timer === 0) {
      setShowButton(true);
      clearInterval(countdown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  const submitVerifyBtn = () => {
    if (fromSetPassword) {
      verifyOtp(otp);
    } else {
      forgetPasswordOTPVerify(otp);
    }
  };
  const verifyOtp = (otp: any) => {
    setLoader(true);
    let payload: any = {
      otp: otp,
      realmName: realmName,
      realmId,
    };
    if (verifyPayload?.isActive === "email") {
      payload = {
        ...payload,
        recoveryEmailId: userEmailID,
      };
    } else {
      payload = {
        ...payload,
        phoneNumber: userMobileNumber ?? sessionStorage.mobileNumber,
      };
    }
    if (sendChampVerificationReference) {
      payload.sendChampVerificationReference = resendOtpClicked ? referenceNumber : sendChampVerificationReference;
    }

    getUserNameApi(payload)
      .then((apiResponse: any) => {
        setLoader(false);
        if (apiResponse?.status === 200) {
          setResponseName(apiResponse?.data?.data);
          if (!fromUserName) {
            storeData("otp", otp, true);
            navigate("/set-password");
          }
          setBackToLogin(true);
        } else if (apiResponse?.data?.message === "Invalid OTP") {
          setNonFormikError(apiResponse?.data?.message);
        }
      })
      .catch((error: any) => {
        setOtp("");
        setLoader(false);
        handleErrorResponse(error);
      });
  };

  const forgetPasswordOTPVerify = (otp: any) => {
    setLoader(true);
    let payload: any = {
      otp: otp,
      realmName: realmName,
      userName: userName,
      recoveryAttempt: !fromSetPassword,
      realmId,
    };
    if (verifyPayload?.isActive === "email") {
      payload = {
        ...payload,
        recoveryEmailId: userEmailID,
      };
    } else {
      payload = {
        ...payload,
        phoneNumber: userMobileNumber ?? sessionStorage.mobileNumber,
      };
    }
    if (sendChampVerificationReference) {
      payload.sendChampVerificationReference = resendOtpClicked ? referenceNumber : sendChampVerificationReference;
    }
    verifyForgetPasswordOtpApi(payload)
      .then((response: any) => {
        setLoader(false);
        if (response.status === 200) {
          setResponseName(response?.data?.data);
          if (!fromUserName) {
            storeData("otp", otp, true);
            navigate("/set-password");
          }
          setBackToLogin(true);
        } else if (response?.data?.message === "Invalid OTP") {
          setNonFormikError(response?.data?.message);
        }
      })
      .catch((err: any) => {
        setOtp("");
        setLoader(false);
        handleErrorResponse(err);
      });
  };

  const handleOtpChange = async (value: string) => {
    setNonFormikError(null);
    setOtp(value);
  };
  const getResendContent = () => {
    if (showButton && !backToLogin && !disableResendCode) {
      return (
        <p className="reSend primary-color hoverUnderLine" onClick={sendOtp} role="none">
          {t("header.resendOtp")}
        </p>
      );
    } else if (!showButton && !backToLogin && !disableResendCode) {
      return (
        <p className="reSend">
          {t("header.resendCode")}: {timer} {t("header.seconds")}
        </p>
      );
    } else {
      return null;
    }
  };
  return (
    <div className="w-full mobileSecurity mb-12">
      {contextHolder}
      <div className="mobileSecurity-title">
        <div className="w-full flex justify-center">{/* <img src={phoneIcon} alt="authIcon" /> */}</div>
        <div className="secondary">
          <p className="text-center">
            {t("header.enterVerificationCode")}
            {verifyPayload?.isActive === `${t("common.enterVerificationCode")}`
              ? ` ${t("common.mobileNumber")} (${maskMobileNumber(verifyPayload?.phoneNumber)})`
              : ` ${t("common.email")} (${maskEmail(verifyPayload?.userName)})`}
          </p>
        </div>
      </div>
      <p className="mb-4 text-[#696969] text-center mt-5">{t("header.otpValidFor5MinutesOnly")}</p>

      <div className={`otpInput-form relative`}>
        {nonFormikError ? <div className="error  mt-1 mb-2 top-[-32px]">{nonFormikError}</div> : null}
        <OtpInput
          value={otp}
          onChange={handleOtpChange}
          numInputs={6}
          shouldAutoFocus={true}
          skipDefaultStyles={true}
          inputType="number"
          renderInput={(props: any) => <input {...props} disabled={disableResendCode} />}
        />
        {getResendContent()}
      </div>
      <div className="btn mobileSecurityBtn">
        {responseName !== "" && fromUserName && (
          <div className="copyClip flex items-center float-end">
            <span className="material-icons colorGreen mr-2">check_circle</span> {t("header.yourUsername")}: {responseName}
            <span className="material-icons-outlined content_copy ml-2 cursor-pointer" onClick={handleCopyClick}>
              content_copy
            </span>
          </div>
        )}

        {!backToLogin ? (
          <Button type="primary" className="bg-akku login-btn" disabled={otp.length !== 6} onClick={submitVerifyBtn} loading={loader}>
            {t("common.submit")}
          </Button>
        ) : (
          <Button type="primary" className="bg-akku login-btn" onClick={() => navigateToLogin(navigate, deviceUrl)}>
            {t("header.backToLogin")}
          </Button>
        )}
      </div>
    </div>
  );
}

export default UserLoginTheme(ValidateOtp);
