import React, { useMemo } from "react";
import { Field, FieldArray, ErrorMessage } from "formik";
import { Input, Select } from "antd";
import { getAllKeys } from "../../../helper/connectorHelper";
import { useTranslation } from "react-i18next";
const { TextArea } = Input;

interface RoleProvisionProps {
  index: number;
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  provisioning: any;
  provisionDetails: any;
  isKeyValueEmptyAssignRole: boolean;
  isKeyValueEmptyGetRole: boolean;
  samlConfig: any;
  formName: string;
  setGetRoleResponseAttributes?: any;
  setAssignRoleResponseAttributes?: any;
}

const RoleProvisionForm: React.FC<RoleProvisionProps> = ({
  index,
  values,
  setFieldValue,
  provisioning,
  provisionDetails,
  isKeyValueEmptyAssignRole,
  isKeyValueEmptyGetRole,
  samlConfig,
  formName,
  setGetRoleResponseAttributes,
  setAssignRoleResponseAttributes,
}) => {
  const { t } = useTranslation();
  useMemo(() => {
    const response = values?.getRole[0]?.response;

    if (response) {
      try {
        const parsedObject = JSON.parse(response);
        const flattenedKeys: any = getAllKeys(parsedObject) || [];
        setGetRoleResponseAttributes(flattenedKeys);
      } catch (error) {
        if (setGetRoleResponseAttributes) {
          setGetRoleResponseAttributes("");
        }
        console.error("Invalid JSON string:", error);
      }
    } else {
      if (setGetRoleResponseAttributes) {
        setGetRoleResponseAttributes("");
      }
    }
  }, [values?.getRole[0]?.response]);

  useMemo(() => {
    const response = values?.assignRole[0]?.response;

    if (response) {
      try {
        const parsedObject = JSON.parse(response);
        const flattenedKeys: any = getAllKeys(parsedObject) || [];
        setAssignRoleResponseAttributes(flattenedKeys);
      } catch (error) {
        if (setAssignRoleResponseAttributes) {
          setAssignRoleResponseAttributes("");
        }
        console.error("Invalid JSON string:", error);
      }
    } else {
      if (setAssignRoleResponseAttributes) {
        setAssignRoleResponseAttributes("");
      }
    }
  }, [values?.assignRole[0]?.response]);

  const shouldRenderGetRole = (): boolean | undefined => {
    if (formName === "getRole") {
      return provisioning.isActiveEdit && provisioning.endPointResponseDtosLen !== 0 ? provisionDetails?.getRole[0]?.headerParam && !isKeyValueEmptyGetRole : true;
    }
    return undefined;
  };
  const shouldRenderAssignRole = (): boolean | undefined => {
    if (formName === "assignRole") {
      return provisioning.isActiveEdit && provisioning.endPointResponseDtosLen !== 0 ? provisionDetails?.assignRole[0]?.headerParam && !isKeyValueEmptyAssignRole : true;
    }
    return undefined;
  };

  return (
    <>
      {/* Get Role */}
      {formName === "getRole" && (
        <div key={samlConfig} className=" w-[90%] justify-start gap-x-20 flex-wrap flex">
          <div className="mb-10 input-container 2xl:w-[42%]  w-[38%]">
            <p className="font-medium text-[#1D1D24] font-Inter flex text-[18px] items-center "> {t("appManagement.provisioning.apiEndpointUrl")}*</p>
            <Field id={"apiEndpointURL1"} as={Input} type="text" className="h-[56px]  text-[18px] font-medium form-type-box" name={`${formName}.${index}.apiEndpointURL`} />
            <ErrorMessage name={`${formName}.${index}.apiEndpointURL`} component="div" className=" custom-error error-message " />
          </div>
          <div className="mb-10 input-container 2xl:w-[42%]  w-[38%]">
            <p className="text-[#1D1D24] font-Inter text-[18px]  font-medium">{t("appManagement.provisioning.methodType")}*</p>

            <Field
              id="methodType1"
              name={`${formName}.${index}.methodType`}
              type="text"
              as={Select}
              className="h-[56px] loginThemeText font-medium text-[18px] w-[100%] "
              // value={`${formName}.${index}.methodType` || undefined}
              value={values[formName][index].methodType || undefined}
              filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value: string) => setFieldValue(`${formName}.${index}.methodType`, value)}
              placeholder={t("common.select")}
            >
              <Select.Option value="Get">{t("appManagement.provisioning.get")}</Select.Option>
              <Select.Option value="Post">{t("appManagement.provisioning.post")}</Select.Option>
            </Field>
            <ErrorMessage name={`${formName}.${index}.methodType`} component="div" className=" custom-error error-message " />
          </div>

          <div className="mb-10 input-container 2xl:w-[42%]  w-[38%]">
            <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">
              {t("appManagement.provisioning.requestPayload")} {values[formName][index].methodType === "Post" && "*"}
            </p>
            <Field id="requestPayload1" as={TextArea} type="text" className="min-h-[200px] font-medium text-[18px] form-type-box" name={`${formName}.${index}.requestPayload`} />
            <ErrorMessage name={`${formName}.${index}.requestPayload`} component="div" className="error-message custom-error" />
          </div>
          <div className="mb-10 input-container 2xl:w-[42%]  w-[38%]">
            <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">{t("appManagement.provisioning.response")}*</p>
            <Field
              id="response1"
              type="text"
              className="min-h-[200px] font-medium text-[18px] form-type-box"
              name={`${formName}.${index}.response`}
              as={TextArea}
              onChange={(e: any) => {
                setFieldValue(`${formName}.${index}.response`, e.target.value);
                setFieldValue(`${formName}.${index}.serviceProviderAttributesDto`, { id: "", name: "" });
              }}
            />
            <ErrorMessage name={`${formName}.${index}.response`} className="error-message custom-error" component="div" />
          </div>

          <div className="mb-10 input-container 2xl:w-[94%]  w-[100%]">
            <p className="font-medium text-[#1D1D24] font-Inter flex text-[20px] items-center ">{t("appManagement.provisioning.responseAttributesMapping")}</p>
            <div className="flex justify-between  w-[100%] mt-5">
              <div className=" 2xl:w-[45%]  w-[38%]">
                <p className="font-medium text-[#1D1D24] font-Inter flex text-[18px] items-center ">{t("appManagement.provisioning.id")}</p>
                <Field name={`${formName}.${index}.serviceProviderAttributesDto.id`} as={Input} type="text" className="h-[56px]  text-[18px] font-medium form-type-box" />
                <ErrorMessage name={`${formName}.${index}.serviceProviderAttributesDto.id`} component="div" className="custom-error error-message" />
              </div>
              <div className=" 2xl:w-[46.5%]  w-[38%]">
                <p className="font-medium text-[#1D1D24] font-Inter flex text-[18px] items-center ">{t("common.name")}</p>
                <Field name={`${formName}.${index}.serviceProviderAttributesDto.name`} as={Input} type="text" className="h-[56px]  text-[18px] font-medium form-type-box" />
                <ErrorMessage name={`${formName}.${index}.serviceProviderAttributesDto.name`} component="div" className="custom-error error-message" />
              </div>
            </div>
          </div>

          {/* PrimarykeyAttributes start*/}
          {/* <div className="w-[94%] header-parameter-wrapper">
            <FieldArray name={`${formName}.${index}.primaryKeyAttributeList`}>
              {({ push, remove, form }) => (
                <div className="w-full header-parameter-wrapper">
                  {form.values[formName]?.[index]?.primaryKeyAttributeList?.map((param: string, paramIndex: number) => (
                    <div key={paramIndex} className="w-[50%] relative flex justify-start gap-x-20">
                      <div className="mt-4 header-param w-[90%] input-container">
                        <p className={`${paramIndex === 0 ? "text-[#1D1D24] flex text-[18px] font-medium items-center font-Inter" : "opacity-0"}`}>Response Attributes Mapping</p>
                        <Field
                          id={`key-${paramIndex}`}
                          as={Input}
                          type="text"
                          className="h-[56px] font-medium text-[18px] form-type-box"
                          name={`${formName}.${index}.primaryKeyAttributeList.${paramIndex}`}
                          placeholder="Key"
                        />
                        <ErrorMessage name={`${formName}.${index}.primaryKeyAttributeList.${paramIndex}`} component="div" className="error-message custom-error" />
                      </div>
                      <div className="absolute provision-action !right-[-70px]">
                        {form.values[formName][index].primaryKeyAttributeList.length > 1 && (
                          <button type="button" onClick={() => remove(paramIndex)} className="remove-btn" data-testid="remove-btn">
                            <span className="material-symbols-outlined provision-plus-btn">remove</span>
                          </button>
                        )}
                        {paramIndex === form.values[formName][index].primaryKeyAttributeList.length - 1 && (
                          <button type="button" onClick={() => push("")} data-testid="add-btn">
                            <span className="material-symbols-outlined provision-plus-btn">add</span>
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>
          </div> */}
          {/* PrimarykeyAttributes end*/}

          {/* {shouldRenderGetRole() && ( */}
          <FieldArray name={`${formName}.${index}.headerParam`}>
            {({ push, remove }) => (
              <div className="w-[94%] header-parameter-wrapper">
                {samlConfig?.headerParam?.map((param: any, paramIndex: any) => (
                  <div key={param} className="w-full  relative flex justify-start gap-x-20  ">
                    <div className=" relative mt-4 header-param  2xl:w-[46%]  w-[38%] input-container">
                      <p className={`${paramIndex === 0 ? "text-[#1D1D24] flex text-[18px]  font-medium items-center font-Inter " : " opacity-0"}`}>
                        {t("appManagement.provisioning.requestParameters")}
                      </p>
                      <Field
                        id="key1"
                        as={Input}
                        type="text"
                        className="h-[56px] font-medium text-[18px] form-type-box"
                        name={`${formName}.${index}.headerParam.${paramIndex}.key`}
                        placeholder={t("appManagement.provisioning.key")}
                      />
                      <ErrorMessage name={`${formName}.${index}.headerParam.${paramIndex}.key`} component="div" className="error-message custom-error" />
                    </div>
                    <div className=" relative mt-4 header-param  2xl:w-[46%]  w-[38%] input-container">
                      <p className={`${paramIndex === 0 ? "text-[#1D1D24] font-Inter flex text-[18px] items-center  font-medium" : " opacity-0"}`}>&nbsp;</p>
                      <Field
                        id="value1"
                        placeholder={t("appManagement.provisioning.value")}
                        as={Input}
                        type="text"
                        className="h-[56px] font-medium text-[18px] form-type-box"
                        name={`${formName}.${index}.headerParam.${paramIndex}.value`}
                      />
                      <ErrorMessage name={`${formName}.${index}.headerParam.${paramIndex}.value`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="absolute provision-action right-[-100px]">
                      {samlConfig.headerParam.length > 1 && (
                        <button type="button" onClick={() => remove(paramIndex)} className=" remove-btn">
                          <span className="material-symbols-outlined provision-plus-btn ">remove</span>
                        </button>
                      )}
                      {paramIndex === samlConfig.headerParam.length - 1 && (
                        <button type="button" onClick={() => push({ key: "", value: "" })} data-testid="add-btn">
                          <span className="material-symbols-outlined provision-plus-btn">add</span>
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
          {/* )} */}
          <FieldArray name={`${formName}.${index}.pathVariable`}>
            {({ push, remove }) => (
              <div className="w-[94%] header-parameter-wrapper">
                {samlConfig?.pathVariable?.map((param: any, paramIndex: any) => (
                  <div key={param} className="w-full  relative flex justify-start gap-x-20  ">
                    <div className="relative mt-4 header-param  2xl:w-[46%]  w-[38%] input-container">
                      <p className={`${paramIndex === 0 ? "text-[#1D1D24] flex text-[18px]  font-medium items-center font-Inter " : " opacity-0"}`}> {t("appManagement.provisioning.pathVariable")}</p>
                      <Field
                        id="key1"
                        as={Input}
                        type="text"
                        className="h-[56px] font-medium text-[18px] form-type-box"
                        name={`${formName}.${index}.pathVariable.${paramIndex}.key`}
                        placeholder={t("appManagement.provisioning.key")}
                      />
                      <ErrorMessage name={`${formName}.${index}.pathVariable.${paramIndex}.key`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="relative mt-4 header-param  2xl:w-[46%]  w-[38%] input-container">
                      <p className={`${paramIndex === 0 ? "text-[#1D1D24] font-Inter flex text-[18px] items-center  font-medium" : " opacity-0"}`}>&nbsp;</p>
                      <Field
                        id="value1"
                        placeholder={t("appManagement.provisioning.value")}
                        as={Input}
                        type="text"
                        className="h-[56px] font-medium text-[18px] form-type-box"
                        name={`${formName}.${index}.pathVariable.${paramIndex}.value`}
                      />
                      <ErrorMessage name={`${formName}.${index}.pathVariable.${paramIndex}.value`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="absolute provision-action right-[-100px]">
                      {samlConfig.pathVariable.length > 1 && (
                        <button type="button" onClick={() => remove(paramIndex)} className=" remove-btn">
                          <span className="material-symbols-outlined provision-plus-btn">remove</span>
                        </button>
                      )}
                      {paramIndex === samlConfig.pathVariable.length - 1 && (
                        <button type="button" onClick={() => push({ key: "", value: "" })}>
                          <span className="material-symbols-outlined provision-plus-btn">add</span>
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
        </div>
      )}

      {/* Assign Role */}
      {formName === "assignRole" && (
        <div key={samlConfig} className="w-[90%] justify-start gap-x-20 flex-wrap flex">
          <div className="mb-10 input-container 2xl:w-[42%]  w-[38%]">
            <p className="font-medium text-[#1D1D24] font-Inter flex text-[18px] items-center ">{t("appManagement.provisioning.apiEndpointUrl")}*</p>
            <Field id="apiEndpointURL2" as={Input} type="text" className="h-[56px]  text-[18px] font-medium form-type-box" name={`${formName}.${index}.apiEndpointURL`} />
            <ErrorMessage name={`${formName}.${index}.apiEndpointURL`} component="div" className=" custom-error error-message " />
          </div>
          <div className="mb-10 input-container 2xl:w-[42%]  w-[38%]">
            <p className="text-[#1D1D24] font-Inter text-[18px]  font-medium">{t("appManagement.provisioning.methodType")}*</p>

            <Field
              id="methodType2"
              name={`${formName}.${index}.methodType`}
              type="text"
              as={Select}
              className="h-[56px] loginThemeText font-medium text-[18px] w-[100%] "
              // value={`${formName}.${index}.methodType` || undefined}
              value={values[formName][index].methodType || undefined}
              filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value: string) => setFieldValue(`${formName}.${index}.methodType`, value)}
              placeholder={t("common.select")}
            >
              <Select.Option value="Get">{t("appManagement.provisioning.get")}</Select.Option>
              <Select.Option value="Post">{t("appManagement.provisioning.post")}</Select.Option>
            </Field>
            <ErrorMessage name={`${formName}.${index}.methodType`} component="div" className=" custom-error error-message " />
          </div>

          <div className="mb-10 input-container 2xl:w-[42%]  w-[38%]">
            <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">
              {t("appManagement.provisioning.requestPayload")}
              {values[formName][index].methodType === "Post" && "*"}
            </p>
            <Field id="requestPayload2" as={TextArea} type="text" className="min-h-[200px] font-medium text-[18px] form-type-box" name={`${formName}.${index}.requestPayload`} />
            <ErrorMessage name={`${formName}.${index}.requestPayload`} component="div" className="error-message custom-error" />
          </div>
          <div className="mb-10 input-container 2xl:w-[42%]  w-[38%]">
            <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">{t("appManagement.provisioning.response")}*</p>
            <Field
              id="response2"
              type="text"
              className="min-h-[200px] font-medium text-[18px] form-type-box"
              name={`${formName}.${index}.response`}
              as={TextArea}
              onChange={(e: any) => {
                setFieldValue(`${formName}.${index}.response`, e.target.value);
                setFieldValue(`${formName}.${index}.primaryKeyAttributeList`, [""]);
              }}
            />
            <ErrorMessage name={`${formName}.${index}.response`} className="error-message custom-error" component="div" />
          </div>

          {/* Response Attributes Mapping */}
          {/* <div className="mb-10 input-container 2xl:w-[94%]  w-[100%]">
            <p className="font-medium text-[#1D1D24] font-Inter flex text-[20px] items-center ">Response Attributes Mapping</p>
            <div className="flex justify-between  w-[100%] mt-5">
              <div className=" 2xl:w-[45%]  w-[38%]">
                <p className="font-medium text-[#1D1D24] font-Inter flex text-[18px] items-center ">Id</p>
                <Field name={`${formName}.${index}.serviceProviderAttributesDto.id`} as={Input} type="text" className="h-[56px]  text-[18px] font-medium form-type-box" />
                <ErrorMessage name={`${formName}.${index}.serviceProviderAttributesDto.id`} component="div" className="custom-error error-message" />
              </div>
              <div className=" 2xl:w-[46.5%]  w-[38%]">
                <p className="font-medium text-[#1D1D24] font-Inter flex text-[18px] items-center ">Name</p>
                <Field name={`${formName}.${index}.serviceProviderAttributesDto.name`} as={Input} type="text" className="h-[56px]  text-[18px] font-medium form-type-box" />
                <ErrorMessage name={`${formName}.${index}.serviceProviderAttributesDto.name`} component="div" className="custom-error error-message" />
              </div>
            </div>
          </div> */}

          <div className="mb-10 w-full pl-7">
            <FieldArray name={`${formName}.${index}.primaryKeyAttributeList`}>
              {({ push, remove, form }) => (
                <div className="w-full header-parameter-wrapper">
                  {form.values[formName]?.[index]?.primaryKeyAttributeList?.map((param: string, paramIndex: number) => (
                    <div key={paramIndex} className="w-[50%] relative flex justify-start gap-x-20">
                      <div className="mt-4 header-param w-[84%] input-container">
                        <p className={`${paramIndex === 0 ? "text-[#1D1D24] flex text-[18px] font-medium items-center font-Inter" : "opacity-0"}`}>
                          {t("appManagement.provisioning.responseAttributesMapping")}
                        </p>
                        <Field
                          id={`key-${paramIndex}`}
                          as={Input}
                          type="text"
                          className="h-[56px] font-medium text-[18px] form-type-box"
                          name={`${formName}.${index}.primaryKeyAttributeList.${paramIndex}`}
                          placeholder="Key"
                        />
                        <ErrorMessage name={`${formName}.${index}.primaryKeyAttributeList.${paramIndex}`} component="div" className="error-message custom-error" />
                      </div>
                      <div className="absolute provision-action">
                        {form.values[formName][index].primaryKeyAttributeList.length > 1 && (
                          <button type="button" onClick={() => remove(paramIndex)} className="remove-btn" data-testid="remove-btn">
                            <span className="material-symbols-outlined provision-plus-btn">remove</span>
                          </button>
                        )}

                        {paramIndex === form.values[formName][index].primaryKeyAttributeList.length - 1 && (
                          <button
                            type="button"
                            onClick={() => {
                              if (form?.values[formName][index]?.primaryKeyAttributeList[paramIndex] !== "") {
                                push("");
                              }
                            }}
                            data-testid="add-btn"
                          >
                            <span className="material-symbols-outlined provision-plus-btn">add</span>
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>
          </div>

          {/* {shouldRenderAssignRole() && ( */}
          <FieldArray name={`${formName}.${index}.headerParam`}>
            {({ push, remove }) => (
              <div className="w-[94%] header-parameter-wrapper">
                {samlConfig?.headerParam?.map((param: any, paramIndex: any) => (
                  <div key={param} className="w-full  relative flex justify-start gap-x-20">
                    <div className="relative mt-4 header-param  2xl:w-[46%]  w-[38%] input-container">
                      <p className={`${paramIndex === 0 ? "text-[#1D1D24] flex text-[18px]  font-medium items-center font-Inter " : " opacity-0"}`}>
                        {t("appManagement.provisioning.requestParameters")}
                      </p>
                      <Field
                        id="key2"
                        as={Input}
                        type="text"
                        className="h-[56px] font-medium text-[18px] form-type-box"
                        name={`${formName}.${index}.headerParam.${paramIndex}.key`}
                        placeholder={t("appManagement.provisioning.key")}
                      />
                      <ErrorMessage name={`${formName}.${index}.headerParam.${paramIndex}.key`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="relative mt-4 header-param  2xl:w-[46%]  w-[38%] input-container">
                      <p className={`${paramIndex === 0 ? "text-[#1D1D24] font-Inter flex text-[18px] items-center  font-medium" : " opacity-0"}`}>&nbsp;</p>
                      <Field
                        id="value2"
                        placeholder={t("appManagement.provisioning.value")}
                        as={Input}
                        type="text"
                        className="h-[56px] font-medium text-[18px] form-type-box"
                        name={`${formName}.${index}.headerParam.${paramIndex}.value`}
                      />
                      <ErrorMessage name={`${formName}.${index}.headerParam.${paramIndex}.value`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="absolute provision-action right-[-100px]">
                      {samlConfig.headerParam.length > 1 && (
                        <button type="button" onClick={() => remove(paramIndex)} className=" remove-btn">
                          <span className="material-symbols-outlined provision-plus-btn ">remove</span>
                        </button>
                      )}
                      {paramIndex === samlConfig.headerParam.length - 1 && (
                        <button type="button" onClick={() => push({ key: "", value: "" })}>
                          <span className="material-symbols-outlined provision-plus-btn">add</span>
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
          {/* )} */}
          <FieldArray name={`${formName}.${index}.pathVariable`}>
            {({ push, remove }) => (
              <div className="w-[94%]  header-parameter-wrapper">
                {samlConfig?.pathVariable?.map((param: any, paramIndex: any) => (
                  <div key={param} className="w-full  relative flex justify-start gap-x-20  ">
                    <div className="relative mt-4 header-param  2xl:w-[46%]  w-[38%] input-container">
                      <p className={`${paramIndex === 0 ? "text-[#1D1D24] flex text-[18px]  font-medium items-center font-Inter " : " opacity-0"}`}>{t("appManagement.provisioning.pathVariable")}</p>
                      <Field
                        id="key2"
                        as={Input}
                        type="text"
                        className="h-[56px] font-medium text-[18px] form-type-box"
                        name={`${formName}.${index}.pathVariable.${paramIndex}.key`}
                        placeholder={t("appManagement.provisioning.key")}
                      />
                      <ErrorMessage name={`${formName}.${index}.pathVariable.${paramIndex}.key`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="relative mt-4 header-param  2xl:w-[46%]  w-[38%] input-container">
                      <p className={`${paramIndex === 0 ? "text-[#1D1D24] font-Inter flex text-[18px] items-center  font-medium" : " opacity-0"}`}>&nbsp;</p>
                      <Field
                        id="value2"
                        placeholder={t("appManagement.provisioning.value")}
                        as={Input}
                        type="text"
                        className="h-[56px] font-medium text-[18px] form-type-box"
                        name={`${formName}.${index}.pathVariable.${paramIndex}.value`}
                      />
                      <ErrorMessage name={`${formName}.${index}.pathVariable.${paramIndex}.value`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="absolute provision-action right-[-100px]">
                      {samlConfig.pathVariable.length > 1 && (
                        <button type="button" onClick={() => remove(paramIndex)} className=" remove-btn">
                          <span className="material-symbols-outlined provision-plus-btn">remove</span>
                        </button>
                      )}
                      {paramIndex === samlConfig.pathVariable.length - 1 && (
                        <button type="button" onClick={() => push({ key: "", value: "" })}>
                          <span className="material-symbols-outlined provision-plus-btn">add</span>
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
        </div>
      )}
    </>
  );
};

export default RoleProvisionForm;
