import { FieldArray, Form, Formik } from "formik";
import { Button, Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomButtonBack from "../../../../../../../layouts/component/CustomButtonBack";
import { ConnectorConfig, FormValues, UpdateParams } from "../../types/provisioningTypes";
import { useEffect, useState } from "react";
import {
  setAssignRoleProvisioningConnectorEndpointConfigId,
  setGetRoleProvisioningConnectorEndpointConfigId,
  setIsProvisioningApiCalled,
  setIsProvisioningApiError,
  setProvisioning,
  setProvisioningButtonStatus,
} from "../../../../../../../redux/slice/provisioning/ProvisioningSlice";
import { getRoleProvisionErrorEvaluatorScroll, roleEndPointDescriptionPayload, roleProvisionSchema, transformHeaderParameters, UseGetEndpointDescription } from "../../../helper/connectorHelper";
import useGetApiRequests from "../../../../../../../services/axios/useApiRequests";
import { retrieveData } from "../../../../../../../services/storage/Storage";
import { handleRequestError } from "../../../../../../../layouts/toast/ErrorNotificationMessage";
import RoleProvisionForm from "../form/RoleProvisionFormField";
import { useTranslation } from "react-i18next";

const RoleProvision = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setActiveKey, formRef } = props;
  const dispatch = useDispatch();
  const provisioningConnectorConfigure = useGetApiRequests("provisioningConnectorConfigure", "POST");
  const provisioningConnectorConfigureUpdate = useGetApiRequests("provisioningConnectorConfigureUpdate", "PUT");

  const formInitialValues = useSelector((state: any) => state?.provisioning?.provisioning);
  const buttonStatus = useSelector((state: any) => state?.provisioning?.duplicateProvisioningButtons);

  const provisioning = useSelector((state: any) => state?.provisioning);
  const provisionDetails = useSelector((state: any) => state?.provisioning?.provisioning);
  const isTenantAdmin = useSelector((state: any) => state?.ProfileDetailsSlice?.isTenantAdmin);
  const getRoleAkkuProvisioningConnectorEndpointConfigId = useSelector((state: any) => state.provisioning?.getRoleProvisioningConnectorEndpointConfigId);
  const assignRoleAkkuProvisioningConnectorEndpointConfigId = useSelector((state: any) => state.provisioning?.assignRoleProvisioningConnectorEndpointConfigId);
  const generalDetails = provisioning?.generalDetails;

  const endPointResponseDtosData = useSelector((state: any) => state?.provisioning?.endPointResponseDtosData);
  const isApiCalled = useSelector((state: any) => state?.provisioning?.isProvisioningApiCalled);
  const isApiError = useSelector((state: any) => state?.provisioning?.isProvisioningApiError);

  const isKeyValueEmptyGetRole = (provisionDetails?.getRole[0]?.headerParam as any[])?.some((data: any) => data?.key === "");
  const isKeyValueEmptyAssignRole = (provisionDetails?.assignRole[0]?.headerParam as any[])?.some((data: any) => data?.key === "");

  const realmId = retrieveData("realmId", true);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [subActiveKey, setSubActiveKey] = useState<string>("1");
  const [responseAttributes, setResponseAttributes] = useState<any>(null);
  useEffect(() => {
    // Revalidate the form on language change
    if (formRef.current) {
      formRef.current.validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);
  const [getRoleResponseAttributes, setGetRoleResponseAttributes] = useState<any>(null);
  const [assignRoleResponseAttributes, setAssignRoleResponseAttributes] = useState<any>(null);

  const handleSubmit = (values: FormValues) => {
    if (provisioning?.isDuplicate || isDirty || isApiError?.getRoleProvisionApiError || isApiError?.assignRoleProvisionApiError) {
      if (subActiveKey === "1") {
        getRolePayloadConnectorConfigure(values);
      } else {
        assigneRolePayloadConnectorConfigure(values);
      }
      dispatch(setProvisioning(values));
    } else if (subActiveKey === "1") {
      setSubActiveKey("2");
    } else {
      setActiveKey("3");
    }
  };

  // GetRole API Call Starts

  const getRolePayloadConnectorConfigure = async (values: FormValues) => {
    const params: UpdateParams = { akkuProvisioningConnectorId: provisioning.akkuProvisioningConnectorId };
    const roleGetPayload: any = createRoleGetPayload(values);
    const isCreated = UseGetEndpointDescription("role_list", endPointResponseDtosData);
    const isDuplicateUpdate = provisioning?.isDuplicate ? provisioning?.isDuplicate && isApiCalled?.getRoleProvisionApiCalled : true; // for duplicate
    const shouldUpdate = isDuplicateUpdate && ((provisioning?.isActiveEdit && isCreated) || isApiCalled?.getRoleProvisionApiCalled);
    if (shouldUpdate) {
      roleGetPayload.akkuProvisioningConnectorEndpointConfigId = getRoleAkkuProvisioningConnectorEndpointConfigId || formInitialValues?.getRole[0]?.akkuProvisioningConnectorEndpointConfigId;
      roleGetPayload.isActive = provisioning.isActive;
      roleGetPayload.isTenantAdmin = isTenantAdmin;
      await handleGetRoleApiCall(provisioningConnectorConfigureUpdate, roleGetPayload, params);
    } else {
      roleGetPayload.isTenantAdmin = isTenantAdmin;
      handleGetRoleApiCall(provisioningConnectorConfigure, roleGetPayload);
    }
  };

  const handleGetRoleApiCall = async (apiCall: (payload: ConnectorConfig, config?: any, params?: UpdateParams) => Promise<any>, payload: ConnectorConfig, params?: UpdateParams) => {
    try {
      const response = await apiCall(payload, {}, params);
      if (response?.status === 200) {
        if (provisioning?.isDuplicate) {
          dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorGetRoleProvisioning: false, connectorAssignRoleProvisioning: true }));
        }

        if (!provisioning.isActiveEdit || provisioning.isDuplicate) {
          const provisioningConnectorEndpointConfigId = response?.data?.data?.akkuProvisioningConnectorEndpointConfigId;
          dispatch(setGetRoleProvisioningConnectorEndpointConfigId(provisioningConnectorEndpointConfigId));
        }
        dispatch(setIsProvisioningApiCalled({ ...isApiCalled, getRoleProvisionApiCalled: true }));
        setSubActiveKey("2");
      }
    } catch (err) {
      dispatch(setIsProvisioningApiError({ ...isApiError, getRoleProvisionApiError: true }));
      handleRequestError(err);
    }
  };

  const createRoleGetPayload = (values: FormValues): ConnectorConfig => {
    const { getRole } = values;
    const baseConfig = {
      apiEndpointUrl: getRole[0]?.apiEndpointURL,
      methodType: getRole[0]?.methodType,
      endpointDescription: roleEndPointDescriptionPayload(getRole[0]?.methodType),
      requestPayload: getRole[0]?.requestPayload || null,
      sampleResponse: getRole[0]?.response,
      primaryKeyAttributeList: [""],
      serviceProviderAttributesDto: getRole[0]?.serviceProviderAttributesDto?.id && getRole[0]?.serviceProviderAttributesDto?.name ? getRole[0]?.serviceProviderAttributesDto : null,
      headerParameters: transformHeaderParameters(getRole[0]?.headerParam),
      pathVariable: transformHeaderParameters(getRole[0]?.pathVariable),
      realmId: realmId,
      name: generalDetails?.name,
      type: "request",
      endpointType: "GENERAL",
      isRoleProvisioningConfig: true,
    };

    if (provisioning?.isActiveEdit) {
      return {
        ...baseConfig,
        akkuProvisioningConnectorEndpointConfigId: formInitialValues?.getRole[0]?.akkuProvisioningConnectorEndpointConfigId,
      };
    }

    return baseConfig;
  };

  // AssignRole API Call Starts
  const assigneRolePayloadConnectorConfigure = async (values: FormValues) => {
    const params: UpdateParams = { akkuProvisioningConnectorId: provisioning.akkuProvisioningConnectorId };
    const roleAssignPayload: any = createRoleAssignPayload(values);
    const isCreated = UseGetEndpointDescription("assign_role", endPointResponseDtosData);
    const isDuplicateUpdate = provisioning?.isDuplicate ? provisioning?.isDuplicate && isApiCalled?.assignRoleProvisionApiCalled : true; // for duplicate
    const shouldUpdate = isDuplicateUpdate && ((provisioning.isActiveEdit && isCreated) || isApiCalled?.assignRoleProvisionApiCalled);
    if (shouldUpdate) {
      roleAssignPayload.akkuProvisioningConnectorEndpointConfigId = assignRoleAkkuProvisioningConnectorEndpointConfigId || formInitialValues?.assignRole[0]?.akkuProvisioningConnectorEndpointConfigId;
      roleAssignPayload.isActive = provisioning.isActive;
      roleAssignPayload.isTenantAdmin = isTenantAdmin;
      await handleAssignRoleApiCall(provisioningConnectorConfigureUpdate, roleAssignPayload, params);
    } else {
      roleAssignPayload.isTenantAdmin = isTenantAdmin;
      await handleAssignRoleApiCall(provisioningConnectorConfigure, roleAssignPayload);
    }
  };

  const handleAssignRoleApiCall = async (apiCall: (payload: ConnectorConfig, config?: any, params?: UpdateParams) => Promise<any>, payload: ConnectorConfig, params?: UpdateParams) => {
    try {
      const response = await apiCall(payload, {}, params);
      if (response.status === 200) {
        if (provisioning?.isDuplicate) {
          dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorAssignRoleProvisioning: false, connectorGetGroupProvisioning: true }));
        }

        if (!provisioning.isActiveEdit || provisioning.isDuplicate) {
          const provisioningConnectorEndpointConfigId = response?.data?.data?.akkuProvisioningConnectorEndpointConfigId;
          dispatch(setAssignRoleProvisioningConnectorEndpointConfigId(provisioningConnectorEndpointConfigId));
        }
        dispatch(setIsProvisioningApiCalled({ ...isApiCalled, assignRoleProvisionApiCalled: true, getRoleProvisionApiCalled: true })); // If ApiCalled set is true
        setActiveKey("3");
      }
    } catch (err) {
      dispatch(setIsProvisioningApiError({ ...isApiError, assignRoleProvisionApiError: true }));
      handleRequestError(err);
    }
  };

  const createRoleAssignPayload = (values: FormValues): ConnectorConfig => {
    const { assignRole } = values;
    const baseConfig = {
      apiEndpointUrl: assignRole[0]?.apiEndpointURL,
      methodType: assignRole[0]?.methodType,
      endpointDescription: roleEndPointDescriptionPayload(assignRole[0]?.methodType),
      requestPayload: assignRole[0]?.requestPayload || null,
      sampleResponse: assignRole[0]?.response,
      primaryKeyAttributeList: assignRole[0]?.primaryKeyAttributeList,
      serviceProviderAttributesDto: null,
      headerParameters: transformHeaderParameters(assignRole[0]?.headerParam),
      pathVariable: transformHeaderParameters(assignRole[0]?.pathVariable),
      realmId: realmId,
      name: generalDetails?.name,
      type: "request",
      endpointType: "PROVISIONING",
      isRoleProvisioningConfig: true,
    };

    if (provisioning?.isActiveEdit) {
      return {
        ...baseConfig,
        akkuProvisioningConnectorEndpointConfigId: formInitialValues?.assignRole[0]?.akkuProvisioningConnectorEndpointConfigId,
      };
    }
    return baseConfig;
  };

  const handleBack = () => {
    if (subActiveKey === "2") {
      setSubActiveKey("1");
    } else {
      setActiveKey("1");
    }
  };

  const handleSkip = () => {
    if (subActiveKey === "1") {
      setSubActiveKey("2");
    } else {
      setActiveKey("3");
    }
  };

  const handleCollapseChange = async (key: string | string[]) => {
    const activePanelKey = key[0]; // Directly access the first element of the array

    if (subActiveKey === "1") {
      if (activePanelKey === "2") {
        if (!isApiCalled?.getRoleProvisionApiCalled) {
          await formRef.current?.submitForm();
        } else if (formRef.current?.dirty) {
          await formRef.current?.submitForm();
        } else {
          setSubActiveKey("2");
        }
      }
    }

    if (subActiveKey === "2") {
      if (activePanelKey === "1") {
        setSubActiveKey("1");
      }
    }
  };

  return (
    <Formik
      initialValues={formInitialValues}
      values={formInitialValues}
      onSubmit={(value: any) => handleSubmit(value)}
      validationSchema={roleProvisionSchema(t, subActiveKey, getRoleResponseAttributes, assignRoleResponseAttributes)}
      enableReinitialize={true}
      innerRef={formRef}
    >
      {({ values, setFieldValue, dirty, isSubmitting, errors }) => {
        setIsDirty(dirty);
        if (isSubmitting) {
          const roleErrors: any = errors.getRole;
          if (roleErrors && roleErrors.length > 0) {
            getRoleProvisionErrorEvaluatorScroll(roleErrors[0], "1");
          } else {
            const assignErrors: any = errors.assignRole;
            if (assignErrors && assignErrors.length > 0) {
              getRoleProvisionErrorEvaluatorScroll(assignErrors[0], "2");
            }
          }
        }
        return (
          <Form>
            <div className=" w-full flex flex-wrap flex-col gap-2 justify-center items-center">
              <div className="w-[95%]">
                <Collapse
                  expandIconPosition="end"
                  className="w-[100%]  custom-header-provision bg-[#fff]"
                  accordion
                  activeKey={subActiveKey}
                  onChange={(el: any) => handleCollapseChange(el)}
                  items={[
                    {
                      key: "1",
                      label: <p className="font-normal text-[#000] font-Inter text-[18px]">{t("appManagement.provisioning.getRole")}</p>,
                      children: (
                        <FieldArray name="getRole">
                          {() => (
                            <>
                              {values?.getRole?.length !== 0 &&
                                values?.getRole?.map((getRoleConfig: any, index: any) => (
                                  <RoleProvisionForm
                                    key={index}
                                    index={index}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    provisioning={provisioning}
                                    provisionDetails={provisionDetails}
                                    isKeyValueEmptyGetRole={isKeyValueEmptyGetRole}
                                    isKeyValueEmptyAssignRole={isKeyValueEmptyAssignRole}
                                    samlConfig={getRoleConfig}
                                    formName="getRole"
                                    setGetRoleResponseAttributes={setGetRoleResponseAttributes}
                                  />
                                ))}
                            </>
                          )}
                        </FieldArray>
                      ),
                    },
                    {
                      key: "2",
                      label: <p className="font-normal text-[#000] font-Inter text-[18px]">{t("appManagement.provisioning.assignRole")}</p>,
                      children: (
                        <FieldArray name="assignRole">
                          {() => (
                            <>
                              {values?.assignRole?.length !== 0 &&
                                values?.assignRole?.map((assignRoleConfig: any, index: any) => (
                                  <RoleProvisionForm
                                    key={index}
                                    index={index}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    provisioning={provisioning}
                                    provisionDetails={provisionDetails}
                                    isKeyValueEmptyGetRole={isKeyValueEmptyGetRole}
                                    isKeyValueEmptyAssignRole={isKeyValueEmptyAssignRole}
                                    samlConfig={assignRoleConfig}
                                    formName="assignRole"
                                    setAssignRoleResponseAttributes={setAssignRoleResponseAttributes}
                                  />
                                ))}
                            </>
                          )}
                        </FieldArray>
                      ),
                    },
                  ]}
                />
              </div>
            </div>

            <div className="footer flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
              <div className="modal-footer w-full mx-auto ">
                <div className="w-full flex justify-end pr-5">
                  <CustomButtonBack text={t("common.skip")} onClick={() => handleSkip()} />
                  <CustomButtonBack text={t("common.back")} onClick={() => handleBack()} />
                  <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                    {t("common.next")}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RoleProvision;
