import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Collapse } from "antd";

import UserProvision from "./user-provisioning/UserProvision";
import RoleProvision from "./role-provisioning/RoleProvision";
import GroupProvision from "./group-provisioning/GroupProvision";
import { setProvisioningCollapseCurrent, setProvisioningValidations } from "../../../../../../redux/slice/app-store/appConfigureSlice";
import { useTranslation } from "react-i18next";

const ConnectorProvision = () => {
  const { t } = useTranslation();
  const appConfigure = useSelector((state: any) => state?.appConfigure);

  const userProvisionRef = useRef<any>(null);
  const roleProvisionRef = useRef<any>(null);
  const groupProvisionRef = useRef<any>(null);
  const collapseRef = useRef<any>(null);

  const provisioning = useSelector((state: any) => state?.provisioning);
  const isApiCalled = useSelector((state: any) => state?.provisioning?.isProvisioningApiCalled);

  const [activeKey, setActiveKey] = useState<string>("1");

  const handleCollapseChange = async (key: string | string[]) => {
    const activePanelKey = key[0]; // Directly access the first element of the array

    if (activeKey === "1") {
      if (activePanelKey === "2") {
        if (!isApiCalled?.userProvisionApiCalled) {
          await userProvisionRef.current?.submitForm();
        } else if (userProvisionRef.current?.dirty) {
          await userProvisionRef.current?.submitForm();
        } else {
          setActiveKey("2");
        }
      }

      if (activePanelKey === "3") {
        if (!isApiCalled?.userProvisionApiCalled) {
          await userProvisionRef.current?.submitForm();
        } else if (userProvisionRef.current?.dirty) {
          await userProvisionRef.current?.submitForm();
        } else if (!isApiCalled?.roleProvisionApiCalled) {
          setActiveKey("2");
          await roleProvisionRef.current?.submitForm();
        } else {
          setActiveKey("3");
        }
      }
    }

    if (activeKey === "2") {
      if (activePanelKey === "1") {
        setActiveKey("1");
      }

      if (activePanelKey === "3") {
        if (!isApiCalled?.assignRoleProvisionApiCalled || !isApiCalled?.assignRoleProvisionApiCalled) {
          await roleProvisionRef.current?.submitForm();
        } else if (roleProvisionRef.current?.dirty) {
          await roleProvisionRef.current?.submitForm();
        } else {
          setActiveKey("3");
        }
      }
    }

    if (activeKey === "3") {
      if (activePanelKey === "2") {
        setActiveKey("2");
      }
      if (activePanelKey === "1") {
        setActiveKey("1");
      }
    }
  };

  return (
    <div className="w-full px-10 pb-5 connector-provision">
      <p className="app-header py-8">
        {t("appManagement.provisioning.connectorDetailsProvisioning")}
        {provisioning?.isDuplicate ? `${t("appManagement.copy")}` : null}
      </p>
      <div className="w-full mx-auto acc-provision pr-5">
        <Collapse
          ref={collapseRef}
          accordion
          activeKey={activeKey}
          onChange={(el: any) => handleCollapseChange(el)}
          items={[
            {
              key: "1",
              label: <p className="font-medium text-[#000] font-Inter text-[18px]"> {t("appManagement.provisioning.userProvisioning")}</p>,
              children: <UserProvision formRef={userProvisionRef} setActiveKey={setActiveKey} />,
            },
            {
              key: "2",
              label: <p className="font-medium text-[#000] font-Inter text-[18px]">{t("appManagement.provisioning.roleProvisioning")}</p>,
              children: <RoleProvision formRef={roleProvisionRef} setActiveKey={setActiveKey} />,
            },
            {
              key: "3",
              label: <p className=" text-[#000] font-Inter text-[18px] font-medium">{t("appManagement.provisioning.groupProvisioning")}</p>,
              children: <GroupProvision formRef={groupProvisionRef} setActiveKey={setActiveKey} />,
            },
          ]}
          className="w-[100%]  custom-header bg-[#fff]"
          expandIconPosition="end"
        />
      </div>
    </div>
  );
};

export default ConnectorProvision;
