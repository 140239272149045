import React, { useEffect, useState } from "react";
import { Button } from "antd";
import ConnectionSourceAndTarget from "./ConnectionSourceAndTarget";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStep, setDeProvisioningBackButtonClicked } from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { useTranslation } from "react-i18next";
const ManageConnection = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const provisioning = useSelector((state: any) => state?.provisioning);
  const [triggerError, setTriggerError] = useState<boolean>(false);
  const handleBackToUser = () => {
    dispatch(setDeProvisioningBackButtonClicked(true));
    dispatch(setActiveStep(3));
  };
  const handleNext = () => {
    if (provisioning?.selectedSourceConnector === "") {
      setTriggerError(true);
    } else {
      setTriggerError(false);
      dispatch(setActiveStep(5));
    }
  };
  useEffect(() => {
    if (provisioning?.selectedSourceConnector !== "") {
      setTriggerError(false);
    }
  }, [provisioning?.selectedSourceConnector]);
  return (
    <div className="w-full px-10 pb-20 manage-connection">
      <p className="app-header pt-8">
        {" "}
        {t("appManagement.provisioning.selectYourConnector")} {provisioning?.isDuplicate ? `${t("appManagement.copy")}` : null}
      </p>
      <div className="w-full connection-container">
        <div className="w-full">
          <ConnectionSourceAndTarget triggerError={triggerError} />
        </div>
        <div className="w-full mx-auto pb-10 ">
          <div className="footer ">
            <div className="modal-footer w-full mx-auto ">
              <div className="w-full flex justify-end pr-5">
                <CustomButtonBack onClick={handleBackToUser} text={t("common.back")} />
                <Button onClick={handleNext} type="primary" className="bg-[#5441DA] submit-btn">
                  {t("common.next")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageConnection;
