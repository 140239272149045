import React, { useEffect, useRef, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import GeneralDetailsFormEdit from "../../../layouts/dashboard-module/customer-table/modal/forms/GeneralDetailsFormEdit";
import { tenantEditValidationSchema } from "../../../layouts/dashboard-module/customer-table/modal/schema/ValidationScheme";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { useDispatch, useSelector } from "react-redux";
import ProductsAndPlans from "../../../layouts/dashboard-module/customer-table/modal/forms/products/ProductsAndPlans";
import Loader from "../../../layouts/component/Loader";
import { useNavigate } from "react-router-dom";
import "./EditTenentDetails.scss";
import EditEmailConfigurationEdit from "./EditEmailConfigurationEdit";
import SsoSessionSettingFormEdit from "../../../layouts/dashboard-module/customer-table/modal/forms/SsoSessionSettingFormEdit";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { retrieveData } from "../../../services/storage/Storage";
import SuccessMessageModal from "../../access-manager/create/IpTimeSuccessModal";
import { startCase } from "lodash";
import { setTenantActiveStep } from "../../../redux/slice/CustomerSlice";
import { FormValues } from "./EditTenantDetailsTypes";

const EditTenentDetails = () => {
  const { t } = useTranslation();
  const formRef = useRef<any>(null);
  const [initialValues, setInitialValues] = useState<any>({
    customerId: "",
    displayName: "",
    customerEmailId: "",
    noOfLicense: null,
    phone: "",
    loginTheme: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [isOpenPopup, setIsOpen] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [isDirty, setIsDirty] = useState<boolean>(true);
  const getCustomerDetailsApi = useGetApiRequests("createCustomer", "GET");
  const updateCustomerDetails = useGetApiRequests("createCustomer", "PUT");
  const realmName = retrieveData("realmName", true);

  const handleNavigate = (step: number) => {
    if (step === 1 || step === 2) {
      navigate("/tenant-admin-dashboard");
    } else {
      dispatch(setTenantActiveStep(0));
      navigate("/admin-dashboard");
    }
  };

  const currentUserData = useSelector((state: any) => state?.ProfileDetailsSlice?.profileDetails);
  const tenantActiveStep = useSelector((state: any) => state?.CustomerSlice?.tenantActiveStep);
  const tenantAdminRealmName = useSelector((state: any) => state?.CustomerSlice?.tenantAdminRealmName);
  const customerRealmName = useSelector((state: any) => state?.CustomerSlice?.customerRealmName);
  const tenantAdminDisplayName = useSelector((state: any) => state?.CustomerSlice?.tenantAdminDisplayName);
  const tenantsCustomerDisplayName = useSelector((state: any) => state?.CustomerSlice?.tenantsCustomerDisplayName);

  useEffect(() => {
    getDetails();
    // console.log(currentUserData);
  }, []);

  const getDetails = async () => {
    let transformedRealmName = "";
    if (tenantActiveStep === 1) {
      transformedRealmName = tenantAdminRealmName;
    } else if (tenantActiveStep === 2) {
      transformedRealmName = customerRealmName;
    } else {
      transformedRealmName = currentUserData?.customerName;
    }

    setLoader(true);
    const pathParams: any = {
      // name: currentUserData?.customerName,
      name: transformedRealmName,
    };
    try {
      const response: any = await getCustomerDetailsApi("", {}, pathParams);
      const status = response.status;
      const responseData = response?.data?.data;
      if (status === 200) {
        setLoader(false);
        setInitialValues({
          // General Details
          customerId: responseData?.customerGeneralDetailsDto?.customerId,
          displayName: responseData?.customerGeneralDetailsDto?.displayName,
          customerEmailId: responseData?.customerGeneralDetailsDto?.primaryEmailId,
          noOfLicense: responseData?.customerGeneralDetailsDto?.noOfLicense,
          phone: responseData?.customerGeneralDetailsDto?.primaryMobileNumber?.replace(/\D/g, ""),
          loginTheme: responseData?.customerGeneralDetailsDto?.loginTheme,
          passwordLogin: responseData?.customerGeneralDetailsDto?.passwordLogin,
          defaultPassword: responseData?.customerGeneralDetailsDto?.defaultPassword,
          smsServiceProvider: responseData?.customerGeneralDetailsDto?.smsServiceProvider,

          // Email Configurations
          senderEmail: responseData?.emailConfigurationDto?.fromEmailAddress,
          displaySenderEmail: responseData?.emailConfigurationDto?.fromDisplayName,
          replyEmail: responseData?.emailConfigurationDto?.replyToEmailAddress,
          displayReplyEmail: responseData?.emailConfigurationDto?.replyToDisplayName,

          //SSO Settings
          ssoSessionIdleTimeout: responseData?.sessionDto?.ssoSessionIdleTimeout / 60,
          ssoSessionMaxLifespan: responseData?.sessionDto?.ssoSessionMaxLifespan / 60,
          ssoSessionIdleTimeoutRememberMe: responseData?.sessionDto?.ssoSessionIdleTimeoutRememberMe / 60,
          ssoSessionMaxLifespanRememberMe: responseData?.sessionDto?.ssoSessionMaxLifespanRememberMe / 60,
          loginTimeout: responseData?.sessionDto?.loginTimeout / 60,
          loginActionTimeout: responseData?.sessionDto?.loginActionTimeout / 60,
        });
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const defaultToZeroIfFalsy = (value: any) => (value ? value : 0);

  const processSessionPayload = (values: Record<string, any>) => {
    try {
      const payload: Record<string, any> = {
        ssoSessionIdleTimeout: values?.ssoSessionIdleTimeout,
        ssoSessionMaxLifespan: values?.ssoSessionMaxLifespan,
        ssoSessionIdleTimeoutRememberMe: defaultToZeroIfFalsy(values?.ssoSessionIdleTimeoutRememberMe),
        ssoSessionMaxLifespanRememberMe: defaultToZeroIfFalsy(values?.ssoSessionMaxLifespanRememberMe),
        loginTimeout: values?.loginTimeout,
        loginActionTimeout: values?.loginActionTimeout,
      };

      for (const key in payload) {
        if (payload.hasOwnProperty(key) && values?.[key] !== undefined) {
          const value = parseInt(values[key], 10);
          if (!isNaN(value)) {
            payload[key] = value * 60; // Convert to seconds
          }
        }
      }

      return payload;
    } catch {
      return {};
    }
  };

  const getPayload = (values: any) => ({
    customerGeneralDetailsDto: {
      customerId: values?.customerId,
      primaryEmailId: values?.customerEmailId,
      displayName: values?.displayName,
      noOfLicense: values?.noOfLicense,
      loginTheme: values?.loginTheme,
      primaryMobileNumber: values?.phone,
      parentCustomer: "master",
      customerType: "CLIENT",
      passwordLogin: values?.passwordLogin,
      defaultPassword: values?.defaultPassword,
      smsServiceProvider: values?.smsServiceProvider,
    },

    emailConfigurationDto: {
      fromEmailAddress: values?.senderEmail,
      fromDisplayName: values?.displaySenderEmail,
      replyToEmailAddress: values?.replyEmail,
      replyToDisplayName: values?.displayReplyEmail,
    },

    sessionDto: processSessionPayload(values),
  });

  const handleSubmit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    setBtnLoader(true);
    let transformedRealmName = "";
    if (tenantActiveStep === 1) {
      transformedRealmName = tenantAdminRealmName;
    } else if (tenantActiveStep === 2) {
      transformedRealmName = customerRealmName;
    } else {
      transformedRealmName = currentUserData?.customerName;
    }

    const pathParams: any = {
      name: transformedRealmName,
    };
    const payload = getPayload(values);
    try {
      const response: any = await updateCustomerDetails(payload, "", pathParams);
      const status = response.status;
      const responseMessage = response?.data?.message;
      if (status === 200 || status === 201) {
        setBtnLoader(false);
        setResponseMessage(responseMessage);
        setIsOpen(true);
        setTimeout(() => {
          resetForm();
          handleClose();
        }, 1000);
      }
    } catch (err) {
      setBtnLoader(false);
      handleRequestError(err);
    }
  };

  const handleClose = () => {
    getDetails();
    setIsOpen(false);
  };

  const handleUpdate = async () => {
    await formRef?.current?.submitForm();
  };

  return (
    <>
      {isDirty && (
        <div className="flex w-full edit-admin absolute z-10">
          <p>{t("submitDetailsPrompt")}</p>
          <Button
            loading={btnLoader}
            type="primary"
            className="ml-4 mr-2 flex items-center justify-evenly font-semibold text-[18px] font-Inter w-[115px] h-[40px] bg-[#5441DA] text-white rounded-full"
            size="large"
            onClick={handleUpdate}
          >
            {t("Update")}
          </Button>

          <Button
            className="flex items-center justify-evenly font-semibold text-[18px] font-Inter w-[115px] h-[40px] !text-[#5441DA] bg-[#FFFFFF] border-[#5441DA] rounded-full"
            size="large"
            onClick={() => handleNavigate(tenantActiveStep)}
          >
            Cancel
          </Button>
        </div>
      )}
      <div className="akku-container">
        {loader && <Loader />}

        <div className={`main-container ${isDirty && "mt-12"}`}>
          <div className="dashboard-container h-full rounded overflow-y-scroll edit-tenant">
            <ul className="flex breadcrumb">
              {tenantActiveStep > 0 ? (
                <>
                  <li role="none" className="font-Inter  pr-1 cursor-pointer text-[18px]" onClick={() => handleNavigate(0)}>
                    {startCase(realmName)} {">"}
                  </li>

                  <li role="none" className="font-Inter  pr-1 cursor-pointer text-[18px]" onClick={() => handleNavigate(tenantActiveStep)}>
                    {tenantActiveStep === 2 ? tenantsCustomerDisplayName : tenantAdminDisplayName} {">"}
                  </li>
                </>
              ) : (
                <li role="none" className="font-Inter  pr-1 cursor-pointer text-[18px]" onClick={() => handleNavigate(0)}>
                  {startCase(realmName)} {">"}
                </li>
              )}
              <li className="active font-Inter text-[18px]" data-testid="risk-assessment">
                Edit Details
              </li>
            </ul>
            <div className="w-full">
              <Formik innerRef={formRef} initialValues={initialValues} validationSchema={tenantEditValidationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, setFieldValue, errors, dirty }) => {
                  setIsDirty(dirty);
                  return (
                    <Form>
                      <p className="title py-4">General Details</p>
                      <div className="w-full">
                        <GeneralDetailsFormEdit setFieldValue={setFieldValue} values={values} errors={errors} isDirty={dirty} />
                      </div>
                      <p className="title py-4">Email Configuration</p>
                      <div className="w-full">
                        <EditEmailConfigurationEdit />
                      </div>
                      <p className="title py-4">SSO Settings</p>
                      <div className="w-full">
                        <SsoSessionSettingFormEdit setFieldValue={setFieldValue} values={values} errors={errors} />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
            {/* <div className="w-full products-edit">
            <ProductsAndPlans active currentTab={0} />
          </div> */}
          </div>
        </div>
      </div>
      {isOpenPopup && <SuccessMessageModal responseMessage={responseMessage} handleModalClose={handleClose} open={isOpenPopup} />}
    </>
  );
};

export default EditTenentDetails;
