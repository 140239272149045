import React, { useEffect, useState } from "react";
import { Modal, Input, Button } from "antd";
import ImageView from "../../../layouts/component/ImageView";
import PasswordStrengthIndicator from "../../../Utils/PasswordStrengthIndicator";
import { retrieveData } from "../../../services/storage/Storage";
import { createValidationSchema } from "../../../Utils/DynamicSchemaForPassword";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { useSelector } from "react-redux";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import Loader from "../../../layouts/component/Loader";
import "./PasswordSet.scss";
import akkuLogo from "../.../../../../assets/images/logo/akkuDark.png";
import { PASSWORD_SET_SUCCESS } from "../../../const/ModulePermissionConst";
import SetPasswordSuccessModal from "./SetPasswordSuccessModal";
import { useTranslation } from "react-i18next";

const UpdatePassword = (props: any) => {
  const { updatePassCode, forceSetPassword, handleCloseModal, getDetails } = props;

  const { t } = useTranslation();
  const getPasswordPolicy = useGetApiRequests("getPasswordPolicy", "GET");
  const updatePassword = useGetApiRequests("updatePassword", "PUT");
  const realmName = retrieveData("realmName", true);
  const [open, setOpen] = useState<boolean>(false);
  const [passwordPolicy, setPasswordPolicy] = useState<any>(null);
  const [loader, setLoader] = useState(false);
  const [validSchema, setValidSchema] = useState<any>();
  const [oldPassword, setOldPassword] = useState<boolean>(true);
  const [visibleConfirmPassword, setConfirmVisiblePassword] = useState<boolean>(true);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

  const restriction = useSelector((state: any) => state?.ProfileDetailsSlice?.profileDetails);
  const keycloak = useSelector((state: any) => state?.keycloak);
  const userId: string = keycloak?.KeycloakInfo?.subject || "";

  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const getPasswordPolicyList = async () => {
    setLoader(true);
    const pathParams = {
      id: realmName,
    };
    try {
      const response: any = await getPasswordPolicy("", {}, pathParams);
      const status = response?.status;
      if (status === 200) {
        setLoader(false);
        const data = response?.data?.data;
        setPasswordPolicy(data);
        const validationSchema = createValidationSchema(data);
        setValidSchema(validationSchema);
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  useEffect(() => {
    getPasswordPolicyList();
    setOpen(updatePassCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePassCode]);

  const closeModal = () => {
    setOpen(false);
    handleCloseModal();
  };
  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };
  const handleSubmit = async (values: any) => {
    setLoader(true);
    const payload = {
      userEmailId: restriction.username,
      password: values.password,
      realmName: realmName,
      isRecoveryAttempt: false,
      recoveryAttempt: false,
    };
    try {
      const response: any = await updatePassword(payload, {}, {}, { "User-Id": userId });
      const status = response.status;
      if (status === 200) {
        setOpenSuccessModal(true);
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const toggleRestPassword = () => {
    setOldPassword(!oldPassword);
  };
  const toggleVisibilityConfirmPassword = () => {
    setConfirmVisiblePassword(!visibleConfirmPassword);
  };

  return (
    <>
      <Modal className={`${forceSetPassword ? "update" : "closeButton"} rounded-lg`} onCancel={closeModal} maskClosable={false} centered open={open} footer={false}>
        {loader && <Loader />}

        <div className="modal-body  pt-4  px-[20px]">
          <div className="flex w-full justify-center items-center flex-col">
            <div className="flex justify-center  w-full">
              <div className="header w-full flex items-center flex-col ">
                <ImageView src={akkuLogo} alt="shield" className="delete-apps" />
                <p>{t("header.setPassword")}</p>
              </div>
            </div>
            <div className="w-full pt-5">
              <Formik initialValues={initialValues} validationSchema={validSchema} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values }) => {
                  return (
                    <Form>
                      <div className="w-full">
                        <div className="mb-8 w-full input-container relative">
                          <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("header.password")}</p>
                          <Field as={Input} type={oldPassword ? "Password" : "text"} className="h-[50px] font-medium text-[18px]" name="password" />
                          <span className="material-symbols-outlined absolute top-[42px] right-3 text-[#667085] cursor-pointer" onClick={toggleRestPassword} role="none">
                            {oldPassword ? " visibility_off" : " visibility"}
                          </span>
                        </div>
                        <div className="mb-10 w-full input-container relative">
                          <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("header.reEnterPassword")}</p>
                          <Field as={Input} type={visibleConfirmPassword ? "Password" : "text"} className="h-[50px] font-medium text-[18px]" name="confirmPassword" />
                          <ErrorMessage name="confirmPassword" component="div" className="error-message" />
                          <span
                            data-testid="password-toggle-btn"
                            className="material-symbols-outlined absolute top-[42px] right-3 text-[#667085] cursor-pointer"
                            onClick={toggleVisibilityConfirmPassword}
                            role="none"
                          >
                            {visibleConfirmPassword ? " visibility_off" : " visibility"}
                          </span>
                        </div>
                        <PasswordStrengthIndicator password={values.password} passwordPolicy={passwordPolicy} />
                      </div>
                      <Button type="primary" className=" bg-[#5441DA] mt-5 font-semibold text-[20px] h-12 text-white w-full" htmlType="submit">
                        {t("common.confirm")}
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
      {openSuccessModal && (
        <SetPasswordSuccessModal
          open={openSuccessModal}
          handleModalClose={handleCloseSuccessModal}
          handleParentModalClose={closeModal}
          responseMessage={PASSWORD_SET_SUCCESS}
          finalAPiCall={getDetails}
        />
      )}
    </>
  );
};

export default UpdatePassword;
