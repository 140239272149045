import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConnectorDetailsPublish from "./connector/ConnectorDetailsPublish";
import ProvisionGroupMappingPublish from "./provision-group-mapping/ProvisionGroupMappingPublish";
import ProvisionRoleMappingPublish from "./provision-role-mapping/ProvisionRoleMappingPublish";
import FinalReviewUserDataPublish from "./modal/FinalReviewUserDataPublish";
import ConnectionAuthenticationPublish from "./manage-auth/ConnectionAuthenticationPublish";

import MappingUserStatus from "./mapper-status/MappingUserStatus";
import ConnectionDatabaseAuthentication from "./manage-auth/ConnectionDatabaseAuthentication";
import ConnectionDatabaseDetailsProvision from "./manage-connection/ConnectionDatabaseDetailsProvision";
import ConnectionDatabaseDetailsDeprovision from "./manage-connection/ConnectionDatabaseDetailsDeprovision";
import { clearAllData } from "../../../../redux/slice/provisioning/ProvisioningSlice";
import { clearPublishAllData, clearPublishActiveStep, setIsPublish } from "../../../../redux/slice/provisioning/ProvisioningPublishSlice";
import "./AppProvisionConfiguration.scss";
import "./AppProvisionResponsive.scss";
import ProvisionPublishStepper from "./stepper/ProvisionPublishStepper";

const AppProvisionPublishConfiguration = () => {
  const currentSteps = useSelector((state: any) => state?.provisioningPublish);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearAllData());
      dispatch(clearPublishAllData());
      dispatch(clearPublishActiveStep());
      dispatch(setIsPublish(true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="akku-container" data-testid="connector-detailsId">
      <div className="main-container app">
        <div className="w-full flex app-container">
          <div className="app-stepper bg-white">
            <ProvisionPublishStepper />
          </div>
          <div className="app-form-container flex-1 ">
            {currentSteps?.activeStep === 0 && <ConnectorDetailsPublish />}
            {currentSteps?.activeStep === 1 && <ConnectionAuthenticationPublish />}
            {currentSteps?.activeStep === 2 && <ProvisionGroupMappingPublish />}
            {currentSteps?.activeStep === 3 && <ProvisionRoleMappingPublish />}
            {currentSteps?.activeStep === 4 && <FinalReviewUserDataPublish />}
            {/* {currentSteps?.activeStep === 5 && <MappingUserStatus />}
            {currentSteps?.activeStep === 6 && <ConnectionDatabaseAuthentication />}
            {currentSteps?.activeStep === 7 && <ConnectionDatabaseDetailsProvision />}
            {currentSteps?.activeStep === 9 && <ConnectionDatabaseDetailsDeprovision />} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppProvisionPublishConfiguration;
