import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { useDispatch, useSelector } from "react-redux";
import FinalReviewUserDataAccordionAttributes from "./FinalReviewUserDataAccordion/FinalReviewUserDataAccordionAttributes";
import FinalReviewUserDataAccordionGroups from "./FinalReviewUserDataAccordion/FinalReviewUserDataAccordionGroups";
import FinalReviewUserDataAccordionRoles from "./FinalReviewUserDataAccordion/FinalReviewUserDataAccordionRoles";
import "./FinalReview.scss";
import { Button, Collapse } from "antd";
import { CollapseProps } from "antd/lib";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import SuccessMessageModal from "../../../../access-manager/create/IpTimeSuccessModal";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import Loader from "../../../../../layouts/component/Loader";
import { retrieveData } from "../../../../../services/storage/Storage";
import { provisioningConstant } from "../const/provisioningConts";
import { setPublishActiveStep } from "../../../../../redux/slice/provisioning/ProvisioningPublishSlice";
import { useTranslation } from "react-i18next";

const FinalReviewUserDataPublish = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const realmId = retrieveData("realmId", true);
  const token: string = retrieveData("authToken", true);
  const getProvisioningMappingAttributesURL: string = process.env.REACT_APP_PROVISIONING_CLOUD_FUN_BASEURL || "";
  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");
  const [roleAttributeDetails, setRoleAttributeDetails] = useState<any>(null);
  const [activeKey, setActiveKey] = useState<string>("1");

  const provisioning = useSelector((state: any) => state?.provisioning);
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);
  const [loader, setLoader] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [apiHitCount, setApiHitCount] = useState<number>(0);
  const [noOfApiCalls, setNoOfApiCalls] = useState();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [groupAttributeDetails, setGroupAttributeDetails] = useState<any>(null);
  useEffect(() => {
    if (apiHitCount === noOfApiCalls) {
      setOpenSuccessModal(true);
      setTimeout(() => {
        handleCloseSuccessModal();
        navigate("/app-store");
      }, 3000);
    }
  }, [apiHitCount]);

  useEffect(() => {
    getProvisioningConnectorDetailsApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createRolePayload = () => {
    const connectorName = generalDetails?.name?.toUpperCase();
    const payload = {
      realmId: realmId,
      apiType: provisioningConstant.apiTypeReadPermission,
      connectorName: connectorName,
      endPointDescription: provisioningConstant.roleListEndPointDescription,
    };
    return payload;
  };

  const createGroupPayload = () => {
    const connectorName = generalDetails?.name?.toUpperCase();
    const payload = {
      realmId: realmId,
      apiType: provisioningConstant.apiTypeReadPermission,
      connectorName: connectorName,
      endPointDescription: provisioningConstant.groupListEndPointDescription,
    };
    return payload;
  };

  const getProvisioningConnectorDetailsApi = async () => {
    setLoader(true);
    let params = {
      akkuProvisioningConnectorId: appDetails?.akkuProvisioningConnectorId,
    };
    const queryParams = { realmId: retrieveData("realmId", true) };
    try {
      const response: any = await provisioningConnectorDetails("", queryParams, params);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data?.data;
        getProvisioningGroupMappingAttributes(data);
        getProvisioningRoleMappingAttributes(data);
      }
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const getProvisioningGroupMappingAttributes = async (el: any) => {
    setLoader(true);
    const payload = createGroupPayload();
    try {
      const response = await fetch(getProvisioningMappingAttributesURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data?.statusCode === 200) {
        try {
          const finalResults = constructFinalResult(el?.akkuProvisioningGroupMapDtoList, data);
          setGroupAttributeDetails(finalResults);
        } catch (e) {
          console.log("Error in group attribute", e);
        }
        setLoader(false);
      } else {
        // handleRequestError(data);
        console.log("Error in fetching group attribute", data);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  function constructFinalResult(mappedData: any, apiResponse: any) {
    const { akku_data, sp_data } = apiResponse;
    return mappedData.map((mappedItem: any) => {
      // Find the corresponding akku_data and sp_data based on the ids
      const akkuItem = akku_data.find((item: any) => item.id === mappedItem.akkuGroupId) || { id: "", name: "" };
      const spItem = sp_data.find((item: any) => item.id === mappedItem.spGroupId) || { id: "", name: "" };
      // Construct the final result for each mappedItem
      return {
        source: akkuItem.name || "", // Map akku data name to source
        sourceId: akkuItem.id || "", // Map akku data id to sourceId
        target: spItem.name || "", // Map sp data name to target
        targetId: spItem.id || "", // Map sp data id to targetId
      };
    });
  }
  const getProvisioningRoleMappingAttributes = async (element: any) => {
    setLoader(true);
    const payload = createRolePayload();

    try {
      const response = await fetch(getProvisioningMappingAttributesURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data?.statusCode === 200) {
        try {
          const finalResults = constructFinalResultForRoleAttributes(element?.akkuProvisioningRoleMapDtoList, data);
          setRoleAttributeDetails(finalResults);
        } catch (e) {
          console.log("Error in role attribute", e);
        }

        setLoader(false);
      } else {
        console.log("Error in fetching role attribute", data);
        // handleRequestError(data);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  function constructFinalResultForRoleAttributes(mappedData: any, apiResponse: any) {
    try {
      const { akku_data, sp_data } = apiResponse;
      return mappedData?.map((mappedItem: any) => {
        // Find the corresponding akku_data and sp_data based on the ids
        const akkuItem = akku_data.find((item: any) => item.id === mappedItem?.keycloakRoleId) || { id: "", name: "" };
        const spItem = sp_data.find((item: any) => item.id === mappedItem?.spRoleId) || { id: "", name: "" };
        // Construct the final result for each mappedItem
        return {
          source: akkuItem.name || "", // Map akku data name to source
          sourceId: akkuItem.id || "", // Map akku data id to sourceId
          target: spItem.name || "", // Map sp data name to target
          targetId: spItem.id || "", // Map sp data id to targetId
        };
      });
    } catch (e) {
      console.log("error in constructFinalResultForRoleAttributes", e);
    }
  }
  const handleBack = () => {
    if (appDetails?.isRoleProvisioningConfigured) {
      dispatch(setPublishActiveStep(3));
    } else {
      dispatch(setPublishActiveStep(2));
    }
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleCollapseChange = (key: any) => {
    setActiveKey(key);
  };
  const handleNavigate = () => {
    navigate("/app-store");
  };

  const hanldeConfirm = () => {
    setResponseMessage("Provisioning connector configured successfully.");
    setOpenSuccessModal(true);
    setTimeout(() => {
      handleCloseSuccessModal();
      navigate("/app-store");
    }, 2000);
  };

  const items: CollapseProps["items"] = [
    ...(appDetails?.isGroupOuProvisioningConfigured
      ? [
          {
            key: "1",
            label: <p className="text-[#000] font-Inter text-[20px] font-normal">{t("appManagement.provisioning.groups")}</p>,
            children: <FinalReviewUserDataAccordionGroups groupAttributeDetails={groupAttributeDetails} />,
          },
        ]
      : []),
    ...(appDetails?.isRoleProvisioningConfigured
      ? [
          {
            key: "2",
            label: <p className="text-[#000] font-Inter text-[20px] font-normal">{t("appManagement.provisioning.roles")}</p>,
            children: <FinalReviewUserDataAccordionRoles roleAttributeDetails={roleAttributeDetails} />,
          },
        ]
      : []),
  ];

  return (
    <>
      <div className="w-full final-review-user-data p-7 bg-[#ffffff]">
        <ul className="flex breadcrumb">
          <li role="none" className="font-Inter  pr-1 cursor-pointer !text-[14px]" onClick={handleNavigate}>
            {t("appManagement.appManagement")} {"/ "}
          </li>
          <li className="font-Inter !text-[14px]">
            {" "}
            {t("appManagement.provisioningLabel")} {"/ "}
          </li>
          <li className="font-Inter !text-[14px]">
            {t("appManagement.provisioning.mapping")} {"/ "}
          </li>
          <li className="active font-Inter !text-[14px]">{t("appManagement.provisioning.finalReview")}</li>
        </ul>

        <div className="w-full pb-5">
          <p className="app-header pt-5">
            {t("appManagement.provisioning.finalReviewUserData")} - ({t("appManagement.publish")})
          </p>
          <p className="text-[#475467] text-[20px] font-Inter ">{t("appManagement.provisioning.reviewMapping")}</p>
        </div>

        <div className=" overflow-y-auto mx-auto pt-3 pb-60 xl:pb-20 w-[100%] flex flex-col gap-2 overflow-x-auto final-review-user-data-accordions-wrappr">
          <div className="w-full final-review-user-data-wrapper pb-60 xl:pb-20">
            <Collapse accordion activeKey={activeKey} onChange={handleCollapseChange} items={items} className="w-[95%] custom-header  " expandIconPosition="end" />
          </div>
          {/* <div className="w-full">
          <FinalReviewUserDataAccordionGroups />
        </div>
        <div className="w-full">
          <FinalReviewUserDataAccordionRoles />
        </div> */}
        </div>
        <div className="footer">
          <div className="modal-footer mx-auto w-full pr-5">
            <div className="w-full justify-end flex ">
              <CustomButtonBack onClick={handleBack} text={t("common.back")} />
              <Button type="primary" onClick={hanldeConfirm} className="bg-[#5441DA]  submit-btn">
                {t("common.confirm")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader />}
      {openSuccessModal && <SuccessMessageModal open={openSuccessModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </>
  );
};

export default FinalReviewUserDataPublish;
