export const hideOnRoutes = [
  "/register",
  "/user-register",
  "/login",
  "/verify-email",
  "/",
  "/upload-logo",
  "/setpassword",
  "/forgot-password",
  "/forgot-username",
  "/multi-factor-authentication",
  "/sent-otp-mobile",
  "/sent-otp-email",
  "/validate-otp",
  "/reset-password",
  "/set-password",
  "/dashboard",
  "/device-logout",
];
export const availableRoutes = [
  "/dashboard",
  "/admin-dashboard",
  "/user",
  "/user/edit",
  "/user/csv-upload",
  "/user/add-user",
  "/app-store",
  "/app-store/configure-app",
  "/user/device-restriction",
  "/access-manager/device-restriction",
  "/access-manager/device-based-restriction",
  "/user/groups",
  "/user/create-groups",
  "/access-manager",
  "/access-manager/device-restriction",
  "/access-manager/select-ip",
  "/access-manager/time-parameters",
  "/access-manager/groups",
  "/access-manager/assignUsers",
  "/role-management",
  "/edit-connected-directory",
  "/role-management/configure-role",
  "/directory/configure-directory",
  "/create-new-app",
  "/credential-manager",
  "/password-management",
  "/edit-kerberos-directory",
  "/edit-tenant",
  "/risk-assessment",
  "/user/select-ip",
  "/user/time-parameters",
  "/account-provision",
  "/device-management",
  "/device-management/csv-upload",
  "/publish-provision",
  "/audit-log",
  "/tenant-admin-dashboard",
];
