import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../layouts/component/Loader";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../../services/storage/Storage";
import SuccessMessageModal from "../../../../access-manager/create/IpTimeSuccessModal";
import ProvisionGroupMappingForm from "./ProvisionGroupMappingForm";
import { setMappingGroupAttributes } from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { provisioningConstant } from "../const/provisioningConts";
import { setPublishActiveStep } from "../../../../../redux/slice/provisioning/ProvisioningPublishSlice";
import { useTranslation } from "react-i18next";
const ProvisionGroupMappingPublish = () => {
  const { t, i18n } = useTranslation();
  const getProvisioningMappingAttributesURL: string = process.env.REACT_APP_PROVISIONING_CLOUD_FUN_BASEURL || "";
  const dispatch = useDispatch();
  const provisioning = useSelector((state: any) => state?.provisioning);
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);

  const mappingGroupAttributes = useSelector((state: any) => state?.provisioning?.mappingGroupAttributes);

  const updateProvisioningGroupAttributes = useGetApiRequests("updateProvisioningGroupAttributes", "PUT");

  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");

  const realmId = retrieveData("realmId", true);
  const token: string = retrieveData("authToken", true);
  const [loader, setLoader] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(mappingGroupAttributes);
  const [target, setTarget] = useState([]);
  const [source, setSource] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [connectorDetails, setConnectorDetails] = useState<any>(null);
  const [isDirty, setIsDirty] = useState(false);
  const transformList: any = [];
  const validationSchema = Yup.object({
    forms: Yup.array().of(
      Yup.object({
        source: Yup.string().required(t("appManagement.provisioning.errors.pleaseSelectRequiredValue")),
        target: Yup.string().required(t("appManagement.provisioning.errors.pleaseSelectRequiredValue")),
      }),
    ),
  });

  useEffect(() => {
    getProvisioningConnectorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const formikRef = useRef<any>(null);
  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);
  useEffect(() => {
    setFormInitialValues(mappingGroupAttributes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappingGroupAttributes]);

  const handleSubmit = async (values: any) => {
    if (isDirty) {
      dispatch(setMappingGroupAttributes(values));
      updateProvisioningGroupAttributesApi(values);
    } else {
      handleToNext();
    }
  };

  const createPayloadSAVE = (forms: any) => {
    try {
      return forms?.forms?.map((form: any) => {
        return {
          akkuGroupId: form?.sourceId,
          spGroupId: form?.targetId,
          akkuProvisioningConnectorId: appDetails?.akkuProvisioningConnectorId,
          realmId: realmId,
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getProvisioningConnectorDetails = async () => {
    setLoader(true);
    let params = {
      akkuProvisioningConnectorId: appDetails?.akkuProvisioningConnectorId,
    };
    const queryParams = { realmId: retrieveData("realmId", true) };
    try {
      const response: any = await provisioningConnectorDetails("", queryParams, params);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data?.data;
        setConnectorDetails(data);
        getProvisioningGroupMappingAttributes(data);
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const updateProvisioningGroupAttributesApi = (values: any) => {
    const payload = createPayloadSAVE(values);
    const groupMapDtoList = connectorDetails.akkuProvisioningGroupMapDtoList || [];
    let addAkkuProvisioningGroupMapIds = payload.map((item1: any) => {
      const match = groupMapDtoList.find((item: any) => item?.akkuGroupId === item1?.akkuGroupId || item?.spGroupId === item1?.spGroupId);
      if (match) {
        return {
          ...item1,
          akkuProvisioningGroupMapId: match.akkuProvisioningGroupMapId,
        };
      } else {
        return item1; // Return the payload item if no match is found
      }
    });
    const nonMatchingGroupMapIds = groupMapDtoList
      ?.filter((item: any) => {
        const matchingItem = addAkkuProvisioningGroupMapIds?.find((secondItem: any) => secondItem?.akkuGroupId === item?.akkuGroupId || secondItem?.spGroupId === item?.spGroupId);
        return !matchingItem;
      })
      .map((item: any) => item?.akkuProvisioningGroupMapId);
    const finalPayload = {
      publishStatus: appDetails?.publishStatus,
      akkuProvisioningGroupMapDtoList: addAkkuProvisioningGroupMapIds,
      removeGroupMapIdList: [...nonMatchingGroupMapIds],
    };
    updateApiCall(finalPayload);
  };

  const updateApiCall = async (payload: any) => {
    try {
      const response: any = await updateProvisioningGroupAttributes(payload);
      const status = response?.status;
      if (status === 200) {
        setLoader(false);
        setResponseMessage(response.data.message);
        setOpenSuccessModal(true);
        setTimeout(() => {
          handleCloseSuccessModal();
          handleToNext();
        }, 3000);
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const createPayload = () => {
    const connectorName = generalDetails?.name?.toUpperCase();
    const payload = {
      realmId: realmId,
      apiType: provisioningConstant.apiTypeReadPermission,
      connectorName: connectorName,
      endPointDescription: provisioningConstant.groupListEndPointDescription,
    };
    return payload;
  };
  const getProvisioningGroupMappingAttributes = async (el: any) => {
    setLoader(true);
    const payload = createPayload();
    try {
      const response = await fetch(getProvisioningMappingAttributesURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data?.statusCode === 200) {
        try {
          const finalResults = constructFinalResult(el?.akkuProvisioningGroupMapDtoList, data);
          dispatch(
            setMappingGroupAttributes({
              forms: finalResults,
            }),
          );
        } catch (err) {
          console.error(err);
        }

        setSource(data?.akku_data);
        setTarget(data?.sp_data);

        setLoader(false);
      } else {
        // this else is for handling fetch api errors
        // handleRequestError(data);
        console.error("Error fetching group attributes: ", data);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  function constructFinalResult(mappedData: any, apiResponse: any) {
    try {
      const { akku_data, sp_data } = apiResponse;

      const results = mappedData?.map((mappedItem: any) => {
        // Find the corresponding akku_data and sp_data based on the ids
        const akkuItem = akku_data.find((item: any) => item.id === mappedItem?.akkuGroupId) || { id: "", name: "" };
        const spItem = sp_data.find((item: any) => item.id === mappedItem?.spGroupId) || { id: "", name: "" };

        if (!akkuItem.name?.trim()) {
          return { source: "", target: "", sourceId: "", targetId: "" };
        }
        // Construct the final result for each mappedItem
        return {
          source: akkuItem.name || "", // Map akku data name to source
          sourceId: akkuItem.id || "", // Map akku data id to sourceId
          target: spItem.name || "", // Map sp data name to target
          targetId: spItem.id || "", // Map sp data id to targetId
        };
      });

      // Check if results are empty or undefined
      if (!results || results.length === 0) {
        return [{ source: "", target: "", sourceId: "", targetId: "" }];
      }

      return results;
    } catch (e) {
      console.log(e, "error");
      return [{ source: "", target: "", sourceId: "", targetId: "" }]; // Return default in case of an error
    }
  }

  const handleToNext = () => {
    // For PublishStatus is true, publish flow
    if (!appDetails?.isRoleProvisioningConfigured) {
      dispatch(setPublishActiveStep(4));
    } else {
      dispatch(setPublishActiveStep(3));
    }
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  return (
    <>
      <div className="w-full px-10 pb-5 mapping-attributes">
        <p className="app-header pt-8">
          {t("appManagement.provisioning.mappingGroups")} {provisioning?.isDuplicate ? `${t("appManagement.copy")}` : null}
        </p>
      </div>
      <div className="w-full mapping-attributes">
        <Formik initialValues={formInitialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize innerRef={formikRef}>
          {({ values, setFieldValue }) => (
            <Form>
              <ProvisionGroupMappingForm
                values={values}
                setFieldValue={setFieldValue}
                transformList={transformList}
                setFormInitial={setFormInitialValues}
                target={target}
                source={source}
                handleSubmit={handleSubmit}
                setIsDirty={setIsDirty}
              />
            </Form>
          )}
        </Formik>
      </div>
      {loader && <Loader />}
      {openSuccessModal && <SuccessMessageModal open={openSuccessModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </>
  );
};

export default ProvisionGroupMappingPublish;
