import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  customerValues: any;
  customerId: any;
  getCustomerDetails: object;
  reloadCustomerDashboard: boolean;
  realmId: any;
  customerTableCurrentPageNumber: number;
  customerRealmId: string;
  tenantAdminRealmId: string;
  tenantActiveStep: number;
  tenantAdminRealmName: string;
  customerRealmName: string;
  adminsCustomerType: string;
  tenantsCustomerType: string;
  tenantAdminDisplayName: string;
  tenantsCustomerDisplayName: string;
}

const initialState: AuthState = {
  customerValues: {},
  customerId: "",

  getCustomerDetails: {
    generalDetails: {},
    emailDetails: {},
    ssoDetails: {},
    plansDetails: {},
  },
  reloadCustomerDashboard: false,
  realmId: "",
  customerTableCurrentPageNumber: 1,
  customerRealmId: "",
  tenantAdminRealmId: "",
  tenantActiveStep: 0,
  tenantAdminRealmName: "",
  customerRealmName: "",
  adminsCustomerType: "",
  tenantsCustomerType: "",
  tenantAdminDisplayName: "",
  tenantsCustomerDisplayName: "",
};

const customerSlice = createSlice({
  name: "customerSlice",
  initialState,
  reducers: {
    setCustomerData: (state, action: PayloadAction<any>) => {
      state.customerValues = action.payload;
    },
    clearCustomerData: (state) => {
      state.customerValues = {};
    },
    setCustomerId: (state, action: PayloadAction<any>) => {
      state.customerId = action.payload;
    },
    setCustomerDetailFromApi: (state, action: PayloadAction<any>) => {
      state.getCustomerDetails = action.payload;
    },
    clearCustomerDetailsFromApi: (state) => {
      state.getCustomerDetails = {
        generalDetails: {},
        emailDetails: {},
        ssoDetails: {},
        plansDetails: {},
      };
    },
    setReloadCustomerDashboard: (state) => {
      state.reloadCustomerDashboard = !state.reloadCustomerDashboard;
    },
    setRealmId: (state, action: PayloadAction<any>) => {
      state.realmId = action.payload;
    },
    setCustomerTableCurrentPageNumber: (state, action: PayloadAction<any>) => {
      state.customerTableCurrentPageNumber = action.payload;
    },
    setTenantAdminRealmId: (state, action: PayloadAction<any>) => {
      state.tenantAdminRealmId = action.payload;
    },
    setCustomerRealmId: (state, action: PayloadAction<any>) => {
      state.customerRealmId = action.payload;
    },
    setTenantAdminRealmName: (state, action: PayloadAction<any>) => {
      state.tenantAdminRealmName = action.payload;
    },

    setCustomerRealmName: (state, action: PayloadAction<any>) => {
      state.customerRealmName = action.payload;
    },
    setTenantActiveStep: (state, action: PayloadAction<any>) => {
      state.tenantActiveStep = action.payload;
    },
    setAdminsCustomerType: (state, action: PayloadAction<any>) => {
      state.adminsCustomerType = action.payload;
    },
    setTenantsCustomerType: (state, action: PayloadAction<any>) => {
      state.tenantsCustomerType = action.payload;
    },
    setTenantAdminDisplayName: (state, action: PayloadAction<any>) => {
      state.tenantAdminDisplayName = action.payload;
    },
    setTenantsCustomerDisplayName: (state, action: PayloadAction<any>) => {
      state.tenantsCustomerDisplayName = action.payload;
    },
  },
});

export const {
  setCustomerData,
  clearCustomerData,
  setCustomerId,
  setCustomerDetailFromApi,
  clearCustomerDetailsFromApi,
  setReloadCustomerDashboard,
  setRealmId,
  setCustomerTableCurrentPageNumber,
  setTenantAdminRealmId,
  setCustomerRealmId,
  setTenantActiveStep,
  setTenantAdminRealmName,
  setCustomerRealmName,
  setAdminsCustomerType,
  setTenantsCustomerType,
  setTenantAdminDisplayName,
  setTenantsCustomerDisplayName,
} = customerSlice.actions;
export default customerSlice.reducer;
