import React, { useEffect, useRef } from "react";
import { ErrorMessage, Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { validationSchemaForTimeRestriction } from "./TimeRestrictionValidationSchema";
import { Button, Select, TimePicker, Input } from "antd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AssignToTimeParameter from "./AssignToTimeParameter";
import { useTranslation } from "react-i18next";

export default function TimeParametersRestrictionEdit(props: any) {
  const {
    initialValues,
    onSubmit,
    onChangingZone,
    handleOnChange,
    handleRemoveList,
    setIsNewRowAdded,
    handleChangeGroups,
    setDropdownVisibleForTime,
    handleAddUsersInTime,
    handleAddGroupsInTime,
    handleChangeUsersForTime,
    handleLabelClick,
    userSearchGroupDataForTime,
    selectedValues,
    zonesList,
    dropdownVisibleForTime,
    usersSearchListForTime,
    selectedRowKeys,
    groupDataForTime,
    listOfUsersForTime,
    unassignUserForTime,
    rowSelection,
    loader,
  } = props.props;
  const { search } = useLocation();
  const { t, i18n } = useTranslation();
  const formikRef = useRef<any>(null);
  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);
  const params = new URLSearchParams(search);
  const isEditFromUrl = params.get("isEdit");
  const savedPayloadForTimeParam = useSelector((state: any) => state?.IpRestrictionSlice?.storePayload);

  return (
    <div className="ip-container">
      <div className="mx-auto selectIpEdit">
        <Formik initialValues={initialValues} validationSchema={validationSchemaForTimeRestriction(t)} onSubmit={onSubmit} enableReinitialize={true} innerRef={formikRef}>
          {({ handleSubmit, setFieldValue, values }) => {
            return (
              <Form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="w-full flex pt-0 relative">
                  <div className="flex w-full  flex-col">
                    <div className="flex w-[70%] ml-8 justify-between mb-8">
                      <div className="relative w-[48%]">
                        <p className="text-[#1D1D24] font-medium text-[18px] font-Inter">{t("common.name")}*</p>
                        <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="name" maxLength={30} />
                        <ErrorMessage component="div" name="name" className="error-message" />
                      </div>
                      <div className="relative w-[48%]">
                        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("common.description")}*</p>
                        <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="description" maxLength={60} />
                        <ErrorMessage component="div" className="error-message" name="description" />
                      </div>
                    </div>
                    {/* start */}
                    <div className=" w-[70%] ml-8">
                      <FieldArray name="timeParameter" data-testid="timeFieldArray">
                        {({ remove, push }) => {
                          return (
                            <>
                              {values?.timeParameter?.map((field: any, index: any) => (
                                <div className="w-full relative xl:mt-0 mt-4" key={field?.id}>
                                  <div className="w-full flex justify-between relative flex-wrap">
                                    <div className="mb-4 lg:mb-11 w-[100%] lg:w-[48%] md:w-[100%] zonesList xl:mt-0 mt-4">
                                      <p className={`${index === 0 ? "" : "lg:opacity-0"} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}> {t("accessManager.selectZone")}**</p>
                                      <Field
                                        name={`timeParameter[${index}].timezone`}
                                        showSearch
                                        as={Select}
                                        onChange={(value: string) => onChangingZone(value, setFieldValue, index)}
                                        placeholder="Select Zone"
                                        optionFilterProp="children"
                                        className="w-full h-[48px] font-medium text-[18px]"
                                        filterOption={(input: any, option: any) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                                        filterSort={(optionA: any, optionB: any) => optionA?.children?.toLowerCase().localeCompare(optionB?.children?.toLowerCase())}
                                      >
                                        {zonesList?.map((option: any) => (
                                          <Select.Option value={option?.label} key={option?.label}>
                                            {option?.label}
                                          </Select.Option>
                                        ))}
                                      </Field>
                                      <ErrorMessage name={`timeParameter[${index}].timezone`} className="error-message" component="div" />
                                    </div>
                                    <div className="mb-4 lg:mb-11 w-[100%] lg:w-[48%] xl:mt-0 mt-4">
                                      <p className={`${index === 0 ? "" : "lg:opacity-0"} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>
                                        {" "}
                                        {t("accessManager.selectRange")}* {t("accessManager.startTime")}
                                      </p>
                                      <div className="rangePicker">
                                        {Object?.keys(savedPayloadForTimeParam)?.length > 0 && savedPayloadForTimeParam ? (
                                          <div className="w-full flex">
                                            <div className="w-1/2 pr-2">
                                              <Field name={`timeParameter[${index}].timeFrom`} className="mr-2">
                                                {({ field }: FieldProps<any>) => (
                                                  <TimePicker
                                                    format="hh:mm A"
                                                    use12Hours
                                                    onChange={(value, dateString) => {
                                                      handleOnChange("timeFrom", dateString, setFieldValue, value, index);
                                                    }}
                                                    suffixIcon={<span className="material-symbols-outlined pr-1">schedule</span>}
                                                    value={field?.value}
                                                  />
                                                )}
                                              </Field>
                                              <ErrorMessage name={`timeParameter[${index}].timeFrom`} className="error-message" component="div" />
                                            </div>
                                            <div className="w-[50%]">
                                              <Field name={`timeParameter[${index}].timeTo`}>
                                                {({ field }: FieldProps<any>) => (
                                                  <TimePicker
                                                    format="hh:mm A"
                                                    use12Hours
                                                    onChange={(value, dateString) => {
                                                      handleOnChange("timeTo", dateString, setFieldValue, value, index);
                                                    }}
                                                    suffixIcon={<span className="material-symbols-outlined pr-1">schedule</span>}
                                                    value={field?.value}
                                                  />
                                                )}
                                              </Field>
                                              <ErrorMessage name={`timeParameter[${index}].timeTo`} className="error-message" component="div" />
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="w-full flex">
                                            <div className="w-1/2 mr-2">
                                              <Field name={`timeParameter[${index}].timeFrom`} className="mr-2">
                                                {({ field }: FieldProps<any>) => {
                                                  return (
                                                    <TimePicker
                                                      use12Hours
                                                      format="hh:mm A"
                                                      suffixIcon={<span className="material-symbols-outlined pr-1">schedule</span>}
                                                      value={field?.value}
                                                      onChange={(value, dateString) => {
                                                        handleOnChange("timeFrom", dateString, setFieldValue, value, index);
                                                      }}
                                                    />
                                                  );
                                                }}
                                              </Field>

                                              <ErrorMessage name={`timeParameter[${index}].timeFrom`} component="div" className="error-message" />
                                            </div>
                                            <div className="w-1/2">
                                              <Field name={`timeParameter[${index}].timeTo`}>
                                                {({ field }: FieldProps<any>) => (
                                                  <TimePicker
                                                    value={field?.value}
                                                    onChange={(value, dateString) => {
                                                      handleOnChange("timeTo", dateString, setFieldValue, value, index);
                                                    }}
                                                    suffixIcon={<span className="material-symbols-outlined pr-1">schedule</span>}
                                                    format="hh:mm A"
                                                    use12Hours
                                                  />
                                                )}
                                              </Field>
                                              <ErrorMessage name={`timeParameter[${index}].timeTo`} className="error-message" component="div" />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex xl:mt-0 mt-4 justify-center items-center absolute right-[-130px] top-7">
                                    <span
                                      className={`${values.timeParameter.length === 1 ? "pointer-events-none " : ""}cursor-pointer material-symbols-outlined add-custom ip remove`}
                                      onClick={() => handleRemoveList(index, remove, values.timeParameter[index])}
                                      role="none"
                                    >
                                      remove
                                    </span>
                                    {index === values.timeParameter.length - 1 && (
                                      <span
                                        role="none"
                                        className="ip material-symbols-outlined add-custom cursor-pointer"
                                        onClick={() => {
                                          setIsNewRowAdded(true);
                                          push({ timeTo: "", timeFrom: "", timezone: "", index: "" });
                                        }}
                                      >
                                        add
                                      </span>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </>
                          );
                        }}
                      </FieldArray>
                    </div>
                    {/* end */}
                    <div className="col-end-4 selectIP relative  flex justify-end mt-8">
                      <Button htmlType="submit" className="btn bg-[#5441DA] w-[400px] h-[55px] text-[20px]" type="primary">
                        {isEditFromUrl === "true" ? `${t("common.update")}` : `${t("common.save")}`}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <AssignToTimeParameter
        props={{
          handleChangeGroups: handleChangeGroups,
          setDropdownVisibleForTime: setDropdownVisibleForTime,
          handleAddUsersInTime: handleAddUsersInTime,
          handleAddGroupsInTime: handleAddGroupsInTime,
          handleChangeUsersForTime: handleChangeUsersForTime,
          handleLabelClick: handleLabelClick,
          userSearchGroupDataForTime: userSearchGroupDataForTime,
          selectedValues: selectedValues,
          dropdownVisibleForTime: dropdownVisibleForTime,
          usersSearchListForTime: usersSearchListForTime,
          selectedRowKeys: selectedRowKeys,
          groupDataForTime: groupDataForTime,
          listOfUsersForTime: listOfUsersForTime,
          unassignUserForTime: unassignUserForTime,
          rowSelection: rowSelection,
          loader: loader,
        }}
      />
    </div>
  );
}
