import React, { useEffect, useState } from "react";
import Assessments from "./components/Assessments";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardFilter } from "../../redux/slice/DashboardFilterSlice";
import useDashboardService from "./useDashboardService";
import { customerTable } from "../../constant/prerequisites/prerequisites";
import { retrieveData } from "../../services/storage/Storage";
import AdminHeaderHeader from "./components/AdminHeaderHeader";
import { handleRequestError } from "../../layouts/toast/ErrorNotificationMessage";
import "./Dashboard.scss";
import { setCustomerDetailsDefaultPassword } from "../../redux/slice/user-basic-details/UserBasicDetailsSlice";
import { setAkkuMasterStatus } from "../../redux/slice/DashboardSlice";
import ScreenLoader from "../../layouts/loader/ScreenLoader";

const Dashboard: React.FC = () => {
  const currentRole = retrieveData("currentRole", true);
  const realmName = retrieveData("realmName", true);
  const reloadCustomerDashboard = useSelector((state: any) => state?.CustomerSlice?.reloadCustomerDashboard);
  const customerTableCurrentPage = useSelector((state: any) => state?.CustomerSlice?.customerTableCurrentPageNumber);
  const restriction = useSelector((state: any) => state?.restriction?.restrictionDetails);
  const userDetails = useSelector((state: any) => state?.dashboardDetails);
  const userProfileDetails = useSelector((state: any) => state?.ProfileDetailsSlice?.profileDetails);

  const requestDashboardFilter = useGetApiRequests("dashboardFilter", "GET");
  const getCustomerDetailsApi = useGetApiRequests("createCustomer", "GET");

  const realmIdFromRedux = useSelector((state: any) => state?.CustomerSlice?.realmId);
  const realmId = retrieveData("realmId", true);
  const dispatch = useDispatch();

  const [loader, setLoader] = useState<boolean>(true);

  const { getDashboardDetails } = useDashboardService();

  const requestPayload = {
    realmId: realmId,
    riskAssessmentDto: { customerType: "all" },
    recoveryAttemptsDto: {
      recoveryType: "Password",
    },
    topSellerDto: {
      year: 2024,
      orderBy: "product",
    },
  };

  if (currentRole === "user") {
    window.location.href = "/dashboard";
  }

  useEffect(() => {
    !restriction.isActive && init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadCustomerDashboard, realmIdFromRedux]);

  const init = () => {
    const customerTableInitialFilterData: any = { ...customerTable?.initialFilterData, pageNumber: customerTableCurrentPage };
    getDashboardDetails(customerTableInitialFilterData, "dashboardAll", requestPayload);
    getFilterData();
    getCustomerDetails();
  };
  const getCustomerDetails = async () => {
    setLoader(true);
    const pathParams = {
      name: realmName,
    };
    try {
      const response: any = await getCustomerDetailsApi("", {}, pathParams);

      const status = response.status;
      if (status === 200) {
        const defaultPassword = response?.data?.data?.customerGeneralDetailsDto?.defaultPassword;
        dispatch(setCustomerDetailsDefaultPassword(defaultPassword));
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error("error:", error);
      // handleRequestError(error);
      // dispatch(setCustomerDetailsDefaultPassword(true));
    }
  };

  const getFilterData = async () => {
    try {
      const response: any = await requestDashboardFilter();
      const status = response.status;
      if (status === 200) {
        const data = response.data;
        dispatch(setDashboardFilter(data));
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };

  const handleSetStatus = () => {
    dispatch(setAkkuMasterStatus(!userDetails?.isActiveAkkuMaster));
  };

  return (
    <div className="akku-container">
      {loader && <ScreenLoader />}
      {(userProfileDetails?.customerType === "MASTER" || userProfileDetails?.customerType === "MSSP") && <AdminHeaderHeader />}
      {!restriction.isActive && (
        <div className={`main-container admin ${userProfileDetails?.customerType === "MASTER" || userProfileDetails?.customerType === "MSSP" ? "!pt-14" : ""}`}>
          {/* <pre>{JSON.stringify(userDetails?.isActiveAkkuMaster, null, 1)}</pre>
          <button onClick={handleSetStatus}>test</button> */}
          <Assessments data-testid="assessments-component" />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
