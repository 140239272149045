import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  updateDashboard: boolean;
  groupsFilterReload?: boolean;
  listOfRestrictionModal: boolean;
  authSuccess: boolean;
  isTimeout: boolean;
}

const initialState: AuthState = {
  updateDashboard: false,
  groupsFilterReload: false,
  listOfRestrictionModal: false,
  authSuccess: false,
  isTimeout: false,
};

const courseTabSlice = createSlice({
  name: "navigateCourseTab",
  initialState,
  reducers: {
    setUpdateDashboard: (state, action: PayloadAction<boolean>) => {
      state.updateDashboard = action.payload;
    },
    clearUpdateDashboard: (state) => {
      state.updateDashboard = false;
    },
    setReloadGroupFilter: (state, action: PayloadAction<boolean>) => {
      state.groupsFilterReload = action.payload;
    },
    setListOfRestrictionModal: (state, action: PayloadAction<boolean>) => {
      state.listOfRestrictionModal = action.payload;
    },
    setAuthSuccess: (state, action: PayloadAction<boolean>) => {
      state.authSuccess = action.payload;
    },
    setIsTimeout: (state, action: PayloadAction<boolean>) => {
      state.isTimeout = action.payload;
    },
  },
});

export const { setAuthSuccess, setUpdateDashboard, clearUpdateDashboard, setReloadGroupFilter, setListOfRestrictionModal, setIsTimeout } = courseTabSlice.actions;
export default courseTabSlice.reducer;
