import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  profileDetails: {};
  isTenantAdmin: boolean;
  isPublished: boolean;
}

const initialState: AuthState = {
  profileDetails: {},
  isTenantAdmin: false,
  isPublished: false,
};

const ProfileDetailsSlice: any = createSlice({
  name: "profileTabSlice",
  initialState,
  reducers: {
    setProfileDetails: (state, action: PayloadAction<boolean>) => {
      state.profileDetails = action.payload;
    },
    setIsTenantAdmin: (state, action: PayloadAction<boolean>) => {
      state.isTenantAdmin = action.payload;
    },
    setIsPublished: (state, action: PayloadAction<boolean>) => {
      state.isPublished = action.payload;
    },
    clearProfileDetails: (state) => {
      state.profileDetails = {};
    },
  },
});

export const { setProfileDetails, setIsTenantAdmin, clearProfileDetails, setIsPublished } = ProfileDetailsSlice.actions;
export default ProfileDetailsSlice.reducer;
