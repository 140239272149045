import React, { useMemo } from "react";
import { Field, FieldArray, ErrorMessage } from "formik";
import { Input, Select } from "antd";
import { getAllKeys } from "../../../helper/connectorHelper";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
const { TextArea } = Input;

interface UserProvisionProps {
  index: number;
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  provisioning: any;
  provisionDetails: any;
  isKeyValueEmpty: boolean;
  samlConfig: any;
  formName: string;
  setResponseAttributes?: any;
  responseAttributes?: any;
  errors?: any;
}

const UserProvisionForm: React.FC<UserProvisionProps> = ({
  index,
  values,
  setFieldValue,
  provisioning,
  provisionDetails,
  isKeyValueEmpty,
  samlConfig,
  formName,
  setResponseAttributes,
  responseAttributes,
}) => {
  const { t } = useTranslation();
  useMemo(() => {
    const response = values?.userProvision[0]?.response;

    if (response) {
      try {
        const parsedObject = JSON.parse(response);
        const flattenedKeys: any = getAllKeys(parsedObject) || [];
        setResponseAttributes(flattenedKeys);
      } catch (error) {
        if (setResponseAttributes) {
          setResponseAttributes("");
        }
        console.error("Invalid JSON string:", error);
      }
    } else {
      if (setResponseAttributes) {
        setResponseAttributes("");
      }
    }
  }, [values?.userProvision[0]?.response]);

  const shouldRender = (): boolean | undefined => {
    if (formName === "userProvision") {
      return provisioning.isActiveEdit && provisioning.endPointResponseDtosLen !== 0 ? provisionDetails?.userProvision[0]?.headerParam && !isKeyValueEmpty : true;
    } else if (formName === "roleProvision") {
      return provisioning.isActiveEdit && provisioning.endPointResponseDtosLen !== 0 ? provisionDetails?.roleProvision[0]?.headerParam && !isKeyValueEmpty : true;
    }
    return undefined;
  };

  const primaryKeyAttributeOption = (el: any) => {
    const result =
      el?.length > 0 &&
      el?.map((item: any) => {
        return {
          value: item,
          label: String(item),
        };
      });
    return result || [];
  };

  return (
    <div key={samlConfig} className=" w-[90%] justify-start gap-x-20 flex-wrap flex">
      <div className="mb-10 input-container 2xl:w-[42%]  w-[38%]">
        <p className="font-medium text-[#1D1D24] font-Inter flex text-[18px] items-center ">{t("appManagement.provisioning.tokenApiEndpointUrl")}*</p>
        <Field id="apiEndpointURL" as={Input} type="text" className="h-[56px]  text-[18px] font-medium form-type-box" name={`${formName}.${index}.apiEndpointURL`} />
        <ErrorMessage name={`${formName}.${index}.apiEndpointURL`} component="div" className="custom-error error-message" />
      </div>
      <div className="mb-10 input-container 2xl:w-[42%]  w-[38%]">
        <p className="text-[#1D1D24] font-Inter text-[18px]  font-medium">{t("appManagement.provisioning.methodType")}*</p>
        <Field
          id="methodType"
          name={`${formName}.${index}.methodType`}
          type="text"
          as={Select}
          className="h-[56px] loginThemeText font-medium text-[18px] w-[100%] "
          value={values[formName][index].methodType || undefined}
          filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={(value: string) => setFieldValue(`${formName}.${index}.methodType`, value)}
          placeholder={t("common.select")}
        >
          <Select.Option value="Get">{t("appManagement.provisioning.get")}</Select.Option>
          <Select.Option value="Post">{t("appManagement.provisioning.post")}</Select.Option>
        </Field>
        <ErrorMessage name={`${formName}.${index}.methodType`} component="div" className="custom-error error-message" />
      </div>
      <div className="mb-10 input-container 2xl:w-[42%]  w-[38%]">
        <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">
          {t("appManagement.provisioning.requestPayload")} {values[formName][index].methodType === "Post" && "*"}
        </p>
        <Field
          id="requestPayload"
          as={TextArea}
          type="text"
          className="min-h-[200px] font-medium text-[18px] form-type-box"
          name={`${formName}.${index}.requestPayload`}
          onChange={(e: any) => setFieldValue(`${formName}.${index}.requestPayload`, e.target.value)}
        />
        <ErrorMessage name={`${formName}.${index}.requestPayload`} component="div" className="error-message custom-error" />
      </div>

      <div className="mb-0 w-[42%] input-container">
        <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">{t("appManagement.provisioning.response")}*</p>
        <Field
          id="response"
          type="text"
          className="min-h-[200px] font-medium text-[18px] form-type-box"
          name={`${formName}.${index}.response`}
          as={TextArea}
          onChange={(e: any) => {
            setFieldValue(`${formName}.${index}.response`, e.target.value);
            setFieldValue(`${formName}.${index}.primaryKeyAttributeList`, [""]);
          }}
        />
        <ErrorMessage name={`${formName}.${index}.response`} className="error-message custom-error" component="div" />
      </div>

      {/* primaryKeyAttributeList Start */}

      {/* <div className="mb-10 input-container 2xl:w-[42%]  w-[38%]">
        <p className="text-[#1D1D24] font-Inter text-[18px]  font-medium"> Primary key attributes *</p>
        <Field id="primaryKeyAttributeList" as={Input} type="text" className="h-[56px]  text-[18px] font-medium form-type-box" name={`${formName}.${index}.primaryKeyAttributeList`} />
        <ErrorMessage name={`${formName}.${index}.primaryKeyAttributeList`} component="div" className=" custom-error error-message " />
         <pre>{JSON.stringify(responseAttributes, null, 1)}</pre> 
        
        // <Field
        //   id="primaryKeyAttributeList"
        //   name={`${formName}.${index}.primaryKeyAttributeList`}
        //   type="text"
        //   as={Select}
        //   className="h-[56px] loginThemeText font-medium text-[18px] w-[100%] "
        //   value={values[formName][index]?.primaryKeyAttributeList || undefined}
        //   filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        //   onChange={(value: string) => setFieldValue(`${formName}.${index}.primaryKeyAttributeList`, value)}
        // >
        //   {handleOptionsCreated(responseAttributes)?.map((option: any) => (
        //     <Select.Option key={option.value} value={option.value}>
        //       {option.label}
        //     </Select.Option>
        //   ))}
        // </Field> 
      </div> */}
      <div className="mb-10 w-full">
        <FieldArray name={`${formName}.${index}.primaryKeyAttributeList`}>
          {({ push, remove, form }) => (
            <div className="w-full header-parameter-wrapper">
              {form.values[formName]?.[index]?.primaryKeyAttributeList?.map((param: string, paramIndex: number) => (
                <div key={paramIndex} className="w-[50%] relative flex justify-start gap-x-20">
                  <div className="mt-4 header-param w-[84%] input-container">
                    <p className={`${paramIndex === 0 ? "text-[#1D1D24] flex text-[18px] font-medium items-center font-Inter" : "opacity-0"}`}>
                      {t("appManagement.provisioning.responseAttributesMapping")}
                    </p>
                    <Field
                      id={`key-${paramIndex}`}
                      as={Input}
                      type="text"
                      className="h-[56px] font-medium text-[18px] form-type-box"
                      name={`${formName}.${index}.primaryKeyAttributeList.${paramIndex}`}
                      placeholder={t("appManagement.provisioning.key")}
                    />
                    <ErrorMessage name={`${formName}.${index}.primaryKeyAttributeList.${paramIndex}`} component="div" className="error-message custom-error" />
                  </div>
                  <div className="absolute provision-action">
                    {form.values[formName][index].primaryKeyAttributeList.length > 1 && (
                      <button type="button" onClick={() => remove(paramIndex)} className="remove-btn" data-testid="remove-btn">
                        <span className="material-symbols-outlined provision-plus-btn">remove</span>
                      </button>
                    )}

                    {paramIndex === form.values[formName][index].primaryKeyAttributeList.length - 1 && (
                      <button
                        type="button"
                        onClick={() => {
                          if (form?.values[formName][index]?.primaryKeyAttributeList[paramIndex] !== "") {
                            push("");
                          }
                        }}
                        data-testid="add-btn"
                      >
                        <span className="material-symbols-outlined provision-plus-btn">add</span>
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </FieldArray>
      </div>

      {/* primaryKeyAttributeList End */}

      {/* {shouldRender() && ( */}
      <FieldArray name={`${formName}.${index}.headerParam`}>
        {({ push, remove }) => (
          <div className="w-full header-parameter-wrapper">
            {samlConfig?.headerParam?.map((param: any, paramIndex: any) => (
              <div key={param} className="w-full  relative flex justify-start gap-x-20  ">
                <div className=" relative mt-4 header-param  2xl:w-[42%]  w-[38%] input-container">
                  <p className={`${paramIndex === 0 ? "text-[#1D1D24] flex text-[18px]  font-medium items-center font-Inter " : " opacity-0"}`}>{t("appManagement.provisioning.requestParameters")}</p>
                  <Field
                    id="key"
                    as={Input}
                    type="text"
                    className="h-[56px] font-medium text-[18px] form-type-box"
                    name={`${formName}.${index}.headerParam.${paramIndex}.key`}
                    placeholder={t("appManagement.provisioning.key")}
                  />
                  <ErrorMessage name={`${formName}.${index}.headerParam.${paramIndex}.key`} component="div" className="error-message custom-error" />
                </div>
                <div className=" relative mt-4 header-param  2xl:w-[42%]  w-[38%] input-container">
                  <p className={`${paramIndex === 0 ? "text-[#1D1D24] font-Inter flex text-[18px] items-center  font-medium" : " opacity-0"}`}>&nbsp;</p>
                  <Field
                    id="value"
                    placeholder={t("appManagement.provisioning.value")}
                    as={Input}
                    type="text"
                    className="h-[56px] font-medium text-[18px] form-type-box"
                    name={`${formName}.${index}.headerParam.${paramIndex}.value`}
                  />
                  <ErrorMessage name={`${formName}.${index}.headerParam.${paramIndex}.value`} component="div" className="error-message custom-error" />
                </div>
                <div className="absolute provision-action">
                  {samlConfig.headerParam.length > 1 && (
                    <button type="button" onClick={() => remove(paramIndex)} className=" remove-btn" data-testid="remove-btn">
                      <span className="material-symbols-outlined provision-plus-btn ">remove</span>
                    </button>
                  )}
                  {paramIndex === samlConfig.headerParam.length - 1 && (
                    <button type="button" onClick={() => push({ key: "", value: "" })} data-testid="add-btn">
                      <span className="material-symbols-outlined provision-plus-btn">add</span>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </FieldArray>
      {/* )} */}
      <FieldArray name={`${formName}.${index}.pathVariable`}>
        {({ push, remove }) => (
          <div className="w-full  header-parameter-wrapper">
            {samlConfig?.pathVariable?.map((param: any, paramIndex: any) => (
              <div key={param} className="w-full flex justify-start gap-x-20 relative">
                <div className=" relative mt-4  2xl:w-[42%]  w-[38%] input-container header-param">
                  <p className={`${paramIndex === 0 ? "text-[#1D1D24] flex text-[18px]  font-medium items-center font-Inter " : " opacity-0"}`}>{t("appManagement.provisioning.pathVariable")}</p>
                  <Field
                    id="key"
                    as={Input}
                    type="text"
                    className="h-[56px] font-medium text-[18px] form-type-box"
                    name={`${formName}.${index}.pathVariable.${paramIndex}.key`}
                    placeholder={t("appManagement.provisioning.key")}
                  />
                  <ErrorMessage name={`${formName}.${index}.pathVariable.${paramIndex}.key`} component="div" className="error-message custom-error" />
                </div>
                <div className=" relative mt-4  2xl:w-[42%]  w-[38%] input-container header-param">
                  <p className={`${paramIndex === 0 ? "text-[#1D1D24] font-Inter flex text-[18px] items-center  font-medium" : " opacity-0"}`}>&nbsp;</p>
                  <Field
                    id="value"
                    placeholder={t("appManagement.provisioning.value")}
                    as={Input}
                    type="text"
                    className="h-[56px] font-medium text-[18px] form-type-box"
                    name={`${formName}.${index}.pathVariable.${paramIndex}.value`}
                  />
                  <ErrorMessage name={`${formName}.${index}.pathVariable.${paramIndex}.value`} component="div" className="error-message custom-error" />
                </div>
                <div className="absolute provision-action ">
                  {samlConfig.pathVariable.length > 1 && (
                    <button type="button" onClick={() => remove(paramIndex)} className=" remove-btn" data-testid="remove-btn">
                      <span className="material-symbols-outlined provision-plus-btn">remove</span>
                    </button>
                  )}
                  {paramIndex === samlConfig.pathVariable.length - 1 && (
                    <button type="button" onClick={() => push({ key: "", value: "" })} data-testid="add-btn">
                      <span className="material-symbols-outlined provision-plus-btn">add</span>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default UserProvisionForm;
