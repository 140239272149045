import { Button } from "antd";
import React, { useState } from "react";
import ImageView from "../../../../layouts/component/ImageView";
import { images } from "../../../../assets/images/ImagePaths";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface TemporaryPasswordEvent {
  lastUpdatedOn: string;
}

interface UserInformationHeaderProps {
  handleResetPasswordModal: () => void;
  handleResetPasswordHistoryModal: () => void;
  temporaryPasswordEvents?: TemporaryPasswordEvent[];
}

export default function UserInformationHeader({ temporaryPasswordEvents, handleResetPasswordHistoryModal }: UserInformationHeaderProps) {
  const { t } = useTranslation();
  const customerDetailsDefaultPassword = useSelector((state: any) => state?.userBasicDetails?.customerDetailsDefaultPassword);

  const navigate = useNavigate();

  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  const handleNavigate = () => {
    navigate("/user");
  };

  const handleResetPasswordModal = () => {
    setResetPasswordModalOpen(true);
  };
  const handleCloseResetPasswordModal = () => {
    setResetPasswordModalOpen(false);
  };

  return (
    <div className="flex justify-between">
      <div>
        <div className="editUser-path mb-2 flex">
          <p role="none" onClick={handleNavigate} className=" cursor-pointer">
            {t("menu.userManagement")} /
          </p>
          <span> {t("userManagement.editInformation")}</span>
        </div>
        <h1 className="editUser-title mb-6">{t("userManagement.userInformation")}</h1>
      </div>

      <div className="w-1/2 flex justify-end items-start">
        <div className="flex items-end flex-col justify-end ">
          {customerDetailsDefaultPassword && (
            <>
              <Button
                type="primary"
                className=" ml-4  flex items-center justify-evenly font-semibold text-[18px] font-Inter w-[187px] h-[44px] bg-[#5441DA] text-white rounded"
                size="large"
                onClick={handleResetPasswordModal}
              >
                {t("userManagement.resetPassword")}
              </Button>

              <div className="flex justify-center items-center gap-1 mt-2 cursor-pointer" role="none" onClick={handleResetPasswordHistoryModal}>
                {temporaryPasswordEvents && temporaryPasswordEvents.length > 0 && (
                  <>
                    <ImageView src={images?.clock} alt="chevronDown" />
                    <span className="text-[#737373] text-[16px] font-normal font-Inter ">
                      {t("userManagement.lastUpdatedOn")} : {temporaryPasswordEvents?.[0]?.lastUpdatedOn}
                    </span>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
