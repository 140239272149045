import React, { useEffect, useState } from "react";
import { Input, Select, Tooltip } from "antd";
import { ErrorMessage, Field } from "formik";
import PhoneInput from "react-phone-input-2";
import { retrieveData } from "../../../../../services/storage/Storage";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../toast/ErrorNotificationMessage";
import "react-phone-input-2/lib/bootstrap.css";

const GeneralDetailsFormEdit = (props: any) => {
  const getThemeList = useGetApiRequests("getThemeList", "GET");
  const getSmsProvider = useGetApiRequests("getSmsServiceProviders", "GET");

  const { errors, setFieldValue, values } = props;

  const environment = process.env.REACT_APP_ENV;
  const realmName = retrieveData("realmName", true);
  const [loader, setLoader] = useState<boolean>(false);
  const [themeLists, setThemeLists] = useState<any>([]);
  const [smsProviderList, setSmsProviderList] = useState<any>([]);

  useEffect(() => {
    getServiceProviders();
    initThemeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getServiceProviders = async () => {
    try {
      const response: any = await getSmsProvider();
      const status = response.status;
      if (status === 200) {
        const list = response?.data?.data;
        const providersList = Object.entries(list)?.map(([label, value]) => ({
          label,
          value,
        }));
        setSmsProviderList(providersList);
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const initThemeList = async () => {
    setLoader(true);
    try {
      const response: any = await getThemeList();
      const status = response.status;
      if (status === 200) {
        const options: any[] = [];

        const themes = response?.data?.loginCustomerThemes;
        Object.entries(themes).forEach(([theme, url]) => {
          options.push({
            value: url,
            label: theme,
          });
        });

        setThemeLists(options);
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };
  const handleLoginPasswordChange = (checked: boolean) => {
    setFieldValue("passwordLogin", checked);
  };
  const handleResetPassword = (checked: boolean) => {
    setFieldValue("defaultPassword", checked);
  };

  const handleNext = () => {
    if (Object.keys(errors).length === 0) {
      const submittedValues: any = {
        customerId: values?.customerId,
        displayName: values?.displayName,
        primaryEmailId: values?.customerEmailId,
        noOfLicense: values?.noOfLicense,
        primaryMobileNumber: values?.phone,
        loginTheme: values?.loginTheme,
        parentCustomer: realmName,
        customerType: "CLIENT",
        passwordLogin: values?.passwordLogin,
        defaultPassword: values?.defaultPassword,
        smsServiceProvider: values?.smsServiceProvider,
      };
      const customerGeneralDetail = {
        customerGeneralDetailsDto: submittedValues,
      };
    }
  };

  const getDomainSuffix = (env: any) => {
    const akkuWorkEnvironments = ["sandbox", "production", "uat", "staging", "development"];

    return akkuWorkEnvironments.includes(env) ? ".akku.work" : ".cloudnowtech.com";
  };

  return (
    <div className={`w-full overflow-y-auto general-details-container`}>
      <div className={` overflow-x-hidden mb-10 w-[92%]`}>
        <div className={`w-[90%] flex flex-col edit-basic`}>
          <div className={`w-full  flex mx-auto justify-between flex-wrap pb-2`}>
            {/* <div className="mb-10 w-[42%] relative input-container ">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Customer ID*</p>
              <Field disabled data-testid="customerId-test" as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="customerId" maxLength={30} />
              {values?.customerId && <p className="text-[#1D1D24] text-[17px] font-Inter font-medium mt-1 pl-3">{`https://${values.customerId}${getDomainSuffix(environment)}`}</p>}
              <ErrorMessage name="customerId" component="div" className="error-message" />
            </div> */}

            <div className={`mb-10  input-container relative w-[42%]`}>
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Display name*</p>
              <Field disabled as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="displayName" data-testid="displayName-test" maxLength={30} />
              <ErrorMessage name="displayName" component="div" className="error-message" />
            </div>

            {/* <div className={`mb-10 w-[42%] input-container relative`}>
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Customer email ID*</p>
              <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="customerEmailId" data-testid="customerEmailId-test" />
              <ErrorMessage name="customerEmailId" component="div" className="error-message" />
            </div> */}

            <div className={`mb-10 input-container relative w-[42%]`}>
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">No. of license*</p>
              <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="noOfLicense" data-testid="noOfLicense-test" />
              <ErrorMessage name="noOfLicense" component="div" className="error-message" />
            </div>
            <div className={`mb-10  input-container relative w-[42%]`}>
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Login theme</p>

              <Field
                as={Select}
                type="text"
                className="h-[56px] font-medium text-[18px] w-[100%] loginThemeText"
                name="loginTheme"
                value={values.loginTheme || undefined}
                loader={loader}
                onChange={(value: string) => setFieldValue("loginTheme", value)}
                filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select the option"
              >
                {themeLists.map((option: any) => (
                  <Select.Option key={option.value} value={option.value}>
                    <Tooltip title={option.label}>{option.label}</Tooltip>
                  </Select.Option>
                ))}
              </Field>
              <ErrorMessage name="loginTheme" component="div" className="error-message" />
            </div>

            <div className={`"mb-10  input-container relative w-[42%]`}>
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Mobile Number*</p>
              <PhoneInput inputClass="ant-input h-[56px] w-[100%]" country={"us"} value={values.phone} onChange={(value) => setFieldValue("phone", value)} />
              <ErrorMessage name="phone" component="div" className="error-message" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralDetailsFormEdit;
