import React, { useCallback, useEffect, useState } from "react";
import MyAppCard from "../card/MyAppCard";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { useSelector } from "react-redux";
import { Empty } from "antd";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData, storeData } from "../../../../services/storage/Storage";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";

const AvailableApps = () => {
  const { t } = useTranslation();

  const masterAvailableApps = useGetApiRequests("masterAvailableApps", "POST");
  const [masterData, setMasterData] = useState<any>();
  const searchApp = useSelector((state: any) => state?.appSearchSlice?.searchApps[0]);
  const reInitiateApps = useSelector((state: any) => state?.appsRenderSlice?.appRender);
  const realmId = retrieveData("realmId", true);

  useEffect(() => {
    debouncedSearchMyApps(searchApp);
  }, [reInitiateApps, searchApp]);

  // Debounced API call function
  const debouncedSearchMyApps = useCallback(
    debounce((searchApp) => {
      getFilterData(searchApp);
    }, 800),
    [],
  );

  const headers = {
    "Content-Type": "application/json",
  };
  const sortAppsByNameList = (items: any) => {
    const sortedArrays = items?.sort((a: any, b: any) => {
      const firstElements = a?.name?.toUpperCase();
      const secondElements = b?.name?.toUpperCase();
      if (firstElements < secondElements) {
        return -1;
      }
      if (firstElements > secondElements) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    return sortedArrays;
  };
  const getFilterData = async (searchApp: any) => {
    let payload = { realmId: realmId, ...searchApp };
    try {
      const response: any = await masterAvailableApps(payload, "", {}, headers);
      const status = response.status;
      if (status === 200) {
        const data = response.data?.data;
        const sortedData: any = sortAppsByNameList(data?.akkuMasterClient);
        setMasterData(sortedData);
        storeData("akkuCustometId", data.akkuCustomer.customerId, true);
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };

  return (
    <div className="pt-7">
      <p className="text-[rgb(23,23,23)] text-[28px] font-Inter font-semibold pl-5 pb-6 available-apps-title">
        {t("appManagement.appStore")} ({masterData?.length}){" "}
      </p>
      <div className="flex flex-wrap">
        {masterData?.length !== 0 && <MyAppCard masterData={masterData} available={true} />}
        {masterData?.length === 0 && (
          <div className="w-full flex justify-center">
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
};

export default AvailableApps;
