import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
const UsersSearchDropdown = ({ handleAddApps, setDropdownVisible }: any) => {
  const { t } = useTranslation();
  const handleCancel = () => {
    setDropdownVisible(false);
  };
  return (
    <div>
      <div style={{ padding: "8px", textAlign: "center" }}>
        <div className="w-full flex justify-end ">
          <Button className="w-[144px] h-[48px]  mr-2 font-semibold text-sm" onClick={() => handleCancel()}>
            {t("common.cancel")}
          </Button>
          <Button type="primary" className="bg-[#5441DA] w-[144px] h-[48px] font-semibold text-sm" onClick={handleAddApps}>
            l{t("common.add")}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default UsersSearchDropdown;
