import { ErrorMessage, Field, FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Select } from "antd";
import Loader from "../../../../../../../../layouts/component/Loader";
import { handleRequestError } from "../../../../../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../../../../../services/storage/Storage";
import useGetApiRequests from "../../../../../../../../services/axios/useApiRequests";
import DeleteAttributes from "./DeleteAttributes";
import { useTranslation } from "react-i18next";
interface AddAttribute {}
interface UserAttribute {}

const MapAttributeForm = (props: any) => {
  const { values, setFieldValue, setFormInitial, transformList, editAttribute, handleMapperBack, setIsAttributeDelete, isAttributeDelete } = props;
  const { t } = useTranslation();
  const getMapperAttributes = useGetApiRequests("getMapperAttributes", "POST");
  const [adAttributeList, setAdAttributeList] = useState<AddAttribute[]>([]);
  const [userAttributeList, setUserAttributeList] = useState<UserAttribute[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const realmName = retrieveData("realmName", true);
  const componentId = retrieveData("componentId", true);
  let getComponentId = retrieveData("getComponentDetails", true);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [attributeConfig, setAttributeConfig] = useState<any>();

  useEffect(() => {
    getComponentTypeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const removeExistingAttribute = (attributes: any, attributeList: any) => {
    // Extract sources and targets from the attributes array
    const sourcesList = attributes.map((item: any) => item.source);
    const targetList = attributes.map((item: any) => item.target);

    // Filter out existing attributes from attributeList
    const addAttributeList = attributeList?.adAttributeList.filter((elm: any) => !sourcesList.includes(elm)) || [];
    const addUserAttributeList = attributeList?.userAttributeList.filter((elm: any) => !targetList.includes(elm)) || [];

    setAdAttributeList(addAttributeList);
    setUserAttributeList(addUserAttributeList);
  };

  const getComponentTypeList = async () => {
    setLoader(true);
    let payload;
    if (editAttribute) {
      payload = { componentId: getComponentId, realmName: realmName, userSpecific: true };
    } else {
      payload = {
        componentId: componentId?.data?.componentId,
        realmName: realmName,
        userSpecific: true,
      };
    }
    try {
      const response: any = await getMapperAttributes(payload);
      const status = response.status;
      if (status === 200) {
        removeExistingAttribute(values.forms, response?.data?.data);
      }
      setLoader(false);
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const handleOnChange = (setFieldValue: any, field: string, val: any, index: number, values: any) => {
    const fields = field;
    const setFields = values?.forms.map((elm: any, ind: number) => {
      if (index === ind) {
        elm[fields] = val;
      }
    });
    setFormInitial(setFields);
    // Filter out existing attributes from attributeList
    if (field === "target") {
      const filteredUserAttributes = userAttributeList.filter((attribute: any) => attribute !== val);
      setUserAttributeList(filteredUserAttributes);
    } else {
      const filteredAttributes = adAttributeList.filter((attribute: any) => attribute !== val);
      setAdAttributeList(filteredAttributes);
    }
  };
  const handleRemove = (remove: any, index: number, value: any) => {
    if (value?.id !== "") {
      setIsDelete(true);
      setAttributeConfig(value);
    } else {
      remove(index);
    }
    // add removed attributes to attributeList
    setAdAttributeList((prevAttributes: any) => [...prevAttributes, value?.source].filter((attribute) => attribute.trim() !== ""));
    setUserAttributeList((prevUsersList: any) => [...prevUsersList, value?.target].filter((attribute) => attribute.trim() !== ""));
  };
  const handleBack = () => {
    handleMapperBack();
  };
  const handleClose = () => {
    setIsDelete(false);
  };
  return (
    <>
      {loader && <Loader />}
      <FieldArray name="forms">
        {({ push, remove }) => (
          <>
            <div className="mapper">
              {values?.forms.map((formItem: any, index: any) => (
                <div key={formItem?.id} className="w-full flex mapper-attributes">
                  <div className=" w-[33%] pb-6 mr-10 source">
                    <Field
                      as={Select}
                      suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                      className={values.forms[index]?.isCheck ? " w-[100%] h-[56px] pointer-events-none cursor-not-allowed" : " w-[100%] h-[56px] "}
                      name={`forms.${index}.source`}
                      values={values.forms[index]?.source}
                      onChange={(value: any) => handleOnChange(setFieldValue, "source", value, index, values)}
                      filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {adAttributeList?.map((option: any) => (
                        <Select.Option key={option} value={option}>
                          {option}
                        </Select.Option>
                      ))}
                    </Field>
                    <ErrorMessage name={`forms.${index}.source`} component="div" className="error-message custom-error" />
                  </div>
                  <div className=" w-[33%] pb-6 mr-10 source">
                    <Field
                      as={Select}
                      suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                      className={values.forms[index]?.isCheck ? " w-[100%] h-[56px] pointer-events-none cursor-not-allowed" : " w-[100%] h-[56px] "}
                      name={`forms.${index}.target`}
                      values={values.forms[index]?.target}
                      onChange={(value: any) => handleOnChange(setFieldValue, "target", value, index, values)}
                      filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {userAttributeList?.map((option: any) => (
                        <Select.Option key={option} value={option}>
                          {option}
                        </Select.Option>
                      ))}
                    </Field>
                    <ErrorMessage name={`forms.${index}.target`} component="div" className="error-message custom-error" />
                  </div>
                  <div className=" w-[33%] pb-6   ">
                    <Field
                      as={Select}
                      suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                      className={values.forms[index]?.isCheck ? " w-[100%] h-[56px] pointer-events-none cursor-not-allowed" : " w-[100%] h-[56px] "}
                      name={`forms.${index}.userType`}
                      values={values.forms[index]?.userType}
                      onChange={(value: any) => handleOnChange(setFieldValue, "userType", value, index, values)}
                      filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {transformList.map((option: any) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.id}
                        </Select.Option>
                      ))}
                    </Field>
                    <ErrorMessage name={`forms.${index}.userType`} component="div" className="error-message custom-error" />
                  </div>

                  <div className="w-[270px] flex justify-start ml-5 action ">
                    <span
                      role="none"
                      className={`${values.forms[index]?.isCheck ? " hidden " : ""} add material-symbols-outlined cursor-pointer ml-5 ${values.forms.length - 1 === index ? "add-map-block" : "add-map-hidden"}`}
                      onClick={() => push({ source: "", target: "", userType: "", isCheck: false, id: "" })}
                    >
                      add
                    </span>
                    <span
                      className={`${values.forms[index]?.isCheck ? " hide-filled pointer-events-none" : ""} add material-symbols-outlined cursor-pointer  ml-5 `}
                      onClick={() => handleRemove(remove, index, values.forms[index])}
                      role="none"
                    >
                      remove
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div className="footer edit-mapper">
              <div className="modal-footer w-[95%] mx-auto ">
                <div className="w-full flex justify-end">
                  {editAttribute ? (
                    <>
                      <Button type="primary" onClick={handleBack} className=" btn secondary mr-4">
                        {t("common.back")}
                      </Button>
                      <Button type="primary" htmlType="submit" className=" btn w-auto">
                        {t("userManagement.reviewMapping")}
                      </Button>
                    </>
                  ) : (
                    <Button type="primary" htmlType="submit" className="w-auto btn">
                      {t("userManagement.reviewMapping")}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </FieldArray>
      <DeleteAttributes handleClose={handleClose} isDelete={isDelete} attributeConfig={attributeConfig} setIsAttributeDelete={setIsAttributeDelete} isAttributeDelete={isAttributeDelete} />
    </>
  );
};

export default MapAttributeForm;
