import React from "react";
import "../page-notfound/PageNotFound.scss";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { retrieveData } from "../../services/storage/Storage";
import { useNavigate } from "react-router-dom";
import CustomButtonNext from "../../layouts/component/CustomButton";
import { handleRequestError } from "../../layouts/toast/ErrorNotificationMessage";
const DeploymentErrorPage = () => {
  const realmId = retrieveData("realmId", true);
  const navigate = useNavigate();
  const getLicenseCount = useGetApiRequests("getLicenseCount", "GET");

  const getUsersLicenseCount = async () => {
    const pathParam = {
      realmId: realmId,
    };
    try {
      const response: any = await getLicenseCount("", {}, pathParam);
      const status = response.status;

      if (status === 200) {
        navigate(-1);
      }
    } catch (err) {
      handleRequestError(err);
    }
  };
  return (
    <div className="page-not-found">
      <div className="page-content">
        <div className="text-center">
          <h1>404</h1>
          <p className="flex justify-center mt-4 items-center flex-col">
            <p className="w-[40%]">
              We're currently performing some unexpected maintenance to address a technical issue. We apologize for any inconvenience this may cause and expect to have the system back online shortly.
              Thank you for your patience.
            </p>
            <CustomButtonNext text={"Try again"} onClick={() => getUsersLicenseCount()} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeploymentErrorPage;
