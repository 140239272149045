import React, { useState, useRef, useEffect } from "react";
import { hasPermission } from "../../../../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../../../../const/RolePermissions";
import { useSelector } from "react-redux";
import PermissionsModal from "../../../../../../layouts/permissionsModal/PermissionsModal";
import { cursorNotAllowedStyle } from "../../../../../../hooks/hooks";
import { PermissionsState } from "../StatusToggleHelper";
import { useTranslation } from "react-i18next";

interface SSOStatusToggleProps {
  handleToggle: (item: any, index: number, status: boolean) => void;
  item: any;
  index: number;
}

export default function SSOStatusToggle({ handleToggle, item, index }: SSOStatusToggleProps) {
  const { t } = useTranslation();

  const permissions = useSelector((state: PermissionsState) => state?.permissionsSlice?.attributes);

  const hasEditAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "update");

  const [showToggleSSOStatus, setShowToggleSSOStatus] = useState(false);
  const [permissionModal, setPermissionModal] = useState(false);

  const toggleRef = useRef<HTMLDivElement>(null);
  const appSSOstatus = item?.akkuClientDto?.client?.alwaysDisplayInConsole;

  const handleActiveInActive = () => {
    if (hasEditAppStorePermission) {
      setShowToggleSSOStatus((prev) => !prev);
    } else {
      setPermissionModal(true);
    }
  };

  const handleSelectStatus = (el: boolean) => {
    handleToggle(item, index, el);
    setShowToggleSSOStatus(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (toggleRef.current && !toggleRef?.current?.contains(event.target as Node)) {
      setShowToggleSSOStatus(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClosePermissionModal = () => {
    setPermissionModal(false);
  };

  return (
    <div ref={toggleRef}>
      {showToggleSSOStatus && (
        <div>
          {appSSOstatus ? (
            <p role="none" className="card-inactive-btn absolute cursor-pointer hover:bg-[#f7f7f7]" onClick={() => handleSelectStatus(false)}>
              <span></span> {t("common.inactive")}
            </p>
          ) : (
            <p className="card-active-btn absolute cursor-pointer  hover:bg-[#f7f7f7]" role="none" onClick={() => handleSelectStatus(true)}>
              <span></span> {t("common.active")}
            </p>
          )}
        </div>
      )}

      {appSSOstatus ? (
        <p role="none" onClick={() => handleActiveInActive()} className={`card-status-active hover:bg-[#caf1cf]  ${cursorNotAllowedStyle(hasEditAppStorePermission)} `}>
          <span></span>
          {t("common.active")}
        </p>
      ) : (
        <p role="none" onClick={() => handleActiveInActive()} className={`card-status-inactive hover:bg-[#ffd7d2] ${cursorNotAllowedStyle(hasEditAppStorePermission)}`}>
          <span></span> {t("common.inactive")}
        </p>
      )}

      {permissionModal && <PermissionsModal open={permissionModal} close={handleClosePermissionModal} />}
    </div>
  );
}
