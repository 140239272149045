import React, { useEffect, useState } from "react";
import ImageView from "../../../../layouts/component/ImageView";
import { Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { storeData } from "../../../../services/storage/Storage";
import DeleteApps from "../modal/DeleteApps";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../layouts/toast/ToastBar";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { useDispatch, useSelector } from "react-redux";
import { setAppConfigDetails } from "../../../../redux/slice/app-store/AppDetailsSlice";
import { getPermissionStyle, hasPermission } from "../../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../../const/RolePermissions";
import PermissionsModal from "../../../../layouts/permissionsModal/PermissionsModal";
import NewAppConfiguration from "../modal/new-app-configure/NewAppConfiguration";
import CardOption from "./card-option/CardOption";
import { displayAppLogo, displayAppName, displayDescription } from "./MyAppCardHelper";
import ProvisioningCardOption from "./card-option/ProvisioningCardOption";
import SSOCardOption from "./card-option/SSOCardOption";
import { setIsConfigureButtonPressed } from "../../../../redux/slice/app-store/appConfigureSlice";
import { setAppRender } from "../../../../redux/slice/appsRenderSlice";

import "./Card.scss";
import { cursorNotAllowedStyle } from "../../../../hooks/hooks";
import { useTranslation } from "react-i18next";

const MyAppCard = (props: any) => {
  const { t } = useTranslation();

  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const reInitiateApps = useSelector((state: any) => state?.appsRenderSlice?.appRender);

  const hasEditAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "update");
  const hasCreateAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "create");

  const { myAppDetails, masterData, available, getProvisioningAppList, provisioningLists } = props;
  const clientDisplayConsole = useGetApiRequests("clientDisplayConsole", "PUT");
  const [appDetails, setAppDetails] = useState([]);
  const [myAllAppDetails, setMyAppDetails] = useState([]);
  const [currentAppDetails, setCurrentAppDetails] = useState([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [permissionsModal, setPermissionsModal] = useState<boolean>(false);
  const [appData, setAppData] = useState<any>(null);
  const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.available) {
      setAppDetails(masterData);
    } else {
      setMyAppDetails(myAppDetails);
    }
  }, [props]);

  const ConfigureSingleApp = () => {
    if (hasCreateAppStorePermission) {
      if (appData !== null) {
        dispatch(setAppConfigDetails(appData));
      }
      storeData("configureApp", "configure", true);
      navigate("/app-store/configure-app");
    } else {
      setPermissionsModal(true);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleToggle = (item: any, index: any, event: any) => {
    // Create a new array with updated item
    if (hasEditAppStorePermission) {
      // const updatedItems: any = myAllAppDetails.map((detail: any, idx: any) => {
      //   if (idx === index) {
      //     return {
      //       ...detail,
      //       client: {
      //         ...detail.client,
      //         alwaysDisplayInConsole: !detail.client.alwaysDisplayInConsole, // Toggle the value
      //       },
      //     };
      //   }
      //   return detail;
      // });

      const queryParams = {
        "akku-client-id": item.akkuClientDto.akkuClientId,
        "is-display-in-console": event,
      };
      clientDisplayConsole("", queryParams)
        .then((response: any) => {
          if (response.status === 200) {
            triggerNotification("success", "", response?.data?.message, "topRight");
            dispatch(setAppRender(!reInitiateApps));
          }
          // setMyAppDetails(updatedItems);
        })
        .catch((err: any) => {
          handleRequestError(err);
        });
    } else {
      setPermissionsModal(true);
    }
  };

  const handelCreateNewApp = (item: any) => {
    if (hasCreateAppStorePermission) {
      if (item !== null) {
        setAppData(item);
      } else {
        setAppData(null);
      }

      dispatch(setAppConfigDetails(item));
      dispatch(setIsConfigureButtonPressed(true));
      setOpenPopUp(true);
    } else {
      setPermissionsModal(true);
    }
  };

  const handleModalClose = () => {
    setOpenPopUp(false);
  };

  const handleCloseHelpModal = () => {
    setPermissionsModal(false);
  };

  return (
    <>
      <div className="w-full flex flex-wrap">
        {appDetails && available ? (
          appDetails.length > 0 &&
          appDetails.map((item: any, index: number) => (
            <div className="2xl:w-1/4  lg:w-1/3 sm:w-1/2" key={item.akkuMasterClientId}>
              <div className="mx-4  h-[270px] app-card  mb-8 relative ">
                <div className="card-img rounded-t-lg">
                  <ImageView src={item.logoUrl} alt="my apps" className=" w-[60px] h-[60px] my-apps-icon" />
                </div>
                <div className="px-4 py-2">
                  <p className="text-[#1D1D24] capitalize text-[20px] font-Inter font-semibold my-app-card-name ">{item.name}</p>
                  <Tooltip title={item.description}>
                    <p className="text-[#444] text-[16px] font-Inter card-description">{item.description}</p>
                  </Tooltip>
                </div>
                <div className="card-action">
                  <p role="none" className={`configure font-Inter ${cursorNotAllowedStyle(hasCreateAppStorePermission)}`} onClick={() => handelCreateNewApp(item)}>
                    {t("appManagement.configure")}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            {myAllAppDetails?.map((item: any, index: number) => (
              <div className="2xl:w-1/4  lg:w-1/3 sm:w-1/2" key={item?.akkuClientId}>
                <div className=" mx-4  h-[310px] app-card relative  mb-8 " key={item?.akkuMasterClient?.akkuMasterClientId}>
                  <div className="h-[100px] flex justify-center bg-[#F5F5FA]  items-center rounded-t-lg rounded-tl-lg">
                    <ImageView src={item?.logoUrl ? imageBaseUrl + item?.logoUrl : displayAppLogo(item)} alt="my apps" className=" w-[60px] my-apps-icon h-[60px]" />
                    <CardOption item={item} ConfigureSingleApp={ConfigureSingleApp} setPermissionsModal={setPermissionsModal} getProvisioningAppList={getProvisioningAppList} />
                  </div>
                  <div className="px-4 py-2">
                    <p className="text-[#1D1D24] text-[20px] font-Inter font-semibold my-app-card-name">{displayAppName(item)}</p>
                    <Tooltip title={displayDescription(item)}>
                      <p className="text-[#444] text-[16px] font-Inter card-description my-app-card-description">{displayDescription(item)}</p>
                    </Tooltip>
                  </div>
                  <div className="px-4 pt-2 flex justify-between items-center w-full   enable-disable-sso mt-4">
                    <div className="action flex justify-between w-full pb-4 mt-2">
                      <div className="sso-provisioning-labels flex flex-col gap-3">
                        <p className="text-[#323232] text-[16px] font-Inter"> {t("appManagement.sso")}</p>
                        <p className="text-[#323232] text-[16px] font-Inter mt-1"> {t("appManagement.provisioningLabel")}</p>
                      </div>
                      <div className="flex flex-col gap-3 relative">
                        <SSOCardOption handleToggle={handleToggle} item={item} index={index} />
                        <div>
                          <ProvisioningCardOption handelCreateNewApp={handelCreateNewApp} item={item} index={index} provisioningLists={provisioningLists} />
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      {permissionsModal && <PermissionsModal open={permissionsModal} close={handleCloseHelpModal} />}
      {openPopUp && (
        <NewAppConfiguration
          openPopUp={openPopUp}
          handleModalClose={handleModalClose}
          ConfigureSingleApp={ConfigureSingleApp}
          hasCreateAppStorePermission={hasCreateAppStorePermission}
          setPermissionsModal={setPermissionsModal}
        />
      )}
      {openModal && <DeleteApps openModal={openModal} handleClose={handleClose} currentAppDetails={currentAppDetails} />}
    </>
  );
};

export default MyAppCard;
