import { FieldArray, Form, Formik } from "formik";
import { Button, Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomButtonBack from "../../../../../../../layouts/component/CustomButtonBack";
import { ConnectorConfig, FormValues, UpdateParams } from "../../types/provisioningTypes";
import { useEffect, useState } from "react";
import {
  setActiveStep,
  setAssignGroupProvisioningConnectorEndpointConfigId,
  setEndPointResponseDtosLen,
  setGetGroupProvisioningConnectorEndpointConfigId,
  setIsProvisioningApiCalled,
  setIsProvisioningApiError,
  setProvisioning,
  setProvisioningButtonStatus,
} from "../../../../../../../redux/slice/provisioning/ProvisioningSlice";
import { getRoleProvisionErrorEvaluatorScroll, groupEndPointDescriptionPayload, groupProvisionSchema, transformHeaderParameters, UseGetEndpointDescription } from "../../../helper/connectorHelper";
import useGetApiRequests from "../../../../../../../services/axios/useApiRequests";
import { retrieveData } from "../../../../../../../services/storage/Storage";
import { handleRequestError } from "../../../../../../../layouts/toast/ErrorNotificationMessage";

import SuccessMessageModal from "../../../../../../access-manager/create/IpTimeSuccessModal";
import GroupProvisionForm from "../form/GroupProvisionFormField";
import { useTranslation } from "react-i18next";

const GroupProvision = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setActiveKey, formRef } = props;

  const provisioningConnectorConfigure = useGetApiRequests("provisioningConnectorConfigure", "POST");
  const provisioningConnectorConfigureUpdate = useGetApiRequests("provisioningConnectorConfigureUpdate", "PUT");

  const dispatch = useDispatch();
  const formInitialValues = useSelector((state: any) => state?.provisioning?.provisioning);
  const buttonStatus = useSelector((state: any) => state?.provisioning?.duplicateProvisioningButtons);

  const provisioning = useSelector((state: any) => state?.provisioning);
  const provisionDetails = useSelector((state: any) => state?.provisioning?.provisioning);
  const isTenantAdmin = useSelector((state: any) => state?.ProfileDetailsSlice?.isTenantAdmin);
  const getGroupAkkuProvisioningConnectorEndpointConfigId = useSelector((state: any) => state.provisioning?.getGroupProvisioningConnectorEndpointConfigId);
  const assignAkkuProvisioningConnectorEndpointConfigId = useSelector((state: any) => state.provisioning?.assignGroupProvisioningConnectorEndpointConfigId);
  const generalDetails = provisioning?.generalDetails;

  const endPointResponseDtosData = useSelector((state: any) => state?.provisioning?.endPointResponseDtosData);
  const isApiCalled = useSelector((state: any) => state?.provisioning?.isProvisioningApiCalled);
  const isApiError = useSelector((state: any) => state?.provisioning?.isProvisioningApiError);

  const isKeyValueEmptyGetGroup = (provisionDetails?.getGroup[0]?.headerParam as any[])?.some((data: any) => data?.key === "");
  const isKeyValueEmptyAssignGroup = (provisionDetails?.assignGroup[0]?.headerParam as any[])?.some((data: any) => data?.key === "");
  const realmId = retrieveData("realmId", true);

  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [subActiveKey, setSubActiveKey] = useState<string>("1");
  const [responseAttributes, setResponseAttributes] = useState<any>(null);
  useEffect(() => {
    // Revalidate the form on language change
    if (formRef.current) {
      formRef.current.validateForm();
    }
  }, [i18n.language]);
  const [getGroupResponseAttributes, setGetGroupResponseAttributes] = useState<any>(null);
  const [assignGroupResponseAttributes, setAssignGroupResponseAttributes] = useState<any>(null);

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };

  const handleSubmit = (values: FormValues) => {
    if (provisioning?.isDuplicate || isDirty || isApiError?.getGroupProvisionApiError || isApiError?.assignGroupProvisionApiError) {
      if (subActiveKey === "1") {
        getRolePayloadConnectorConfigure(values);
      } else {
        setLoader(true);
        assigneGroupPayloadConnectorConfigure(values);
      }
      dispatch(setProvisioning(values));
    } else if (subActiveKey === "1") {
      setSubActiveKey("2");
    } else {
      dispatch(setActiveStep(3));
    }
  };

  // GetRole API Call Starts

  const getRolePayloadConnectorConfigure = async (values: FormValues) => {
    const params: UpdateParams = { akkuProvisioningConnectorId: provisioning?.akkuProvisioningConnectorId };
    const roleGetPayload: any = createGroupGetPayload(values);
    const isCreated = UseGetEndpointDescription("group_ou_list", endPointResponseDtosData);
    const isDuplicateUpdate = provisioning?.isDuplicate ? provisioning?.isDuplicate && isApiCalled?.getGroupProvisionApiCalled : true; // for duplicate
    const shouldUpdate = isDuplicateUpdate && ((provisioning?.isActiveEdit && isCreated) || isApiCalled?.getGroupProvisionApiCalled);
    if (shouldUpdate) {
      roleGetPayload.akkuProvisioningConnectorEndpointConfigId = getGroupAkkuProvisioningConnectorEndpointConfigId || formInitialValues?.getGroup[0]?.akkuProvisioningConnectorEndpointConfigId;
      roleGetPayload.isActive = provisioning.isActive;
      roleGetPayload.isTenantAdmin = isTenantAdmin;
      handleGetGroupApiCall(provisioningConnectorConfigureUpdate, roleGetPayload, params);
    } else {
      roleGetPayload.isTenantAdmin = isTenantAdmin;
      handleGetGroupApiCall(provisioningConnectorConfigure, roleGetPayload);
    }
  };

  const handleGetGroupApiCall = async (apiCall: (payload: ConnectorConfig, config?: any, params?: UpdateParams) => Promise<any>, payload: ConnectorConfig, params?: UpdateParams) => {
    try {
      const response = await apiCall(payload, {}, params);
      if (response?.status === 200) {
        if (provisioning?.isDuplicate) {
          dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorGetGroupProvisioning: false, connectorAssignGroupProvisioning: true }));
        }

        if (!provisioning?.isActiveEdit || provisioning?.isDuplicate) {
          const provisioningConnectorEndpointConfigId = response?.data?.data?.akkuProvisioningConnectorEndpointConfigId;
          dispatch(setGetGroupProvisioningConnectorEndpointConfigId(provisioningConnectorEndpointConfigId));
        }
        dispatch(setIsProvisioningApiCalled({ ...isApiCalled, getGroupProvisionApiCalled: true }));
        setSubActiveKey("2");
      }
    } catch (err) {
      dispatch(setIsProvisioningApiError({ ...isApiError, getGroupProvisionApiError: true }));
      handleRequestError(err);
    }
  };

  const createGroupGetPayload = (values: FormValues): ConnectorConfig => {
    const { getGroup } = values;
    const baseConfig = {
      apiEndpointUrl: getGroup[0]?.apiEndpointURL,
      methodType: getGroup[0]?.methodType,
      endpointDescription: groupEndPointDescriptionPayload(getGroup[0]?.methodType),
      requestPayload: getGroup[0]?.requestPayload || null,
      sampleResponse: getGroup[0]?.response,
      primaryKeyAttributeList: [""],
      serviceProviderAttributesDto: getGroup[0]?.serviceProviderAttributesDto?.id && getGroup[0]?.serviceProviderAttributesDto?.name ? getGroup[0]?.serviceProviderAttributesDto : null,
      headerParameters: transformHeaderParameters(getGroup[0]?.headerParam),
      pathVariable: transformHeaderParameters(getGroup[0]?.pathVariable),
      realmId: realmId,
      name: generalDetails?.name,
      endpointType: "GENERAL",
      type: "request",
      isGroupProvisioningConfig: true,
    };

    if (provisioning?.isActiveEdit) {
      return {
        ...baseConfig,
        akkuProvisioningConnectorEndpointConfigId: formInitialValues?.getGroup[0]?.akkuProvisioningConnectorEndpointConfigId,
      };
    }

    return baseConfig;
  };

  // AssignRole API Call Starts
  const assigneGroupPayloadConnectorConfigure = async (values: FormValues) => {
    const params: UpdateParams = { akkuProvisioningConnectorId: provisioning?.akkuProvisioningConnectorId };
    const roleAssignPayload: any = createGroupAssignPayload(values);
    const isCreated = UseGetEndpointDescription("assign_group", endPointResponseDtosData);
    const isDuplicateUpdate = provisioning?.isDuplicate ? provisioning?.isDuplicate && isApiCalled?.assignGroupProvisionApiCalled : true; // for duplicate
    const shouldUpdate = isDuplicateUpdate && ((provisioning?.isActiveEdit && isCreated) || isApiCalled?.assignGroupProvisionApiCalled);
    if (shouldUpdate) {
      roleAssignPayload.akkuProvisioningConnectorEndpointConfigId = assignAkkuProvisioningConnectorEndpointConfigId || formInitialValues?.assignGroup[0]?.akkuProvisioningConnectorEndpointConfigId;
      roleAssignPayload.isActive = provisioning?.isActive;
      roleAssignPayload.isTenantAdmin = isTenantAdmin;
      handleAssignRoleApiCall(provisioningConnectorConfigureUpdate, roleAssignPayload, params);
    } else {
      roleAssignPayload.isTenantAdmin = isTenantAdmin;
      handleAssignRoleApiCall(provisioningConnectorConfigure, roleAssignPayload);
    }
  };

  const handleAssignRoleApiCall = async (apiCall: (payload: ConnectorConfig, config?: any, params?: UpdateParams) => Promise<any>, payload: ConnectorConfig, params?: UpdateParams) => {
    try {
      const response = await apiCall(payload, {}, params);

      if (response.status === 200) {
        if (!provisioning?.isActiveEdit || provisioning?.isDuplicate) {
          const provisioningConnectorEndpointConfigId = response?.data?.data?.akkuProvisioningConnectorEndpointConfigId;
          dispatch(setAssignGroupProvisioningConnectorEndpointConfigId(provisioningConnectorEndpointConfigId));
        }
        setLoader(false);
        setResponseMessage(response?.data?.message);
        handleApiResponse(response);
      }
    } catch (err) {
      setLoader(false);
      dispatch(setIsProvisioningApiError({ ...isApiError, assignGroupProvisionApiError: true }));
      handleRequestError(err);
    }
  };

  const createGroupAssignPayload = (values: FormValues): ConnectorConfig => {
    const { assignGroup } = values;
    const baseConfig = {
      apiEndpointUrl: assignGroup[0]?.apiEndpointURL,
      methodType: assignGroup[0]?.methodType,
      endpointDescription: groupEndPointDescriptionPayload(assignGroup[0]?.methodType),
      requestPayload: assignGroup[0]?.requestPayload || null,
      sampleResponse: assignGroup[0]?.response,
      primaryKeyAttributeList: assignGroup[0]?.primaryKeyAttributeList,
      serviceProviderAttributesDto: null,
      headerParameters: transformHeaderParameters(assignGroup[0]?.headerParam),
      pathVariable: transformHeaderParameters(assignGroup[0]?.pathVariable),
      realmId: realmId,
      name: generalDetails?.name,
      type: "request",
      endpointType: "PROVISIONING",
      isGroupProvisioningConfig: true,
    };

    if (provisioning?.isActiveEdit) {
      return {
        ...baseConfig,
        akkuProvisioningConnectorEndpointConfigId: formInitialValues?.assignGroup[0]?.akkuProvisioningConnectorEndpointConfigId,
      };
    }
    return baseConfig;
  };

  const handleApiResponse = (response: any) => {
    const status = response.status;
    if (status === 200) {
      dispatch(setIsProvisioningApiCalled({ ...isApiCalled, assignGroupProvisionApiCalled: true, getGroupProvisionApiCalled: true })); // If ApiCalled set is true
      if (provisioning.endPointResponseDtosLen === 0) {
        dispatch(setEndPointResponseDtosLen(1));
      }
      setLoader(false);
      setResponseMessage(response?.data?.message);
      // setSuccessModal(true);
      // setTimeout(() => {
      if (provisioning?.isDuplicate) {
        dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorAssignGroupProvisioning: false, connectorDeprovisioning: true }));
      }
      // handleCloseSuccessModal();
      dispatch(setActiveStep(3));
      // }, 3000);
    }
  };

  const handleBack = () => {
    if (subActiveKey === "2") {
      setSubActiveKey("1");
    } else {
      setActiveKey("2");
    }
  };

  const handleCollapseChange = async (key: string | string[]) => {
    const activePanelKey = key[0]; // Directly access the first element of the array
    if (subActiveKey === "1") {
      if (activePanelKey === "2") {
        if (!isApiCalled?.getGroupProvisionApiCalled) {
          await formRef.current?.submitForm();
        } else if (formRef.current?.dirty) {
          await formRef.current?.submitForm();
        } else {
          setSubActiveKey("2");
        }
      }
    }

    if (subActiveKey === "2") {
      if (activePanelKey === "1") {
        setSubActiveKey("1");
      }
    }
  };

  const handleSkip = () => {
    if (subActiveKey === "1") {
      setSubActiveKey("2");
    } else {
      dispatch(setActiveStep(3));
    }
  };

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        values={formInitialValues}
        onSubmit={(value: any) => handleSubmit(value)}
        validationSchema={groupProvisionSchema(t, subActiveKey, getGroupResponseAttributes, assignGroupResponseAttributes)}
        enableReinitialize={true}
        innerRef={formRef}
      >
        {({ values, setFieldValue, dirty, isSubmitting, errors }) => {
          setIsDirty(dirty);
          if (isSubmitting) {
            const getErrors: any = errors.getGroup;
            if (getErrors && getErrors.length > 0) {
              getRoleProvisionErrorEvaluatorScroll(getErrors[0], "3");
            } else {
              const assignErrors: any = errors.assignGroup;
              if (assignErrors && assignErrors.length > 0) {
                getRoleProvisionErrorEvaluatorScroll(assignErrors[0], "4");
              }
            }
          }

          return (
            <Form>
              <div className=" w-full flex flex-wrap flex-col gap-2 justify-center items-center">
                <div className="w-[95%]">
                  <Collapse
                    className="w-[100%]  custom-header-provision bg-[#fff]"
                    accordion
                    expandIconPosition="end"
                    activeKey={subActiveKey}
                    onChange={(el: any) => handleCollapseChange(el)}
                    items={[
                      {
                        key: "1",
                        label: <p className="font-normal text-[#000] font-Inter text-[18px]"> {t("appManagement.provisioning.getGroup")}</p>,
                        children: (
                          <FieldArray name="getGroup">
                            {() => (
                              <>
                                {values?.getGroup?.length !== 0 &&
                                  values?.getGroup?.map((samlConfig: any, index: any) => (
                                    <GroupProvisionForm
                                      key={index}
                                      index={index}
                                      values={values}
                                      setFieldValue={setFieldValue}
                                      provisioning={provisioning}
                                      provisionDetails={provisionDetails}
                                      isKeyValueEmptyGetGroup={isKeyValueEmptyGetGroup}
                                      isKeyValueEmptyAssignGroup={isKeyValueEmptyAssignGroup}
                                      samlConfig={samlConfig}
                                      formName="getGroup"
                                      id={"3"}
                                      setGetGroupResponseAttributes={setGetGroupResponseAttributes}
                                    />
                                  ))}
                              </>
                            )}
                          </FieldArray>
                        ),
                      },
                      {
                        key: "2",
                        label: <p className="font-normal text-[#000] font-Inter text-[18px]">{t("appManagement.provisioning.assignGroup")}</p>,
                        children: (
                          <FieldArray name="assignGroup">
                            {() => (
                              <>
                                {values?.assignGroup?.length !== 0 &&
                                  values?.assignGroup?.map((samlConfig: any, index: any) => (
                                    <GroupProvisionForm
                                      key={index}
                                      index={index}
                                      values={values}
                                      setFieldValue={setFieldValue}
                                      provisioning={provisioning}
                                      provisionDetails={provisionDetails}
                                      isKeyValueEmptyGetGroup={isKeyValueEmptyGetGroup}
                                      isKeyValueEmptyAssignGroup={isKeyValueEmptyAssignGroup}
                                      samlConfig={samlConfig}
                                      formName="assignGroup"
                                      id={"4"}
                                      setAssignGroupResponseAttributes={setAssignGroupResponseAttributes}
                                    />
                                  ))}
                              </>
                            )}
                          </FieldArray>
                        ),
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="footer flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
                <div className="modal-footer w-full mx-auto ">
                  <div className="w-full flex justify-end pr-5">
                    <CustomButtonBack text={t("common.skip")} onClick={() => handleSkip()} />
                    <CustomButtonBack text={t("common.back")} onClick={() => handleBack()} />
                    <Button loading={loader} type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                      {t("common.next")}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      {successModal && <SuccessMessageModal open={successModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </>
  );
};

export default GroupProvision;
