import React, { useState } from "react";
import { Dropdown } from "antd";
import NewAppConfiguration from "../../modal/new-app-configure/NewAppConfiguration";
import { useDispatch, useSelector } from "react-redux";
import { hasPermission } from "../../../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../../../const/RolePermissions";
import DeleteProvisioningApp from "../../../provisioning/delete/DeleteProvisioningApp";
import { setProvisioningConfigEdit, setProvisioningEditStatus, setSSOConfigEdit, setSSOEditStatus } from "../../../../../redux/slice/app-store/appConfigureSlice";
import ProvisionEditStatusModal from "../modal/ProvisionEditStatusModal";
import { provisioningMessage } from "../../../const/appStoreConstMessage";
import PermissionsModal from "../../../../../layouts/permissionsModal/PermissionsModal";
import { cursorNotAllowedStyle } from "../../../../../hooks/hooks";
import { setConnectorId, setEditStatusToggle, setGeneralDetailsEdit, setIsDuplicate } from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import { useNavigate } from "react-router-dom";
import { setAppConfigDetails } from "../../../../../redux/slice/app-store/AppDetailsSlice";
import { setAppRender } from "../../../../../redux/slice/appsRenderSlice";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { images } from "../../../../../assets/images/ImagePaths";
import { retrieveData } from "../../../../../services/storage/Storage";
import { setIsPublished } from "../../../../../redux/slice/profile/ProfileDetailsSlice";
import { useTranslation } from "react-i18next";

export default function CardOption({ item, setPermissionsModal, getProvisioningAppList }: any) {
  const { t } = useTranslation();
  const publishingApps = useGetApiRequests("provisioningConnector", "PUT");
  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");
  const isTenantAdmin = useSelector((state: any) => state?.ProfileDetailsSlice?.isTenantAdmin);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appConfigure = useSelector((state: any) => state?.appConfigure);
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const userProfileDetails = useSelector((state: any) => state?.ProfileDetailsSlice?.profileDetails);

  const hasCreateAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "create");
  const hasEditAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "update");
  const hasDeleteAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "delete");
  const reInitiateApps = useSelector((state: any) => state?.appsRenderSlice?.appRender);

  const shouldShowPublish = userProfileDetails?.customerType === "MASTER" || userProfileDetails?.customerType === "MSSP";

  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [deleteAccess, setDeleteAccess] = useState<boolean>(false);
  const [provisionObject, setProvisionObject] = useState<object>({});
  const [isActiveDeleteModal, setIsActiveDeleteModal] = useState<boolean>(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [editAccess, setEditAccess] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [authConfigId, setAuthConfigId] = useState<String>("");

  const handleDeleteProvisionOpenModal = (item: any) => {
    if (hasDeleteAppStorePermission) {
      const checkStatus = item?.akkuProvisioningConnectorDto?.isActive;
      if (checkStatus) {
        setDeleteAccess(true);
      } else {
        if (!item?.akkuProvisioningConnectorDto?.publishStatus) {
          setIsActiveDeleteModal(true);
        } else {
          if (!isTenantAdmin) {
            setIsActiveDeleteModal(true);
          } else {
            getProvisioningConnectorDetails(item);
          }
        }
      }
    } else {
      setPermissionModal(true);
    }
  };

  const getProvisioningConnectorDetails = async (item: any) => {
    setLoading(true);
    let params = {
      akkuProvisioningConnectorId: item?.akkuProvisioningConnectorDto?.akkuProvisioningConnectorId,
    };
    const queryParams = { realmId: retrieveData("realmId", true) };
    try {
      const response: any = await provisioningConnectorDetails("", queryParams, params);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data?.data;
        const authConfigId = data.akkuProvisioningConnectorAuthConfigId;
        setAuthConfigId(authConfigId);
        setIsActiveDeleteModal(true);
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      handleRequestError(err);
    }
  };

  const handleDeleteProvisionCloseModal = () => {
    setIsActiveDeleteModal(false);
  };

  const handleModalClose = () => {
    setOpenPopUp(false);
  };

  const handleRequestEdit = (item: any) => {
    const editConfig = {
      provisioningConfigEdit: !item.akkuProvisioningConnectorDto ? true : false,
      provisioningEditStatus: item?.akkuProvisioningConnectorDto?.isActive || false,
      SSOConfigEdit: item.akkuClientDto ? false : true,
      // SSOEditStatus: item?.akkuClientDto?.akkuCustomer?.active || false,
    };
    dispatch(
      setProvisioningConfigEdit({
        ...appConfigure.provisioningConfigEdit,
        isActiveConfig: editConfig?.provisioningConfigEdit,
      }),
    );
    // setSSOConfigEdit: (state, action: PayloadAction<any>) => {
    //   state.SSOConfigEdit = action.payload;
    // },
    dispatch(
      setSSOConfigEdit({
        ...appConfigure.SSOConfigEdit,
        isActiveConfig: editConfig?.SSOConfigEdit,
      }),
    );

    dispatch(setProvisioningEditStatus(editConfig?.provisioningEditStatus));
    // dispatch(setSSOEditStatus(editConfig?.SSOEditStatus));
    // provisioningEditStatus: item?.akkuProvisioningConnectorDto?.isActive || false,
    // SSOEditStatus: item?.akkuClientDto?.akkuCustomer?.active || false,

    //dispatch(clearAllEditData())
    setOpenPopUp(true);
    setProvisionObject(item);
  };

  const accessRequestEdit = (item: any): void => {
    const isPublished = item?.akkuProvisioningConnectorDto?.publishStatus || false;
    try {
      if (hasEditAppStorePermission) {
        handleRequestEdit(item);
        dispatch(setAppConfigDetails(item?.akkuMasterClientDto));
        dispatch(setIsPublished(isPublished));
      } else {
        setPermissionModal(true);
      }
    } catch (error) {
      console.error("An error occurred while processing the request:", error);
    }
  };

  const handleCloseEditAccessModal = () => {
    setEditAccess(false);
  };

  const handleRequestDuplicate = (item: any) => {
    if (hasCreateAppStorePermission) {
      if (item.akkuProvisioningConnectorDto) {
        // if (item?.akkuProvisioningConnectorDto?.isActive) {
        //   setEditAccess(true);
        //   setMessage(provisioningMessage?.requestDeactivateProvisioning);
        // } else {
        dispatch(setEditStatusToggle(true));
        dispatch(setAppConfigDetails(item?.akkuMasterClientDto));
        dispatch(setConnectorId(item?.akkuProvisioningConnectorDto?.akkuProvisioningConnectorId));
        dispatch(setGeneralDetailsEdit(item?.akkuProvisioningConnectorDto));
        dispatch(setIsDuplicate(true));
        navigate("/account-provision");
        // }
      } else {
        setEditAccess(true);
        setMessage(t("appManagement.requestConfigureProvisioning"));
      }
    } else {
      setPermissionModal(true);
    }
  };

  const handlePublish = async (item: any) => {
    if (
      item?.akkuProvisioningConnectorDto?.isGroupOuProvisioningConfigured ||
      item?.akkuProvisioningConnectorDto?.isRoleProvisioningConfigured ||
      item?.akkuProvisioningConnectorDto?.isUserProvisioningConfigured
    ) {
      const payload = { ...item.akkuProvisioningConnectorDto };
      payload.isActive = true;
      payload.publishStatus = true;
      const pathParams: any = {
        id: item?.akkuProvisioningConnectorDto?.akkuProvisioningConnectorId,
      };
      try {
        const response: any = await publishingApps(payload, "", pathParams);
        const status = response.status;
        if (status === 200) {
          dispatch(setAppRender(!reInitiateApps));
        }
      } catch (err) {
        handleRequestError(err);
      }
    } else {
      setEditAccess(true);
      setMessage(t("appManagement.requestConfigureProvisioning"));
    }
  };

  const items: any["items"] = [
    {
      label: (
        <span
          role="none"
          onClick={() => handleRequestDuplicate(item)}
          className={`flex hover:bg-[#5441DA0F] justify-start items-center  px-3 py-1  ${cursorNotAllowedStyle(hasEditAppStorePermission)}`}
        >
          <span className="material-symbols-outlined mr-2">content_copy</span>
          <span className="text-[16px] font-medium"> {t("appManagement.duplicate")}</span>
        </span>
      ),
      key: "1",
    },
    ...(shouldShowPublish && !item?.akkuProvisioningConnectorDto?.publishStatus
      ? [
          {
            label: (
              <span className={`px-3 hover:bg-[#5441DA0F] py-1 flex justify-start items-center ${cursorNotAllowedStyle(hasEditAppStorePermission)}`} role="none" onClick={() => handlePublish(item)}>
                <img src={images?.publish} alt="publish" className="mr-2" />
                <span className="text-[16px] font-medium">{t("appManagement.publish")}</span>
              </span>
            ),
            key: "2",
          },
        ]
      : []),

    {
      label: (
        <span
          role="none"
          className={`flex justify-start items-center hover:bg-[#5441DA0F] px-3 py-1 ${cursorNotAllowedStyle(hasEditAppStorePermission)}`}
          onClick={() => {
            accessRequestEdit(item);
          }}
        >
          <span className="material-symbols-outlined mr-2">edit</span> <span className="text-[16px] font-normal font-Inter">{t("appManagement.edit")}</span>
        </span>
      ),
      key: "3",
    },
    {
      label: (
        <span
          className={`px-3 hover:bg-[#5441DA0F] py-1 flex justify-start items-center ${cursorNotAllowedStyle(hasEditAppStorePermission)}`}
          role="none"
          onClick={() => handleDeleteProvisionOpenModal(item)}
        >
          <span className="material-symbols-outlined mr-2">delete</span> <span className="text-[16px] font-medium">{loading ? `${t("appManagement.deleting")}` : `${t("appManagement.delete")}`}</span>
        </span>
      ),
      key: "4",
    },
  ];
  const handleCloseDeleteAccessModal = () => {
    setDeleteAccess(false);
  };
  const handleClosePermissionModal = () => {
    setPermissionModal(false);
  };
  return (
    <>
      <Dropdown menu={{ items }} trigger={["click"]} className="dropdown absolute right-0 top-1">
        <span className="mt-3 mr-2 material-symbols-outlined text-[#98A2B3] cursor-pointer self-start">more_vert</span>
      </Dropdown>

      <NewAppConfiguration
        openPopUp={openPopUp}
        handleModalClose={handleModalClose}
        hasEditAppStorePermission={hasEditAppStorePermission}
        setPermissionsModal={setPermissionsModal}
        provisionObject={provisionObject}
      />
      {permissionModal && <PermissionsModal open={permissionModal} close={handleClosePermissionModal} />}
      {isActiveDeleteModal && (
        <DeleteProvisioningApp
          handleClose={handleDeleteProvisionCloseModal}
          open={isActiveDeleteModal}
          connectorId={item?.akkuProvisioningConnectorDto?.akkuProvisioningConnectorId}
          getProvisioningAppList={getProvisioningAppList}
          items={item}
          authConfigId={authConfigId}
        />
      )}
      {editAccess && <ProvisionEditStatusModal open={editAccess} handleModalClose={handleCloseEditAccessModal} message={message} />}
      {deleteAccess && <ProvisionEditStatusModal open={deleteAccess} handleModalClose={handleCloseDeleteAccessModal} message={t("appManagement.requestDeactivateProvisioning")} />}
    </>
  );
}
