import React from "react";
import { ErrorMessage, Field } from "formik";
import { Button, Input } from "antd";

const EditEmailConfigurationEdit = () => {
  return (
    <div className={`w-full overflow-y-auto general-details-container`}>
      <div className={` overflow-x-hidden mb-2 w-[92%]`}>
        <div className={`w-[90%] flex flex-col edit-basic`}>
          <div className={`w-full  flex justify-between flex-wrap pb-2`}>
            <div className="mb-10 w-[40%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">From*</p>
              <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="senderEmail" data-testid="editSendMail-config" placeholder="Sender email address" />
              <ErrorMessage name="senderEmail" component="div" className="error-message" />
            </div>
            <div className="mb-10 w-[40%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">From display name*</p>
              <Field as={Input} type="text" data-testid="editMail-config" className="h-[56px] font-medium text-[18px]" name="displaySenderEmail" placeholder="Display name for sender email address" />
              <ErrorMessage name="displaySenderEmail" component="div" className="error-message" />
            </div>
            <div className="mb-10 w-[40%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Reply to*</p>
              <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="replyEmail" placeholder="Reply to email address" />
              <ErrorMessage name="replyEmail" component="div" className="error-message" />
            </div>
            <div className="mb-10 w-[40%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Reply to display name*</p>
              <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="displayReplyEmail" placeholder="Display name for reply email address" />
              <ErrorMessage name="displayReplyEmail" component="div" className="error-message" />
            </div>
          </div>
        </div>
      </div>

      <div className="footer hidden ">
        <Button htmlType="submit" type="primary" className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white">
          Next
        </Button>
      </div>
    </div>
  );
};

export default EditEmailConfigurationEdit;
