import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Collapse } from "antd";
import UserDeProvision from "./user-deProvisioning/UserDeprovision";
import RoleDeprovision from "./role-deprovisioning/RoleDeprovision";
import GroupDeprovision from "./group-deprovisioning/GroupDeprovision";
import { setDeProvisioningCollapseCurrent, setDeProvisioningValidations } from "../../../../../../redux/slice/app-store/appConfigureSlice";
import { useTranslation } from "react-i18next";

const ConnectorProvision = () => {
  const { t } = useTranslation();
  const appConfigure = useSelector((state: any) => state?.appConfigure);

  const userDeProvisionRef = useRef<any>(null);
  const roleDeProvisionRef = useRef<any>(null);
  const groupDeProvisionRef = useRef<any>(null);

  const provisioning = useSelector((state: any) => state?.provisioning);
  const isApiCalled = useSelector((state: any) => state?.provisioning?.isDeProvisioningApiCalled);

  const [activeKey, setActiveKey] = useState<string>("1");

  const handleCollapseChange = async (key: string | string[]) => {
    const activePanelKey = key[0]; // Directly access the first element of the array

    if (activeKey === "1") {
      if (activePanelKey === "2") {
        if (!isApiCalled?.userDeProvisionApiCalled) {
          await userDeProvisionRef.current?.submitForm();
        } else if (userDeProvisionRef.current?.dirty) {
          await userDeProvisionRef.current?.submitForm();
        } else {
          setActiveKey("2");
        }
      }

      if (activePanelKey === "3") {
        if (!isApiCalled?.userDeProvisionApiCalled) {
          await userDeProvisionRef.current?.submitForm();
        } else if (userDeProvisionRef.current?.dirty) {
          await userDeProvisionRef.current?.submitForm();
        } else if (!isApiCalled?.roleDeProvisionApiCalled) {
          setActiveKey("2");
          await roleDeProvisionRef.current?.submitForm();
        } else {
          setActiveKey("3");
        }
      }
    }

    if (activeKey === "2") {
      if (activePanelKey === "1") {
        setActiveKey("1");
      }

      if (activePanelKey === "3") {
        if (!isApiCalled?.roleDeProvisionApiCalled) {
          await roleDeProvisionRef.current?.submitForm();
        } else if (roleDeProvisionRef.current?.dirty) {
          await roleDeProvisionRef.current?.submitForm();
        } else {
          setActiveKey("3");
        }
      }
    }

    if (activeKey === "3") {
      if (activePanelKey === "2") {
        setActiveKey("2");
      }
      if (activePanelKey === "1") {
        setActiveKey("1");
      }
    }
  };

  return (
    <div className="w-full px-10 pb-20 connector-provision">
      <p className="app-header py-8">
        {" "}
        {t("appManagement.provisioning.connectorDetailsDeprovisioning")}
        {provisioning?.isDuplicate ? `${t("appManagement.copy")}` : null}
      </p>
      <div className="pr-5 w-full mx-auto acc-provision">
        <Collapse
          accordion
          activeKey={activeKey}
          onChange={(el: any) => handleCollapseChange(el)}
          items={[
            {
              key: "1",
              label: <p className="font-medium text-[#000] font-Inter text-[18px]"> {t("appManagement.provisioning.userDeprovisioning")}</p>,
              children: <UserDeProvision formRef={userDeProvisionRef} setActiveKey={setActiveKey} />,
            },
            {
              key: "2",
              label: <p className="font-medium text-[#000] font-Inter text-[18px]"> {t("appManagement.provisioning.roleDeprovisioning")}</p>,
              children: <RoleDeprovision formRef={roleDeProvisionRef} setActiveKey={setActiveKey} />,
            },
            {
              key: "3",
              label: <p className="font-medium text-[#000] font-Inter text-[18px]"> {t("appManagement.provisioning.groupDeprovisioning")}</p>,
              children: <GroupDeprovision formRef={groupDeProvisionRef} setActiveKey={setActiveKey} />,
            },
          ]}
          className="w-[100%] px-1 custom-header bg-[#fff]"
          expandIconPosition="end"
        />
      </div>
    </div>
  );
};

export default ConnectorProvision;
