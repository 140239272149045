import { Avatar, Button, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { customFilters, generateClassName } from "./tableHelper";
import ImageView from "../../../../../../layouts/component/ImageView";
import EditUserDetailsModal from "../modal/edit-user/EditUserDetailsModal";
import ServiceFilterModal from "../modal/filter-modal/ServiceFilterModal";
import CustomNoData from "./CustomNoData";
import CustomFilter from "./CustomFilter";
import { Service, getUniqueServices } from "./customerTableHelper";
import SyncUserDetails from "./SyncUserDetails";
import { prerequisitesTable } from "../../../../../../constant/prerequisites/prerequisites";
import { useUserContext } from "../../userContext/UserContext";
import { SortOrder } from "antd/lib/table/interface";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDeviceRestrictionList } from "../../../../../../redux/slice/DeviceRestrictionSlice";
import { retrieveData, storeData } from "../../../../../../services/storage/Storage";
import { modulePermissions } from "../../../../../../const/RolePermissions";
import { getPermissionStyle, hasPermission } from "../../../../../../redux/slice/permissions/permissionUtils";
import PermissionsModal from "../../../../../../layouts/permissionsModal/PermissionsModal";
import generateTitle from "../../../../../../layouts/Table/generateTitle";
import AccessManagerListModal from "./AccessManagerListModal";
import { setAuthSuccess, setListOfRestrictionModal } from "../../../../../../redux/slice/Users/UserDashboardUpdateSlice";
import "./UserTable.scss";
import Restrictions from "./tablecloumn/Restrictions";
import { useTranslation } from "react-i18next";
import MFAUserManagementModal from "../../modal/MFAUserManagementModal";
const UsersTable = (props: any) => {
  const { t } = useTranslation();
  const userName = retrieveData("userName", true);
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const hasUpdatePermission = permissions && hasPermission(permissions, modulePermissions.userManagement, "update");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { customerOriginalTableData, currentPage, pageSize, loader, userList, handlePaginationChange, groupList } = useUserContext();
  const [customerTableData, setCustomerTableData] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [editUserDetails, setEditUserDetails] = useState(false);
  const [showHandlePopup, setShowHandelPopup] = useState(false);
  const [showFilterData, setShowFilterData] = useState<Service[]>([]);
  const [permissionsModal, setPermissionsModal] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [statusFilterVisible, setStatusFilterVisible] = useState<boolean>(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [activateModal, setActivateModal] = useState(false);

  const [mfaModalOpen, setMFAModalOpen] = useState(false);
  const toggleDashboard = useSelector((state: any) => state?.UserDashboardUpdateSlice?.updateDashboard);
  const openRestrictionModal = useSelector((state: any) => state?.UserDashboardUpdateSlice?.listOfRestrictionModal);
  const timerRunout = useSelector((state: any) => state?.UserDashboardUpdateSlice);

  useEffect(() => {
    setCustomerTableData(customerOriginalTableData);
    setActivateModal(openRestrictionModal);
    const uniqueServicesFilterArray: Service[] = customerOriginalTableData && getUniqueServices(customerOriginalTableData);
    setShowFilterData(uniqueServicesFilterArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, toggleDashboard]);

  const handleCloseMFAModal = () => {
    setMFAModalOpen(false);
  };
  // Helper function for generating column titles

  const handleFilterClose = () => {
    setFilterVisible(false);
  };
  const handleStatusFilterClose = () => {
    setStatusFilterVisible(false);
  };
  // Common filterIcon for columns that need it
  const commonFilterIcon = (filtered: any) => (
    <Tooltip title={t("userManagement.filter")}>
      <p role="none" className="flex text-[20px] items-center justify-start font-Inter text-[#1D1D24] font-medium" onClick={() => setStatusFilterVisible(!statusFilterVisible)}>
        <span className="material-symbols-outlined ml-2">filter_list</span>
      </p>
    </Tooltip>
  );
  const groupFilterIcon = (filtered: any) => (
    <Tooltip title={t("userManagement.filter")}>
      <p role="none" onClick={() => setFilterVisible(!filterVisible)} className="flex text-[20px] items-center justify-start font-Inter text-[#1D1D24] font-medium ">
        <span className="material-symbols-outlined ml-2 group-filter">filter_list</span>
      </p>
    </Tooltip>
  );
  // Common render function for text content
  const commonRender = (text: any) => (
    <div>
      <Tooltip title={text}>
        <p className={`${text?.toLowerCase()} user-status text-[#545263] `}>
          <span></span> {text || "-"}
        </p>
      </Tooltip>
    </div>
  );
  const capitalizeFirstLetter = (string: string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  const statusRender = (text: any) => {
    const formattedText = capitalizeFirstLetter(text);
    const statusTranslations: any = {
      Active: t("common.active"),
      Inactive: t("common.inactive"),
      Deleted: t("common.deleted"),
      Disabled: t("common.disabled"),
    };
    return (
      <div>
        <Tooltip title={statusTranslations[formattedText]}>
          <p className={`${formattedText?.toLowerCase()} user-status text-[#545263] `}>
            <span></span> {statusTranslations[formattedText] || "-"}
          </p>
        </Tooltip>
      </div>
    );
  };
  const groupCommonRender = (text: any) => {
    if (!text) {
      return <div className="text-center">-</div>;
    }
    const groups = text?.split(",");
    const firstGroup = groups?.[0];
    const remainingGroups = groups;

    return (
      <div>
        <Tooltip title={remainingGroups.length > 0 ? remainingGroups.join(", ") : null}>
          <p className={`${firstGroup?.toLowerCase()} cursor-default font-Inter text-[#545263] text-[16px] text-center user-status font-medium`}>
            <span> {firstGroup || "-"}</span>
            {remainingGroups.length > 1 ? `... +${remainingGroups.length - 1}` : ""}
          </p>
        </Tooltip>
      </div>
    );
  };
  const handelCloseModal = () => {
    setEditUserDetails(false);
  };
  const handelClose = () => {
    setShowHandelPopup(false);
  };

  const handleNameClick = (record: any, name = "name") => {
    // Check if the timer has run out
    if (timerRunout?.isTimeout) {
      // If timer ran out and authSuccess is false, show the MFA modal
      if (!timerRunout?.authSuccess) {
        setMFAModalOpen(true);

        if (hasUpdatePermission) {
          if (name === "service") {
            customerTableData?.map((data: any, index: number) => {
              if (index === record) {
                storeData("currentSelectedRow", data, true);
              }
            });
          } else {
            storeData("currentSelectedRow", record, true);
          }
        }
      }
    } else {
      // If timer has not run out, proceed with current flow
      if (hasUpdatePermission) {
        if (name === "service") {
          customerTableData?.map((data: any, index: number) => {
            if (index === record) {
              storeData("currentSelectedRow", data, true);
            }
          });
        } else {
          storeData("currentSelectedRow", record, true);
        }
        navigate("/user/edit");
      } else {
        setPermissionsModal(true);
      }
    }
  };

  const closeModal = () => {
    setActivateModal(false);
    dispatch(setListOfRestrictionModal(false));
  };
  const columns = [
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          t("userManagement.name"), // Use the translation object here
          sortColumns?.find(({ column }: any) => column.key === "name"),
        ),
      dataIndex: "name",
      key: "name",
      width: "20%",
      defaultSortOrder: "ascend" as SortOrder,
      sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name),
      render: (text: any, record: any) => (
        <div
          role="none"
          onClick={() => (userName !== record.email ? handleNameClick(record) : undefined)}
          className={`${generateClassName(hasUpdatePermission, userName, record.email)}
       ${getPermissionStyle(hasUpdatePermission, "permission-disabled-userEdit ")}`}
        >
          <p className={`${text?.toLowerCase()} name user-name  text-[#5441da] cursor-pointer`}>
            <span>{text || "-"}</span>
          </p>
        </div>
      ),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          t("userManagement.email"), // Use the translation object here
          sortColumns?.find(({ column }: any) => column.key === "email"),
        ),
      dataIndex: "email",
      key: "email",
      width: "20%",
      defaultSortOrder: "ascend" as SortOrder,
      sorter: (a: any, b: any) => a?.email?.localeCompare(b?.email),
      render: commonRender,
    },
    {
      title: () => generateTitle(t("userManagement.groups"), ""), // Use the translation object here
      dataIndex: "groups",
      key: "groups",
      width: "20%",
      filterIcon: groupFilterIcon,
      render: groupCommonRender,
      filterDropdown: <CustomFilter type="group" groupList={groupList} handleFilterClose={handleFilterClose} />,
      filterDropdownVisible: filterVisible,
    },
    {
      title: () => generateTitle(t("userManagement.restrictions"), ""),
      dataIndex: "restrictions",
      key: "restrictions",
      width: "15%",
      render: (record: any, value: any) => {
        return <Restrictions value={value} record={record} setActivateModal={setActivateModal} setPermissionModal={setPermissionModal} />;
      },
    },
    {
      title: () => generateTitle(t("userManagement.status"), ""),
      dataIndex: "status",
      key: "status",
      width: "15%",
      filters: customFilters,
      onFilter: (value: any, record: any) => record.status.includes(value),
      filterIcon: commonFilterIcon,
      render: statusRender,
      filterDropdown: <CustomFilter type="status" handleFilterClose={handleStatusFilterClose} />,
      filterDropdownVisible: statusFilterVisible,
    },
    {
      title: (
        <div>
          <p className="user-service ">{t("userManagement.services")}</p>
        </div>
      ),
      dataIndex: "service",
      key: "service",
      width: "15%",
      render: (record: any, text: any, index: number) => (
        <div>
          {record?.every((item: any) => item?.client_name === null) ? (
            // Render sync option here
            <Button
              onClick={!hasUpdatePermission ? () => setPermissionsModal(true) : () => handleNameClick(index, "service")}
              type="primary"
              className={`bg-[#5441DA] w-[38px] ml-3 h-[38px] flex justify-center items-center sync-btn ${getPermissionStyle(hasUpdatePermission)}`}
              icon={<span className="material-symbols-outlined text-lg">add</span>}
            />
          ) : (
            // Render an alternative if sync option is not enabled
            <Avatar.Group size="large" maxCount={3}>
              {record?.map((elm: any, index: any) => (
                <Tooltip key={elm?.client_name} title={elm?.client_name} placement="top">
                  <Avatar className="user-logo">
                    <ImageView className="" src={elm?.logoPath} alt="avatar.img" />
                  </Avatar>
                </Tooltip>
              ))}
            </Avatar.Group>
          )}
        </div>
      ),
    },
  ];

  const getRowClassName = (record: any) => {
    return record.status === "deleted" ? "disabled-row" : "";
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const selectedRows: any = newSelectedRowKeys?.map((key: any) => customerTableData.find((row: any) => row.key === key));
    selectedRows.filter((row: any) => row && row.restrictions === "inactive");
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRow(newSelectedRowKeys);
    dispatch(setDeviceRestrictionList(selectedRows));
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: record?.status === "deleted", // Disable checkbox for records having deleted status
    }),
  };
  const clearSelection = () => {
    setSelectedRowKeys([]);
    setSelectedRow([]);
  };
  const handleCloseHelpModal = () => {
    setPermissionsModal(false);
  };
  const handleClosePermissionModalInUser = () => {
    setPermissionModal(false);
  };
  return (
    <>
      {permissionsModal && <PermissionsModal open={permissionsModal} close={handleCloseHelpModal} />}
      <div className="user-table">
        <SyncUserDetails selectedRowKeys={selectedRow} rowObject={customerTableData} setSelectedRow={setSelectedRow} clearSelection={clearSelection} />
        <div className="w-full">
          {customerTableData && (
            <Table
              locale={{
                triggerDesc: `${t("userManagement.triggerDesc")}`,
                triggerAsc: `${t("userManagement.triggerAsc")}`,
                cancelSort: `${t("userManagement.cancelSort")}`,
                emptyText: <CustomNoData />,
              }}
              loading={loader}
              pagination={{
                current: currentPage === 0 ? 1 : currentPage,
                pageSize: pageSize,
                total: userList?.length > 0 ? userList[0]?.userData?.totalRecords : 0,
                showSizeChanger: true,
                pageSizeOptions: prerequisitesTable.pageSizeOptions,
                showPrevNextJumpers: true,
                onChange: handlePaginationChange,
              }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={customerTableData}
              // locale={{ emptyText: <CustomNoData /> }}
              rowClassName={getRowClassName}
            />
          )}

          {editUserDetails && <EditUserDetailsModal editUserDetails={editUserDetails} selectedRowObject={{}} handelCloseModal={handelCloseModal} />}
          {showHandlePopup && <ServiceFilterModal filterData={showFilterData} showHandlePopup={showHandlePopup} handelClose={handelClose} setCustomerTableData={setCustomerTableData} />}
        </div>
      </div>
      {permissionModal && <PermissionsModal open={permissionModal} close={handleClosePermissionModalInUser} />}
      {activateModal && <AccessManagerListModal activate={activateModal} closeModal={closeModal} />}

      {mfaModalOpen && <MFAUserManagementModal modalOpen={mfaModalOpen} handleCloseModal={handleCloseMFAModal} />}
    </>
  );
};

export default UsersTable;
