import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { CustomExpandIcon, userRestrictionTableColumns } from "./UserRestrictionTableHelper";
import "./UserRestrictionTable.scss";
import { useTranslation } from "react-i18next";

const UserRestriction = (props: any) => {
  const { t, i18n } = useTranslation();
  const { restrictionList } = props;
  const [dataSource, setDataSource] = useState<any>([]);

  const findIpRange = (ipInfo: any) => {
    if (ipInfo.endsWith(".0.0/16")) {
      return "range16";
    } else {
      return "range24";
    }
  };

  useEffect(() => {
    const updateDataSource = () => {
      const filteredTimeBased = restrictionList?.userTimeBasedAuthorizationDtoList;
      const filteredIpBased = restrictionList?.akkuUserIpBasedAuthorizationDtoList;
      const filterDeviceBased = restrictionList?.userDeviceInfoDtoList;
      const deviceStatus = filterDeviceBased?.every((item: any) => item?.status === true);
      const timeStatus = filteredTimeBased?.every((item: any) => item?.isActive === true);
      const ipStatus = filteredIpBased?.every((item: any) => item?.active === true);

      const data: any = [];

      if (filterDeviceBased?.length > 0) {
        data.push({
          key: 1,
          name: t("userManagement.deviceRestriction"),
          status: deviceStatus ? t("userManagement.agentSent") : t("userManagement.inActive"),
          description: filterDeviceBased.map((ipAuth: any) => ({
            id: ipAuth?.akkuUserDeviceInfoId,
            serialNumber: ipAuth?.serialNumber,
            createdOn: ipAuth?.createdOn,
          })),
          resend: "",
          type: "Expandable",
        });
      }
      if (filteredIpBased?.length > 0) {
        data.push({
          key: 2,
          name: t("userManagement.ipRestriction"),
          status: ipStatus ? t("userManagement.active") : t("userManagement.inActive"),
          description: filteredIpBased.map((ipAuth: any) => ({
            Name: ipAuth?.name,
            Descriptions: ipAuth?.description,
            ChooseP: ipAuth?.authorizationType,
            Starting: ipAuth?.ipInfos ? ipAuth.ipInfos?.[0]?.ipInfo : "N/A",
            Range: ipAuth?.ipInfos?.[0]?.ipRange ? findIpRange(ipAuth?.ipInfos?.[0]?.ipInfo) : "N/A",
          })),
          type: "Expandable",
        });
      }

      if (filteredTimeBased?.length > 0) {
        data.push({
          key: 3,
          name: t("userManagement.timeRestriction"),
          status: timeStatus ? t("userManagement.active") : t("userManagement.inActive"),
          description: filteredTimeBased.map((timeAuth: any) => ({
            Name: timeAuth?.name,
            Descriptions: timeAuth?.description,
            Zone: timeAuth?.timezone,
            Time: `${timeAuth?.accessFrom} to ${timeAuth?.accessTo}`,
          })),
          type: "Expandable",
        });
      }

      setDataSource(data);
    };

    // Update the data source when the restrictionList or the language changes
    updateDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restrictionList, i18n.language]);

  const expandedRowRender = (record: any) => {
    if (record?.key === 1) {
      return (
        <>
          {record?.description?.map((item: any) => (
            <div className="description pt-2" key={item?.id}>
              <p className="description-content">
                <span className="description-title"> {t("userManagement.serialNumber")}:</span> {item?.serialNumber}
              </p>
              <p className=" description-content">
                <span className="description-title">{t("userManagement.createdOn")}:</span> {item?.createdOn}
              </p>
            </div>
          ))}
        </>
      );
    } else if (record?.key === 3) {
      return (
        <>
          {record?.description?.map((item: any) => (
            <div className="description pt-2" key={item?.name}>
              <p className=" description-content">
                <span className="description-title">{t("userManagement.name")}:</span> {item?.Name}
              </p>
              <p className=" description-content">
                <span className="description-title">{t("userManagement.description")}:</span> {item?.Descriptions}
              </p>
              <p className=" description-content">
                <span className="description-title">{t("userManagement.zone")}:</span> {item?.Zone}
              </p>
              <p className=" description-content">
                <span className="description-title">{t("userManagement.range")}:</span> {item?.Time}
              </p>
            </div>
          ))}
        </>
      );
    } else if (record?.key === 2) {
      return (
        <>
          {record?.description?.map((item: any) => (
            <div className="description pt-2" key={item?.name}>
              <p className="description-content">
                <span className="description-title">{t("userManagement.name")}:</span> {item?.Name}
              </p>
              <p className="description-content">
                <span className="description-title">{t("userManagement.description")}:</span> {item?.Descriptions}
              </p>
              <p className="description-content">
                <span className="description-title">{t("userManagement.chooseTypeForIP")}:</span> {item?.ChooseP}
              </p>
              <p className="description-content">
                <span className="description-title">{t("userManagement.startingIP")}:</span> {item?.Starting}
              </p>
              <p className="description-content">
                <span className="description-title">{t("userManagement.range")}:</span> {item?.Range}
              </p>
            </div>
          ))}
        </>
      );
    } else if (record?.key === 3) {
      return (
        <>
          {record?.description?.map((item: any) => (
            <div className="description pt-2" key={item?.name}>
              <p className="description-content">
                <span className="description-title">{t("userManagement.name")}:</span> {item?.Name}
              </p>
              <p className="description-content">
                <span className="description-title"> {t("userManagement.description")}:</span> {item?.Descriptions}
              </p>
              <p className="description-content">
                <span className="description-title"> {t("userManagement.selectZone")}:</span> {item?.Zone}
              </p>

              <p className="description-content">
                <span className="description-title"> {t("userManagement.selectRange")}:</span> {item?.Time}
              </p>
            </div>
          ))}
        </>
      );
    } else {
      return <p>{t("userManagement.notAvailable")}</p>;
    }
  };

  return (
    <div data-testid="restrictionTable">
      {dataSource?.length > 0 && (
        <Table
          className="restriction-table"
          columns={userRestrictionTableColumns(t)}
          expandable={{
            expandedRowRender: expandedRowRender,
            rowExpandable: (record) => record.type !== "Not Expandable",
            expandIcon: CustomExpandIcon,
          }}
          dataSource={dataSource}
          pagination={false}
        />
      )}
    </div>
  );
};

export default UserRestriction;
