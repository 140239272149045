const en = {
  welcome: "Welcome ",
  lastLogin: "Last Login",
  editDetailsPrompt: "If you want to edit the details please click the button to edit.",
  submitDetailsPrompt: "Looks like you have made some changes",
  details: "Details",
  active: "active",
  inActive: "Inactive",
  menu: {
    smartAnalytics: "Smart Analytics",
    userManagement: "User Management",
    appManagement: "App Management",
    roleManagement: "Role Management",
    accessManager: "Access Manager",
    passwordManager: "Password Manager",
    credentialManagement: "Credential Management",
    deviceManagement: "Device Management",
    currentVersion: "Current version",
  },
  appManagement: {
    appManagement: "App Management",
    adminCredentials: "Admin Credentials",
    apiSecret: "Api Secret",
    mappingGroups: "Mapping groups",
    capabilityConfig: "Capability Config",
    apiKey: "API Key",
    uploadFile: "Upload File",
    delegatedUser: "Delegated User",
    idpInitiatedSsoUrlName: "IDP-Initiated SSO URL Name",
    idpInitiatedSsoRelayState: "IDP-Initiated SSO Relay State",
    enterDescription: "Enter Description",
    appName: "App Name",
    configurationDocument: " Configuration Document",
    copied: "Copied",
    immediate: "Immediate",
    activateProvision: "Activate provision",
    noteStartProvisioningEngine: "Note: Below you can specify when to start the provisioning engine.",
    selectTheOption: "Select the option",
    provisioningEngineDeactivated: "Provisioning engine deactivated successfully.",
    provisioningEngineActivated: "Provisioning engine activated successfully.",
    deleting: "Deleting",
    requestDeactivateProvisioning: "Please deactivate Provisioning to access the delete option.",
    requestActivateProvisioningEdit: "Please deactivate Provisioning to access the edit option.",
    requestConfigureProvisioning: "Please complete the provisioning.",
    requestActivateProvisioning: "Please complete the provisioning connector to activate.",
    editLowerCase: "edit",
    configureLowerCase: "configure",
    configure: "Configure",
    myApps: "My Apps",
    appStore: "App Store",
    sso: "SSO",
    provisioningLabel: "Provisioning",
    configureSso: "Configure SSO",
    configureProvisioning: "Please configure provisioning.",
    publishedApps: "Published Apps",
    duplicate: "Duplicate",
    publish: "Publish",
    edit: "Edit",
    delete: "Delete",
    whichOneDoYouLikeTo: "Which one do you like to ",
    removingWillRevokeUserAccess: "Removing will revoke user access permissions. However, you can reconfigure it via Available Apps.",
    pleaseConfigureSso: "Please configure SSO.",
    pleaseDeactivateProvisioning: "Please deactivate Provisioning to continue edit.",
    areYouSureYouWantToDelete: "Are you sure you want to delete the provisioning and SSO connector?",
    pleaseCompleteProvisioning: "Please complete the provisioning.",
    openIdEndpointConfiguration: "OpenId Endpoint Configuration",
    saml2IdentityProviderMetadata: "SAML 2.0 Identity Provider Metadata",
    copy: "Copy",
    viewCertificate: "View Certificate",
    samlDescriptor: "SAML Descriptor",
    applicationType: "Application Type",
    saml: "SAML",
    openId: "OpenID",
    cas: "CAS",
    generalSettings: "General Settings",
    loginSettings: "Login Settings",
    advancedSettings: "Advanced Settings",
    clientType: "Client Type",
    clientId: "Client ID",
    rootUrl: "Root URL",
    homeUrl: "Home URL",
    validRedirectUrl: "Valid redirect URL",
    addValidRedirectUrls: "Add Valid Redirect URLs",
    validRedirectLogoutUrl: "Valid redirect Logout URL",
    masterSamlProcessingUrl: "Master SAML Processing URL",
    assertionConsumerServicePostBindingUrl: "Assertion Consumer Service POST Binding URL",
    assertionConsumerServiceRedirectBindingUrl: "Assertion Consumer Service Redirect Binding URL",
    clientSecret: "Client Secret",
    regenerate: "Regenerate",
    clientAuthentication: "Client Authentication",
    authorization: "Authorization",
    authenticationFlow: "Authentication Flow",
    standardFlow: "Standard Flow",
    implicitFlow: "Implicit Flow",
    oauth2DeviceAuthorizationGrant: "OAuth 2.0 Device Authorization Grant",
    directAccessGrants: "Direct access grants",
    oidcCibaGrant: "OIDC CIBA Grant",
    serviceAccountRoles: "Service account roles",
    validPostLogoutRedirectUrl: "Valid Post Logout redirect URL",
    addValidPostLogoutRedirectUrl: "Add Valid Post Logout redirect URLs",
    webOrigin: "Web Origin",
    frontChannelLogoutUrl: "Front-channel logout URL",
    clientTypeDescription: "OpenID Connect allows Clients to verify the identity of the End-User based on the authentication performed by an Authorization Server",
    clientIdDescription: "Specifies ID referenced in URI and tokens. For example my-client. For SAML this is also the expected issuer value from authn requests",
    rootUrlDescription: "Root URL appended to relative URLs",
    homeUrlDescription: "Default URL to use when the auth server needs to redirect or link back to the client.",
    validRedirectUrlDescription:
      "Valid URI pattern a browser can redirect to after a successful login. Simple wildcards are allowed such as http://example.com/*. Relative path can be specified too such as /my/relative/path/*. Relative paths are relative to the client root URL, or if none is specified the auth server root URL is used. For SAML, you must set valid URI patterns if you are relying on the consumer service URL embedded with the login request.",
    validRedirectLogoutUrlDescription:
      "Valid URI pattern a browser can redirect to after a successful logout. A value of + or an empty field will use the list of valid redirect URIs. A value of - will not allow any post-logout redirect URIs. Simple wildcards are allowed such as http://example.com/*. Relative path can be specified too such as /my/relative/path/*. Relative paths are relative to the client root URL, or if none is specified the auth server root URL is used",
    assertionConsumerServicePostUrlDescription: "Specifies ID referenced in URI and tokens. For example my-client. For SAML this is also the expected issuer value from authn requests",
    assertionConsumerServiceRedirectUrlDescription: "Specifies ID referenced in URI and tokens. For example my-client. For SAML this is also the expected issuer value from authn requests",
    clientAuthenticationDescription: "This defines the type of the OIDC client. When it is ON, the OIDC type is set to confidential access type. When it iss OFF, it is set to public access type.",
    authorizationDescription: "Enable/Disable fine-grained authorization support for a client",
    webOriginDescription: "Allowed CORS origins. To permit all origins of Valid Redirect URIs, add + This does not include the * wildcard though. To permit all origins, explicitly add",
    idpUrlDescription:
      "URL fragment name to reference client when you want to do IDP Initiated SSO. Leaving this empty will disable IDP Initiated SSO. The URL you will reference from your browser will be {server-root}/realms/{realm}/protocol/saml/clients/{client-url-name}",
    idpSsoDescription: "Relay state you want to send with SAML request when you want to do IDP Initiated SSO.",
    masterSamlDescription:
      "If configured, this URL will be used for every binding to both the SP is Assertion Consumer and Single Logout Services. This can be individually overridden for each binding and service in the Fine Grain SAML Endpoint Configuration",
    ssoErrors: {
      clientIdRequired: "Client ID is required",
      masterSamlProcessingUrlRequired: "Master SAML Processing URL is required",
      validRedirectUrlRequired: "Valid Redirect URL is required",
      validRedirectLogoutUrlRequired: "Valid Redirect Logout URL is required",
      assertionConsumerUrlRedirectRequired: "Assertion Consumer Service Redirect Binding URL is required",
      assertionConsumerUrlPostRequired: "Assertion Consumer Service POST Binding URL is required",
      baseUrlRequired: "Base URL is required",
      rootUrlRequired: "Root URL is required",
      invalidUrl: "Invalid URL",
      clientAuthenticationRequired: "Client Authentication is required",
      serviceAccountsEnabledRequired: "Service Accounts Enabled is required",
      authorizationRequired: "Authorization is required",
      directAccessGrantsRequired: "Direct Access Grants is required",
      standardFlowRequired: "Standard Flow is required",
      implicitFlowRequired: "Implicit Flow is required",
      deviceAuthorizationGrantEnabledRequired: "Device Authorization Grant Enabled is required",
      oidcCibaGrantEnabledRequired: "OIDC CIBA Grant Enabled is required",
      webOriginsRequired: "Web Origins is required",
      frontChannelLogoutUrlRequired: "Front-channel logout URL is required",
      apiKeyRequired: "API key is required",
      apiSecretRequired: "API secret key is required",
      sourceRequired: "Source is required",
      targetRoleRequired: "Target role is required",
    },
    provisioning: {
      queryDetectEnginePerformanceProblem: "Query Detect Engine Performance Problem",
      connector: "Connector",
      pleaseSelectSourceConnector: "Please select source connector",
      key: "Key",
      value: "Value",
      connectorDetails: "Connector Details",
      generalDetails: "General Details",
      authentication: "Authentication",
      provisioning: "Provisioning",
      deprovisioning: "Deprovisioning",
      selectYourTargetApps: "Select your target apps",
      mapping: "Mapping",
      attributes: "Attributes",
      groups: "Groups",
      roles: "Roles",
      connectorName: "Connector Name",
      description: "Description",
      serviceProviderDomain: "Service Provider Domain",
      connectorType: "Connector Type",
      authenticationType: "Authentication Type",
      tokenType: "Token Type",
      basicAuth: "Basic Auth",
      restApi: "Rest API",
      database: "Database",
      bearerToken: "Bearer Token",
      oAuth2: "OAuth 2.0",
      serviceToken: "Service Token",
      apiToken: "API Token",
      oidc: "OIDC",
      connectorDetailsGeneral: "Connector Details - General Details",
      connectorDetailsAuthentication: "Connector Details - Authentication",
      tokenApiEndpointUrl: "Token API Endpoint Url",
      administratorUsernameEmail: "Administrator Username / Email",
      scope: "Scope",
      tokenApiMethodType: "Token API Method Type",
      usernameEmail: "Username / Email",
      username: "Username",
      grantType: "Grant type",
      clientSecret: "Client_secret",
      clientId: "Client_id",
      password: "Password",
      connectorDetailsProvisioning: "Connector Details - Provisioning",
      userProvisioning: "User Provisioning",
      userDeprovisioning: "User Deprovisioning",
      roleProvisioning: "Role Provisioning",
      groupProvisioning: "Group Provisioning",
      apiEndpointUrl: "API Endpoint Url",
      methodType: "Method Type",
      post: "Post",
      get: "Get",
      requestPayload: "Request Payload",
      response: "Response",
      responseAttributesMapping: "Response Attributes Mapping",
      requestParameters: "Request Parameters",
      pathVariable: "Path Variable",
      getRole: "Get Role",
      assignRole: "Assign Role",
      getGroup: "Get Group",
      assignGroup: "Assign Group",
      methodTypeDeprovisioning: "Method Type",
      connectorDetailsDeprovisioning: "Connector Details - Deprovisioning",
      roleDeprovisioning: "Role Deprovisioning",
      groupDeprovisioning: "Group Deprovisioning",
      selectYourConnector: "Select your connector",
      selectSource: "Select Source",
      akkuuConnector: "Akku_Connector",
      connected: "Connected",
      descriptionAkkuConnector: "The Akku connector provisions users from Akku to target apps, managing accounts, permissions, and data for seamless, secure access.",
      selectTarget: "Select Target",
      selectConnectors: "Select Connectors",
      mappingAttributes: "Mapping - Attributes",
      sourceAkku: "SOURCE - Akku",
      target: "TARGET",
      mappingGroups: "Mapping - Groups",
      mappingRoles: "Mapping - Roles",
      finalReviewUserData: "Final Review - User Data",
      reviewMapping: "Please review the mapping below and confirm changes if any",
      id: "Id",
      finalReview: "Final Review",
      errors: {
        requiredConnectorName: "Connector name is required",
        requiredDescription: "Description is required",
        requiredTokenType: "Token Type is required",
        requiredConnectorType: "Connector type is required",
        requiredAuthType: "Authentication Type is required",
        tokenApiEndPointUrl: "Token API EndPoint Url",
        usernameRequired: "Username is required",
        passwordRequired: "Password is required",
        grantTypeRequired: "Grant type is required",
        clientIdRequired: "Client ID is required",
        clientSecretRequired: "Client Secret is required",
        serviceToken: "Service Token",
        adminUsernameEmailRequired: "Administrator Username / Email is required",
        apiTokenRequired: "API Token is required",
        usernameEmailRequired: "Username / Email is required",
        invalidUrlFormat: "Invalid URL format",
        urlRequired: "URL is required",
        tokenApiEndpoint: "Token API Endpoint URL is required",
        json: "The input must be a valid JSON string",
        plainString: "The input must be a string, not JSON or other formats",
        primaryKeyAttributeList: "Enter a valid primaryKeyAttributeList",
        apiEndpointUrlRequired: "API Endpoint URL is required",
        methodTypeRequired: "Method Type is required",
        requestPayloadRequired: "Request Payload is required",
        responseRequired: "Response is required",
        serviceTokenRequired: "Service Token is required",
        validId: "Enter a valid ID",
        validName: "Enter a valid name",
        sourceRequired: "Please select the source required value",
        targetRequired: "Please select the target required value",
        pleaseSelectRequiredValue: "Please select the required value",
      },
    },
  },
  accessManager: {
    userLowerCase: "user",
    clearSelection: "Clear selection",
    deleteUsers: "Delete Users",
    deleteUsersConfirmation: "Are you sure you want to delete the user(s) from the device restriction?",
    groupsLowerCase: "groups",
    usersLowerCase: "users",
    totalNoOf: "Total No. of",
    group: "Group",
    unassignGroups: "Unassign Group(s)",
    unassignUsers: "Unassign user(s)",
    assignedTo: "Assigned to",
    blacklistIp: "Blacklist IP",
    accessManager: "Access Manager",
    enableControl: "Enables you to control how your applications and data is accessed.",
    createRestrictions: "Create Restrictions",
    type: "Type",
    assigned: "Assigned",
    action: "Action",
    controlAccessPrompt: "How would you like to control access to your applications?",
    selectRestrictions: "Select how you would like to restrict access, then assign users.",
    selectIp: "Select IP",
    specifyIpAccess: "Specify what IP can access your data",
    whitelistDevices: "Whitelist Device/s",
    bindDevices: "Bind your device/s to your data",
    timeParameters: "Time Parameters",
    setTimeParameters: "Set Time Parameters",
    accessTime: "Stipulate what time your data can be accessed",
    chooseTypeForIp: "Choose type for IP",
    whitelistIp: "Whitelist IP",
    range: "Range",
    ipv4: "IPv4",
    ipv6: "IPv6",
    assignTo: "Assign to",
    organization: "Organization",
    groups: "Groups",
    users: "Users",
    selectZone: "Select Zone",
    selectRange: "Select Range",
    startTime: "(Start Time & End Time)",
    deviceLevel: "Device Level",
    totalUserCount: "Total user count",
    searchGroups: "Search Groups",
    totalNoOfGroups: "Total No. of groups",
    editTimeParameters: "Edit - Time Parameters",
    editDeviceRestriction: "Edit - Device Restriction",
    assignUser: "Assign User",
    searchUsers: "Search Users",
    totalUsers: "Total Users",
    editIpRestriction: "Edit - IP Restriction",
    exactIp: "Exact IP",
    range16: "Range 16",
    range24: "Range 24",
    searchUser: "Search user",
    timeRestriction: "Time Restriction",
    deleteConfirmation: "Are you sure you want to delete this restriction?",
    noRulesDefined: "No rules defined for IP Restriction, Time Restriction, and Device Restriction.",
    createNew: "Create New",
    devicesRestricted: "Devices are already restricted. No additional restrictions can be applied.",
    whitelistPrompt: "Would you like to whitelist your devices on:",
    policyLevel: "Policy Level",
    downloadPrompt: "Please download the Akku Device Manager",
    desktopApps: "Desktop apps",
    addToPolicyEngine: "Once downloaded, please add Akku Device Manager to your policy engine",
    ipRestriction: "IP Restriction",
    deviceRestriction: "Device Restriction",
    mandatoryAssign: "It's mandatory to assign at least one",
    toTheRestriction: "to the restriction.",
    closeTabConfirmation: "Are you sure you want to close this tab? Any unsaved changes will be lost.",
    errors: {
      nameRequired: "Name is required",
      nameMax: "Name cannot exceed 20 characters",
      descriptionRequired: "Description is required",
      descriptionMax: "Description cannot exceed 50 characters",
      ipTypeRequired: "IP type is required",
      assignToRequired: "Please select any one",
      duplicateIp: "IP address already exists",
      invalidIPv6Format: "Invalid IPv6 address format",
      invalidIpSegmentRange: "Invalid IP address segment range",
      exactIpFormat: "Invalid IP address format for exact IP",
      range16Format: "Invalid IP address format for Range 16",
      range24Format: "Invalid IP address format for Range 24",
      invalidIpFormat: "Invalid IP address format",
      ipRequired: "IP address is required",
      ipRangeRequired: "IP Range is required",
      endTimeRequired: "End time is required",
      startTimeRequired: "Start time is required",
      timezoneRequired: "Timezone is required",
    },
  },
  dashboard: {
    searchInApps: "Search in apps",
    allApps: "All Apps",
    adminDashboard: "Admin Dashboard",
    welcome: "Welcome ",
    lastLogin: "Last Login",
    licenseManagement: "License Management",
    usedLicense: "Used License",
    outOf: "Out of",
    plan: "Plan",
    upgrade: "Upgrade",
    recentPurchase: "Recent Purchase",
    productAdoption: "Product Adoption",
    overdueBy: "Overdue by",
    days: "days",
    recoveryAttempts: "Recovery Attempts",
    totalAttempts: "Total Attempts",
    successful: "Successful",
    failure: "Failure",
    mfa: "MFA",
    noMfaAvailable: "No MFA available",
    touchId: "Touch ID",
    authenticator: "Authenticator",
    faceId: "Face ID",
    email: "Email",
    mobilePush: "Mobile Push",
    sms: "SMS",
  },
  riskAssessment: {
    riskAssessment: "Risk Assessment",
    activeUsers: "Active Users",
    suspiciousLogin: "Suspicious Login",
    lockedAccounts: "Locked Accounts",
    failedLogin: "Failed Login",
    lastSuccessfulLogin: "Last Successful Login",
    failedLoginAttempt: "Failed Login Attempt",
    details: "Details",
    unusedLicenses: "Unused Licenses",
    suspiciousLoginAttempt: "Suspicious Login Attempt",
    lockedLoginAttempt: "Locked Login Attempt",
    lastRiskAssessmentDate: "Last Risk Assessment Date",
    lastRiskAssessmentResult: "Last Risk Assessment Result",
  },
  tenants: {
    errors: {
      customerId: {
        required: "Customer Id is required",
        noSpaces: "Customer Id cannot contain spaces",
        invalidCharacters: "Customer Id should not contain uppercase and special characters",
        maxLength: "Customer ID cannot exceed 30 characters",
      },
      displayName: {
        required: "Display name is required",
        maxLength: "Display name must be at most 30 characters",
      },
      customerEmailId: {
        invalid: "Please enter a valid email address for the customer",
        required: "Customer email Id is required",
      },
      noOfLicense: {
        required: "No. of license is required",
        typeError: "No. of license must be a number",
        max: "No. of license cannot exceed 999999",
      },
      phone: {
        required: "Mobile Number is required",
      },
      smsServiceProvider: {
        required: "Region is required",
      },
      invalidEmail: "Invalid email address",
      senderEmail: {
        required: "Sender email ID is required",
      },
      displaySenderEmail: {
        required: "Display name for sender email address is required",
        maxLength: "Display name cannot exceed 20 characters",
      },
      displayReplyEmail: {
        required: "Reply to display name is required",
        maxLength: "Display name cannot exceed 20 characters",
      },
      replyEmail: {
        required: "Reply to email address is required",
        validEmail: "Please enter a valid email address",
        sameAsSender: "Reply email cannot be the same as From email address",
      },
      sessionValidation: {
        ssoSessionIdleTimeout: "Time is required",
        ssoSessionMaxLifespan: "Time is required",
        loginTimeout: "Time is required",
        loginActionTimeout: "Timeout is required",
      },
    },
    selectPlansWarning: "Please select a plan or choose individual products to activate for this customer",
    planChangeWarning: "Changing plans may impact the billing cycle. Please contact Akku admin for assistance.",
    areYouSureWantToUpdateDetails: "Are you sure want to update the details?",
    testingInProgress: "Testing In Progress...",
    success: "Success",
    emailSettings: "Email Settings",
    available: "Available",
    customerEmailId: "Customer email ID",
    loginTheme: "Login Theme",
    resetPassword: "Reset Password",
    exitFullScreen: "Exit full screen",
    expand: "Expand",
    addCustomer: "Add Customer",
    tenants: "Tenants",
    msp: "MSP",
    licenses: "Licenses",
    plan: "Plan",
    adoptionRate: "Adoption Rate",
    renewalDate: "Renewal Date",
    revenue: "Revenue",
    status: "Status",
    addTenant: "Add Tenant",
    generalDetails: "General Details",
    emailConfiguration: "Email Configuration",
    ssoSessionSettings: "SSO Session Settings",
    productsOrPlan: "Products or Plan",
    customerId: "Customer ID",
    displayName: "Display Name",
    noOfLicense: "No. of Licenses",
    mobileNumber: "Mobile Number",
    region: "Region",
    setPasswordOnFirstLogin: "Set Password on First Login",
    forceUserToSetPasswordOnFirstLogin: "Force User to Set Password on First Login",
    restOfTheWorld: "Rest of the World",
    nigeria: "Nigeria",
    from: "From",
    fromDisplayName: "From Display Name",
    replyTo: "Reply To",
    replyToDisplayName: "Reply To Display Name",
    ssoSessionIdle: "SSO Session Idle",
    rememberMe: "(Remember Me)",
    ssoSessionMax: "Max SSO Session",
    loginSettings: "Login Settings",
    loginTimeout: "Login Timeout",
    loginActionTimeout: "Login Action Timeout",
    sessionIdleTimeoutDescription: "Time a session is allowed to be idle before it expires. Tokens and browser sessions are invalidated when a session is expired.",
    sessionMaxTimeoutDescription: "Max time before a session is expired. Tokens and browser sessions are invalidated when a session is expired.",
    rememberMeSessionIdleDescription:
      "Time a remember me session is allowed to be idle before it expires. Tokens and browser sessions are invalidated when a session is expired. If not set, it uses the standard SSO Session Idle value.",
    rememberMeSessionMaxDescription:
      "Max time before a session is expired when a user has set the remember me option. Tokens and browser sessions are invalidated when a session is expired. If not set, it uses the standard SSO Session Max value.",
    loginTimeoutDescription: "Max time a user has to complete a login. This is recommended to be relatively long, such as 30 minutes or more.",
    loginActionTimeoutDescription:
      "Max time a user has to complete login-related actions like updating password or configuring TOTP. This is recommended to be relatively long, such as 5 minutes or more.",
    youCanSelectYourPlan: "You can select your plan",
    selectPlan: "Select Plan",
    selectTheOption: "Select the option",
    adminCanResetPassword: "Admin can reset a user's password and set a temporary one",
    monthly: "Monthly",
    annual: "Annual",
    basic: "Basic",
    professional: "Professional",
    advanced: "Advanced",
    include: "include",
    cloudDirectory: "Cloud Directory",
    ssoIDP: "SSO/IDP",
    adaptiveMFA: "Adaptive MFA",
    adaptiveAccessManager: "Adaptive Access Manager",
    passwordManager: "Password Manager",
    deviceManager: "Device Manager",
  },
  header: {
    invalidOtp: "Invalid OTP",
    resendOtp: "Resend OTP",
    backToLogin: "Back to Login",
    yourUsername: "Your username",
    otpValidFor5MinutesOnly: "OTP valid for 5 minutes only",
    enterVerificationCode: "Enter the verification code sent to your registered",
    resendCode: "Resend Code",
    seconds: "sec",
    verifyOtp: "Verify OTP",
    enterVerificationCodeMobile: "Enter the verification code sent to your registered Mobile Number",
    ipRestrictionsWarning: "Your administrator has restricted your access from this IP address. Please contact administrator for more information on this.",
    timeRestrictionsWarning: "The operation has been canceled due to time restrictions within the application. Please reach out to your system administrator for assistance.",
    unAuthorizedAccess: "Unauthorized Access",
    welcomeToIAM: "Welcome to your IAM portal.",
    passwordRequirement: "You are required to set a password in order to continue.",
    setPassword: "Set Password",
    reEnterPassword: "Re-Enter Password",
    password: "Password",
    profileDetails: "Profile Details",
    changePassword: "Change Password",
    oldPassword: "Old Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    passwordThreeTimesErrorMessage: "Your password cannot be same as the last three passwords",
    passwordThreeAttemptsLockWarning: "(Three incorrect attempts will lock the account)",
    firstNameRequired: "First name is required.",
    lastNameRequired: "Last name is required.",
  },
  common: {
    used: "Used",
    unUsed: "Unused",
    noRecordsFound: "No records found",
    popUpBlocked: "Pop-up has been blocked",
    allowPopUptoLogout: "Please allow pop-ups to log out.",
    searchAndSelect: "Search and Select",
    skip: "Skip",
    agentSent: "Agent Sent",
    authorize: "Authorize",
    description: "Description",
    status: "Status",
    sessionExpired: "Session Expired",
    sessionExpiredMessage: "Your session has expired; Please log in again.",
    contactSupport: "Contact Support",
    firstName: "First Name",
    lastName: "Last Name",
    workEmailId: "Work Email ID",
    personalEmailId: "Personal Email ID",
    phoneNumber: "Phone Number",
    mobileNumber: "Mobile Number",
    confirm: "Confirm",
    okay: "Okay",
    restrictions: "Restrictions",
    unAuthorizedAccess: "Unauthorized Access",
    goBack: "Go back",
    profile: "Profile",
    userDashboard: "User Dashboard",
    logout: "Logout",
    logoutConfirmation: "Are you sure you want to logout?",
    note: "Note",
    email: "Email",
    emailId: "Email Id",
    name: "Name",
    totalUsersCount: "Total users count",
    close: "Close",
    enabled: "Enabled",
    ascending: "Ascending",
    descending: "Descending",
    add: "Add",
    edit: "Edit",
    apply: "Apply",
    filter: "Filter",
    all: "All",
    active: "Active",
    inactive: "Inactive",
    deleted: "Deleted",
    disabled: "Disabled",
    back: "Back",
    personalDetails: "Personal Details",
    basicDetails: "Basic Details",
    cancel: "Cancel",
    next: "Next",
    update: "Update",
    save: "Save",
    proceed: "Proceed",
    search: "Search",
    ok: "OK",
    test: "Test",
    testingInProgress: "Testing In Progress...",
    success: "Success",
    done: "Done",
    areYouSureWantToDelete: "Are you sure you want to delete?",
    no: "No",
    yes: "Yes",
    action: "Action",
    sync: "Sync",
    delete: "Delete",
    triggerDesc: "Click to sort descending ",
    triggerAsc: "Click to sort ascending",
    cancelSort: "Click to cancel sorting",
    select: "Select",
  },
  roleManagement: {
    products: "Products",
    create: "Create",
    view: "View",
    rolesAndPermissions: "Roles and Permissions",
    addNewRole: "Add new role",
    totalRolesCount: "Total roles count",
    predefinedRolesWarning: "Predefined roles like Admin and User are essential for system operation and cannot be changed.",
    role: "Role",
    description: "Description",
    roleManagement: "Role management",
    new: "New",
    addRole: "Add Role",
    roleName: "Role Name",
    roleDescription: "Role Description",
    roleDescriptionRequired: "Role description is required",
    roleNameRequired: "Role name is required",
    permissions: "Permissions",
    delete: "Delete",
    confirmDeleteRole: "Are you sure you want to delete this role?",
    errors: {
      roleNameRequired: "Role name is required",
      roleNameMax: "Role name must be at most 50 characters",
      roleDescriptionRequired: "Role description is required",
      roleDescriptionMax: "Role description must be at most 50 characters",
    },
  },
  passwordManager: {
    passwordManager: "Password Manager",
    passwordPolicy: "Password Policy",
    passwordExpiry: "Password Expiry",
    passwordValidityDescription: "The number of days the password is valid before a new password is required.",
    numericalDigitRequirement: "At least one numerical digit required in the password.",
    digits: "Digits",
    passwordMinimumLength: "Password Minimum Length",
    minimumCharactersDescription: "The minimum number of characters required for the password.",
    notRecentlyUsed: "Not Recently Used",
    recentPasswordReuseWarning: "Last 3 recently used passwords cannot be reused.",
    uppercaseCharacters: "Uppercase Characters",
    uppercaseRequirement: "At least one uppercase letter required in the password.",
    lowercaseCharacters: "Lowercase Characters",
    lowercaseRequirement: "At least one lowercase letter required in the password.",
    specialCharacters: "Special Characters",
    specialCharacterRequirement: "At least one special character required in the password.",
    loginAttempts: "Login Attempts",
    adminLoginLimits: "Admin can set user login attempt limits.",
    recommended: "Recommended",
    restoreDefault: "Restore default",
    loginAttemptIsRequired: "Login attempt is required",
    good: "Good",
    strong: "Strong",
    superStrong: "Super Strong",
    unnecessarilyStrong: "Unnecessarily Strong",
  },
  deviceManagement: {
    enterSerialNumber: "Enter Serial Number",
    enterDeviceUUID: "Enter Device UUID",
    selectType: "Select Type",
    allocationInformation: "This information is necessary for the allocation and administration of the user.",
    editDetails: "Edit Details",
    assignUsers: "Assign Users",
    searchAndSelect: "Search and Select",
    deviceManagement: "Device Management",
    addNewDevice: "Add new device",
    addDevice: "Add Device",
    csv: "CSV",
    devicesSelected: "Devices selected",
    clearSelection: "Clear selection",
    totalDeviceCount: "Total device count",
    deleteDevice: "Delete Device",
    areYouSureYouWantToDeleteTheDevice: "Are you sure you want to delete the device?",
    serialNumber: "Serial Number",
    deviceUUID: "Device UUID",
    deviceType: "Device Type",
    assignedUsers: "Assigned Users",
    laptop: "Laptop",
    desktop: "Desktop",
    csvUpload: {
      csvUpload: "CSV Upload",
      csvUploadData: "Effortlessly add multiple users via data list uploads",
      sampleFile: "Sample CSV File",
      clickToUploadOr: "Click to Upload or",
      dragAndDrop: "Drag and Drop",
      fileFormat: "CSV file format (max 2MB)",
      processing: "Processing your data...",
      waitingMessage: "Please wait as this might take some time if your data is huge",
      uploadCsvFile: "You can only upload CSV files!",
      csvFileLimit: "CSV file must be smaller than 2MB!",
      csvErrorMessageForDeviceManagement: "CSV file must be smaller than 2MB!",
    },
    errors: {
      serialNumberRequired: "Serial Number is required",
      deviceUUIdRequired: "Device UUID is required",
      deviceTypeRequired: "Device Type is required",
    },
  },
  userManagement: {
    submitDetailsPrompt: "Looks like you have made some changes",
    userBehavior: "User Behavior",
    yesterday: "Yesterday",
    today: "Today",
    sevenDaysAgo: "7 Days ago",
    showMore: "Show more",
    confirmDeprovisionUser: "Are you sure you want to deprovision this user from the group?",
    userApps: "User apps",
    provisioningApps: "Provisioning apps",
    dataNotFound: "Data not found",
    ssoApps: "SSO apps",
    userInformation: "User Information",
    clickTheBelowButtonToSyncApps: "Click the below button to sync apps",
    withYourUsers: "with your users",
    askUserToCheckMobileNotifications: "Please ask the user to check their mobile for the welcome notification to set up their account!",
    contactSales: "Contact Sales",
    zone: "Zone",
    editConfigureSearchAndUpdate: "Edit - Configure (Search & Update)",
    editMappingAttributes: "Edit - Mapping (Attributes)",
    mapperSyncTitle: "Mapper Sync",
    disablingActiveDirectoryWarning: "Disabling Active Directory will result in all user accounts being disabled. Are you sure you want to proceed?",
    deleteDirectoryConnectionWarning: "Delete this directory connection will prevent users to login to approved apps.",
    areYouSureYouWantToContinue: "Are you sure you want to continue?",
    managementDirectory: "Management Directory",
    editConfigureAuthentication: "Edit - Configure (Authentication)",
    selectChannel: "Select Channel",
    pleaseSelectChannelToImportUsers: "Please select the channel to import users",
    akkuAsDirectory: "Akku as a directory",
    syncFromYourDirectory: "Sync from your directory",
    finalReviewUserData: "Final Review - User Data",
    reviewMappingDescription: "Please review the mapping below and confirm changes if any",
    deleteRestrictionsWarning: "Are you sure you want to delete the restrictions?",
    noServicesAvailable: "No Services Available",
    resetPasswordInitiatedBy: "Reset password Initiated by",
    inActiveUser: "Are you sure you want to disable this user ?",
    resetPasswordHistory: "Reset Password History",
    totalResetList: "Total Reset list",
    activeUser: "Are you sure you want to enable this user ?",
    updatePhoneNumberWarning: "Are you sure you want to update phone number since user has already registered device to this number?",
    disConnectApp: "Disconnect App",
    connectedDirectory: "Connected Directory",
    syncGroup: "Sync Group",
    syncChangeUsers: "Sync change users",
    serverPrincipal: "Server principal",
    kerberosGeneralDetails: "Kerberos - General Details",
    uiDisplayName: "UI Display Name",
    kerberosRealm: "Kerberos Realm",
    keyTab: "Key Tab",
    syncChangedUsers: "Sync changed users",
    syncAllUsers: "Sync all users",
    mapperSync: "Mapper Sync",
    deleteAttribute: "Delete Attribute",
    reviewMapping: "ReviewMapping",
    sourceLDAP: "SOURCE - LDAP",
    targetAkku: "TARGET - Akku",
    transformation: "TRANSFORMATION",
    mappingAttributes: "Mapping - Attributes",
    readOnly: "Read only",
    writeable: "Writeable",
    unsynced: "Unsynced",
    editMode: "Edit Mode",
    usersDn: "Users DN",
    usernameLDAPAttribute: "Username LDAP Attribute",
    rdnLdapAttribute: "RDN LDAP Attribute",
    uuidLdapAttribute: "UUID LDAP Attribute",
    userObjectClasses: "User object classes",
    syncUpdateInterval: "Sync Update Interval (seconds)",
    certificate: "Certificate",
    directoryName: "Directory Name",
    connectionURL: "Connection URL",
    bindType: "Bind Type",
    bindDN: "Bind DN",
    bindCredentials: "Bind Credentials",
    simple: "Simple",
    configureAuthentication: "Configure - Authentication",
    chooseConnector: "Choose Connector",
    ldap: "LDAP",
    ad: "AD",
    kerberos: "KERBEROS",
    confirmationMessage: "Are you sure you want to close this tab? Any unsaved changes will be lost.",
    deAllocateUsersFromGroup: "De-Allocate user(s) from the group",
    groupDetails: "Group Details",
    groupName: "Group Name",
    selectUsers: "Select users to add in groups",
    emailId: "Email Id",
    disable: "Disable",
    enable: "Enable",
    search: "Search",
    userManagement: "User Management",
    editInformation: "Edit Information",
    basicDetails: "Basic Details",
    resetPassword: "Reset Password",
    lastUpdatedOn: "Last updated on ",
    firstName: "First Name",
    lastName: "Last Name",
    workEmailId: "Work Email ID",
    userType: "User Type",
    restrictions: "Restrictions",
    personalEmailId: "Personal Email ID",
    mobileNumber: "Mobile Number",
    enterDetailsOrAddCustomField: "Please enter the following details or add custom field",
    groupsConnectedApps: "Groups connected apps",
    update: "Update",
    deleteRestrictionsConfirmation: "Are you sure you want to delete the restrictions ?",
    untitled: "Untitled",
    connectedApps: "Connected Apps",
    individualApps: "Individual apps",

    disconnectApps: "Disconnect App",
    confirmDisconnectAppFromGroup: "Are you sure you want to disconnect this app from this group ?",
    cancel: "Cancel",
    yes: "Yes",
    no: "No",
    add: "Add",
    selectAppsToAddUser: "Select apps to add to user",
    selectAppsToAddGroup: "Select apps to add in the groups",
    restrictionType: "Restriction Type",
    serialNumber: "Serial Number",
    createdOn: "Created On",
    name: "Name",
    description: "Description",
    selectZone: "Select Zone",
    selectRange: "Select Range",
    deviceRestriction: "Device restriction",
    ipRestriction: "IP restriction",
    timeParameters: "Time parameters",
    inActive: "Inactive",
    active: "Active",
    status: "Status",
    agentSent: "Agent Sent",
    chooseTypeForIP: "Choose type for IP",
    startingIP: "Starting IP",
    range: "Range",
    notAvailable: "Not available",
    timeRestriction: "Time restriction",
    enter: "Enter",
    temporaryPassword: "Enter the temporary password",
    note: "Note",
    passwordRequirement: "The password set on this screen will be required for the user’s first login after resetting their password.",
    uppercaseCharacters: "Password must contain at least one uppercase letter",
    lowercaseCharacters: "Password must contain at least one lowercase letter",
    specialCharacters: "Password must contain at least one special character",
    digits: "Password must contain at least one digit",
    passwordLength: "Password must be at least {{length}} characters",
    noAppsFound: "No apps found",
    addUser: "Add user",
    users: "Users",
    directory: "Directory",
    csv: "CSV",
    totalUserCount: "Total User Count",
    changeToGroupView: "Change to group view",
    clearSelection: "Clear selection",
    syncAll: "Sync All",
    sync: "Sync",
    pleaseConfigureAtLeastOneApp: "Please configure at least one app to add users",
    upgradeYourPlan: "Upgrade your plan to use this product. Contact Akku support.",
    contactAdmin: "Contact admin",
    suspend: "Suspend",
    delete: "Delete",
    account: "Account",
    areYouSureSuspend: "Are you sure you want to suspend these user accounts?",
    deletingUserAccount: "Deleting this user account will make the user lose all their permissions with their associated apps.",
    userSelected: "User selected",
    usersSelected: "Users selected",
    groups: "Groups",
    accessManager: "Access Manager",
    assignGroup: "Assign Group",
    theSearchIsCaseSensitive: "The search is case sensitive",
    totalGroups: "Total Groups",
    changeToUserView: "Change to user view",
    deleteGroup: "Delete Group",
    createGroup: "Create Group",
    email: "Email",
    services: "Services",
    triggerDesc: "Click to sort descending ",
    triggerAsc: "Click to sort ascending",
    cancelSort: "Click to cancel sorting",
    filter: "Filter",
    removeRestriction: "Remove restriction",
    deAllocateUser: "De-allocate User",
    deAllocateUserConfirmation: "Are you sure you want to de-allocate the selected user from this group?",
    deleteGroupConfirmation: "Are you sure you want to delete the group(s)?",
    singleUser: "SINGLE USER",
    allocationInformation: "This information is necessary for the allocation and administration of the user",
    personalDetailsMessage: "This information will assist Akku in guiding the user through the onboarding process",
    configureSearchUpdate: {
      configureSearchHeading: "Configure - Search & Update",
      editMode: "Edit Mode*",
      userDn: "Users DN*",
      userLdap: "Username LDAP Attribute*",
      rdnLdap: "RDN LDAP Attribute*",
      uuidLdap: "UUID LDAP Attribute*",
      userObject: "User object classes*",
    },
    deviceAuthorization: {
      deviceAuthorization: "Device Authorization",
      selectionContent: "Would you like to whitelist your devices on:",
      policyLevel: "Policy Level",
      deviceLevel: "Device Level",
      downloadAkku: "Please download the Akku Device Manager",
      desktopApps: "Desktop apps",
      pleaseAddContent: "Once downloaded, please add Akku Device Manager to your policy engine",
      cancel: "Cancel",
      next: "Next",
    },
    csvUpload: {
      csvUpload: "CSV Upload",
      csvUploadData: "Effortlessly add multiple users via data list uploads",
      sampleFile: "Sample CSV File",
      clickToUploadOr: "Click to Upload or",
      dragAndDrop: "Drag and Drop",
      fileFormat: "CSV file format (max 2MB)",
      processing: "Processing your data...",
      waitingMessage: "Please wait as this might take some time if your data is huge",
      uploadCsvFile: "You can only upload CSV files!",
      csvFileLimit: "CSV file must be smaller than 2MB!",
      csvErrorMessageForDeviceManagement: "CSV file must be smaller than 2MB!",
    },
    errors: {
      firstName: {
        required: "Please enter the first name",
        letterAndSpace: "Only letters and spaces are allowed in the first name",
        minLength: "Minimum length is 4 characters for the first name",
        maxLength: "Maximum length is 16 characters for the first name",
      },
      lastName: {
        required: "Please enter the last name",
        letterAndSpace: "Only letters and spaces are allowed in the last name",
        minLength: "Minimum length is 1 character for the last name",
        maxLength: "Maximum length is 16 characters for the last name",
      },
      phone: {
        required: "Mobile Number is required",
        minLength: "Mobile Number must be at least 8 characters",
        maxLength: "Mobile Number cannot exceed 13 characters",
      },
      personalEmail: {
        required: "Email is required",
        invalidEmail: "Invalid email format",
      },
      workEmail: {
        required: "Email is required",
        invalidEmail: "Invalid email format",
      },
      customField: {
        required: "Please fill this field",
        minLength: "Please enter at least 3 characters",
        uniqueName: "Field already exists",
      },
      email: {
        invalid: "Invalid email format",
        required: "Please enter the email",
      },
      userType: {
        required: "User Type is required",
      },
      newField: {
        required: "Please fill this field",
        min: "Please enter at least 3 characters",
      },
      fieldLabel: {
        uniqueCheck: "Attribute name already exists",
        required: "Field is required",
        min: "Please enter at least 3 characters",
        duplicate: "Field already exists",
      },
      groupDescriptionRequired: "Group description is required",
      groupDescriptionLength: "Group description must be at most 100 characters long",
      groupNameRequired: "Group name is required",
      ldap: {
        connectionUrl: "Connection URL is required",
        ldapName: "Directory name is required",
        bindDn: "Bind DN is required",
        bindCredentials: "Bind credentials are required",
        editModeRequired: "Edit Mode is required",
        usersDnRequired: "Users DN is required",
        usernameLDAPAttributeRequired: "Username LDAP attributes are required",
        rdnLdapAttributeRequired: "RDN LDAP attributes are required",
        uuidLdapAttributeRequired: "UUID LDAP attributes are required",
        userObjectClassesRequired: "User object classes are required",
        syncUpdateIntervalRequired: "Sync update interval is required",
        certificateRequired: "Certificate is required",
      },
      kerberos: {
        kerberosNameRequired: "UI display name is required",
        kerberosNameMin: "UI display name must be at least 3 characters",
        kerberosRealmNameRequired: "Kerberos realm name is required",
        kerberosRealmNameMin: "Kerberos realm name must be at least 3 characters",
        serverPrincipalNameRequired: "Server principal is required",
        serverPrincipalNameMin: "Server principal must be at least 3 characters",
        keytabPathRequired: "Key tab is required",
      },
      ldapReviewMapping: {
        sourceRequired: "Source LDAP is required",
        targetRequired: "Target LDAP is required",
        userTypeRequired: "Transformation type is required",
      },
    },
    addUsers: {
      header: "Add Users",
      iconAlt: "add user",
    },
    stepper: {
      channel: "Channel",
      directoryConnection: "Directory Connection",
      chooseConnector: "Choose Connector",
      configure: "Configure",
      authentication: "Authentication",
      searchAndUpdate: "Search & Update",
      mapping: "Mapping",
      attributes: "Attributes",
      userStatus: "User Status",
      notifications: "Notifications",
    },
    userStepper: {
      channel: {
        title: "Channel",
      },
      directoryConnection: {
        title: "Directory Connection",
        description: "Enter your personal details",
      },
      chooseConnector: {
        title: "Choose Connector",
        description: "Enter your personal details",
      },
      configure: {
        title: "Configure",
        description: "Enter your security details",
        children: {
          authentication: {
            title: "Authentication",
            description: "Enter child step 1 details",
          },
          searchAndUpdate: {
            title: "Search & Update",
            description: "Enter child step 2 details",
          },
        },
      },
      mapping: {
        title: "Mapping",
        description: "Enter your account details",
        children: {
          attributes: {
            title: "Attributes",
            description: "Enter child step 1 details",
          },
          userStatus: {
            title: "User Status",
            description: "Enter child step 2 details",
          },
          notifications: {
            title: "Notifications",
            description: "Enter child step 3 details",
          },
        },
      },
    },
    mfa: {
      multiFactorAuthentication: "Multi-factor authentication",
      selectVerificationMethod: "Select a Verification method",
      mobilePushNotification: "Mobile Push Notification",
      emailOtp: "Email OTP",
      smsOtp: "SMS OTP",
      verifiedSuccessfully: "Verified Successfully",
      navigatingToUserManagement: "Navigating to User management console.",
      enterVerificationCodeEmail: "Enter the verification code sent to your registered Email address",
      resendCode: "Resend Code",
      seconds: "sec",
      verifyOtp: "Verify OTP",
      enterVerificationCodeMobile: "Enter the verification code sent to your registered Mobile Number",
      checkYourDevice: "Check your device",
      notificationMessage: "Akku sent a notification to your device. Tap ",
      notificationAction: "on the notification to verify it’s you.",
    },
  },
};
export default en;
