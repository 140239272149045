import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik, Form } from "formik";
import TestingModal from "../modal/TestingModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveStep,
  setAuthentication,
  setConnectorId,
  setIsBackButtonPressed,
  setConnectorDetailDirty,
  setProvisioningButtonStatus,
  setIsConnectorApiCalled,
  setAuthenticationEdited,
  setIsConnectionTested,
  setConnectorAuthenticationEndpointConfigId,
} from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../../services/storage/Storage";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { connectionAuthPayload, connectionAuthSchema, removeEmptyStringsFromArray, updateIsActiveNext } from "../helper/connectorHelper";
import { RootState } from "./AuthenticationTypes";
import { Button } from "antd";
import RenderAuthFields from "./RenderAuthFields";
import { useTranslation } from "react-i18next";

const ConnectionAuthentication = () => {
  const { t, i18n } = useTranslation();
  const provisioningConnectionURL: string | undefined = process.env.REACT_APP_PROVISIONING_CLOUD_FUN_BASEURL;

  const dispatch = useDispatch();
  const appDetails = useSelector((state: RootState) => state?.AppDetailsSlice?.appDetails);
  const isTenantAdmin = useSelector((state: any) => state?.ProfileDetailsSlice?.isTenantAdmin);
  const isPublished = useSelector((state: any) => state?.ProfileDetailsSlice?.isPublished);
  const connectorDetailsTokenType = useSelector((state: RootState) => state?.provisioning?.generalDetails?.tokenType);
  const provisioning = useSelector((state: RootState) => state?.provisioning);
  const generalDetails = useSelector((state: RootState) => state?.provisioning?.generalDetails);
  const authentication = useSelector((state: RootState) => state?.provisioning?.authentication);
  const isTestAuthentication = useSelector((state: RootState) => state?.provisioning?.authenticationTestButtonClicked);
  const akkuProvisioningConnectorId = useSelector((state: RootState) => state.provisioning?.akkuProvisioningConnectorId);
  const buttonStatus = useSelector((state: any) => state?.provisioning?.duplicateProvisioningButtons);
  const akkuProvisioningConnectorEndpointConfigId = useSelector((state: any) => state.provisioning?.connectorAuthenticationEndpointConfigId);

  const provisioningConnectorTestApi = useGetApiRequests("provisioningConnectorTestApi", "POST");
  const provisioningConnectorCreate = useGetApiRequests("provisioningConnector", "POST");
  const provisioningConnectorUpdate = useGetApiRequests("provisioningConnectorUpdate", "PUT");

  const connectorEndPointConfiguration = useGetApiRequests("provisioningConnectorConfigure", "POST");
  const provisioningConnectorConfigureUpdate = useGetApiRequests("provisioningConnectorConfigureUpdate", "PUT");

  const realmId = retrieveData("realmId", true);

  const [isActiveNext, setIsActiveNext] = useState<boolean>(false);
  const [testModal, setTestModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [rotate, setRotate] = useState(false);
  const [authenticationDirty, setAuthenticationDirty] = useState<boolean>(false);

  useEffect(() => {
    updateIsActiveNext(provisioning, setIsActiveNext, dispatch);
  }, []);
  const formikRef = useRef<any>(null);

  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);
  const connectionTestApiCall = async (values: any) => {
    const token: string = retrieveData("authToken", true);
    let scopeStore = removeEmptyStringsFromArray(values.scope);
    setRotate(true);
    setTestModal(true);
    let payload = connectionAuthPayload(values, generalDetails, realmId, appDetails, connectorDetailsTokenType);
    if (scopeStore.length > 0) {
      payload.scope = values?.scope?.join("||");
    } else {
      payload.scope = "";
    }
    payload.name = generalDetails?.name?.toLowerCase();
    payload.applicationName = generalDetails?.name?.toLowerCase();
    payload.apiType = "VALIDATION";
    payload.isDefaultSourceConnector = undefined;
    payload.logoUrl = undefined;
    const requestUrl: any = provisioningConnectionURL;
    try {
      // Call Test API
      const response = await fetch(requestUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data?.statusCode === 200 && data?.success) {
        setRotate(false);
        setTimeout(() => {
          setIsActiveNext(true);
          dispatch(setIsConnectionTested(true));
          setAuthenticationDirty(false);
          dispatch(setConnectorDetailDirty(false));
        }, 1000);
      } else {
        setIsActiveNext(false);
        setRotate(false);
        setTestModal(false);
        dispatch(setIsConnectionTested(false));
        setAuthenticationDirty(true);
        dispatch(setConnectorDetailDirty(true));
        const err = {
          response: {
            status: 400,
            data: {
              message: "Testing connection failed",
            },
          },
        };
        handleRequestError(err);
      }
    } catch (err: any) {
      console.error("error during test connection", err);
      setIsActiveNext(false);
      setTestModal(false);
      setRotate(false);
      dispatch(setIsConnectionTested(false));
      setAuthenticationDirty(true);
      dispatch(setConnectorDetailDirty(true));
      handleRequestError(err);
    }
  };

  const handleSubmit = async (values: any) => {
    if (isActiveNext && !authenticationDirty) {
      if (provisioning?.isConnectionTested) {
        handleTestAuthentication(values);
      } else {
        handleToNext();
      }
    } else {
      if (provisioning?.isConnectionTested && !provisioning?.connectorDetailDirty && !authenticationDirty) {
        handleTestAuthentication(values);
      } else {
        if (provisioning?.connectorDetailDirty || authenticationDirty) {
          connectionTestApiCall(values);
        } else {
          if (!provisioning?.isAuthenticationEdited && !provisioning?.isDuplicate) {
            handleTestAuthentication(values);
          } else {
            connectionTestApiCall(values);
          }
        }
      }
    }
  };

  const handleTestAuthentication = async (values: any) => {
    const commonPayload = {
      tokenApiEndpoint: "",
      userName: "",
      password: "",
      grantType: "",
      clientId: "",
      secret: "",
      tokenApiUrl: "",
      userNameEmail: "",
      apiToken: "",
      scope: [""],
      tokenMethodType: "",
    };
    setLoader(true);
    if (connectorDetailsTokenType === "OIDC") {
      const storePayload = {
        ...commonPayload,
        tokenApiEndpoint: values?.tokenApiEndpoint,
        userName: values?.userName,
        password: values?.password,
        grantType: values?.grantType,
        clientId: values?.clientId,
        secret: values?.secret,
        scope: values?.scope?.length > 0 ? values?.scope : [""],
      };
      dispatch(setAuthentication(storePayload));
    } else if (connectorDetailsTokenType === "SERVICE_TOKEN") {
      const storePayload = {
        ...commonPayload,
        tokenApiUrl: values?.tokenApiUrl,
        tokenApiEndpoint: values?.tokenApiEndpoint,
        userNameEmail: values?.userNameEmail,
        tokenMethodType: values?.tokenMethodType,
        scope: values?.scope?.length > 0 ? values?.scope : [""],
      };
      dispatch(setAuthentication(storePayload));
    } else {
      const storePayload = {
        ...commonPayload,
        apiToken: values?.apiToken,
        tokenApiEndpoint: values?.tokenApiEndpoint,
        userNameEmail: values?.userNameEmail,
        tokenMethodType: values?.tokenMethodType,
        scope: values?.scope?.length > 0 ? values?.scope : [""],
      };
      dispatch(setAuthentication(storePayload));
    }
    createPayloadConnector(values);
  };

  const createPayloadConnector = (values: any) => {
    const payload = connectionAuthPayload(values, generalDetails, realmId, appDetails, connectorDetailsTokenType);
    provisioningConnectorApiCall(payload);
  };

  const handleBackToUser = () => {
    dispatch(setIsBackButtonPressed(true));
    if (!provisioning.isConnectorApiCalled) {
      dispatch(setIsConnectionTested(false));
    }
    dispatch(setActiveStep(0));
  };

  const handleToNext = () => {
    dispatch(setIsConnectionTested(false));
    if (provisioning?.isDuplicate) {
      dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorUserProvisioning: true }));
    }
    dispatch(setAuthenticationEdited(false));
    dispatch(setActiveStep(2));
  };

  const handleApiResponse = (response: any) => {
    if (response?.status === 200) {
      setIsActiveNext(true);
      dispatch(setAuthenticationEdited(false));
      dispatch(setConnectorDetailDirty(false));
      dispatch(setIsConnectorApiCalled(true));
      if (response?.data?.data?.akkuProvisioningConnectorId && response?.data?.data?.akkuProvisioningConnectorId !== "") {
        dispatch(setConnectorId(response?.data?.data?.akkuProvisioningConnectorId)); //setting connector id while saving the app for first time
      }
      if (provisioning?.isDuplicate) {
        dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorAuthentication: false }));
      }
      setLoader(false);
      setTimeout(() => {
        setRotate(false);
        handleToNext();
      }, 1000);
    }
  };

  const handleApiError = (err: any) => {
    setIsActiveNext(false);
    setTestModal(false);
    setRotate(false);
    handleRequestError(err);
    setLoader(false);
  };

  const provisioningConnectorApiCall = async (payload: any) => {
    setRotate(true);
    try {
      let response: any;
      const param = {
        akkuProvisioningConnectorId: akkuProvisioningConnectorId ?? provisioning?.akkuProvisioningConnectorId,
      };
      let endPointConfigPayload: any = {
        apiEndpointUrl: payload?.tokenApiEndpoint,
        methodType: payload?.tokenMethodType,
        realmId: realmId,
        name: generalDetails?.name,
        type: "request",
        endpointType: "TOKEN",
        endpointDescription: "token_validator",
      };
      payload.akkuMasterClientId = appDetails?.akkuMasterClientId || appDetails?.akkuMasterClient?.akkuMasterClientId;
      if (provisioning?.isActiveEdit || (authenticationDirty && isTestAuthentication)) {
        //check if the provisioning duplicate or not.
        if (buttonStatus?.connectorAuthentication && !provisioning?.isConnectorApiCalled) {
          payload.isTenantAdmin = isTenantAdmin;
          payload.publishStatus = isPublished;
          response = await provisioningConnectorCreate(payload);
          dispatch(setConnectorId(response?.data?.data?.akkuProvisioningConnectorId));
          if (response?.status === 200) {
            const endPointResponse: any = await connectorEndPointConfiguration(endPointConfigPayload);
            const provisioningConnectorEndpointConfigId = endPointResponse?.data?.data?.akkuProvisioningConnectorEndpointConfigId;
            dispatch(setConnectorAuthenticationEndpointConfigId(provisioningConnectorEndpointConfigId));
          }
        } else {
          payload.isActive = provisioning?.isActive;
          payload.isTenantAdmin = isTenantAdmin;
          payload.publishStatus = isPublished;
          response = await provisioningConnectorUpdate(payload, "", param);
          endPointConfigPayload.akkuProvisioningConnectorEndpointConfigId = akkuProvisioningConnectorEndpointConfigId;
          const params: any = { akkuProvisioningConnectorId: provisioning?.akkuProvisioningConnectorId };
          await provisioningConnectorConfigureUpdate(endPointConfigPayload, "", params);
        }
      } else {
        if (provisioning?.isBackButtonEnabled && provisioning?.isConnectionTested && provisioning?.isConnectorApiCalled) {
          payload.isActive = provisioning.isActive;
          payload.isTenantAdmin = isTenantAdmin;
          payload.publishStatus = isPublished;
          response = await provisioningConnectorUpdate(payload, "", param);
          const params: any = { akkuProvisioningConnectorId: provisioning?.akkuProvisioningConnectorId };
          endPointConfigPayload.akkuProvisioningConnectorEndpointConfigId = akkuProvisioningConnectorEndpointConfigId;
          await provisioningConnectorConfigureUpdate(endPointConfigPayload, "", params);
        } else {
          payload.isTenantAdmin = isTenantAdmin;
          payload.publishStatus = isPublished;
          response = await provisioningConnectorCreate(payload);
          dispatch(setConnectorId(response?.data?.data?.akkuProvisioningConnectorId));
          if (response?.status === 200) {
            const endPointResponse: any = await connectorEndPointConfiguration(endPointConfigPayload);
            const provisioningConnectorEndpointConfigId = endPointResponse?.data?.data?.akkuProvisioningConnectorEndpointConfigId;
            dispatch(setConnectorAuthenticationEndpointConfigId(provisioningConnectorEndpointConfigId));
          }
        }
      }
      handleApiResponse(response);
    } catch (err: any) {
      handleApiError(err);
    }
  };

  const AuthFieldsComponent = React.memo(({ values, connectorDetailsTokenType }: any) => {
    const memoizedAuthFields = useMemo(() => {
      return <RenderAuthFields connectorDetailsTokenType={connectorDetailsTokenType} values={values} />;
    }, [connectorDetailsTokenType, values]);

    return <div>{memoizedAuthFields}</div>;
  });

  return (
    <div className="w-full px-10 pb-20 ">
      <p className="app-header pt-8">
        {t("appManagement.provisioning.connectorDetails")} - {t("appManagement.provisioning.authentication")} {provisioning?.isDuplicate ? `${t("appManagement.copy")}` : null}
      </p>
      <div className="w-full mx-auto">
        <Formik
          initialValues={authentication}
          validationSchema={connectionAuthSchema(connectorDetailsTokenType, t)}
          onSubmit={(values, { setTouched, resetForm }) => {
            handleSubmit(values);
            // Reset the dirty state after form submission
            setTouched({});
            resetForm({ values });
          }}
          enableReinitialize
          innerRef={formikRef}
        >
          {({ dirty, errors, values }) => {
            setAuthenticationDirty(dirty);
            return (
              <Form>
                <div className="w-full pt-10  app-connector auth flex-wrap app-connector-main">
                  <AuthFieldsComponent values={values} connectorDetailsTokenType={connectorDetailsTokenType} />
                  <div className="footer ">
                    <div className=" w-full mx-auto modal-footer">
                      <div className="pr-5 w-full flex justify-end ">
                        <CustomButtonBack text={t("common.back")} onClick={() => handleBackToUser()} />
                        {!isActiveNext || dirty ? (
                          <Button loading={loader} type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                            {t("common.test")}
                          </Button>
                        ) : (
                          <Button loading={loader} htmlType="submit" type="primary" className="bg-[#5441DA] submit-btn">
                            {t("common.next")}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {testModal && <TestingModal testModal={testModal} setTestModal={setTestModal} rotate={rotate} />}
    </div>
  );
};

export default ConnectionAuthentication;
