import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";

import { groupEndPointDescriptionPayload, transformHeaderParameters, UseGetEndpointDescription, userDeProvisionErrorEvaluatorScroll } from "../../../helper/connectorHelper";
import {
  setActiveStep,
  setDeProvisionConnectorEndpointConfigId,
  setDeProvisioning,
  setIsDeProvisioningApiCalled,
  setIsDeProvisioningApiError,
  setProvisioningButtonStatus,
} from "../../../../../../../redux/slice/provisioning/ProvisioningSlice";
import useGetApiRequests from "../../../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../../../../services/storage/Storage";
import * as Yup from "yup";
import CustomButtonBack from "../../../../../../../layouts/component/CustomButtonBack";
import DeProvisionFormFields from "../form/DeprovisionFormFields";
import SuccessMessageModal from "../../../../../../access-manager/create/IpTimeSuccessModal";
import { useTranslation } from "react-i18next";

const GroupDeProvision = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setActiveKey } = props;

  const dispatch = useDispatch();
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);

  const akkuDeProvisionConnectorEndpointConfigId = useSelector((state: any) => state.provisioning?.akkuDeProvisionConnectorEndpointConfigId);
  const akkuProvisioningConnectorId = useSelector((state: any) => state.provisioning?.akkuProvisioningConnectorId);
  const buttonStatus = useSelector((state: any) => state?.provisioning?.duplicateProvisioningButtons);
  const isTenantAdmin = useSelector((state: any) => state?.ProfileDetailsSlice?.isTenantAdmin);
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);
  const provisioning = useSelector((state: any) => state?.provisioning);
  const endPointResponseDtosData = useSelector((state: any) => state?.provisioning?.endPointResponseDtosData);
  const formInitialValues = useSelector((state: any) => state?.provisioning?.deProvisioning);
  const deProvisionDetails = useSelector((state: any) => state?.provisioning?.deProvisioning);

  const isApiCalled = useSelector((state: any) => state?.provisioning?.isDeProvisioningApiCalled);
  const isApiError = useSelector((state: any) => state?.provisioning?.isDeProvisioningApiError);

  const deProvisioningConnectorConfigure = useGetApiRequests("provisioningConnectorConfigure", "POST");
  const deProvisioningConnectorConfigureUpdate = useGetApiRequests("provisioningConnectorConfigureUpdate", "PUT");

  const realmId = retrieveData("realmId", true);

  const [deProvisionDirty, setDeProvisionDirty] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  useEffect(() => {
    // Revalidate the form on language change
    if (props.formRef.current) {
      props.formRef.current.validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);
  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };

  const handleSubmit = (values: any) => {
    if (provisioning?.isDuplicate || deProvisionDirty || isApiError?.groupDeProvisionApiError) {
      setLoader(true);
      createPayloadConnectorConfigure(values);
      dispatch(setDeProvisioning(values));
    } else {
      dispatch(setActiveStep(4));
    }
  };

  const createPayloadConnectorConfigure = async (values: any) => {
    const { groupDeProvision } = values;
    const deProvisionCreatePayload = {
      apiEndpointUrl: groupDeProvision[0].apiEndpointURL,
      methodType: groupDeProvision[0].methodType,
      endpointDescription: groupEndPointDescriptionPayload(groupDeProvision[0].methodType),
      requestPayload: groupDeProvision[0]?.requestPayload || null,
      sampleResponse: groupDeProvision[0]?.response || null,
      realmId: realmId,
      name: generalDetails?.name,
      type: "request",
      headerParameters: transformHeaderParameters(values?.groupDeProvision[0]?.headerParam),
      pathVariable: transformHeaderParameters(values?.groupDeProvision[0]?.pathVariable),
      isTenantAdmin: isTenantAdmin,
    };
    const deProvisionPayloadUpdate: any = {
      apiEndpointUrl: groupDeProvision[0]?.apiEndpointURL,
      methodType: groupDeProvision[0]?.methodType,
      endpointDescription: groupEndPointDescriptionPayload(groupDeProvision[0].methodType),
      requestPayload: groupDeProvision[0]?.requestPayload || null,
      sampleResponse: groupDeProvision[0]?.response || null,
      realmId: realmId,
      name: generalDetails?.name,
      type: "request",
      headerParameters: transformHeaderParameters(values?.groupDeProvision[0]?.headerParam),
      pathVariable: transformHeaderParameters(values?.groupDeProvision[0]?.pathVariable),
      akkuProvisioningConnectorEndpointConfigId: akkuDeProvisionConnectorEndpointConfigId?.groupDeProvision || formInitialValues?.groupDeProvision[0]?.akkuProvisioningConnectorEndpointConfigId,
      isTenantAdmin: isTenantAdmin,
    };
    const isCreated = UseGetEndpointDescription("delete_group", endPointResponseDtosData);
    const isDuplicateUpdate = provisioning?.isDuplicate ? provisioning?.isDuplicate && isApiCalled?.groupDeProvisionApiCalled : true; // for duplicate
    const shouldUpdateDeProvision = isDuplicateUpdate && ((provisioning?.isActiveEdit && isCreated) || isApiCalled?.groupDeProvisionApiCalled);

    if (shouldUpdateDeProvision) {
      handleUpdateDeProvisionApiCall(deProvisionPayloadUpdate);
    } else {
      handleCreateDeProvisionApiCall(deProvisionCreatePayload);
    }
  };
  const handleCreateDeProvisionApiCall = async (payload: any) => {
    try {
      const response: any = await deProvisioningConnectorConfigure(payload);
      const deProvisionConnectorEndpointConfigId = response?.data?.data?.akkuProvisioningConnectorEndpointConfigId;
      dispatch(setDeProvisionConnectorEndpointConfigId({ ...akkuDeProvisionConnectorEndpointConfigId, groupDeProvision: deProvisionConnectorEndpointConfigId }));
      handleApiResponse(response);
    } catch (err: any) {
      setLoader(false);
      dispatch(setIsDeProvisioningApiError({ ...isApiError, groupDeProvisionApiError: true }));
      handleRequestError(err);
    }
  };

  const handleUpdateDeProvisionApiCall = async (payload: any) => {
    const param = {
      akkuProvisioningConnectorId: provisioning?.akkuProvisioningConnectorId ?? akkuProvisioningConnectorId,
    };
    try {
      const response: any = await deProvisioningConnectorConfigureUpdate(payload, {}, param);
      handleApiResponse(response);
    } catch (err: any) {
      setLoader(false);
      dispatch(setIsDeProvisioningApiError({ ...isApiError, groupDeProvisionApiError: true }));
      handleRequestError(err);
    }
  };

  const handleApiResponse = (response: any) => {
    const status = response.status;
    if (status === 200) {
      setLoader(false);
      dispatch(setIsDeProvisioningApiCalled({ ...isApiCalled, groupDeProvisionApiCalled: true }));
      setResponseMessage(response?.data?.message);
      setSuccessModal(true);
      setTimeout(() => {
        if (provisioning?.isDuplicate) {
          dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorGroupDeprovisioning: false, connectorMappingAttributes: true }));
        }
        handleCloseSuccessModal();
        dispatch(setActiveStep(4));
      }, 3000);
    }
  };

  const handleBack = () => {
    setActiveKey("2");
  };

  const handleSkip = () => {
    if (provisioning?.isDuplicate) {
      dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorGroupDeprovisioning: false, connectorMappingAttributes: true }));
    }
    dispatch(setActiveStep(4));
  };

  const headerParamSchema = Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.string().required("Key is required"),
        // value: Yup.string().required("Value is required"),
      }),
    )
    .required("Header Param is required");

  const connectorDeProvisionSchema = Yup.object().shape({
    groupDeProvision: Yup.array().of(
      Yup.object().shape({
        apiEndpointURL: Yup.string().required(t("appManagement.provisioning.errors.apiEndpointUrlRequired")),
        methodType: Yup.string().required(t("appManagement.provisioning.errors.methodTypeRequired")),
        // Uncomment and translate the following fields if needed in the future
        // headerParametersType: Yup.string().required(t("appManagement.provisioning.errors.requestParametersTypeRequired")),
        // headerParam: appDetails?.akkuMasterClientId === 1 ? headerParamSchema : Yup.array().notRequired(),
        // pathVariable: appDetails?.akkuMasterClientId === 2 ? headerParamSchema : Yup.array().notRequired(),
      }),
    ),
  });
  return (
    <>
      <Formik
        initialValues={formInitialValues}
        values={formInitialValues}
        onSubmit={(value: any) => handleSubmit(value)}
        validationSchema={connectorDeProvisionSchema}
        enableReinitialize={true}
        innerRef={props.formRef}
      >
        {({ values, setFieldValue, dirty, isSubmitting, errors }) => {
          setDeProvisionDirty(dirty);
          if (isSubmitting && Object.keys(errors).length > 0) {
            const err: any = errors.groupDeProvision;
            userDeProvisionErrorEvaluatorScroll(err[0], "3");
          }
          return (
            <Form>
              <div className=" w-full flex flex-wrap flex-col gap-2 justify-center items-center">
                <FieldArray name="groupDeProvision">
                  {() => (
                    <>
                      {values?.groupDeProvision?.length !== 0 &&
                        values?.groupDeProvision?.map((samlConfig: any, index: any) => (
                          <DeProvisionFormFields
                            key={index}
                            index={index}
                            values={values}
                            setFieldValue={setFieldValue}
                            provisioning={provisioning}
                            provisionDetails={deProvisionDetails}
                            samlConfig={samlConfig}
                            formName="groupDeProvision"
                            id={3}
                          />
                        ))}
                    </>
                  )}
                </FieldArray>
              </div>

              <div className="footer flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
                <div className="modal-footer w-full mx-auto ">
                  <div className="w-full flex justify-end pr-5">
                    <CustomButtonBack text={t("common.skip")} onClick={() => handleSkip()} />
                    <CustomButtonBack text={t("common.back")} onClick={() => handleBack()} />
                    <Button loading={loader} type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                      {t("common.next")}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      {successModal && <SuccessMessageModal open={successModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </>
  );
};

export default GroupDeProvision;
