import React, { useEffect, useState } from "react";
import PlansHeader from "./PlansHeader";
import { Button, Checkbox } from "antd";
import type { CheckboxProps } from "antd";
import CustomButtonBack from "../../../../../component/CustomButtonBack";
import useGetApiRequests from "../../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../toast/ErrorNotificationMessage";
import { triggerNotification } from "../../../../../toast/ToastBar";
import Loader from "../../../../../component/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerDetailFromApi, setReloadCustomerDashboard } from "../../../../../../redux/slice/CustomerSlice";
import { ErrorMessages } from "../../../../../../const/Messages";
import { compareObjects } from "../customerHelper";
import { plansDetails } from "./ProductsAndPlanHelperFIle";
import "./Plans.scss";
import { useTranslation } from "react-i18next";

const ProductsAndPlans = (props: any) => {
  const { handleCloseModal, isEdit, setGetCustomerDetails, setCurrentTab, getCustomerDetails, currentTab, ssoDetailsPayload, active } = props;

  const { t } = useTranslation();

  const disabled = false;
  const [plansSelected, setPlansSelected] = useState<boolean>(true);
  const [productsSelected, setProductsSelected] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [allPlans, setAllPlans] = useState([]);
  const [yearlySubscription, setYearlySubscription] = useState(false);
  const [featureIds, setFeatureIds] = useState<any>([]);

  const customerId = useSelector((state: any) => state?.CustomerSlice?.customerId);
  const customerPlanData = useSelector((state: any) => state?.CustomerSlice?.getCustomerDetails?.plansDetails);
  const customerGeneralData = useSelector((state: any) => state?.CustomerSlice?.getCustomerDetails?.generalDetails);
  const keycloak = useSelector((state: any) => state?.keycloak);
  const payloadData = useSelector((state: any) => state?.CustomerSlice?.getCustomerDetails);
  const userId: string = keycloak?.KeycloakInfo?.subject || "";

  const createCustomer = useGetApiRequests("customerOnBoardingCreation", "POST");
  const updateCustomerDetails = useGetApiRequests("createCustomer", "PUT");
  const getPlansAndFeatures = useGetApiRequests("customerPlansAndFeatures", "GET");
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(customerPlanData).length > 0) {
      setPlansSelected(!customerPlanData?.featureIdList);
      if (customerPlanData?.featureIdList && customerPlanData?.featureIdList.length > 0) {
        setProductsSelected(true);
      }
      setSelectedId(customerPlanData?.planId);
      setSelectedPlan(customerPlanData?.planId);
      if (customerPlanData?.subscriptionType === "yearly") {
        setYearlySubscription(true);
      }
      if (customerPlanData?.featureIdList && customerPlanData?.featureIdList?.length > 0) setFeatureIds(customerPlanData?.featureIdList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);
  useEffect(() => {
    getActivePlans();
    if (isEdit) {
      setPlansSelected(!customerPlanData?.featureIdList);
      if (customerPlanData?.featureIdList && customerPlanData?.featureIdList.length > 0) {
        setProductsSelected(true);
      }
      setSelectedId(customerPlanData?.planId);
      setSelectedPlan(customerPlanData?.planId);
      if (customerPlanData?.subscriptionType === "yearly") {
        setYearlySubscription(true);
      }
      if (customerPlanData?.featureIdList && customerPlanData?.featureIdList?.length > 0) setFeatureIds(customerPlanData?.featureIdList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getActivePlans = async () => {
    try {
      setLoader(true);
      const response: any = await getPlansAndFeatures("");
      const status = response.status;
      if (status === 200) {
        setLoader(false);
        if (response?.data?.data?.planList.length > 0) {
          const mergedArray: any = [];
          response.data?.data?.planList.forEach((responseItem: any) => {
            const matchingPlan = plansDetails.find((plan: any) => plan?.planType === responseItem?.name);
            if (matchingPlan) {
              const mergedItem = {
                akkuPlanId: responseItem?.akkuPlanId,
                name: responseItem?.name,
                isActive: responseItem?.isActive,
                isCustom: responseItem?.isCustom,
                include: matchingPlan?.include,
                products: matchingPlan?.products,
                planType: responseItem?.description,
              };
              mergedArray.push(mergedItem);
            }
          });
          const customOrder = ["Basic", "Professional", "Advanced"];
          const sortedPlans = mergedArray.sort((a: any, b: any) => customOrder.indexOf(a.name) - customOrder.indexOf(b.name));
          console.log("🚀 ~ getActivePlans ~ sortedPlans:", sortedPlans);

          setAllPlans(sortedPlans);
        } else {
          triggerNotification("warning", "", ErrorMessages?.noPlans, "topRight");
        }
      }
    } catch (err: any) {
      setLoader(false);

      handleRequestError(err);
    }
  };

  const onPlanSelection: CheckboxProps["onChange"] = (e: any) => {
    if (e.target.checked === false) {
      setSelectedId(null);
      setSelectedPlan(null);
    } else {
      setFeatureIds([]);
    }
    setPlansSelected(e.target.checked);
    if (productsSelected) {
      setProductsSelected(false);
    }
  };

  const handleBack = () => {
    setCurrentTab(3);
  };

  const handleCheckboxChange = (planType: any, e: any, item: any) => {
    if (e.target.checked === true) {
      setSelectedPlan(item?.akkuPlanId);
      setSelectedId(item?.akkuPlanId);
    } else {
      setSelectedPlan(null);
      setSelectedId(null);
    }
  };

  const handleSuccess = (response: any) => {
    if (response?.status === 201 || response?.status === 200) {
      dispatch(setReloadCustomerDashboard());
      triggerNotification("success", "", response?.data?.message, "topRight");
      handleCloseModal();
      setLoader(false);
    }
  };

  const handleError = (err: any) => {
    setLoader(false);
    if (err?.response?.status === 401 || err?.response?.status === 500) {
      handleRequestError(err);
    } else {
      triggerNotification("error", "", err?.response?.data?.message, "topRight");
    }
  };

  const handleNext = () => {
    setLoader(true);
    let subscriptionType = "";
    if (!yearlySubscription) {
      subscriptionType = "monthly";
    } else {
      subscriptionType = "yearly";
    }
    const payload: any = {
      subscriptionType: subscriptionType,
      adminUserEmailId: customerGeneralData?.primaryEmailId,
    };
    if (plansSelected) {
      payload.planId = selectedId;
    } else {
      payload.featureIdList = featureIds;
    }
    const customerPlan = {
      customerPlanPayloadDto: payload,
    };
    dispatch(
      setCustomerDetailFromApi({
        ...getCustomerDetails,
        plansDetails: payload,
      }),
    );
    setGetCustomerDetails({
      ...getCustomerDetails,
      plansDetails: payload,
    });
    if ((plansSelected || productsSelected) && (payload?.planId || payload?.featureIdList?.length > 0)) {
      if (!isEdit) {
        const finalPayload = {
          customerGeneralDetailsDto: payloadData?.generalDetails,
          emailConfigurationDto: payloadData?.emailDetails,
          sessionDto: ssoDetailsPayload?.sessionDto,
          customerPlanPayloadDto: payload,
          loggedInUserId: userId,
        };

        createCustomer(finalPayload).then(handleSuccess).catch(handleError);
      } else {
        const valueMatched = compareObjects(customerPlanData, payload);

        if (!valueMatched) {
          const pathParams: any = {
            name: customerId,
          };

          updateCustomerDetails(customerPlan, "", pathParams).then(handleSuccess).catch(handleError);
        } else {
          setLoader(false);
          handleCloseModal();
        }
      }
    } else {
      setLoader(false);
      triggerNotification("warning", "", t("tenants.selectPlansWarning"), "topRight");
    }
  };
  // Utility function to convert string to camelCase and handle special cases like "SSO/IDP"
  const formatString = (str: string) => {
    return str
      .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase()) // Remove non-alphanumeric characters, capitalize the next letter
      .replace(/^(.)/, (match) => match.toLowerCase()) // Ensure the first letter is lowercase
      .replace(/SSOIDP/i, "ssoIDP"); // Special case handling for "SSO/IDP"
  };
  return (
    <div className="w-full">
      <div className="mb-2 plan-header">
        <PlansHeader active={active} onClick={handleBack} yearlySubscription={yearlySubscription} setYearlySubscription={setYearlySubscription} />
      </div>
      <div className="w-full plans-container overflow-y-auto">
        <p className="plan-note pl-12 text-[#5441DA] font-Inter text-[17px] font-medium italic">
          {t("common.note")}:{t("tenants.youCanSelectYourPlan")}
        </p>

        <div className="plans mt-7 pl-[50px]">
          <div className="plans-selected mb-6 ">
            <Checkbox checked={plansSelected} disabled={disabled} onChange={onPlanSelection}>
              {t("tenants.selectPlan")}
            </Checkbox>
          </div>
          <div className="w-full mx-auto flex flex-wrap">
            {allPlans?.map((elm: any) => (
              <div className="w-[25%] ml-9 flex items-start justify-center product-list" key={elm?.akkuPlanId}>
                <Checkbox disabled={!plansSelected} checked={selectedPlan === elm?.akkuPlanId} className="mr-3" onChange={(e) => handleCheckboxChange(elm.planType, e, elm)} />
                <div className={`${selectedPlan === elm?.akkuPlanId ? "selected" : ""} card w-full`}>
                  <h2>{t(`tenants.${formatString(elm?.planType)}`)}</h2>
                  <p>{t(`tenants.${formatString(elm?.include)}`)}</p>
                  <ul>{elm?.products.map((item: any, index: any) => <li key={index}>{t(`tenants.${formatString(item)}`)}</li>)}</ul>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="products plans  mt-12 pl-[50px]"></div>
      </div>
      <div className="footer ">
        <div className="modal-footer w-[95%] mx-auto ">
          <div className="w-full flex justify-end">
            <CustomButtonBack onClick={handleBack} text={t("common.back")} />

            <Button htmlType="submit" onClick={handleNext} type="primary" className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white">
              {isEdit ? `${t("common.update")}` : `${t("common.save")}`}
            </Button>
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </div>
  );
};

export default ProductsAndPlans;
