import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuthSuccess, setIsTimeout } from "../../redux/slice/Users/UserDashboardUpdateSlice";

export default function MFASecurity() {
  const seconds: number = 600;
  const dispatch = useDispatch();
  //timer functions
  const timerRunout = useSelector((state: any) => state?.UserDashboardUpdateSlice);

  const [timer, setTimer] = useState(seconds); // 60 seconds for initial and reset times
  const [errorFlag, setErrorFlag] = useState(false); // flag for the error message
  const intervalRef = useRef<number | null>(null); // to store the interval ID

  // Function to start the countdown timer
  const startTimer = () => {
    if (intervalRef.current) clearInterval(intervalRef.current); // Clear any existing interval before starting a new one

    intervalRef.current = window.setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(intervalRef.current!); // Stop the timer at 0
          setErrorFlag(true); // Set the flag to true if timer runs out
          dispatch(setIsTimeout(true)); // Set Redux isTimeout to true when timer runs out
          return 0;
        }
        return prev - 1;
      });
    }, 1000); // Decrease by 1 second every interval
  };

  // Function to reset the timer to 60 seconds and restart the countdown
  const resetTimer = () => {
    setTimer(seconds); // Reset to 60 seconds
    setErrorFlag(false); // Reset the error flag
    startTimer(); // Restart the timers
  };

  // Start the countdown timer when the component mounts
  useEffect(() => {
    startTimer(); // Start the timer initially
    return () => {
      clearInterval(intervalRef.current!); // Clear the interval when component unmounts
    };
  }, []);

  const handleKeyboardEvent = () => {
    if (!errorFlag) resetTimer(); // Reset timer on any key press
  };
  // Event listener for any mouse event to reset the timer, but only if the timer has not run out
  useEffect(() => {
    const handleMouseEvent = () => {
      if (!errorFlag) resetTimer(); // Only reset the timer if it hasn't run out
    };
    window.addEventListener("mousedown", handleMouseEvent);
    window.addEventListener("keydown", handleKeyboardEvent);

    return () => {
      window.removeEventListener("mousedown", handleMouseEvent);
      window.removeEventListener("keydown", handleKeyboardEvent);
    };
  }, [errorFlag]);

  // Reset timer and handle timerRunout and authSuccess
  useEffect(() => {
    if (timerRunout?.authSuccess) {
      if (errorFlag) {
        // If authSuccess becomes true and the timer has run out, reset the timer
        resetTimer();
        dispatch(setAuthSuccess(false)); // Reset authSuccess to false to avoid continuous resets
      }
    }
  }, [timerRunout?.authSuccess, errorFlag]);

  // Format the timer in MM:SS format
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  return <div></div>;
}
