import React, { useState } from "react";
import { Button, DatePicker, Input, Select, Tooltip } from "antd";
import AuditLogTable from "./auditLog-table/AuditLogTable";
import { retrieveData } from "../../services/storage/Storage";
import { Formik, Form, Field, FieldProps } from "formik";
import dayjs from "dayjs";
import { triggerNotification } from "../../layouts/toast/ToastBar";
import { apiResponseMessage } from "../../constant/apiResponseMessage";
import { handleRequestError } from "../../layouts/toast/ErrorNotificationMessage";
import { auditLogActionType } from "./UserAuditHelper";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { useSelector } from "react-redux";
import "./userAuditLog.scss";

export default function UserAuditLog() {
  const auditLogsDownload = useGetApiRequests("auditLogDownload", "POST");
  const keycloak = useSelector((state: any) => state?.keycloak);
  const userId: string = keycloak?.KeycloakInfo?.subject || "";
  const initialValues = {
    search: "",
    actionType: [],
    dateRange: [] as any[],
    ipAddress: "",
  };
  const baseURL = process.env.REACT_APP_BASEURL;
  const token: string = retrieveData("authToken", true);
  const realmId = retrieveData("realmId", true);
  const [formValue, setFormValue] = useState({ ...initialValues });

  const { RangePicker } = DatePicker;

  const [loader, setLoader] = useState<boolean>(false);
  const [tableDataLists, setTableDataLists] = useState<any>([]);

  const [sizeChanger, setSizeChanger] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const handleGetUserAuditDetails = async (values: any, currentPage: number, sizeChanger: number) => {
    setFormValue({ ...formValue, ...values });
    const storeActionTypes = values.actionType;
    const formattedStartDate = values?.dateRange?.length > 0 ? dayjs(values?.dateRange[0])?.format("MM/DD/YY") : null;
    const formattedEndDate = values?.dateRange?.length > 0 ? dayjs(values?.dateRange[1])?.format("MM/DD/YY") : null;

    setLoader(true);
    const baseUrl = `${baseURL}/v1/audit-logs/search`;

    // Construct the query string
    const queryParams = new URLSearchParams();

    // Append action types
    storeActionTypes.forEach((actionType: any) => {
      queryParams.append("actionTypes", actionType);
    });

    // Append other parameters
    if (formattedStartDate) {
      queryParams.append("fromDate", formattedStartDate);
    }
    if (formattedEndDate) {
      queryParams.append("toDate", formattedEndDate);
    }
    queryParams.append("startValue", currentPage.toString());
    queryParams.append("limitValue", sizeChanger.toString());
    queryParams.append("ip", values?.ipAddress || "");
    queryParams.append("searchTerm", values?.search || "");
    queryParams.append("realmId", realmId);

    const url = `${baseUrl}?${queryParams.toString()}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        triggerNotification("error", "", apiResponseMessage?.tryAging, "topRight");
        return;
      }

      const data = await response.json();

      if (data.status === "OK") {
        setTableDataLists(data.data);

        setTotalRecords(data?.meta?.totalRecords ?? 0);
      }
      setLoader(false);
      // Process data here as needed
    } catch (error) {
      setLoader(false);
      handleRequestError(error);
    } finally {
      setLoader(false);
    }
  };

  const handleAuditLogsDownload = async () => {
    const queryParams = {
      parentId: userId,
    };
    const payload = {
      realmId: realmId,
    };
    try {
      const response: any = await auditLogsDownload(payload, queryParams);
      const status = response.status;
      if (status === 201) {
        triggerNotification("success", "", response?.data?.message, "topRight");
      }
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const handleSubmit = (values: any) => {
    handleGetUserAuditDetails(values, currentPage, sizeChanger);
  };

  const handlePageChange = (page: any) => {
    const pageSize = (page - 1) * sizeChanger;
    setCurrentPage(pageSize);

    handleGetUserAuditDetails(formValue, pageSize, sizeChanger);
  };
  const handlePageSizeChange = (current: any, newSize: any) => {
    setSizeChanger(newSize);

    handleGetUserAuditDetails(formValue, currentPage, newSize);
  };
  return (
    <div className="akku-container user-auditLog ">
      <div className="main-container auditLog">
        <div className="auditLog-title relative flex justify-between items-center">
          <h2 className="flex items-center mt-4">User Audit Log</h2>

          <Tooltip title={"Download User Audit Log"}>
            <Button disabled={!tableDataLists?.length} onClick={handleAuditLogsDownload} type="primary" className="accessManger-addBtn w-[44px] h-[44px] bg-[#5441DA]">
              <span className="material-symbols-outlined">download </span>
            </Button>
          </Tooltip>
        </div>
        <div className="auditLog-filters">
          <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize={true}>
            {({ resetForm, setFieldValue }) => {
              return (
                <Form>
                  <div className="flex justify-between w-[100%] gap-10">
                    <div className="filter w-full">
                      <p className="label">Search</p>
                      <Field name="search">{({ field }: FieldProps) => <Input {...field} placeholder="Enter" className="w-[100%] h-[48px]" />}</Field>
                    </div>

                    <div className="filter w-full">
                      <p className="label">Action Type</p>
                      <Field name="actionType">
                        {({ field }: FieldProps) => (
                          <Select
                            {...field}
                            mode="multiple"
                            className="w-[100%] h-[48px]"
                            allowClear
                            placeholder="Please select"
                            onChange={(value) => setFieldValue("actionType", value)}
                            options={auditLogActionType}
                          />
                        )}
                      </Field>
                    </div>

                    <div className="filter w-full">
                      <p className="label">Date range</p>
                      <Field name="dateRange">
                        {({ field }: FieldProps) => (
                          <>
                            <RangePicker className="w-[100%] h-[48px]" onChange={(value) => setFieldValue("dateRange", value)} />
                          </>
                        )}
                      </Field>
                    </div>

                    <div className="filter w-full">
                      <p className="label">IP Address</p>
                      <Field name="ipAddress">{({ field }: FieldProps) => <Input {...field} placeholder="Enter" className="w-[100%] h-[48px]" />}</Field>
                    </div>
                  </div>

                  <div className="auditLog-filtersButton">
                    <Button className="clear-btn" size="large" onClick={() => resetForm()}>
                      Clear
                    </Button>
                    <Button className="submit-btn" size="large" type="primary" htmlType="submit" loading={loader}>
                      Submit
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className="auditLog-table">
          <AuditLogTable
            tableData={tableDataLists}
            sizeChanger={sizeChanger}
            currentPage={currentPage}
            totalRecords={totalRecords}
            loader={loader}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            formValues={formValue}
            handleGetUserAuditDetails={handleGetUserAuditDetails}
            setCurrentPage={setCurrentPage}
            setSizeChanger={setSizeChanger}
          />
        </div>
      </div>
    </div>
  );
}
