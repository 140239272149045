import { Input, Select } from "antd";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { ScopeFieldArray } from "../connector/form/connector-authentication/ScopeFieldArray";
import { useTranslation } from "react-i18next";
const { TextArea } = Input;

interface Values {
  tokenApiEndpoint?: string;
  userName?: string;
  password?: string;
  grantType?: string;
  clientId?: string;
  secret?: string;
  userNameEmail?: string;
  apiToken?: string;
  tokenMethodType?: string;
  [key: string]: any;
}

interface RenderAuthFieldsProps {
  connectorDetailsTokenType: string;
  values: Values;
  publish?: any;
}

const RenderAuthFields: React.FC<RenderAuthFieldsProps> = ({ connectorDetailsTokenType, values, publish }) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext<Values>();
  switch (connectorDetailsTokenType) {
    case "OIDC":
      return (
        <div className="connection-authentication w-full flex justify-between self-start flex-wrap">
          <div className="mb-12 w-[45%] input-container relative">
            <p> {t("appManagement.provisioning.tokenApiEndpointUrl")}*</p>
            <Field as={Input} type="text" className="form-type-box" name="tokenApiEndpoint" data-testid="tokenApiUrl-input" />
            <ErrorMessage name="tokenApiEndpoint" component="div" className="error-message" />
          </div>
          <div className="mb-12 w-[45%] input-container relative">
            <p>{t("appManagement.provisioning.username")}*</p>
            <Field as={Input} type="text" className="form-type-box" name="userName" data-testid="userName-input" />
            <ErrorMessage name="userName" component="div" className="error-message" />
          </div>
          <div className="mb-12 w-[45%] input-container relative">
            <p>{t("appManagement.provisioning.password")}*</p>
            <Field as={Input} type="text" className="form-type-box" name="password" data-testid="password-input" />
            <ErrorMessage name="password" component="div" className="error-message" />
          </div>
          <div className="mb-12 w-[45%] input-container relative">
            <p>{t("appManagement.provisioning.grantType")} *</p>
            <Field as={Input} type="text" className="form-type-box" name="grantType" data-testid="grantType-input" />
            <ErrorMessage name="grantType" component="div" className="error-message" />
          </div>
          <div className="mb-12 w-[45%] input-container relative">
            <p>{t("appManagement.provisioning.clientId")}*</p>
            <Field as={Input} type="text" className="form-type-box" name="clientId" data-testid="clientId-input" />
            <ErrorMessage name="clientId" component="div" className="error-message" />
          </div>
          <div className="mb-12 w-[45%] input-container relative">
            <p>{t("appManagement.provisioning.clientSecret")} *</p>
            <Field as={Input} type="text" className="form-type-box" name="secret" data-testid="clientSecret-input" />
            <ErrorMessage name="secret" component="div" className="error-message" />
          </div>
          <div className="mb-12 w-[45%] input-container relative">
            <ScopeFieldArray values={values} />
          </div>
        </div>
      );
    case "SERVICE_TOKEN":
      if (publish) {
        return (
          <div className="connection-authentication w-full flex justify-between self-start flex-wrap">
            <div className="mb-12 w-[45%] input-container relative">
              <p>{t("appManagement.provisioning.serviceToken")}*</p>
              <Field as={TextArea} type="text" className="form-type-box !min-h-[450px]" name="tokenApiUrl" data-testid="tokenApiUrl-input" />
              <ErrorMessage name="tokenApiUrl" component="div" className="error-message" />
            </div>
            <div className="w-[50%] input-container relative">
              <div className="mb-12 w-[100%] input-container relative">
                <p>{t("appManagement.provisioning.usernameEmail")}*</p>
                <Field as={Input} type="text" className="form-type-box" name="userNameEmail" data-testid="userNameEmail-input" />
                <ErrorMessage name="userNameEmail" component="div" className="error-message" />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="connection-authentication w-full flex justify-between self-start flex-wrap">
            <div className="mb-12 w-[45%] input-container relative">
              <p>{t("appManagement.provisioning.serviceToken")}*</p>
              <Field as={TextArea} type="text" className="form-type-box !min-h-[450px]" name="tokenApiUrl" data-testid="tokenApiUrl-input" />
              <ErrorMessage name="tokenApiUrl" component="div" className="error-message" />
            </div>
            <div className="w-[50%] input-container relative">
              <div className="mb-10 w-[100%] input-container relative authenticationEndpoint">
                <p>{t("appManagement.provisioning.tokenApiEndpointUrl")}</p>
                <Field as={Input} type="text" className="form-type-box" name="tokenApiEndpoint" data-testid="tokenApiUrl-input" />
                <ErrorMessage name="tokenApiEndpoint" component="div" className="error-message" />
              </div>
              {/* <div className="mb-12 w-[100%] input-container relative">
              <p className="text-[#1D1D24] font-Inter text-[18px]  font-medium">Token API Method Type</p>
              <Field
                name="tokenMethodType"
                type="text"
                as={Select}
                className="h-[56px] loginThemeText font-medium text-[18px] w-[100%] "
                value={values?.tokenMethodType || undefined}
                filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value: string) => setFieldValue("tokenMethodType", value)}
                placeholder="Select"
              >
                <Select.Option value="Get">Get</Select.Option>
                <Select.Option value="Post">Post</Select.Option>
              </Field>
              <ErrorMessage name="tokenMethodType" component="div" className="error-message" />
            </div> */}
              <div className="mb-10 w-[100%] input-container relative">
                <p>{t("appManagement.provisioning.administratorUsernameEmail")}*</p>
                <Field as={Input} type="text" className="form-type-box" name="userNameEmail" data-testid="userNameEmail-input" />
                <ErrorMessage name="userNameEmail" component="div" className="error-message" />
              </div>
              <div className="mb-12 w-[100%] input-container relative">
                <ScopeFieldArray values={values} />
              </div>
            </div>
          </div>
        );
      }
    default:
      if (publish) {
        return (
          <div className="connection-authentication w-full flex justify-between self-start flex-wrap">
            <div className="mb-12 w-[45%] input-container relative">
              <p>{t("appManagement.provisioning.apiToken")}*</p>
              <Field as={TextArea} type="text" className="form-type-box !min-h-[450px]" name="apiToken" data-testid="apiToken-input" />
              <ErrorMessage name="apiToken" component="div" className="error-message" />
            </div>
            <div className="w-[50%] input-container relative">
              <div className="mb-12 w-[100%] input-container relative">
                <p>{t("appManagement.provisioning.usernameEmail")}*</p>
                <Field as={Input} type="text" className="form-type-box" name="userNameEmail" data-testid="userNameEmail-input" />
                <ErrorMessage name="userNameEmail" component="div" className="error-message" />
              </div>
              <div className="mb-12 w-[100%] input-container relative">
                <p>{t("appManagement.provisioning.password")}</p>
                <Field as={Input} type="text" className="form-type-box" name="password" data-testid="password-input" />
                <ErrorMessage name="password" component="div" className="error-message" />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="connection-authentication w-full flex justify-between self-start flex-wrap">
            <div className="mb-12 w-[45%] input-container relative">
              <p>{t("appManagement.provisioning.apiToken")}*</p>
              <Field as={TextArea} type="text" className="form-type-box !min-h-[450px]" name="apiToken" data-testid="apiToken-input" />
              <ErrorMessage name="apiToken" component="div" className="error-message" />
            </div>
            <div className="w-[50%] input-container relative">
              <div className="mb-12 w-[100%] input-container relative authenticationEndpoint">
                <p>{t("appManagement.provisioning.tokenApiEndpointUrl")}</p>
                <Field as={Input} type="text" className="form-type-box" name="tokenApiEndpoint" data-testid="tokenApiUrl-input" />
                <ErrorMessage name="tokenApiEndpoint" component="div" className="error-message" />
              </div>
              <div className="mb-12 w-[100%] input-container relative">
                <p className="text-[#1D1D24] font-Inter text-[18px]  font-medium">{t("appManagement.provisioning.tokenApiMethodType")}</p>

                <Field
                  name="tokenMethodType"
                  type="text"
                  as={Select}
                  className="h-[56px] loginThemeText font-medium text-[18px] w-[100%] "
                  // value={values.userProvision[index].methodType || undefined}
                  filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={(value: string) => setFieldValue("tokenMethodType", value)}
                  placeholder="Select"
                >
                  <Select.Option value="Get">{t("appManagement.provisioning.get")}</Select.Option>
                  <Select.Option value="Post">{t("appManagement.provisioning.post")}</Select.Option>
                </Field>
                <ErrorMessage name="tokenMethodType" component="div" className="error-message" />
              </div>
              <div className="mb-12 w-[100%] input-container relative">
                <p>{t("appManagement.provisioning.usernameEmail")}*</p>
                <Field as={Input} type="text" className="form-type-box" name="userNameEmail" data-testid="userNameEmail-input" />
                <ErrorMessage name="userNameEmail" component="div" className="error-message" />
              </div>
              <div className="mb-12 w-[100%] input-container relative">
                <ScopeFieldArray values={values} />
              </div>
            </div>
          </div>
        );
      }
  }
};

export default RenderAuthFields;
