import { Empty, Table } from "antd";
import React, { useEffect, useState } from "react";
import { images } from "../../../assets/images/ImagePaths";
import { prerequisitesTable } from "../../../constant/prerequisites/prerequisites";
import AuditLogTableHeader from "./AuditLogTableHeader";
import AuditLogsDeleteModal from "./AuditLogsDeleteModal";
import { auditLogTableColumns } from "../UserAuditHelper";

const customEmptyPlaceholder = <Empty image={images.tableFilterEmpty} description={<span className="tableEmpty">Set filter to view and download the report</span>} />;
interface AuditLogTableInterface {
  tableData: any[];
  currentPage: number;
  sizeChanger: number;
  totalRecords: number;
  loader: boolean;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: any;
  formValues: {};
  handleGetUserAuditDetails: (formValues: {}, page: number, size: number) => void;
  setCurrentPage: (page: number) => void;
  setSizeChanger: (size: number) => void;
}
export default function AuditLogTable({
  tableData,
  currentPage,
  sizeChanger,
  totalRecords,
  loader,
  handlePageChange,
  handlePageSizeChange,
  formValues,
  handleGetUserAuditDetails,
  setCurrentPage,
  setSizeChanger,
}: AuditLogTableInterface) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [tableDataSource, setTableDataSource] = useState([]);
  const [auditLogsDeleteModalOpen, setAuditLogsDeleteModalOpen] = useState<boolean>(false);

  useEffect(() => {
    createTableData();
  }, [tableData, currentPage, sizeChanger, totalRecords]);

  const formatDateTime = (timestamp: any) => {
    const date = new Date(timestamp);
    const formattedDate = date?.toLocaleDateString("en-GB"); // Format: DD/MM/YY
    const formattedTime = date?.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" }).toLowerCase(); // Format: HH:mm am/pm
    return `${formattedDate} at ${formattedTime}`;
  };

  const createTableData = () => {
    const apiData: any = tableData || [];
    const dataSource = apiData?.map((item: any) => ({
      key: item?.id,
      username: item?.userName,
      DateTime: formatDateTime(item?.eventTime),
      actionType: item?.actionType,
      activityLogs: item?.activityLog,
      os: item?.osName,
      IPAddress: item?.ipAddress,
      device: item?.device,
    }));
    setTableDataSource(dataSource);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRow(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const clearSelection = () => {
    setSelectedRowKeys([]);
    setSelectedRow([]);
  };
  const handleAuditLogDeleteModal = () => {
    setAuditLogsDeleteModalOpen(true);
  };
  const handelDeleteAuditLogsModalClose = () => {
    setAuditLogsDeleteModalOpen(false);
  };

  return (
    <div>
      <AuditLogTableHeader totalRecords={totalRecords} clearSelection={clearSelection} selectedRow={selectedRow} handleAuditLogDeleteModal={handleAuditLogDeleteModal} />
      <Table
        dataSource={tableDataSource}
        columns={auditLogTableColumns}
        rowSelection={rowSelection}
        pagination={{
          current: currentPage / sizeChanger + 1,
          total: totalRecords,
          showSizeChanger: true,
          pageSizeOptions: prerequisitesTable.pageSizeOptions,
          defaultPageSize: sizeChanger,
          pageSize: sizeChanger,
          onChange: handlePageChange,
          onShowSizeChange: handlePageSizeChange,
        }}
        loading={loader}
        locale={{ emptyText: customEmptyPlaceholder }}
      />
      {auditLogsDeleteModalOpen && (
        <AuditLogsDeleteModal
          handleGetUserAuditDetails={handleGetUserAuditDetails}
          deleteLogsModalOpen={auditLogsDeleteModalOpen}
          handelDeleteAuditLogsModalClose={handelDeleteAuditLogsModalClose}
          selectedRow={selectedRow}
          formValues={formValues}
          clearSelection={clearSelection}
          setCurrentPage={setCurrentPage}
          setSizeChanger={setSizeChanger}
        />
      )}
    </div>
  );
}
