import React from "react";
import { Select, Tooltip } from "antd";
import { ErrorMessage, Field } from "formik";
import { attemptsLists } from "./PasswordManagerHelper";
import { useTranslation } from "react-i18next";

interface PasswordManagerInterface {
  setFieldValue: (field: string, value: any) => void;
  values: { loginAttempts: string };
}

export default function PasswordManagerLoginAttempt(props: Readonly<PasswordManagerInterface>) {
  const { setFieldValue, values } = props;
  const { t } = useTranslation();

  return (
    <div className="w-full justify-between flex relative">
      <p className=" font-Inter w-[70%]">
        {t("passwordManager.loginAttempts")}
        <Tooltip title={t("passwordManager.adminLoginLimits")}>
          <span className="material-symbols-outlined">info</span>
        </Tooltip>
      </p>

      <div className="w-1/2 flex justify-end">
        <Field
          as={Select}
          type="text"
          className="h-[50px] font-medium text-[18px] w-[100%] loginThemeText"
          name="loginAttempts"
          value={values?.loginAttempts || undefined}
          onChange={(value: string) => setFieldValue("loginAttempts", value)}
          filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          placeholder="Select"
        >
          {attemptsLists.map((option: any) => (
            <Select.Option key={option.value} value={option.value}>
              <Tooltip title={option.label}>
                {option.label} {option?.value === 3 && t("passwordManager.recommended")}
              </Tooltip>
            </Select.Option>
          ))}
        </Field>
      </div>
      <ErrorMessage name="loginAttempts" component="div" className="error-message" />
    </div>
  );
}
