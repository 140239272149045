import React from "react";

type UserBehaviorCardContentProps = {
  type?: string;
  dots?: boolean;
  isActiveArrow?: boolean;
  details?: any;
};

export default function UserBehaviorCardContentSevenDaysAgo({ type, details }: UserBehaviorCardContentProps) {
  return (
    <div className="flex">
      <div className="date">{details?.formattedTime}</div>
      <div className="description">
        <p>{details?.actionType}</p>
        <p>{details?.activityLog}</p>
      </div>
    </div>
  );
}
