import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";

import { endPointDescriptionPayload, transformHeaderParameters, UseGetEndpointDescription, userDeProvisionErrorEvaluatorScroll } from "../../../helper/connectorHelper";
import {
  setActiveStep,
  setDeProvisionConnectorEndpointConfigId,
  setDeProvisioning,
  setIsDeProvisioningApiCalled,
  setIsDeProvisioningApiError,
  setProvisioningBackButtonClicked,
  setProvisioningButtonStatus,
} from "../../../../../../../redux/slice/provisioning/ProvisioningSlice";
import useGetApiRequests from "../../../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../../../../services/storage/Storage";
import * as Yup from "yup";
import CustomButtonBack from "../../../../../../../layouts/component/CustomButtonBack";
import { setCurrentStep } from "../../../../../../../redux/slice/app-store/AppDetailsSlice";
import DeProvisionFormFields from "../form/DeprovisionFormFields";
import { useTranslation } from "react-i18next";

const UserDeProvision = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setActiveKey } = props;

  const dispatch = useDispatch();
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);
  const akkuDeProvisionConnectorEndpointConfigId = useSelector((state: any) => state.provisioning?.akkuDeProvisionConnectorEndpointConfigId);
  const akkuProvisioningConnectorId = useSelector((state: any) => state.provisioning?.akkuProvisioningConnectorId);
  const buttonStatus = useSelector((state: any) => state?.provisioning?.duplicateProvisioningButtons);
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);
  const provisioning = useSelector((state: any) => state?.provisioning);
  const isTenantAdmin = useSelector((state: any) => state?.ProfileDetailsSlice?.isTenantAdmin);
  const formInitialValues = useSelector((state: any) => state?.provisioning?.deProvisioning);
  const deProvisionDetails = useSelector((state: any) => state?.provisioning?.deProvisioning);
  const endPointResponseDtosData = useSelector((state: any) => state?.provisioning?.endPointResponseDtosData);
  const isApiCalled = useSelector((state: any) => state?.provisioning?.isDeProvisioningApiCalled);
  const isApiError = useSelector((state: any) => state?.provisioning?.isDeProvisioningApiError);
  const deProvisioningConnectorConfigure = useGetApiRequests("provisioningConnectorConfigure", "POST");
  const deProvisioningConnectorConfigureUpdate = useGetApiRequests("provisioningConnectorConfigureUpdate", "PUT");

  const realmId = retrieveData("realmId", true);

  const [deProvisionDirty, setDeProvisionDirty] = useState<boolean>(false);
  useEffect(() => {
    // Revalidate the form on language change
    if (props.formRef) {
      props.formRef.current.validateForm();
    }
  }, [i18n.language]);
  const handleSubmit = (values: any) => {
    if (provisioning?.isDuplicate || deProvisionDirty || isApiError?.userDeProvisionApiError) {
      createPayloadConnectorConfigure(values);
      dispatch(setDeProvisioning(values));
    } else {
      setActiveKey("2");
    }
  };

  const createPayloadConnectorConfigure = async (values: any) => {
    const { userDeProvision } = values;
    const deProvisionCreatePayload = {
      apiEndpointUrl: userDeProvision[0]?.apiEndpointURL,
      methodType: userDeProvision[0]?.methodType,
      endpointDescription: endPointDescriptionPayload(userDeProvision[0]?.methodType),
      requestPayload: userDeProvision[0]?.requestPayload || null,
      sampleResponse: userDeProvision[0]?.response || null,
      realmId: realmId,
      name: generalDetails.name,
      type: "request",
      endpointType: "DEPROVISIONING",
      // isUserProvisioningConfig: true,
      headerParameters: transformHeaderParameters(values?.userDeProvision[0]?.headerParam),
      pathVariable: transformHeaderParameters(values?.userDeProvision[0]?.pathVariable),
      isTenantAdmin: isTenantAdmin,
    };
    const deProvisionPayloadUpdate: any = {
      apiEndpointUrl: userDeProvision[0]?.apiEndpointURL,
      methodType: userDeProvision[0]?.methodType,
      endpointDescription: endPointDescriptionPayload(userDeProvision[0]?.methodType),
      requestPayload: userDeProvision[0]?.requestPayload || null,
      sampleResponse: userDeProvision[0]?.response || null,
      realmId: realmId,
      name: generalDetails.name,
      type: "request",
      endpointType: "DEPROVISIONING",
      // isUserProvisioningConfig: true,
      headerParameters: transformHeaderParameters(values?.userDeProvision[0]?.headerParam),
      pathVariable: transformHeaderParameters(values?.userDeProvision[0]?.pathVariable),
      akkuProvisioningConnectorEndpointConfigId: akkuDeProvisionConnectorEndpointConfigId?.userDeProvision || formInitialValues?.userDeProvision[0]?.akkuProvisioningConnectorEndpointConfigId,
      isTenantAdmin: isTenantAdmin,
    };

    const isCreated = UseGetEndpointDescription("delete_user", endPointResponseDtosData);
    const isDuplicateUpdate = provisioning?.isDuplicate ? provisioning?.isDuplicate && isApiCalled?.userDeProvisionApiCalled : true; // for duplicate
    const shouldUpdateDeProvision = isDuplicateUpdate && ((provisioning?.isActiveEdit && isCreated) || isApiCalled?.userDeProvisionApiCalled);

    if (shouldUpdateDeProvision) {
      handleUpdateDeProvisionApiCall(deProvisionPayloadUpdate);
    } else {
      handleCreateDeProvisionApiCall(deProvisionCreatePayload);
    }
  };
  const handleCreateDeProvisionApiCall = async (payload: any) => {
    try {
      const response: any = await deProvisioningConnectorConfigure(payload);
      const deProvisionConnectorEndpointConfigId = response?.data?.data?.akkuProvisioningConnectorEndpointConfigId;
      dispatch(setDeProvisionConnectorEndpointConfigId({ ...akkuDeProvisionConnectorEndpointConfigId, userDeProvision: deProvisionConnectorEndpointConfigId }));
      const status = response.status;
      if (status === 200) {
        handleApiResponse(response);
      }
    } catch (err: any) {
      dispatch(setIsDeProvisioningApiError({ ...isApiError, userDeProvisionApiError: true }));
      handleRequestError(err);
    }
  };

  const handleUpdateDeProvisionApiCall = async (payload: any) => {
    const param = {
      akkuProvisioningConnectorId: provisioning?.akkuProvisioningConnectorId ?? akkuProvisioningConnectorId,
    };
    try {
      const response: any = await deProvisioningConnectorConfigureUpdate(payload, {}, param);

      const status = response.status;
      if (status === 200) {
        handleApiResponse(response);
      }
    } catch (err: any) {
      dispatch(setIsDeProvisioningApiError({ ...isApiError, userDeProvisionApiError: true }));
      handleRequestError(err);
    }
  };

  const handleApiResponse = (response: any) => {
    const status = response.status;
    if (status === 200) {
      if (provisioning?.isDuplicate) {
        dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorUserDeprovisioning: false, connectorRoleDeprovisioning: true }));
      }
      dispatch(setIsDeProvisioningApiCalled({ ...isApiCalled, userDeProvisionApiCalled: true })); // If ApiCalled set is true
      setActiveKey("2");
    }
  };

  const handleBack = () => {
    const updatedStepper = {
      activeStep: 2,
      childStepper: "",
    };
    dispatch(setProvisioningBackButtonClicked(true));
    dispatch(setCurrentStep(updatedStepper));
    dispatch(setActiveStep(2));
  };

  const headerParamSchema = Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.string().required("Key is required"),
        // value: Yup.string().required("Value is required"),
      }),
    )
    .required("Header Param is required");

  const connectorDeProvisionSchema = Yup.object().shape({
    userDeProvision: Yup.array().of(
      Yup.object().shape({
        apiEndpointURL: Yup.string().required(t("appManagement.provisioning.errors.apiEndpointUrlRequired")),
        methodType: Yup.string().required(t("appManagement.provisioning.errors.methodTypeRequired")),
        // Uncomment and translate the following fields if needed in the future
        // headerParametersType: Yup.string().required(t("appManagement.provisioning.errors.requestParametersTypeRequired")),
        // headerParam: appDetails?.akkuMasterClientId === 1 ? headerParamSchema : Yup.array().notRequired(),
        // pathVariable: appDetails?.akkuMasterClientId === 2 ? headerParamSchema : Yup.array().notRequired(),
      }),
    ),
  });
  const handleSkip = () => {
    setActiveKey("2");
  };

  return (
    <Formik
      initialValues={formInitialValues}
      values={formInitialValues}
      onSubmit={(value: any) => handleSubmit(value)}
      validationSchema={connectorDeProvisionSchema}
      enableReinitialize={true}
      innerRef={props.formRef}
    >
      {({ values, setFieldValue, dirty, isSubmitting, errors }) => {
        setDeProvisionDirty(dirty);

        if (isSubmitting && Object.keys(errors).length > 0) {
          const err: any = errors.userDeProvision;
          userDeProvisionErrorEvaluatorScroll(err[0], "1");
        }

        return (
          <Form>
            <div className=" w-full flex flex-wrap flex-col gap-2 justify-center items-center">
              <FieldArray name="userDeProvision">
                {() => (
                  <>
                    {values?.userDeProvision?.length !== 0 &&
                      values?.userDeProvision?.map((samlConfig: any, index: any) => (
                        <DeProvisionFormFields
                          key={index}
                          index={index}
                          values={values}
                          setFieldValue={setFieldValue}
                          provisioning={provisioning}
                          provisionDetails={deProvisionDetails}
                          samlConfig={samlConfig}
                          formName="userDeProvision"
                          id={1}
                        />
                      ))}
                  </>
                )}
              </FieldArray>
            </div>

            <div className="footer flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
              <div className="modal-footer w-full mx-auto ">
                <div className="w-full flex justify-end pr-5">
                  <CustomButtonBack text={t("common.skip")} onClick={() => handleSkip()} />
                  <CustomButtonBack text={t("common.back")} onClick={() => handleBack()} />
                  <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                    {t("common.next")}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserDeProvision;
