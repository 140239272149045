import React, { useEffect, useState } from "react";
import CardTitle from "../../component/CardTitle";
import { Button, Input, Select, Tooltip } from "antd";
import batch from "../../../assets/images/icons/customer.png";
import { setCurrentTab } from "../../../redux/slice/TableExpand";
import { useDispatch, useSelector } from "react-redux";
import ImageView from "../../component/ImageView";
import { images } from "../../../assets/images/ImagePaths";
import { FilterData } from "./CustomerTableTypes";
import AddNewCustomersModal from "./modal/AddNewCustomersModal";
import SearchInput from "../../component/SearchField";
import { useTranslation } from "react-i18next";

const TableHeader = ({ setFilterData, filterData, updatePaginationData, filterList, handleSearch, search }: any) => {
  const { t } = useTranslation();

  const toggleTable = useSelector((state: any) => state?.TableExpand?.expanedTab);
  const userDetails = useSelector((state: any) => state?.dashboardDetails);
  const userProfileDetails = useSelector((state: any) => state?.ProfileDetailsSlice?.profileDetails);
  const tenantActiveStep = useSelector((state: any) => state?.CustomerSlice?.tenantActiveStep);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  useEffect(() => {}, [toggleTable]);

  const handelExpand = () => {
    let status = toggleTable;

    dispatch(setCurrentTab(!status));
  };

  const handleOnChangeFilter = (name: string, selectedValue: any) => {
    // Create a new FilterData object with the updated values
    const storeFilterData: FilterData = {
      ...filterData,
      [name]: selectedValue,
    };
    // Update Redux state with the new filter data and fetch updated dashboard details
    updatePaginationData(storeFilterData);
  };
  const handelSearch = (value: string) => {
    console.log(value);
  };

  const handleAddCustomer = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="_card-header --no-border">
        <div className="title items-center">
          <CardTitle title={t("tenants.tenants")} src={batch} />
        </div>
        <div className="flex ">
          <div className=" items-center mr-10 relative hidden">
            <Input
              placeholder={t("common.search")}
              className="h-[48px] w-[300px] text-[20px] pl-10"
              onChange={(event) => {
                handelSearch(event.target.value);
              }}
              data-testid="searchId"
            />
            <span className="material-symbols-outlined absolute left-2">search</span>
          </div>

          <div className="customer-search mr-8">
            <SearchInput search={search} placeholder={t("common.search")} onChange={handleSearch} />
          </div>

          <div className="hidden">
            <Select
              suffixIcon={
                <div className="w-6">
                  <ImageView src={images.chevronDown} alt="chevronDown" />
                </div>
              }
              className="w-[150px] h-[48px] mr-8 text-[20px]"
              showSearch
              onChange={(value) => handleOnChangeFilter("sortBy", value)}
              value={filterData?.sortBy}
            >
              {filterList?.customerInsightsList?.map((option: any) => (
                <Select.Option key={option?.value} value={option?.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="hidden">
            <Select
              suffixIcon={
                <div className="w-6">
                  <ImageView src={images.chevronDown} alt="chevronDown" />
                </div>
              }
              className="w-[150px]  h-[48px] mr-8 text-[20px]"
              showSearch
              value={filterData?.customerType}
              onChange={(value) => handleOnChangeFilter("customerType", value)}
            >
              {filterList?.clientList?.map((option: any) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Tooltip title={t("tenants.addCustomer")}>
            <Button type="primary" className="add-customer bg-[#5441DA]" onClick={handleAddCustomer}>
              <span className="material-symbols-outlined">add </span>
            </Button>
          </Tooltip>
          {!userDetails?.isActiveAkkuMaster && (
            <Tooltip title={!toggleTable ? `${t("tenants.expand")}` : `${t("tenants.exitFullScreen")}`} placement="top">
              <Button
                className={`flex w-[34px] h-[48px] __common btn justify-center items-center`}
                icon={<span className="material-symbols-outlined text-[#667085]"> {!toggleTable ? "expand_content" : "close_fullscreen"}</span>}
                onClick={handelExpand}
                size={"large"}
              />
            </Tooltip>
          )}
        </div>
      </div>
      {isOpen && <AddNewCustomersModal isOpen={isOpen} handleClose={handleClose} />}
    </>
  );
};

export default TableHeader;
