import React from "react";
import { Button, Tooltip } from "antd";

interface AuditLogTableInterface {
  clearSelection: () => void;
  handleAuditLogDeleteModal: () => void;
  selectedRow: any[];
  totalRecords: number;
}

const AuditLogTableHeader = ({ clearSelection, handleAuditLogDeleteModal, selectedRow, totalRecords }: AuditLogTableInterface) => {
  return (
    <>
      <div className="justify-start items-center flex ">
        <h6 className="totalCount">Total Record count : {totalRecords}</h6>

        {selectedRow?.length > 0 && (
          <div className="flex justify-center gap-5 mb-[18px] ml-[15px]">
            <p role="none" className="text-[18px] font-Inter text-[#5441DA] ml-3 cursor-pointer users-clear font-medium" onClick={clearSelection}>
              Clear selection
            </p>
            <Tooltip title={"Delete"}>
              <Button icon={<span className="text-[20px] material-icons-outlined text-[#667085]">delete</span>} className="w-[24px] h-[30px]" onClick={handleAuditLogDeleteModal} />
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );
};

export default AuditLogTableHeader;
