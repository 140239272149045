const images = {
  brandLogo: require("./logo/brand-logo.svg").default,
  notificationDelete: require("./user/delete-user.png"),
  notificationSuspend: require("./user/suspend-user.png"),
  chevronDown: require("./icons/chevron-down.png"),
  location: require("./icons/location.png"),
  bell: require("./icons/bell.png"),
  userIcon: require("./icons/user-img.png"),
  mobile: require("./user/phone-out.png"),
  email: require("./user/mail_outline.png"),
  notificationDone: require("./user/offline_pin.png"),
  notificationMail: require("./icons/mailWithCheck.png"),
  suspend: require("./user/suspend.png"),
  edit: require("./user/edit-2.png"),
  deleted: require("./user/delete.png"),
  jira: require("./user/jira.png"),
  adminIcon: require("./user/user-icon.png"),
  thumbIcon: require("./icons/fingerprint.png"),
  faceIcon: require("./icons/face.png"),
  smsIcon: require("./icons/sms.png"),
  mailIcon: require("./icons/mail.png"),
  phoneIcon: require("./icons/phone_android.png"),
  qrCodeIcon: require("./icons/qr_code.png"),
  salesforce: require("./user/sales-force.png"),
  upload: require("./user/upload.png"),
  addIcon: require("./user/add.png"),
  appsConfigure: require("./user/app-configure.png"),
  nodata: require("./user/nodata.png"),
  registerBackground: require("./register/register-background.png"),
  preview: require("./icons/preview.png"),
  newRegister: require("./register/user-register.png"),
  darkLogo: require("./logo/akkuDark.png"),
  appStore: require("./icons/app-management.png"),
  registerLogo: require("./register/login-logo.png"),
  uploadImg: require("./register/upload-img.png"),
  otpValidate: require("./register/verify-otp.png"),
  setting: require("./user/logo/settings_accessibility.png"),
  settingRoll: require("./user/logo/settings_b_roll.png"),
  cloud: require("./user/logo/cloud.png"),
  password: require("./user/logo/password.png"),
  shield_lock: require("./user/logo/shield_lock.png"),
  addUserIcons: require("./icons/addUserIcons.png"),
  userModalBg: require("./user/modalBg.png"),
  channelIcon: require("./icons/akkuasdirectory.svg"),
  ldapToAkku: require("./add-user/ldap-akku.png"),
  channel: require("./icons/channel.png"),
  solvermindLogo: require("./logo/logo-solverming.png"),
  profile: require("./logo/profile.png"),
  noData: require("./user/no-data.png"),
  visible: require("./icons/visible.png"),
  inVisible: require("./icons/invisible.png"),
  noAppImage: require("./akku/no-app.png"),
  connectedDirectory: require("./add-user/ldap-connected.png"),
  sync: require("./icons/sync.png"),
  successPinIcon: require("./device-restriction/success_pin.png"),
  windows: require("./device-restriction/windows.png"),
  mac: require("./device-restriction/mac.png"),
  linux: require("./device-restriction/linux.png"),
  group: require("./add-customer/groupIcon.png"),
  accessManage: require("./products/access-manager.png"),
  passwordManage: require("./products/password-manager.png"),
  amfaManage: require("./products/amfa.png"),
  cloudDirectory: require("./products/cloud-directory.png"),
  ssoManage: require("./products/sso.png"),
  syncUser: require("./icons/usersync.png"),
  syncAll: require("./icons/sync-btn.png"),
  disableLdap: require("./icons/ldap-disconnect.svg").default,
  menuIcon: require("./icons/menu-icon.png"),
  provisionIcon: require("./app-store/provision-config.png"),
  ssoIcon: require("./app-store/sso-configure.png"),
  link: require("./icons/link.png"),
  rightArrow: require("./icons/arrow-right.png"),
  greenCheck: require("./icons/greencheck.svg").default,
  groupSync: require("./icons/groupSync.png"),
  passwordLock: require("./icons/password-lock.png"),
  unauthorized: require("./icons/unauthorized.svg").default,
  pageNotFound: require("./icons/page-not-found-bg.png"),
  menuLeft: require("./icons/ggMenuLeftAlt.png"),
  shield: require("./icons/shield_lock.png"),
  ip: require("./icons/ip.png"),
  timer: require("./icons/timer.png"),
  devices: require("./icons/devices.png"),
  warningDanger: require("./user/warningdanger.png"),
  addDevice: require("./icons/add-device.png"),
  clock: require("./icons/clock.png"),
  mailBlock: require("./icons/mailBlack.png"),
  smsBlack: require("./icons/smsBlack.png"),
  mobilePush: require("./icons/mobilePush.png"),
  arrowBack: require("./icons/arrowBack.png"),
  mobileNotifications: require("./icons/mobileNotifications.png"),
  publish: require("./icons/publish.png"),
  usFlag: require("./USFlag.png"),
  frFlag: require("./FrFlag.png"),
  tableFilterEmpty: require("./icons/tableFilterEmpty.png"),
};
export { images };
