import { FieldArray, Form, Formik } from "formik";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomButtonBack from "../../../../../../../layouts/component/CustomButtonBack";
import { ConnectorConfig, FormValues, UpdateParams } from "../../types/provisioningTypes";
import { useEffect, useState } from "react";
import {
  setActiveStep,
  setIsBackButtonPressed,
  setIsProvisioningApiCalled,
  setIsProvisioningApiError,
  setProvisioning,
  setProvisioningButtonStatus,
  setUserProvisioningConnectorEndpointConfigId,
} from "../../../../../../../redux/slice/provisioning/ProvisioningSlice";
import { connectorProvisionSchema, endPointDescriptionPayload, transformHeaderParameters, UseGetEndpointDescription, userProvisionErrorEvaluatorScroll } from "../../../helper/connectorHelper";
import useGetApiRequests from "../../../../../../../services/axios/useApiRequests";
import { retrieveData } from "../../../../../../../services/storage/Storage";
import { setCurrentStep } from "../../../../../../../redux/slice/app-store/AppDetailsSlice";
import { handleRequestError } from "../../../../../../../layouts/toast/ErrorNotificationMessage";

import UserProvisionForm from "../form/ProvisionFormFields";
import { useTranslation } from "react-i18next";

const UserProvision = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setActiveKey } = props;
  const provisioningConnectorConfigure = useGetApiRequests("provisioningConnectorConfigure", "POST");
  const provisioningConnectorConfigureUpdate = useGetApiRequests("provisioningConnectorConfigureUpdate", "PUT");

  const dispatch = useDispatch();
  const formInitialValues = useSelector((state: any) => state?.provisioning?.provisioning);
  const appConfigure = useSelector((state: any) => state?.appConfigure);
  const isTenantAdmin = useSelector((state: any) => state?.ProfileDetailsSlice?.isTenantAdmin);
  const buttonStatus = useSelector((state: any) => state?.provisioning?.duplicateProvisioningButtons);
  const isApiCalled = useSelector((state: any) => state?.provisioning?.isProvisioningApiCalled);
  const isApiError = useSelector((state: any) => state?.provisioning?.isProvisioningApiError);
  const endPointResponseDtosData = useSelector((state: any) => state?.provisioning?.endPointResponseDtosData);
  const provisioning = useSelector((state: any) => state?.provisioning);
  const provisionDetails = useSelector((state: any) => state?.provisioning?.provisioning);
  const akkuProvisioningConnectorEndpointConfigId = useSelector((state: any) => state.provisioning?.userProvisioningConnectorEndpointConfigId);
  const generalDetails = provisioning?.generalDetails;

  const isKeyValueEmpty = (provisionDetails?.userProvision[0]?.headerParam as any[])?.some((data: any) => data?.key === "");
  const isCreated = UseGetEndpointDescription("create_user", endPointResponseDtosData) || UseGetEndpointDescription("get_user", endPointResponseDtosData);

  const realmId = retrieveData("realmId", true);

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [responseAttributes, setResponseAttributes] = useState<any>(null);

  useEffect(() => {
    // Revalidate the form on language change
    if (props.formRef.current) {
      props.formRef.current.validateForm();
    }
  }, [i18n.language]);
  const handleSubmit = (values: FormValues) => {
    if (provisioning?.isDuplicate || isDirty || isApiError?.userProvisionApiError) {
      createPayloadConnectorConfigure(values);
      dispatch(setProvisioning(values));
    } else {
      setActiveKey("2");
    }
  };

  const createPayloadConnectorConfigure = async (values: FormValues) => {
    const payload: any = createPayload(values);
    const isDuplicateUpdate = provisioning?.isDuplicate ? provisioning?.isDuplicate && isApiCalled?.userProvisionApiCalled : true; // for duplicate
    const shouldUpdate = isDuplicateUpdate && ((provisioning?.isActiveEdit && isCreated) || isApiCalled?.userProvisionApiCalled);
    if (shouldUpdate) {
      payload.akkuProvisioningConnectorEndpointConfigId = akkuProvisioningConnectorEndpointConfigId || formInitialValues?.userProvision[0]?.akkuProvisioningConnectorEndpointConfigId;
      payload.isActive = provisioning?.isActive;
      payload.isTenantAdmin = isTenantAdmin;
      handleUpdateApiCall(payload);
    } else {
      payload.isTenantAdmin = isTenantAdmin;
      handleCreateApiCall(payload);
    }
  };
  const handleCreateApiCall = async (payload: ConnectorConfig) => {
    await handleApiCall(provisioningConnectorConfigure, payload);
  };

  const handleUpdateApiCall = async (payload: ConnectorConfig) => {
    const params: UpdateParams = { akkuProvisioningConnectorId: provisioning.akkuProvisioningConnectorId };
    await handleApiCall(provisioningConnectorConfigureUpdate, payload, params);
  };
  const handleApiCall = async (apiCall: (payload: ConnectorConfig, config?: any, params?: UpdateParams) => Promise<any>, payload: ConnectorConfig, params?: UpdateParams) => {
    try {
      const response = await apiCall(payload, {}, params);
      if (!provisioning?.isActiveEdit || provisioning?.isDuplicate) {
        const provisioningConnectorEndpointConfigId = response?.data?.data?.akkuProvisioningConnectorEndpointConfigId;
        dispatch(setUserProvisioningConnectorEndpointConfigId(provisioningConnectorEndpointConfigId));
      }
      handleApiResponse(response);
    } catch (err) {
      dispatch(setIsProvisioningApiError({ ...isApiError, userProvisionApiError: true }));
      handleRequestError(err);
    }
  };
  const handleApiResponse = (response: any) => {
    const status = response?.status;
    if (status === 200) {
      if (provisioning?.isDuplicate) {
        dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorUserProvisioning: false, connectorGetRoleProvisioning: true }));
      }
      dispatch(setIsProvisioningApiCalled({ ...isApiCalled, userProvisionApiCalled: true })); // If ApiCalled set is true
      setActiveKey("2");
    }
  };

  const createPayload = (values: FormValues): ConnectorConfig => {
    const { userProvision } = values;
    const baseConfig = {
      apiEndpointUrl: userProvision[0]?.apiEndpointURL,
      methodType: userProvision[0]?.methodType,
      endpointDescription: endPointDescriptionPayload(userProvision[0]?.methodType),
      requestPayload: userProvision[0]?.requestPayload || null,
      sampleResponse: userProvision[0]?.response,
      primaryKeyAttributeList: userProvision[0]?.primaryKeyAttributeList,
      serviceProviderAttributesDto: null,
      headerParameters: transformHeaderParameters(values?.userProvision[0]?.headerParam),
      pathVariable: transformHeaderParameters(values?.userProvision[0]?.pathVariable),
      realmId: realmId,
      name: generalDetails?.name,
      type: "request",
      endpointType: "PROVISIONING",
      isUserProvisioningConfig: true,
    };

    if (provisioning?.isActiveEdit) {
      return {
        ...baseConfig,
        akkuProvisioningConnectorEndpointConfigId: formInitialValues?.userProvision[0]?.akkuProvisioningConnectorEndpointConfigId,
      };
    }

    return baseConfig;
  };
  const handleBack = () => {
    dispatch(setIsBackButtonPressed(true));
    const updatedStepper = {
      activeStep: 1,
      childStepper: "",
    };
    dispatch(setCurrentStep(updatedStepper));
    dispatch(setActiveStep(1));
  };

  const handleSkip = () => {
    if (provisioning?.isDuplicate) {
      dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorMappingAttributes: true }));
    }
    dispatch(setActiveStep(4));
  };

  return (
    <Formik
      initialValues={formInitialValues}
      values={formInitialValues}
      onSubmit={(value: any) => handleSubmit(value)}
      // validationSchema={connectorProvisionSchema}
      validationSchema={connectorProvisionSchema(responseAttributes, t)}
      enableReinitialize={true}
      innerRef={props.formRef}
    >
      {({ values, setFieldValue, dirty, errors, isSubmitting }) => {
        setIsDirty(dirty);

        if (isSubmitting && Object.keys(errors).length > 0) {
          const err: any = errors.userProvision;
          userProvisionErrorEvaluatorScroll(err[0]);
        }
        return (
          <Form>
            <div className="w-full flex flex-wrap flex-col gap-2 justify-center items-center">
              <FieldArray name="userProvision">
                {() => (
                  <>
                    {values?.userProvision?.length !== 0 &&
                      values?.userProvision?.map((samlConfig: any, index: any) => (
                        <UserProvisionForm
                          key={index}
                          index={index}
                          values={values}
                          setFieldValue={setFieldValue}
                          provisioning={provisioning}
                          provisionDetails={provisionDetails}
                          isKeyValueEmpty={isKeyValueEmpty}
                          samlConfig={samlConfig}
                          formName="userProvision"
                          setResponseAttributes={setResponseAttributes}
                          responseAttributes={responseAttributes}
                        />
                      ))}
                  </>
                )}
              </FieldArray>
            </div>

            <div className="footer flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]" data-testid="footer">
              <div className="modal-footer w-full mx-auto ">
                <div className="w-full flex justify-end pr-5">
                  <Button
                    onClick={() => handleSkip()}
                    className="skip-btn"
                    size="large"
                    disabled={
                      // Logic for the first scenario
                      (!isApiCalled?.userProvisionApiCalled && !provisioning?.isDuplicate && !provisioning?.isActiveEdit) || // For first and second scenario
                      // Logic for the second scenario
                      (provisioning?.isDuplicate && !isApiCalled?.userProvisionApiCalled) || // For second scenario
                      // Logic for the third scenario
                      (isCreated === false && !isApiCalled?.userProvisionApiCalled) // For third scenario
                    }
                  >
                    {t("common.skip")}
                  </Button>

                  <CustomButtonBack text={t("common.back")} onClick={() => handleBack()} />
                  <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                    {t("common.next")}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserProvision;
