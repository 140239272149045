// React and third-party libraries
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, Select, Input } from "antd";

// Redux-related imports
import { useDispatch, useSelector } from "react-redux";
import { setAppConfigDetails } from "../../../../../redux/slice/app-store/AppDetailsSlice";
import { setAuthentication, setAuthenticationNextButtonClicked, setConnectorDetailDirty, setGeneralDetails, setIsActive } from "../../../../../redux/slice/provisioning/ProvisioningSlice";

// Utilities, helpers, and services
import { createAppPublishSchema } from "./ConnectorValidationSchema";
import { connectorDetailsInitialValue, connectorAuthenticationReduxDetails, getAuthenticationType, getTokenType, getConnectorType } from "../helper/connectorHelper";

// Custom components
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { setPublishActiveStep } from "../../../../../redux/slice/provisioning/ProvisioningPublishSlice";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import Loader from "../../../../../layouts/component/Loader";
import { retrieveData } from "../../../../../services/storage/Storage";
import { useTranslation } from "react-i18next";

const ConnectorDetailsPublish = (props: any) => {
  const { t, i18n } = useTranslation();
  const authenticationType = getAuthenticationType(t);
  const tokenType = getTokenType(t);
  const connectorType = getConnectorType(t);
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);
  const authentication = useSelector((state: any) => state?.provisioning?.authentication);
  const isTenantAdmin = useSelector((state: any) => state?.ProfileDetailsSlice?.isTenantAdmin);
  const isBackButtonPressed = useSelector((state: any) => state?.provisioning?.isBackButtonEnabled);
  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState<any>(connectorDetailsInitialValue);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (!isBackButtonPressed) {
      getProvisioningConnectorDetails();
    }
  }, []);
  const formikRef = useRef<any>(null);
  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);
  useEffect(() => {
    setInitialValues(generalDetails);
  }, [generalDetails]);

  const getProvisioningConnectorDetails = async () => {
    setLoader(true);
    const params = {
      akkuProvisioningConnectorId: appDetails.akkuProvisioningConnectorId,
    };
    const queryParams = { realmId: retrieveData("realmId", true) };
    try {
      const response: any = await provisioningConnectorDetails("", queryParams, params);
      const status = response?.status;

      if (status === 200) {
        const data = response?.data?.data;
        // Reset button states in Redux store
        dispatch(setAuthenticationNextButtonClicked(false));
        const filteredList = data?.endPointResponseDtos?.filter((dto: any) => dto?.endpointDescription === "token_validator");
        if (isTenantAdmin && !data?.akkuProvisioningConnectorAuthConfigId) {
          const generalDetails = {
            username: "",
            password: "",
            name: data?.name,
            description: data?.description,
            type: data?.type,
            isDefaultSourceConnector: data?.isDefaultSourceConnector ? "yes" : "No",
            authenticationType: data?.authenticationType,
            isActive: data?.isActive,
            tokenType: data?.tokenType,
            scope: data?.scope,
            spDomain: data?.spDomain,
            tokenApiEndpoint: filteredList?.[0]?.apiEndpointUrl,
            tokenMethodType: filteredList?.[0]?.methodType,
            akkuProvisioningConnectorAuthConfigId: data?.akkuProvisioningConnectorAuthConfigId,
          };
          dispatch(setGeneralDetails({ ...initialValues, ...generalDetails }));
        } else {
          await handleConnectorDetailsSuccess(data);
          // Reset button states in Redux store
          dispatch(setAuthenticationNextButtonClicked(false));
        }

        // if (isTenantAdmin && (data?.username === "appsadmin@akkudemo.cloudnowapps.in" || data?.username === "yeswanth.a@cloudnowtech.com")) {
        //   const generalDetails = {
        //     username: "",
        //     password: "",
        //     name: data?.name,
        //     description: data?.description,
        //     type: data?.type,
        //     isDefaultSourceConnector: data?.isDefaultSourceConnector ? "yes" : "No",
        //     authenticationType: data?.authenticationType,
        //     isActive: data?.isActive,
        //     tokenType: data?.tokenType,
        //     scope: data?.scope,
        //     spDomain: data?.spDomain,
        //     tokenApiEndpoint: filteredList?.[0]?.apiEndpointUrl,
        //     tokenMethodType: filteredList?.[0]?.methodType,
        //   };
        //   dispatch(setGeneralDetails({ ...initialValues, ...generalDetails }));
        // } else {
        //   await handleConnectorDetailsSuccess(data);
        //   // Reset button states in Redux store
        //   dispatch(setAuthenticationNextButtonClicked(false));
        // }
      }

      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const handleConnectorDetailsSuccess = (data: any) => {
    const filteredList = data?.endPointResponseDtos?.filter((dto: any) => dto?.endpointDescription === "token_validator");
    const { name, description, type, isDefaultSourceConnector, authenticationType, isActive, tokenType } = data;
    const authenticationDetails: any = connectorAuthenticationReduxDetails(data);
    const finalAuthenticationDetails = { ...authenticationDetails, tokenApiEndpoint: filteredList?.[0]?.apiEndpointUrl, tokenMethodType: filteredList?.[0]?.methodType };
    const storeName = name;
    const generalDetails = {
      name: storeName, // connector name
      description, // connector description
      type, // connector type
      isDefaultSourceConnector: isDefaultSourceConnector ? "yes" : "No",
      authenticationType, // connector authentication type
      isActive,
      tokenType, // connector token type
      spDomain: data?.spDomain, // connector sp domain

      akkuProvisioningConnectorAuthConfigId: data?.akkuProvisioningConnectorAuthConfigId,
      scope: data?.scope, // Authentication scope
      username: data?.username, // Authentication username
      password: data?.password, // Authentication password
      tokenApiEndpoint: filteredList?.[0]?.apiEndpointUrl, // Authentication endpoint
      tokenMethodType: filteredList?.[0]?.methodType, // Authentication method type
    };
    // Set the form's initial values and dispatch these details to Redux store
    dispatch(setAuthentication(finalAuthenticationDetails));
    dispatch(setIsActive(isActive));
    dispatch(setGeneralDetails({ ...initialValues, ...generalDetails }));
  };

  const handleSubmit = async (values: any) => {
    dispatch(setGeneralDetails(values));
    dispatch(setAppConfigDetails({ ...appDetails, name: values.name }));
    handleNext();
  };
  const handleBackToUser = () => {
    navigate("/app-store");
  };
  const handleNext = () => {
    dispatch(setPublishActiveStep(1));
  };

  return (
    <div className="w-full px-10 pb-20 ">
      <p className="app-header pt-8">
        {t("appManagement.provisioning.connectorDetails")} - {t("appManagement.provisioning.generalDetails")} ( {t("appManagement.publish")})
      </p>
      <div className="w-full mx-auto overflow-y-auto">
        <Formik initialValues={initialValues} values={initialValues} enableReinitialize={true} validationSchema={createAppPublishSchema(t)} onSubmit={handleSubmit} innerRef={formikRef}>
          {({ values, setFieldValue, dirty }) => {
            dispatch(setConnectorDetailDirty(dirty));
            return (
              <Form>
                <div className="w-full pt-10  app-connector flex-wrap overflow-y-auto">
                  <div className="w-full   pb-32 xl:pb-0 app-connector flex-wrap connector-details overflow-y-auto ">
                    <div className="mb-12 w-[45%] input-container relative">
                      <p> {t("appManagement.provisioning.connectorName")}*</p>
                      <Field disabled={isTenantAdmin} as={Input} type="text" className="form-type-box capitalize" name="name" data-testid="usersN-input" />
                      <ErrorMessage name="name" component="div" className="error-message" />
                    </div>
                    <div className="mb-12 w-[45%] input-container relative">
                      <p>{t("appManagement.provisioning.description")}*</p>
                      <Field disabled={isTenantAdmin} as={Input} type="text" className="form-type-box" name="description" data-testid="usersDn-input" />
                      <ErrorMessage name="description" component="div" className="error-message" />
                    </div>
                    <div className="mb-12 w-[45%] input-container relative">
                      <p>{t("appManagement.provisioning.serviceProviderDomain")}</p>
                      <Field as={Input} type="text" className="form-type-box" name="spDomain" data-testid="spDomainN-input" />
                      <ErrorMessage name="spDomain" component="div" className="error-message" />
                    </div>

                    {values?.type?.trim() === "RESTAPI" ? (
                      <div className="mb-12 w-[45%] input-container relative">
                        <p>{t("appManagement.provisioning.authenticationType")}*</p>
                        <Field
                          disabled={isTenantAdmin}
                          as={Select}
                          suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                          className="w-[100%] h-[56px]"
                          name="authenticationType"
                          value={values.authenticationType || undefined}
                          filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          onChange={(value: any) => setFieldValue("authenticationType", value)}
                          placeholder="Select"
                        >
                          {authenticationType?.map((option: any) => (
                            <Select.Option key={option.value} value={option.value}>
                              {option.label}
                            </Select.Option>
                          ))}
                        </Field>
                        <ErrorMessage name="authenticationType" component="div" className="error-message" />
                      </div>
                    ) : null}

                    <div className="mb-12 w-[45%] input-container relative">
                      <p>{t("appManagement.provisioning.tokenType")}*</p>
                      <Field
                        disabled={isTenantAdmin}
                        as={Select}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        className="w-[100%] h-[56px]"
                        name="tokenType"
                        value={values.tokenType || undefined}
                        filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={(value: any) => setFieldValue("tokenType", value)}
                        placeholder="Select"
                      >
                        {tokenType?.map((option: any) => (
                          <Select.Option key={option.value} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name="tokenType" component="div" className="error-message" />
                    </div>

                    <div className="mb-12 w-[45%] input-container relative">
                      <p>{t("appManagement.provisioning.connectorType")}*</p>
                      <Field
                        as={Select}
                        disabled
                        placeholder="Select"
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        className=" w-[100%] h-[56px]"
                        name="type"
                        value={values.type || undefined}
                        filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={(value: any) => setFieldValue("type", value)}
                      >
                        {connectorType?.map((option: any) => (
                          <Select.Option key={option.value} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name="type" component="div" className="error-message" />
                    </div>
                  </div>
                </div>
                <div className="footer flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
                  <div className="modal-footer w-full mx-auto ">
                    <div className="w-full flex justify-end pr-5">
                      <CustomButtonBack onClick={handleBackToUser} text={t("common.back")} />
                      <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn ">
                        {t("common.next")}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {loader && <Loader />}
    </div>
  );
};
export default ConnectorDetailsPublish;
