import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  provisioningConfigEdit: {};
  SSOConfigEdit: {};
  provisioningEditStatus: boolean;
  SSOEditStatus: boolean;
  isConfigureButtonPressed: boolean;
  provisioningDetailsRequestMethod: {};
  deProvisioningDetailsRequestMethod: {};
  provisioningCollapseCurrentActive: string;
  deProvisioningCollapseCurrentActive: string;
  provisioningValidations: {};
  deProvisioningValidations:{};

}
const initialState: AuthState = {
  provisioningConfigEdit: {
    isActiveConfig: false,
  },
  SSOConfigEdit: {
    isActiveConfig: false,
  },
  provisioningEditStatus: false,
  SSOEditStatus: false,
  isConfigureButtonPressed: false,
  provisioningCollapseCurrentActive: "1",
  deProvisioningCollapseCurrentActive: "1",
  provisioningDetailsRequestMethod: {
    user: "POST",
    role: "POST",
    group: "POST",
  },
  deProvisioningDetailsRequestMethod: {
    user: "POST",
    role: "POST",
    group: "POST",
  },
  provisioningValidations: {
    user: false,
    role: false,
    group: false,
  },
  deProvisioningValidations:{
    user: false,
    role: false,
    group: false,
  }
};

const appConfigureSlice: any = createSlice({
  name: "appConfig",
  initialState,
  reducers: {
    setProvisioningConfigEdit: (state, action: PayloadAction<any>) => {
      state.provisioningConfigEdit = action.payload;
    },

    setSSOConfigEdit: (state, action: PayloadAction<any>) => {
      state.SSOConfigEdit = action.payload;
    },
    setProvisioningEditStatus: (state, action: PayloadAction<boolean>) => {
      state.provisioningEditStatus = action.payload;
    },
    setSSOEditStatus: (state, action: PayloadAction<boolean>) => {
      state.SSOEditStatus = action.payload;
    },
    setIsConfigureButtonPressed: (state, action: PayloadAction<boolean>) => {
      state.isConfigureButtonPressed = action.payload;
    },
    setProvisioningDetailsRequestMethod: (state, action: PayloadAction<any>) => {
      state.provisioningDetailsRequestMethod = action.payload;
    },
    setDeProvisioningDetailsRequestMethod: (state, action: PayloadAction<any>) => {
      state.provisioningDetailsRequestMethod = action.payload;
    },
    setProvisioningCollapseCurrent: (state, action: PayloadAction<any>) => {
      state.provisioningCollapseCurrentActive = action.payload;
    },
    setDeProvisioningCollapseCurrent: (state, action: PayloadAction<any>) => {
      state.deProvisioningCollapseCurrentActive = action.payload;
    },
    setProvisioningValidations: (state, action: PayloadAction<any>) => {
      state.provisioningValidations = action.payload;
    },
     setDeProvisioningValidations: (state, action: PayloadAction<any>) => {
      state.deProvisioningValidations = action.payload;
    },

    clearAllEditData: () => {
      return { ...initialState };
    },
  },
});

export const {
  setProvisioningConfigEdit,
  setSSOConfigEdit,
  setSSOEditStatus,
  setProvisioningEditStatus,
  clearAllEditData,
  setIsConfigureButtonPressed,
  setProvisioningDetailsRequestMethod,
  setDeProvisioningDetailsRequestMethod,
  setProvisioningCollapseCurrent,
  setProvisioningValidations,
  // DeProvision
setDeProvisioningCollapseCurrent,
setDeProvisioningValidations
} = appConfigureSlice.actions;
export default appConfigureSlice.reducer;