export const convertObjectToArray = (obj: any) => {
  if (!obj || typeof obj !== "object" || Object.keys(obj).length === 0) {
    return [{ key: "", value: "" }];
  }
  return Object.entries(obj).map(([key, value]) => ({ key: key, value: value || "" }));
};

export const initializeUserDeprovisioning = (deProvisionDetails: any, endpoint: any, headerParameters: any, pathVariable: any) => {
  const deProvisioningData = JSON.parse(JSON.stringify(deProvisionDetails));
  if (endpoint?.methodType?.toLowerCase() === "delete" && endpoint?.endpointDescription?.toLowerCase() === "delete_user") {
    deProvisioningData.userDeProvision[0].apiEndpointURL = endpoint.apiEndpointUrl;
    deProvisioningData.userDeProvision[0].methodType = endpoint.methodType;
    deProvisioningData.userDeProvision[0].akkuProvisioningConnectorEndpointConfigId = endpoint?.akkuProvisioningConnectorEndpointConfigId;
    deProvisioningData.userDeProvision[0].headerParam = headerParameters;
    deProvisioningData.userDeProvision[0].pathVariable = pathVariable;
    deProvisioningData.userDeProvision[0].requestPayload = endpoint?.requestPayload || "";
    deProvisioningData.userDeProvision[0].response = endpoint?.sampleResponse || "";
    return deProvisioningData;
  }
};

export const initializeRoleDeprovisioning = (deProvisionDetails: any, endpoint: any, headerParameters: any, pathVariable: any) => {
  const deProvisioningData = JSON.parse(JSON.stringify(deProvisionDetails));
  if (endpoint?.methodType?.toLowerCase() === "delete" && endpoint?.endpointDescription?.toLowerCase() === "delete_role") {
    deProvisioningData.roleDeProvision[0].apiEndpointURL = endpoint.apiEndpointUrl;
    deProvisioningData.roleDeProvision[0].methodType = endpoint.methodType;
    deProvisioningData.roleDeProvision[0].akkuProvisioningConnectorEndpointConfigId = endpoint?.akkuProvisioningConnectorEndpointConfigId;
    deProvisioningData.roleDeProvision[0].headerParam = headerParameters;
    deProvisioningData.roleDeProvision[0].pathVariable = pathVariable;
    deProvisioningData.roleDeProvision[0].requestPayload = endpoint?.requestPayload || "";
    deProvisioningData.roleDeProvision[0].response = endpoint?.sampleResponse || "";
    return deProvisioningData;
  }
};
export const initializeGroupDeprovisioning = (deProvisionDetails: any, endpoint: any, headerParameters: any, pathVariable: any) => {
  const deProvisioningData = JSON.parse(JSON.stringify(deProvisionDetails));
  if (endpoint?.methodType?.toLowerCase() === "delete" && endpoint?.endpointDescription?.toLowerCase() === "delete_group") {
    deProvisioningData.groupDeProvision[0].apiEndpointURL = endpoint.apiEndpointUrl;
    deProvisioningData.groupDeProvision[0].methodType = endpoint.methodType;
    deProvisioningData.groupDeProvision[0].akkuProvisioningConnectorEndpointConfigId = endpoint?.akkuProvisioningConnectorEndpointConfigId;
    deProvisioningData.groupDeProvision[0].headerParam = headerParameters;
    deProvisioningData.groupDeProvision[0].pathVariable = pathVariable;
    deProvisioningData.groupDeProvision[0].requestPayload = endpoint?.requestPayload || "";
    deProvisioningData.groupDeProvision[0].response = endpoint?.sampleResponse || "";
    return deProvisioningData;
  }
};

export const initializeUserProvisioning = (provisionDetails: any, endpoint: any, headerParameters: any, pathVariable: any) => {
  let userProvisioned = false;

  const provisionData = JSON.parse(JSON.stringify(provisionDetails));

  // Check for user provisioning
  if (!userProvisioned && (endpoint?.endpointDescription?.toLowerCase() === "create_user" || endpoint?.endpointDescription?.toLowerCase() === "get_user")) {
    provisionData.userProvision[0].akkuProvisioningConnectorEndpointConfigId = endpoint?.akkuProvisioningConnectorEndpointConfigId;
    provisionData.userProvision[0].methodType = endpoint?.methodType;
    provisionData.userProvision[0].apiEndpointURL = endpoint?.apiEndpointUrl;
    provisionData.userProvision[0].tokenApiUrl = endpoint?.authToken;
    provisionData.userProvision[0].apiToken = endpoint?.tokenApiEndpoint;
    provisionData.userProvision[0].headerParam = headerParameters;
    provisionData.userProvision[0].pathVariable = pathVariable;
    provisionData.userProvision[0].requestPayload = endpoint?.requestPayload;
    provisionData.userProvision[0].response = endpoint?.sampleResponse;
    provisionData.userProvision[0].primaryKeyAttributeList = endpoint?.primaryKeyAttributeList && endpoint?.primaryKeyAttributeList?.length > 0 ? endpoint?.primaryKeyAttributeList : [""];

    userProvisioned = true; // Set user provision flag to true
    return provisionData;
  }
};

export const initializeGetRoleProvisioning = (provisionDetails: any, endpoint: any, headerParameters: any, pathVariable: any) => {
  let roleProvisioned = false;
  const provisionData = JSON.parse(JSON.stringify(provisionDetails));
  // Check for role provisioning
  if (!roleProvisioned && endpoint?.endpointDescription?.toLowerCase() === "role_list") {
    provisionData.getRole[0].akkuProvisioningConnectorEndpointConfigId = endpoint?.akkuProvisioningConnectorEndpointConfigId;
    provisionData.getRole[0].methodType = endpoint?.methodType;
    provisionData.getRole[0].apiEndpointURL = endpoint?.apiEndpointUrl;
    provisionData.getRole[0].tokenApiUrl = endpoint?.authToken;
    provisionData.getRole[0].apiToken = endpoint?.tokenApiEndpoint;
    provisionData.getRole[0].headerParam = headerParameters;
    provisionData.getRole[0].pathVariable = pathVariable;
    provisionData.getRole[0].requestPayload = endpoint?.requestPayload;
    provisionData.getRole[0].response = endpoint?.sampleResponse;
    provisionData.getRole[0].serviceProviderAttributesDto =
      endpoint?.serviceProviderAttributesDto && Object.keys(endpoint?.serviceProviderAttributesDto).length > 0 ? endpoint?.serviceProviderAttributesDto : { id: "", name: "" };

    roleProvisioned = true; // Set role provision flag to true
    return provisionData;
  }
};

export const initializeAssignRoleProvisioning = (provisionDetails: any, endpoint: any, headerParameters: any, pathVariable: any) => {
  let roleProvisioned = false;
  const provisionData = JSON.parse(JSON.stringify(provisionDetails));
  // Check for role provisioning
  if (!roleProvisioned && endpoint?.endpointDescription.toLowerCase() === "assign_role") {
    provisionData.assignRole[0].akkuProvisioningConnectorEndpointConfigId = endpoint?.akkuProvisioningConnectorEndpointConfigId;
    provisionData.assignRole[0].methodType = endpoint?.methodType;
    provisionData.assignRole[0].apiEndpointURL = endpoint?.apiEndpointUrl;
    provisionData.assignRole[0].tokenApiUrl = endpoint?.authToken;
    provisionData.assignRole[0].apiToken = endpoint?.tokenApiEndpoint;
    provisionData.assignRole[0].headerParam = headerParameters;
    provisionData.assignRole[0].pathVariable = pathVariable;
    provisionData.assignRole[0].requestPayload = endpoint?.requestPayload;
    provisionData.assignRole[0].response = endpoint?.sampleResponse;
    provisionData.assignRole[0].serviceProviderAttributesDto =
      endpoint?.serviceProviderAttributesDto && Object.keys(endpoint?.serviceProviderAttributesDto).length > 0 ? endpoint?.serviceProviderAttributesDto : { id: "", name: "" };
    provisionData.assignRole[0].primaryKeyAttributeList = endpoint?.primaryKeyAttributeList && endpoint?.primaryKeyAttributeList?.length > 0 ? endpoint?.primaryKeyAttributeList : [""];
    roleProvisioned = true; // Set role provision flag to true
    return provisionData;
  }
};

export const initializeGetGroupProvisioning = (provisionDetails: any, endpoint: any, headerParameters: any, pathVariable: any) => {
  let groupProvisioned = false;
  const provisionData = JSON.parse(JSON.stringify(provisionDetails));
  // Check for group provisioning
  if (!groupProvisioned && endpoint?.endpointDescription.toLowerCase() === "group_ou_list") {
    provisionData.getGroup[0].akkuProvisioningConnectorEndpointConfigId = endpoint?.akkuProvisioningConnectorEndpointConfigId;
    provisionData.getGroup[0].methodType = endpoint?.methodType;
    provisionData.getGroup[0].apiEndpointURL = endpoint?.apiEndpointUrl;
    provisionData.getGroup[0].tokenApiUrl = endpoint?.authToken;
    provisionData.getGroup[0].apiToken = endpoint?.tokenApiEndpoint;
    provisionData.getGroup[0].headerParam = headerParameters;
    provisionData.getGroup[0].pathVariable = pathVariable;
    provisionData.getGroup[0].requestPayload = endpoint?.requestPayload;
    provisionData.getGroup[0].response = endpoint?.sampleResponse;
    provisionData.getGroup[0].serviceProviderAttributesDto =
      endpoint?.serviceProviderAttributesDto && Object.keys(endpoint?.serviceProviderAttributesDto).length > 0 ? endpoint?.serviceProviderAttributesDto : { id: "", name: "" };

    groupProvisioned = true; // Set group provision flag to true
    return provisionData;
  }
};

export const initializeAssignGroupProvisioning = (provisionDetails: any, endpoint: any, headerParameters: any, pathVariable: any) => {
  let groupProvisioned = false;
  const provisionData = JSON.parse(JSON.stringify(provisionDetails));
  // Check for role provisioning
  // Check for group provisioning
  if (!groupProvisioned && endpoint?.endpointDescription.toLowerCase() === "assign_group") {
    provisionData.assignGroup[0].akkuProvisioningConnectorEndpointConfigId = endpoint?.akkuProvisioningConnectorEndpointConfigId;
    provisionData.assignGroup[0].methodType = endpoint?.methodType;
    provisionData.assignGroup[0].apiEndpointURL = endpoint?.apiEndpointUrl;
    provisionData.assignGroup[0].tokenApiUrl = endpoint?.authToken;
    provisionData.assignGroup[0].apiToken = endpoint?.tokenApiEndpoint;
    provisionData.assignGroup[0].headerParam = headerParameters;
    provisionData.assignGroup[0].pathVariable = pathVariable;
    provisionData.assignGroup[0].requestPayload = endpoint?.requestPayload;
    provisionData.assignGroup[0].response = endpoint?.sampleResponse;
    provisionData.assignGroup[0].serviceProviderAttributesDto =
      endpoint?.serviceProviderAttributesDto && Object.keys(endpoint?.serviceProviderAttributesDto).length > 0 ? endpoint?.serviceProviderAttributesDto : { id: "", name: "" };
    provisionData.assignGroup[0].primaryKeyAttributeList = endpoint?.primaryKeyAttributeList && endpoint?.primaryKeyAttributeList?.length > 0 ? endpoint?.primaryKeyAttributeList : [""];

    groupProvisioned = true; // Set group provision flag to true
    return provisionData;
  }
};
