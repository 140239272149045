import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageView from "../../../../../layouts/component/ImageView";
import { cursorNotAllowedStyle } from "../../../../../hooks/hooks";
import { hasPermission } from "../../../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../../../const/RolePermissions";
import { Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { setAppConfigDetails } from "../../../../../redux/slice/app-store/AppDetailsSlice";
import { useTranslation } from "react-i18next";
const PublishedApps = (props: any) => {
  const { t } = useTranslation();
  const { publishedAppList } = props;
  const navigate = useNavigate();
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);

  const hasCreateAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "create");

  const dispatch = useDispatch();
  const handlePublishedRedirect = (item: any) => {
    dispatch(setAppConfigDetails(item));
    navigate("/publish-provision");
  };

  return (
    <>
      <h1 className="text-[rgb(23,23,23)] text-[28px] font-Inter font-semibold pl-5 pb-6 available-apps-title">
        {t("appManagement.publishedApps")} ({publishedAppList?.length})
      </h1>
      <div data-testid="my-apps-empty" className="pt-1 flex flex-wrap w-full">
        {publishedAppList?.map((item: any) => (
          <div className="2xl:w-1/4  lg:w-1/3 sm:w-1/2" key={item}>
            <div className="mx-4  h-[270px] app-card  mb-8 relative ">
              <div className="card-img rounded-t-lg">
                <ImageView src={item?.logoUrl} alt="my apps" className=" w-[60px] h-[60px] my-apps-icon" />
              </div>
              <div className="px-4 py-2">
                <p className="text-[#1D1D24] capitalize text-[20px] font-Inter font-semibold my-app-card-name ">{item?.name}</p>
                <Tooltip title={item?.description}>
                  <p className="text-[#444] text-[16px] font-Inter card-description">{item?.description}</p>
                </Tooltip>
              </div>
              <div className="card-action">
                <p role="none" onClick={() => handlePublishedRedirect(item)} className={`configure font-Inter ${cursorNotAllowedStyle(hasCreateAppStorePermission)}`}>
                  {t("appManagement.configure")}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PublishedApps;
