import { APIConfigURLs } from "../apiInterfaceType/apiInterfaceType";
const provisioningApiBaseUrl = process.env.REACT_APP_PROVISIONING_BASEURL;

const GET_API_URL: APIConfigURLs = {
  theme: {
    url: "/v1/customer",
  },
  dashboard: {
    url: "/api/dashboard/widgets/risk-assessment",
  },
  dashboardFilter: {
    url: "v1/dashboard/widgets/all-filters",
  },
  myAppsList: {
    url: "/v1/client",
  },
  getCategoryList: {
    url: "/v1/category",
  },
  editAppConfiguration: {
    url: "v1/client",
  },
  viewCertificate: {
    url: "v1/realm/key",
  },
  certificate: {
    url: "/realms",
  },
  clientSecretKey: {
    url: "v1/client/secret-key",
  },
  getPlans: {
    url: "v1/plan-feature/list",
  },
  getProducts: {
    url: "v1/feature",
  },
  getAllPlans: {
    url: "v1/akku-plans",
  },
  getLogoutUrls: {
    url: "v1/client/logout-url",
  },
  getGroupsList: {
    url: "v1/group",
  },
  getFilePaths: {
    url: "/v1/user-device-restriction/file-path",
  },
  getListOfAppsByRealmId: {
    url: "/v1/client",
  },
  getGroupAppsList: {
    url: "v1/group",
  },
  getDomain: {
    url: "/v1/domain/subdomain",
  },
  getUserProfile: {
    url: "v1/user",
  },
  customerPlansAndFeatures: {
    url: "/v1/plan-feature/detail",
  },
  getRolesList: {
    url: "/v1/role/key-value/realm",
  },
  getAccessManager: {
    url: "/v1/user/access-manager/realm",
  },
  getAccessManagerDetails: {
    url: "/v1/user/access-manager",
  },
  getPasswordPolicy: {
    url: "/v1/policy/password-policy",
  },
  getApplicationUserList: {
    url: "/v1/client/realm",
  },
  getSmsServiceProviders: {
    url: "/v1/customer/sms-service-provider",
  },
  getRiskAssessmentDetails: {
    url: "/v1/risk-assessment/user/details",
  },
  getDeviceTypes: {
    url: "/v1/device-manager/type",
  },
  getParticularDeviceTypes: {
    url: "/v1/device-manager/realm",
  },
  getMobilePushNotifications: {
    url: "/v1/mfa-authenticator/user",
  },
  getUserAuditLogs: {
    url: "/v1/audit-logs/user-management",
  },
  //user get api urls
  userDetails: {
    url: "v1/user/attribute",
  },
  clientAppDetails: {
    url: "",
    baseURL: "/v1/user/dashboard",
  },
  userProfileDetails: {
    url: "v1/user",
  },
  clientProfileDetails: {
    url: "",
    baseURL: "v1/user",
  },
  getComponentList: {
    url: "v1/component/list",
  },
  getMapperType: {
    url: "v1/component/list/mapper-types",
  },
  getMapperLists: {
    url: "v1/component/list/mappers",
  },
  editConnectedDirectory: {
    url: "/v1/component",
  },
  getAvailableProductList: {
    url: "/v1/role/realm",
  },
  getThemeList: {
    url: "/v1/customer/theme-dropdown",
  },
  getConnectionList: {
    url: "/v1/customer/theme-dropdown",
  },
  getConnectionTableData: {
    url: "/v1/user/realm/",
  },
  getMFAUserList: {
    url: "/v1/user-management/mfa/list",
  },
  getLicenseCount: {
    url: "/v1/customer/user/license-count",
  },
  provisioningAppsList: {
    url: "v1/sp/connector/realm",
    alternateURL: provisioningApiBaseUrl,
  },
  publishedAppsList: {
    url: "/v1/sp/connector/apps/published",
    alternateURL: provisioningApiBaseUrl,
  },
  provisioningConnectorDetails: {
    url: "v1/sp/connector",
    alternateURL: provisioningApiBaseUrl,
  },
  provisionSourceAttributes: {
    url: "v1/sp/connector/endpoint-config/source-attributes",
    alternateURL: provisioningApiBaseUrl,
  },
  getAuditLogsSearch: {
    url: "v1/audit-logs/search",
  },
};

export default GET_API_URL;
