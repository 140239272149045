import { Button, Select } from "antd";
import { ErrorMessage, Field, FieldArray } from "formik";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { useDispatch, useSelector } from "react-redux";
import { setPublishActiveStep } from "../../../../../redux/slice/provisioning/ProvisioningPublishSlice";
import { setActiveStep } from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import { useTranslation } from "react-i18next";

const ProvisionGroupMappingForm = (props: any) => {
  const { values, setFormInitial, source, target, setIsDirty } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const provisioning = useSelector((state: any) => state?.provisioning);
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);
  const isGroupConfigured = useSelector((state: any) => state?.provisioning?.isGroupProvisionConfigured);
  const isRoleConfigured = useSelector((state: any) => state?.provisioning?.isRoleProvisionConfigured);
  const handleOnChange = (field: string, index: number, option: any) => {
    setIsDirty(true);
    const updatedForms = values.forms.map((formItem: any, ind: number) => {
      if (index === ind) {
        return {
          ...formItem,
          [field]: option?.label || "", // set the label (name)
          [field === "source" ? "sourceId" : "targetId"]: option?.value || "", // set the ID correctly based on the field
        };
      }
      return formItem;
    });
    setFormInitial((prevValues: any) => ({
      ...prevValues,
      forms: updatedForms,
    }));
  };

  const handleRemove = (remove: any, index: number) => {
    setIsDirty(true);
    remove(index);
  };
  const handleAdd = (push: any) => {
    push({ source: "", target: "", sourceId: "", targetId: "" });
    setIsDirty(true);
  };

  const handleBack = () => {
    if (appDetails?.publishStatus) {
      dispatch(setPublishActiveStep(1));
    } else {
      dispatch(setActiveStep(5));
    }
  };

  const handleFilterSource = (source: any) => {
    const selectedSources = values?.forms.map((formItem: any) => formItem?.sourceId);
    return source?.filter((option: any) => !selectedSources.includes(option?.id));
  };

  const handleFilterTarget = (target: any) => {
    const selectedTargets = values?.forms?.map((formItem: any) => formItem?.targetId);
    return target?.filter((option: any) => !selectedTargets.includes(option?.id));
  };

  const handleSkip = () => {
    if (appDetails?.publishStatus) {
      dispatch(setPublishActiveStep(3));
    } else {
      dispatch(setActiveStep(7));

      // if (isRoleConfigured) {
      //   dispatch(setActiveStep(7));
      // } else {
      //   dispatch(setActiveStep(8));
      // }
    }
  };

  return (
    <div>
      <FieldArray name="forms">
        {({ push, remove }) => (
          <div className="mapper mt-10 px-7 2xl:px-9 ms-3 2xl:ms-24 overflow-x-auto pb-5 2xl:mr-10 mr-2">
            {values?.forms?.map((formItem: any, index: any) => (
              <div key={formItem} className="w-[500px] xl:w-full flex mapper-attributes items-center relative ">
                <div className="w-[50%] 2xl:w-[33%] mr-10 source relative mt-0 2xl:mt-8 min-h-[75px] xl:min-h-[90px]">
                  {index === 0 && <p className="text-center absolute top-[-50px] w-full">{t("appManagement.provisioning.sourceAkku")}</p>}
                  <Field
                    labelInValue // Added to return { label, value }
                    showSearch
                    allowClear={true}
                    suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                    name={`forms.${index}.source`}
                    as={Select}
                    data-testid="usersIt-input"
                    className={formItem.isCheck ? "w-[100%] h-[56px] pointer-events-none cursor-not-allowed" : "w-[100%] h-[56px]"}
                    value={{ label: formItem.source || "", value: formItem.sourceId || "" }} // set label and value
                    onChange={(option: any) => handleOnChange("source", index, option)}
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {handleFilterSource(source).map((option: any) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Field>
                  <ErrorMessage name={`forms.${index}.source`} component="div" className="error-message custom-error" />
                </div>

                <div className="w-[50%] 2xl:w-[33%] mr-10 target relative mt-0 2xl:mt-8 min-h-[80px] xl:min-h-[90px]">
                  {index === 0 && (
                    <p className="capitalize text-center absolute top-[-50px] w-full">
                      {" "}
                      {t("appManagement.provisioning.target")} - {provisioning?.generalDetails?.name}
                    </p>
                  )}

                  <Field
                    labelInValue // Added to return { label, value }
                    showSearch
                    allowClear={true}
                    suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                    as={Select}
                    name={`forms.${index}.target`}
                    className={formItem.isCheck ? "w-[100%] h-[56px] pointer-events-none cursor-not-allowed" : "w-[100%] h-[56px]"}
                    value={{ label: formItem.target || "", value: formItem.targetId || "" }} // set label and value
                    onChange={(option: any) => handleOnChange("target", index, option)}
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    data-testid="usersIe-input"
                  >
                    {handleFilterTarget(target).map((option: any) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Field>
                  <ErrorMessage name={`forms.${index}.target`} component="div" className="error-message custom-error" />
                </div>

                <div className="w-auto flex justify-start ml-5 mt-0 2xl:mt-8 min-h-[75px] xl:min-h-[90px]">
                  <span
                    role="none"
                    className={`mapping-attributes-plus-icon add material-symbols-outlined ml-5 ${values.forms.length < target.length ? "cursor-pointer" : "cursor-not-allowed"}`}
                    onClick={() => values.forms.length < target.length && handleAdd(push)}
                  >
                    add
                  </span>
                  {values.forms.length !== 1 && (
                    <span
                      data-testid="remove-btn"
                      role="none"
                      className={`mapping-attributes-plus-icon add remove material-symbols-outlined ml-5 ${values.forms.length === 1 ? "cursor-not-allowed text-gray-400" : "cursor-pointer"}`}
                      onClick={() => values.forms.length > 1 && handleRemove(remove, index)}
                    >
                      remove
                    </span>
                  )}
                </div>
              </div>
            ))}
            <div className="footer">
              <div className="modal-footer w-full pr-5 mx-auto ">
                <div className="w-full flex justify-end">
                  <CustomButtonBack text={t("common.skip")} onClick={() => handleSkip()} />
                  <CustomButtonBack onClick={handleBack} text={t("common.back")} />
                  <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                    {t("common.next")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default ProvisionGroupMappingForm;
