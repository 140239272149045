import { SortOrder } from "antd/es/table/interface";
import generateTitle from "../../layouts/Table/generateTitle";

export const auditLogActionType = [
  { label: "Logged In", value: "loggedIn" },
  { label: "Logged Out", value: "loggedOut" },
  { label: "Password changes", value: "passwordChanges" },
  { label: "Role & permission changes", value: "rolePermission" },
];

export const auditLogTableColumns = [
  {
    title: ({ sortColumns }: any) =>
      generateTitle(
        "Username",
        sortColumns?.find(({ column }: any) => column.key === "username"),
      ),
    dataIndex: "username",
    key: "username",
    defaultSortOrder: "ascend" as SortOrder,
    sorter: (a: any, b: any) => a?.username?.localeCompare(b?.username),
  },
  {
    title: ({ sortColumns }: any) =>
      generateTitle(
        "Date and Time",
        sortColumns?.find(({ column }: any) => column.key === "DateTime"),
      ),
    dataIndex: "DateTime",
    key: "DateTime",
    defaultSortOrder: "ascend" as SortOrder,
    sorter: (a: any, b: any) => a?.DateTime?.localeCompare(b?.DateTime),
  },
  {
    title: "Activity logs",
    dataIndex: "activityLogs",
    key: "activityLogs",
  },
  {
    title: "Action Type",
    dataIndex: "actionType",
    key: "actionType",
  },
  {
    title: "IP Address",
    dataIndex: "IPAddress",
    key: "IPAddress",
  },
  {
    title: "OS",
    dataIndex: "os",
    key: "os",
  },
  {
    title: "Device",
    dataIndex: "device",
    key: "device",
  },
];
