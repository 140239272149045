import { useSelector } from "react-redux";
import { cursorNotAllowedStyle } from "../../../../../../hooks/hooks";
import { hasPermission } from "../../../../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../../../../const/RolePermissions";
import { PermissionsState } from "../StatusToggleHelper";
import { useTranslation } from "react-i18next";

export default function ProvisioningStatusToggle({ provisioningStatus, handleActiveProvisioning, handleDeActiveProvisioning }: any) {
  const { t } = useTranslation();
  const permissions = useSelector((state: PermissionsState) => state?.permissionsSlice?.attributes);

  const hasEditAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, "update");

  return (
    <div>
      {provisioningStatus ? (
        <p role="none" onClick={() => handleDeActiveProvisioning()} className={`card-status-active hover:bg-[#caf1cf] ${cursorNotAllowedStyle(hasEditAppStorePermission)}`}>
          <span></span>
          {t("common.active")}
        </p>
      ) : (
        <p role="none" onClick={() => handleActiveProvisioning()} className={`card-status-inactive hover:bg-[#ffd7d2] ${cursorNotAllowedStyle(hasEditAppStorePermission)}`}>
          <span></span> {t("common.inactive")}
        </p>
      )}
    </div>
  );
}
