import "./chooseConnector.scss";
import { Button } from "antd";
import treeIcon from "./../../../../../../../assets/images/icons/account_tree_color.png";
import adIcon from "./../../../../../../../assets/images/icons/adImage.png";
import { useState } from "react";
import { useAddUserContext } from "../../../add-user-context/AddUserContext";
import { useDispatch, useSelector } from "react-redux";
import { setAddUserStepper } from "../../../../../../../redux/slice/AddUserSlice";
import { staticMessage } from "../../../../../../../constant/StaticContent";
import { connectorNext } from "../navigate-helper/LDAPNavigate";
import Loader from "../../../../../../../layouts/component/Loader";
import { clearLDAPInitialValues, clearLDAPUpdateInitialValues } from "../../../../../../../redux/slice/LDAPSlice";
import { useNavigate } from "react-router-dom";
import { handleKerberosNavigate } from "./kerberosHelper";
import { useTranslation } from "react-i18next";
export default function ChooseConnector() {
  const { t } = useTranslation();
  const loader = false;
  const dispatch = useDispatch();
  const stepper = useSelector((state: any) => state?.addUserSlice?.steeper);
  const { closeModal } = useAddUserContext();
  const [selectedConnector, setSelectedConnector] = useState(null);
  const navigate = useNavigate();
  const connectors = ["ldap", "ad", "kerberos"];
  const translatedConnectors = connectors.map((protocol) => t(`userManagement.${protocol}`));
  const handleRedirectNext = () => {
    console.log(selectedConnector, "selectedConnector");
    if (selectedConnector === "Kerberos") {
      dispatch(setAddUserStepper(handleKerberosNavigate(stepper)));
    } else {
      dispatch(clearLDAPInitialValues());
      dispatch(clearLDAPUpdateInitialValues());
      dispatch(setAddUserStepper(connectorNext(stepper)));
    }
  };
  const handleCheckboxChange = (connectorType: any) => {
    setSelectedConnector(connectorType);
  };
  const handleNavigateToUser = () => {
    navigate("/user");
    closeModal();
  };
  return (
    <div className="modal-content">
      {loader && <Loader />}
      <div className="modal-component">
        <div className="modal-header pl-[48px]">
          <h2 className="pointer-events-none cursor-not-allowed">{t("userManagement.chooseConnector")}</h2>
        </div>
        <div className="connector w-full grid grid-cols-4 gap-6 ">
          {translatedConnectors?.map((connector, index) => (
            <div key={connector} className={`card cursor-pointer relative ${selectedConnector === connector ? "bordered" : ""}`}>
              <input
                type="checkbox"
                checked={selectedConnector === connector}
                onChange={() => handleCheckboxChange(connector)}
                className="block w-full h-full absolute opacity-0 cursor-pointer top-0 left-0"
              />
              <div className="card-body flex justify-center flex-wrap pt-4">
                <h4 className="w-full text-center pb-2 uppercase">{connector}</h4>
                <img src={connector === "LDAP" || connector === "Kerberos" ? treeIcon : adIcon} alt={`${connector} icon`} className="h-[60px]" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="button-group bg-white flex justify-end items-center pr-9">
        <Button className="mr-5 flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px]" size="large" onClick={handleNavigateToUser}>
          {t("common.cancel")}
        </Button>
        <Button
          disabled={!selectedConnector}
          onClick={handleRedirectNext}
          data-testid="submit-button"
          type="primary"
          htmlType="submit"
          className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white"
        >
          {t("common.next")}
        </Button>
      </div>
    </div>
  );
}
