import React from "react";
import { Field, FieldArray, ErrorMessage } from "formik";
import { Input, Select } from "antd";
import { useTranslation } from "react-i18next";
const { TextArea } = Input;

interface UserProvisionProps {
  index: number;
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  provisioning: any;
  provisionDetails: any;
  samlConfig: any;
  formName: string;
  id: number;
}

const DeProvisionFormFields: React.FC<UserProvisionProps> = ({ index, values, setFieldValue, provisioning, provisionDetails, samlConfig, formName, id }) => {
  const { t } = useTranslation();
  return (
    <div key={samlConfig} className=" w-[90%] justify-start gap-x-20 flex-wrap flex">
      <div className="mb-10 input-container 2xl:w-[42%]  w-[38%]">
        <p className="font-medium text-[#1D1D24] font-Inter flex text-[18px] items-center ">{t("appManagement.provisioning.apiEndpointUrl")}*</p>
        <Field id={`apiEndpointURL${id}`} as={Input} type="text" className="h-[56px]  text-[18px] font-medium form-type-box" name={`${formName}.${index}.apiEndpointURL`} />
        <ErrorMessage name={`${formName}.${index}.apiEndpointURL`} component="div" className=" custom-error error-message " />
      </div>
      <div className="mb-10 input-container 2xl:w-[42%]  w-[38%]">
        <p className="text-[#1D1D24] font-Inter text-[18px]  font-medium">{t("appManagement.provisioning.methodType")}*</p>

        <Field
          id={`methodType${id}`}
          disabled
          name={`${formName}.${index}.methodType`}
          type="text"
          as={Select}
          className="h-[56px] loginThemeText font-medium text-[18px] w-[100%] "
          // value={`${formName}.${index}.methodType` || undefined}
          value={values[formName]?.[index]?.methodType || undefined}
          filterOption={(input: string, option: React.ReactElement) => option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
          onChange={(value: string) => setFieldValue(`${formName}?.${index}.methodType`, value)}
          placeholder={t("common.select")}
          data-testid="deprovisionSelect"
        >
          <Select.Option value="Delete">{t("common.delete")}</Select.Option>
        </Field>
        <ErrorMessage name={`${formName}.${index}.methodType`} component="div" className="error-message" />
      </div>

      <div className="mb-10 input-container 2xl:w-[42%]  w-[38%]">
        <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">{t("appManagement.provisioning.requestPayload")}</p>
        <Field
          id={`requestPayload${id}`}
          as={TextArea}
          type="text"
          className="min-h-[200px] font-medium text-[18px] form-type-box"
          name={`${formName}.${index}.requestPayload`}
          data-testid="requestPayload"
        />
        <ErrorMessage name={`${formName}.${index}.requestPayload`} component="div" className="error-message custom-error" />
      </div>
      <div className="mb-10 input-container 2xl:w-[42%]  w-[38%]">
        <p className="text-[#1D1D24] font-Inter flex text-[18px] items-center font-medium">{t("appManagement.provisioning.response")}</p>
        <Field data-testid="response" id={`response${id}`} type="text" className="min-h-[200px] font-medium text-[18px] form-type-box" name={`${formName}.${index}.response`} as={TextArea} />
        <ErrorMessage name={`${formName}.${index}.response`} className="error-message custom-error" component="div" />
      </div>

      <FieldArray name={`${formName}.${index}.headerParam`}>
        {({ push, remove }) => (
          <div className="w-full header-parameter-wrapper">
            {samlConfig?.headerParam?.map((param: any, paramIndex: any) => (
              <div key={param} className="w-full  relative flex justify-start gap-x-20  ">
                <div className=" relative mt-4 header-param  2xl:w-[42%]  w-[38%] input-container">
                  <p className={`${paramIndex === 0 ? "text-[#1D1D24] flex text-[18px]  font-medium items-center font-Inter " : " opacity-0"}`}>{t("appManagement.provisioning.requestParameters")}</p>
                  <Field
                    id={`key${id}`}
                    as={Input}
                    type="text"
                    className="h-[56px] font-medium text-[18px] form-type-box"
                    name={`${formName}.${index}.headerParam.${paramIndex}.key`}
                    placeholder={t("appManagement.provisioning.key")}
                    data-testid="attributesKey"
                  />
                  <ErrorMessage name={`${formName}.${index}.headerParam.${paramIndex}.key`} component="div" className="error-message custom-error" />
                </div>
                <div className=" relative mt-4 header-param  2xl:w-[42%]  w-[38%] input-container">
                  <p className={`${paramIndex === 0 ? "text-[#1D1D24] font-Inter flex text-[18px] items-center  font-medium" : " opacity-0"}`}>&nbsp;</p>
                  <Field
                    id={`value${id}`}
                    placeholder={t("appManagement.provisioning.value")}
                    as={Input}
                    type="text"
                    className="h-[56px] font-medium text-[18px] form-type-box"
                    name={`${formName}.${index}.headerParam.${paramIndex}.value`}
                    data-testid="attributesValue"
                  />
                  <ErrorMessage name={`${formName}.${index}.headerParam.${paramIndex}.value`} component="div" className="error-message custom-error" />
                </div>
                <div className="absolute provision-action">
                  {samlConfig.headerParam.length > 1 && (
                    <button type="button" onClick={() => remove(paramIndex)} className=" remove-btn">
                      <span className="material-symbols-outlined provision-plus-btn ">remove</span>
                    </button>
                  )}
                  {paramIndex === samlConfig.headerParam.length - 1 && (
                    <button type="button" onClick={() => push({ key: "", value: "" })}>
                      <span className="material-symbols-outlined provision-plus-btn">add</span>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </FieldArray>

      <FieldArray name={`${formName}.${index}.pathVariable`}>
        {({ push, remove }) => (
          <div className="w-full mt-5 header-parameter-wrapper">
            {samlConfig?.pathVariable?.map((param: any, paramIndex: any) => (
              <div key={param} className="w-full  relative flex justify-start gap-x-20  ">
                <div className=" relative mt-4 header-param  2xl:w-[42%]  w-[38%] input-container">
                  <p className={`${paramIndex === 0 ? "text-[#1D1D24] flex text-[18px]  font-medium items-center font-Inter " : " opacity-0"}`}>{t("appManagement.provisioning.pathVariable")}</p>
                  <Field
                    id={`key${id}`}
                    as={Input}
                    type="text"
                    className="h-[56px] font-medium text-[18px] form-type-box"
                    name={`${formName}.${index}.pathVariable.${paramIndex}.key`}
                    placeholder={t("appManagement.provisioning.key")}
                  />
                  <ErrorMessage name={`${formName}.${index}.pathVariable.${paramIndex}.key`} component="div" className="error-message custom-error" />
                </div>
                <div className=" relative mt-4 header-param  2xl:w-[42%]  w-[38%] input-container">
                  <p className={`${paramIndex === 0 ? "text-[#1D1D24] font-Inter flex text-[18px] items-center  font-medium" : " opacity-0"}`}>&nbsp;</p>
                  <Field
                    id={`value${id}`}
                    placeholder={t("appManagement.provisioning.value")}
                    as={Input}
                    type="text"
                    className="h-[56px] font-medium text-[18px] form-type-box"
                    name={`${formName}.${index}.pathVariable.${paramIndex}.value`}
                  />
                  <ErrorMessage name={`${formName}.${index}.pathVariable.${paramIndex}.value`} component="div" className="error-message custom-error" />
                </div>
                <div className="absolute provision-action ">
                  {samlConfig.pathVariable.length > 1 && (
                    <button type="button" onClick={() => remove(paramIndex)} className=" remove-btn">
                      <span className="material-symbols-outlined provision-plus-btn">remove</span>
                    </button>
                  )}
                  {paramIndex === samlConfig.pathVariable.length - 1 && (
                    <button type="button" onClick={() => push({ key: "", value: "" })} data-testid="add-btn">
                      <span className="material-symbols-outlined provision-plus-btn">add</span>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default DeProvisionFormFields;
