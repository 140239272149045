// React and third-party libraries
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, Select, Input } from "antd";

// Redux-related imports
import { useDispatch, useSelector } from "react-redux";
import { setAppConfigDetails, setCurrentStep } from "../../../../../redux/slice/app-store/AppDetailsSlice";
import {
  setActiveStep,
  setAuthentication,
  setAuthenticationNextButtonClicked,
  setConnectorAuthenticationEndpointConfigId,
  setConnectorDetailDirty,
  setDeProvisionConnectorEndpointConfigId,
  setDeProvisioning,
  setDeProvisioningBackButtonClicked,
  setEndPointResponseDtosData,
  setEndPointResponseDtosLen,
  setGeneralDetails,
  setIsActive,
  setIsGroupProvisionConfigured,
  setIsRoleProvisionConfigured,
  setProvisioning,
  setProvisioningBackButtonClicked,
  setProvisioningButtonStatus,
  setProvisioningConnectorEndpointConfigId,
} from "../../../../../redux/slice/provisioning/ProvisioningSlice";

// Utilities, helpers, and services
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { createAppSchema } from "./ConnectorValidationSchema";
import { connectorDetailsInitialValue, connectorAuthenticationReduxDetails, UseGetEndpointDescription, getAuthenticationType, getTokenType, getConnectorType } from "../helper/connectorHelper";
import {
  convertObjectToArray,
  initializeAssignGroupProvisioning,
  initializeAssignRoleProvisioning,
  initializeGetGroupProvisioning,
  initializeGetRoleProvisioning,
  initializeGroupDeprovisioning,
  initializeRoleDeprovisioning,
  initializeUserDeprovisioning,
  initializeUserProvisioning,
} from "./helper/ConnectorDetailsHelper";

// Custom components
import Loader from "../../../../../layouts/component/Loader";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { retrieveData } from "../../../../../services/storage/Storage";
import { setIsPublished } from "../../../../../redux/slice/profile/ProfileDetailsSlice";
import { useTranslation } from "react-i18next";

const ConnectorDetails = (props: any) => {
  const { t, i18n } = useTranslation();
  const authenticationType = getAuthenticationType(t);
  const tokenType = getTokenType(t);
  const connectorType = getConnectorType(t);
  const generalDetails = useSelector((state: any) => state?.provisioning.generalDetails);
  const provisioning = useSelector((state: any) => state?.provisioning);
  const provisionDetails = useSelector((state: any) => state?.provisioning?.provisioning);
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);
  const deProvisionDetails = useSelector((state: any) => state?.provisioning?.deProvisioning);
  const isBackButtonPressed = useSelector((state: any) => state?.provisioning?.isBackButtonEnabled);
  const buttonStatus = useSelector((state: any) => state?.provisioning?.duplicateProvisioningButtons);

  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState(connectorDetailsInitialValue);
  const formikRef = useRef<any>(null);

  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);
  useEffect(() => {
    if (provisioning?.isActiveEdit && !isBackButtonPressed) {
      getProvisioningConnectorDetails();
    } else {
      dispatch(
        setGeneralDetails({
          ...generalDetails,
          name: appDetails?.name,
          tokenType: generalDetails.tokenType || "",
        }),
      );
    }
  }, []);

  useEffect(() => {
    setInitialValues(generalDetails);
  }, [generalDetails]);

  const handleSubmit = async (values: any) => {
    if (values.connectorType === "Database") {
      handleDBNext();
    } else {
      dispatch(setGeneralDetails(values));
      dispatch(setAppConfigDetails({ ...appDetails, name: values.name }));
      if (provisioning?.isDuplicate) {
        dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorAuthentication: true }));
      }
      handleNext();
    }
  };
  const handleBackToUser = () => {
    navigate("/app-store");
  };
  const handleDBNext = () => {
    const updatedStepper = {
      activeStep: 10,
      childStepper: "",
    };
    dispatch(setCurrentStep(updatedStepper));
  };
  const handleNext = () => {
    if (provisioning?.isDuplicate) {
      dispatch(setProvisioningButtonStatus({ ...buttonStatus, connectorAuthentication: true }));
    }
    dispatch(setActiveStep(1));
  };

  // Function to fetch provisioning connector details from the API
  const getProvisioningConnectorDetails = async () => {
    setLoader(true);
    let params = {
      akkuProvisioningConnectorId: provisioning.akkuProvisioningConnectorId,
    };
    const queryParams = { realmId: retrieveData("realmId", true) };
    try {
      const response: any = await provisioningConnectorDetails("", queryParams, params);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data?.data;
        const isPublished = data?.publishStatus || false;

        dispatch(setIsPublished(isPublished));

        await handleConnectorDetailsSuccess(data);
        await handleProvisionPrerequisites(data);
        await handleDeProvisionPrerequisites(data);

        dispatch(setIsGroupProvisionConfigured(false));
        dispatch(setIsRoleProvisionConfigured(false));

        // Reset button states in Redux store
        dispatch(setAuthenticationNextButtonClicked(false));
        dispatch(setProvisioningBackButtonClicked(false));
        dispatch(setDeProvisioningBackButtonClicked(false));

        // Reset endpoint configuration IDs in Redux store
        dispatch(setProvisioningConnectorEndpointConfigId(""));
        dispatch(setDeProvisionConnectorEndpointConfigId(""));
      }

      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  // Function to handle prerequisites (deprovisioning and provisioning setup)
  const handleProvisionPrerequisites = async (data: any) => {
    let userProvisionData: any = [];
    let roleGetProvisionData: any = [];
    let roleAssignProvisionData: any = [];

    let groupGetProvisionData: any = [];
    let groupAssignProvisionData: any = [];

    try {
      if (data?.endPointResponseDtos.length > 0) {
        const userProvisionPromises = data.endPointResponseDtos.map(async (endpoint: any) => {
          const headerParameters = convertObjectToArray(endpoint?.headerParameters);
          const pathVariable = convertObjectToArray(endpoint?.pathVariable);

          // Initialize deprovisioning process

          const initiateUserProvisioning = await initializeUserProvisioning(provisionDetails, endpoint, headerParameters, pathVariable);

          if (initiateUserProvisioning) {
            return initiateUserProvisioning.userProvision;
          }
        });

        const roleGetProvisionPromises = data.endPointResponseDtos.map(async (endpoint: any) => {
          const headerParameters = convertObjectToArray(endpoint?.headerParameters);
          const pathVariable = convertObjectToArray(endpoint?.pathVariable);

          // Initialize provisioning process
          const initiategetRoleProvisioning = await initializeGetRoleProvisioning(provisionDetails, endpoint, headerParameters, pathVariable);

          if (initiategetRoleProvisioning) {
            return initiategetRoleProvisioning.getRole;
          }
        });

        const roleAssignProvisionPromises = data.endPointResponseDtos.map(async (endpoint: any) => {
          const headerParameters = convertObjectToArray(endpoint?.headerParameters);
          const pathVariable = convertObjectToArray(endpoint?.pathVariable);

          // Initialize provisioning process
          const initiateAssignRoleProvisioning = await initializeAssignRoleProvisioning(provisionDetails, endpoint, headerParameters, pathVariable);

          if (initiateAssignRoleProvisioning) {
            return initiateAssignRoleProvisioning.assignRole;
          }
        });

        const groupGetProvisionPromises = data.endPointResponseDtos.map(async (endpoint: any) => {
          const headerParameters = convertObjectToArray(endpoint?.headerParameters);
          const pathVariable = convertObjectToArray(endpoint?.pathVariable);

          // Initialize provisioning process
          const initiateGetGroupProvisioning = await initializeGetGroupProvisioning(provisionDetails, endpoint, headerParameters, pathVariable);

          if (initiateGetGroupProvisioning) {
            return initiateGetGroupProvisioning.getGroup;
          }
        });

        const groupAssignProvisionPromises = data.endPointResponseDtos.map(async (endpoint: any) => {
          const headerParameters = convertObjectToArray(endpoint?.headerParameters);
          const pathVariable = convertObjectToArray(endpoint?.pathVariable);

          // Initialize provisioning process
          const initiateAssignGroupProvisioning = await initializeAssignGroupProvisioning(provisionDetails, endpoint, headerParameters, pathVariable);

          if (initiateAssignGroupProvisioning) {
            return initiateAssignGroupProvisioning.assignGroup;
          }
        });

        // Wait for all promises to complete
        const userProvisions = await Promise.all(userProvisionPromises);
        const roleGetProvisions = await Promise.all(roleGetProvisionPromises);
        const roleAssignProvisions = await Promise.all(roleAssignProvisionPromises);

        const groupGetProvisions = await Promise.all(groupGetProvisionPromises);
        const groupAssignProvisions = await Promise.all(groupAssignProvisionPromises);

        // Flatten arrays if needed (assuming multiple responses per endpoint)
        userProvisionData = userProvisions.flat().filter(Boolean);
        roleGetProvisionData = roleGetProvisions.flat().filter(Boolean);
        roleAssignProvisionData = roleAssignProvisions.flat().filter(Boolean);

        groupGetProvisionData = groupGetProvisions.flat().filter(Boolean);
        groupAssignProvisionData = groupAssignProvisions.flat().filter(Boolean);

        const provisionPayload = {
          ...provisionDetails,
          ...(userProvisionData.length > 0 && { userProvision: userProvisionData }),
          ...(roleGetProvisionData.length > 0 && { getRole: roleGetProvisionData }),
          ...(roleAssignProvisionData.length > 0 && { assignRole: roleAssignProvisionData }),
          ...(groupGetProvisionData.length > 0 && { getGroup: groupGetProvisionData }),
          ...(groupAssignProvisionData.length > 0 && { assignGroup: groupAssignProvisionData }),
        };

        // console.log("provisionPayload:", provisionPayload);
        dispatch(setProvisioning(provisionPayload));
      }
    } catch (error) {
      console.error("Error during provisioning data setup:", error);
    }
  };

  const handleDeProvisionPrerequisites = async (data: any) => {
    let userDeProvisionData: any = [];
    let roleDeProvisionData: any = [];
    let groupDeProvisionData: any = [];

    try {
      if (data?.endPointResponseDtos.length > 0) {
        const userDeProvisionPromises = data.endPointResponseDtos.map(async (endpoint: any) => {
          const headerParameters = convertObjectToArray(endpoint?.headerParameters);
          const pathVariable = convertObjectToArray(endpoint?.pathVariable);

          // Initialize deprovisioning process
          const initiateUserDeProvisioning = await initializeUserDeprovisioning(deProvisionDetails, endpoint, headerParameters, pathVariable);
          if (initiateUserDeProvisioning) {
            return initiateUserDeProvisioning.userDeProvision;
          }
        });

        const roleDeProvisionPromises = data.endPointResponseDtos.map(async (endpoint: any) => {
          const headerParameters = convertObjectToArray(endpoint?.headerParameters);
          const pathVariable = convertObjectToArray(endpoint?.pathVariable);

          // Initialize provisioning process
          const initiateRoleDeProvisioning = await initializeRoleDeprovisioning(deProvisionDetails, endpoint, headerParameters, pathVariable);
          if (initiateRoleDeProvisioning) {
            return initiateRoleDeProvisioning.roleDeProvision;
          }
        });

        const groupDeProvisionPromises = data.endPointResponseDtos.map(async (endpoint: any) => {
          const headerParameters = convertObjectToArray(endpoint?.headerParameters);
          const pathVariable = convertObjectToArray(endpoint?.pathVariable);

          // Initialize provisioning process
          const initiateGroupDeProvisioning = await initializeGroupDeprovisioning(deProvisionDetails, endpoint, headerParameters, pathVariable);
          if (initiateGroupDeProvisioning) {
            return initiateGroupDeProvisioning.groupDeProvision;
          }
        });

        // Wait for all promises to complete
        const userDeProvisions = await Promise.all(userDeProvisionPromises);
        const roleDeProvisions = await Promise.all(roleDeProvisionPromises);
        const groupDeProvisions = await Promise.all(groupDeProvisionPromises);

        // Flatten arrays if needed (assuming multiple responses per endpoint)
        userDeProvisionData = userDeProvisions.flat().filter(Boolean);
        roleDeProvisionData = roleDeProvisions.flat().filter(Boolean);
        groupDeProvisionData = groupDeProvisions.flat().filter(Boolean);

        const deProvisionPayload = {
          ...deProvisionDetails,
          ...(userDeProvisionData.length > 0 && { userDeProvision: userDeProvisionData }),
          ...(roleDeProvisionData.length > 0 && { roleDeProvision: roleDeProvisionData }),
          ...(groupDeProvisionData.length > 0 && { groupDeProvision: groupDeProvisionData }),
        };

        // console.log("deProvisionPayload:", deProvisionPayload);
        dispatch(setDeProvisioning(deProvisionPayload));
      }
    } catch (error) {
      console.error("Error during provisioning data setup:", error);
    }
  };

  // Function to handle the successful retrieval of connector details
  const handleConnectorDetailsSuccess = (data: any) => {
    const filteredList = data?.endPointResponseDtos?.filter((dto: any) => dto?.endpointDescription === "token_validator");
    const { name, description, type, isDefaultSourceConnector, authenticationType, isActive, tokenType, spDomain } = data;
    const authenticationDetails = connectorAuthenticationReduxDetails(data);
    const finalAuthenticationDetails = {
      ...authenticationDetails,
      tokenApiEndpoint: filteredList?.[0]?.apiEndpointUrl,
      tokenMethodType: filteredList?.[0]?.methodType,
    };
    dispatch(setConnectorAuthenticationEndpointConfigId(filteredList?.[0]?.akkuProvisioningConnectorEndpointConfigId));
    const storeName = provisioning?.isDuplicate ? name + ` - (Copy)` : name;
    const generalDetails = {
      name: storeName,
      description,
      type,
      isDefaultSourceConnector: isDefaultSourceConnector ? "yes" : "No",
      authenticationType,
      isActive,
      tokenType: tokenType ? tokenType : "",
      spDomain,
    };

    // Set the form's initial values and dispatch these details to Redux store
    setInitialValues(generalDetails);
    dispatch(setIsActive(isActive));
    dispatch(setGeneralDetails(generalDetails));
    dispatch(setAuthentication(finalAuthenticationDetails));
    dispatch(setEndPointResponseDtosLen(data?.endPointResponseDtos.length));
    dispatch(setEndPointResponseDtosData(data?.endPointResponseDtos));
  };

  return (
    <div className="w-full px-10 pb-20 ">
      <p className="app-header pt-8">
        {t("appManagement.provisioning.connectorDetails")} - {t("appManagement.provisioning.generalDetails")} {provisioning?.isDuplicate ? `${t("appManagement.copy")}` : null}
      </p>
      <div className="w-full mx-auto overflow-y-auto">
        <Formik initialValues={initialValues} values={initialValues} enableReinitialize={true} validationSchema={createAppSchema(t)} onSubmit={handleSubmit} innerRef={formikRef}>
          {({ values, setFieldValue, dirty }) => {
            dispatch(setConnectorDetailDirty(dirty));
            return (
              <Form>
                <div className="w-full pt-10  app-connector flex-wrap overflow-y-auto">
                  <div className="w-full   pb-32 xl:pb-0 app-connector flex-wrap connector-details overflow-y-auto ">
                    <div className="mb-12 w-[45%] input-container relative">
                      <p>{t("appManagement.provisioning.connectorName")} *</p>
                      <Field as={Input} type="text" className="form-type-box capitalize" name="name" data-testid="usersN-input" />
                      <ErrorMessage name="name" component="div" className="error-message" />
                    </div>
                    <div className="mb-12 w-[45%] input-container relative">
                      <p>{t("appManagement.provisioning.description")} *</p>
                      <Field as={Input} type="text" className="form-type-box" name="description" data-testid="usersDn-input" />
                      <ErrorMessage name="description" component="div" className="error-message" />
                    </div>
                    <div className="mb-12 w-[45%] input-container relative">
                      <p>{t("appManagement.provisioning.serviceProviderDomain")}</p>
                      <Field as={Input} type="text" className="form-type-box" name="spDomain" data-testid="spDomainN-input" />
                      <ErrorMessage name="spDomain" component="div" className="error-message" />
                    </div>

                    <div className="mb-12 w-[45%] input-container relative">
                      <p>{t("appManagement.provisioning.connectorType")}*</p>
                      <Field
                        as={Select}
                        disabled
                        placeholder="Select"
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        className=" w-[100%] h-[56px]"
                        name="type"
                        value={values.type || undefined}
                        filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={(value: any) => setFieldValue("type", value)}
                      >
                        {connectorType?.map((option: any) => (
                          <Select.Option key={option.value} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name="type" component="div" className="error-message" />
                    </div>

                    {values?.type?.trim() === "RESTAPI" ? (
                      <div className="mb-12 w-[45%] input-container relative">
                        <p>{t("appManagement.provisioning.authenticationType")}*</p>
                        <Field
                          as={Select}
                          suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                          className="w-[100%] h-[56px]"
                          name="authenticationType"
                          value={values.authenticationType || undefined}
                          filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          onChange={(value: any) => setFieldValue("authenticationType", value)}
                          placeholder="Select"
                        >
                          {authenticationType?.map((option: any) => (
                            <Select.Option key={option.value} value={option.value}>
                              {option.label}
                            </Select.Option>
                          ))}
                        </Field>
                        <ErrorMessage name="authenticationType" component="div" className="error-message" />
                      </div>
                    ) : null}

                    <div className="mb-12 w-[45%] input-container relative">
                      <p>{t("appManagement.provisioning.tokenType")}</p>
                      <Field
                        as={Select}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        className="w-[100%] h-[56px]"
                        name="tokenType"
                        value={values.tokenType || undefined}
                        filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={(value: any) => setFieldValue("tokenType", value)}
                        placeholder="Select"
                      >
                        {tokenType?.map((option: any) => (
                          <Select.Option key={option.value} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name="tokenType" component="div" className="error-message" />
                    </div>

                    {/* <div className="w-full app-radio flex">
                      <p>Is this a default data source connector?</p>
                      <div className="flex app-type">
                        <Radio.Group
                          onChange={(e) => {
                            setFieldValue("isDefaultSourceConnector", e.target.value);
                          }}
                          value={values.isDefaultSourceConnector}
                        >
                          {defaultConnector?.map((filter: any) => (
                            <div key={filter?.value}>
                              <Radio value={filter?.value}>{filter?.text}</Radio>
                            </div>
                          ))}
                        </Radio.Group>
                      </div>
                    </div> */}
                  </div>
                  {/* <>{initialValues.connectorType === "REST_API" ? <RestApiForm /> : <DBConnectorForm initialValues={initialValues} setFieldValue={setFieldValue} />}</> */}
                </div>
                <div className="footer flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
                  <div className="modal-footer w-full mx-auto ">
                    <div className="w-full flex justify-end pr-5">
                      <CustomButtonBack onClick={handleBackToUser} text={t("common.back")} />
                      <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn ">
                        {t("common.next")}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {loader && <Loader />}
    </div>
  );
};
export default ConnectorDetails;
